import React from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { poisActions } from './../../actions';
import { PoiDetail } from './../../components/PoiCard/PoiDetail';
import { withTranslation } from 'react-i18next';
class PoiDetailPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idItem: this.props.match.params.id,
            vista: this.props.match.params.vista
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(poisActions.getPoiDetail(this.state.idItem));      
    }
    
    render() {
        const { poiDetail } = this.props;
        const { vista } = this.state;
        return (
            <Container>
                {poiDetail && <PoiDetail poi={poiDetail} vista={vista} />}
            </Container>
        )
    }

}

const mapStateToProps = (state) => {
    const { pois } = state;
    const { poiDetail } = pois;
    return {
        poiDetail,
    };
}

const tPoiDetailPage = withTranslation()(PoiDetailPage);
const connectedPoiDetailPage = connect(mapStateToProps)(tPoiDetailPage);
export { connectedPoiDetailPage as PoiDetailPage };