import './SurveyDetail.css';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { forwardTo } from '../../helpers';
import { QuestionCard } from './QuestionCard';
import { SurveySection } from './SurveySection';
/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDay, faAsterisk } from '@fortawesome/free-solid-svg-icons'

export const SurveyDetail = props => {
    const { t } = useTranslation();
    const { survey, user, handleSubmitPage } = props;
    // const shareUrl = `https://gecorweb.com/survey-detail/${survey.EncuestaID}`;
    
    const [active, setSectionActive] = React.useState(survey.Secciones ? survey.Secciones[0].SeccionID : 0);
    const [isValid, setIsValid] = React.useState(true);
    const [respuestas, setRespuestas] = React.useState([]);
    const moveSection = (sectionID) => { window.scrollTo(0, 0); setSectionActive(sectionID);}

    // useEffect(() => { setSectionActive(survey.Secciones ? survey.Secciones[0].SeccionID : 0) }, [])

    const handleSubmit = () => {
        handleSubmitPage(survey.EncuestaID, respuestas);
        forwardTo('/surveys');
    }

    const addRespuesta = (respuesta, value) => {          
        //return respuesta.includes(value) ? respuesta.filter(item => item != value) : respuesta.concat([Object.create(value)]);
        return respuesta.includes(value) ? respuesta.filter(item => item != value) : respuesta.concat([value]);
    };

    const getRespuesta = (preguntaID) => {
        let resp = (respuestas.filter(item => item.preguntaID == preguntaID))[0];
        return resp && resp.respuesta;
    };

    const onChange = (PreguntaID, type, value, oldPreguntasID) => {     
        const OldPreguntasIDaux=[ ...(oldPreguntasID || []), ...[PreguntaID]];
        const resp = respuestas.filter(item => item.preguntaID == PreguntaID);
        const respuestasAux = respuestas.filter(item => !OldPreguntasIDaux.includes(item.preguntaID)); 
    
        let respuestaVal = type != 6 ? value : addRespuesta(resp.length > 0 ? resp[0].respuesta : [], value);
            /*
            resp = {
                preguntaID: PreguntaID,
                tipoID: type,
                respuesta: respuestaVal
            };
            */
            //setRespuestas(respuestasAux.concat(resp));
        const fancyWayConcat = [...respuestasAux, { preguntaID: PreguntaID, tipoID: type, respuesta: respuestaVal }];
        setRespuestas(fancyWayConcat);
     };

    const tieneRespuesta = (preguntaID) => {
        var resp = respuestas.filter(item => item.preguntaID == preguntaID);
        return resp.length > 0;
    };

    const validate = () => {
        let valido = true;
        survey.Preguntas && survey.Preguntas.map(question =>
            valido = valido && (!question.Obligatoria || tieneRespuesta(question.PreguntaID)));
        setIsValid(valido);
        return valido;
    };

    const validateSeccion = (seccionID) => {
        let valido = true;
        let section = survey.Secciones.filter(item => item.SeccionID == seccionID);
        section = section.length > 0 && section[0];
        section.Preguntas.map(question =>
            valido = valido && (!question.Obligatoria || tieneRespuesta(question.PreguntaID)));
        setIsValid(valido);
        return valido;
    };

    const goBack = () => {
        setIsValid(true);
        return true;
    }

    return (
        <div id="survey-detail">
            <div className="img-head-detail-survey">
                <img className="img-head-detail"   src={survey.RutaFoto} alt="ruta foto"/>
            </div>
            <Container>
            <Row>
                <Col md={12}>
                    <div className="survey">
                        <div className="header">
                            {window.innerWidth > 768 && < Row className="row-hed-survey">
                                <Col sm={2}>
                                    {/*<Button className="back-survey-btn" onClick={() => forwardTo('/surveys')}>{t('survey.p5')}</Button>     */}                          
                                </Col>
                                <Col sm={10} md={10} lg={10}>
                                    <div className="header-title">{survey.Titulo}</div>
                                </Col>
                            </Row>}
                            {window.innerWidth <= 768 && <Row className="row-hed-survey">
                                <Col xs={6}>
                                    <Button className="back-survey-btn" onClick={() => forwardTo('/surveys')}>{t('survey.p5')}</Button>
                                </Col>
                                <Col xs={12}>
                                    <div className="header-title">{survey.Titulo}</div>
                                </Col>
                            </Row>}
                        </div>
                        < Row>
                            <Col xs={12} md={8}>
                                <div className="info">
                                    <div className="info-desc">{t('survey.p7')}: {survey.Responsable}</div>
                                    {!survey.Completado && <div className="info-desc"> <FontAwesomeIcon icon={faCalendarDay} />{t('survey.p4')} {survey.FechaFin}</div>}
                                    {survey.Completado && <div className="info-desc"> <FontAwesomeIcon icon={faCalendarDay} />{t('survey.p6')} {survey.Fecha}</div>}
                                    <div className="info-desc">
                                        {survey.Descripcion && survey.Descripcion.split('\n').map(function (item, key) {
                                            return (
                                                <span key={key}>
                                                    {item}
                                                    <br />
                                                </span>
                                            )
                                        })}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Form onSubmit={handleSubmit}>
                            {survey.Preguntas && <div className="questions">
                                {survey.Preguntas.map(question =>
                                    <QuestionCard
                                        key={question.PreguntaID}
                                        type={question.TipoID}
                                        question={question}
                                        user={user}
                                        isSubmitting={survey.Completado}
                                        onChange={onChange}
                                        getRespuesta={getRespuesta}
                                    />)}
                            </div>}
                            {survey.Secciones && survey.Secciones.map((section, index) =>
                                (section.SeccionID == active) && <div className="section" key={section.SeccionID}>
                                    <SurveySection
                                        key={section.SeccionID}
                                        section={section}
                                        user={user}
                                        isSubmitting={survey.Completado}
                                        onChange={onChange}
                                        getRespuesta={getRespuesta}
                                    />
                                    <div className="container-button center">
                                        {index != 0 && <Button
                                            className="survey-btn float-left"
                                            waves="light"
                                            onClick={() => goBack() && moveSection(survey.Secciones[index - 1].SeccionID)} >
                                            {t("survey.prev")}
                                        </Button>}
                                        {index != (survey.Secciones.length - 1) && <Button
                                            className="survey-btn float-right"
                                            waves="light"
                                            onClick={() => (survey.Completado || validateSeccion(survey.Secciones[index].SeccionID)) && moveSection(survey.Secciones[index + 1].SeccionID)} >
                                            {t("survey.next")}
                                        </Button>}
                                        {index == (survey.Secciones.length - 1) && !survey.Completado && <Button
                                            className="survey-btn float-right"
                                            waves="light"
                                            onClick={() => validateSeccion(survey.Secciones[index].SeccionID) && handleSubmit()} >
                                            {t("survey.env")}
                                        </Button>}
                                    </div>
                                </div>
                            )}
                            {!isValid && <div className="error-message">
                                {t("survey.obligatory")}<FontAwesomeIcon className="icon-ob" color="red" icon={faAsterisk} />
                            </div>}
                            <div className="container-button center">
                                {!survey.Completado && !survey.Secciones &&
                                    <Button
                                        className="survey-btn center"
                                        waves="light"
                                        onClick={() => validate() && handleSubmit()}>
                                        {t("survey.env")}
                                    </Button>
                                }
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
            </Container>
        </div >
    );
}

export default SurveyDetail;