import { surveysConstants } from '../constants';
import { surveysApi } from './../helpers';
import { createSelector } from "reselect";

// The initial application state
let initialState = {
    surveyQuery: "",
    mySurveys: surveysApi.loadSurveys(), 
}

//Filter surveys

//Filter surveys
const surveysSelector = state => state.surveys.mySurveys ? state.surveys.mySurveys.Pendientes : [];
const searchTermSelector = state => state.surveys.surveyQuery;
export const filteredSurveys = createSelector(
  [surveysSelector, searchTermSelector],
  (surveys, surveyQuery) => {
    if (!surveys) return undefined;
    if (!surveyQuery || surveyQuery.length < 2) return surveys;
    return surveys.filter(
    survey =>
    survey.Titulo.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .indexOf(surveyQuery.toLowerCase()) != -1
    );
  }
);

//Filter surveys conducted
const surveysSelectorConducted = state => state.surveys.mySurveys ? state.surveys.mySurveys.Realizadas : [];
export const filteredSurveysConducted = createSelector(
  [surveysSelectorConducted, searchTermSelector],
  (surveys, surveyQuery) => {
    if (!surveys) return undefined;
    if (!surveyQuery || surveyQuery.length < 2) return surveys;
    return surveys.filter(
    survey =>
    survey.Titulo.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .indexOf(surveyQuery.toLowerCase()) != -1
    );
  }
);


// Takes care of changing the application state
export const surveys = (state = initialState, action) => {
    switch (action.type) {
        case surveysConstants.SEARCH_SURVEYS:
            return { ...state, surveyQuery: action.surveyQuery }
        case surveysConstants.SEARCH_SURVEYS_CLEAR:
            return { ...state, surveyQuery: "" }
        case surveysConstants.GET_MY_SUCCESS:
            const mySurveys = action.mySurveys
            return { ...state, mySurveys }
        case surveysConstants.GET_SURVEY_DETAIL_SUCCESS:
            return { ...state, surveyDetail: action.surveyDetail }            
        case surveysConstants.SEND_SURVEY_SUCCESS:
          return { ...state, surveyDetail: action.sendSurvey }
          case surveysConstants.SURVEY_CHANGE_VIEW:
            return { ...state, vista: action.vista }
        default:
            return state
    }
}

export default surveys