import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { forwardTo } from '../../helpers'
import './header.css';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faHandHolding, faInfoCircle, faPaw, faQuestionCircle, faSeedling } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

import { useTranslation } from 'react-i18next';
import { NavDropdown } from 'react-bootstrap';

export const Header_143e = props => {
  const {entity } = props;  
  const { t } = useTranslation();

  return (
    <div id="header">
        <Navbar className="menu " sticky="top" expand="lg">
        <Navbar.Brand className="iconBrand_143" target="_blank" rel="noopener noreferrer" href="https://www.torremolinos.es/"><img alt="logo Torremolinos" className="img-header_143" src={entity.Logo} /></Navbar.Brand>
          <Navbar.Toggle className="buttomToggle" aria-controls="basic-navbar-nav" ></Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            </Nav>

            <Nav className="menu2" >
              <Nav.Link onClick={() => forwardTo('/menu-ambiental-torremolinos')}><FontAwesomeIcon icon={faSeedling} />{t("nav.nav9")}</Nav.Link>
            </Nav>

            <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faSeedling} />{t("nav.nav9")}</>} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => forwardTo('/menu-ambiental-torremolinos')}><FontAwesomeIcon icon={faSeedling} />{t("nav.nav1")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/informacion-local-ambiental')}><FontAwesomeIcon icon={faInfoCircle} />{t("nav.nav9-1c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/enviromental-information')}><FontAwesomeIcon icon={faPaw} />{t("nav.nav9-2c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/educacion-ambiental')}><FontAwesomeIcon icon={faGraduationCap} />{t("nav.nav9-3c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/practicas-medio-ambiente')}><FontAwesomeIcon icon={faHandHolding} />{t("nav.nav9-4c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/contact')}><FontAwesomeIcon icon={faQuestionCircle} />{t("nav.nav9-5c")}</NavDropdown.Item>
            {/* <NavDropdown.Item onClick={() => forwardTo('/nearby')}><FontAwesomeIcon icon={faBook} />{t("nav.nav9-6c")}</NavDropdown.Item> */}
          </NavDropdown>
          </Navbar.Collapse>
        </Navbar>
    </div>
      );
    }
    
    export default Header_143e;