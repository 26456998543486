import React from 'react';
import '../CitiesPage/CitiesPage.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faCogs, faFilter, faChartLine, faCheck } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

export const SectionModules = props => {
    const { t } = useTranslation();

    return (
        <div id="section-modules">
            <Container>
                <h1 className="card-titulo">{t('cities.title4')}</h1>
                <Row>
                    <Col md={3} className="col-card-module">
                        <div className="card">
                            <FontAwesomeIcon icon={faFilter} size="4x" />
                            <h2>{t('cities.title4-1')}</h2>
                            <div className="info">
                                <p>{t('cities.title4-1-t')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className="col-card-module">
                        <div className="card">
                            <FontAwesomeIcon icon={faCogs} size="4x" />
                            <h2>{t('cities.title4-2')}</h2>
                            <div className="info">
                                <p>{t('company.card-module1')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className="col-card-module">
                        <div className="card">
                            <FontAwesomeIcon icon={faChartLine} size="4x" />
                            <h2>{t('cities.title4-3')}</h2>
                            <div className="info">
                                <p>{t('cities.title4-3-t')}</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className="col-card-module">
                        <div className="card">
                            <FontAwesomeIcon icon={faCheck} size="4x" />
                            <h2>{t('cities.title4-4')}</h2>
                            <div className="info">
                                <p>{t('company.card-module2')}</p>
                            </div>
                        </div>
                    </Col>
                </Row>





            </Container>
        </div>
    );
}

export default SectionModules;