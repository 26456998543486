import React from 'react';
import './GweetPage.css';
import Container from 'react-bootstrap/Container';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import { isWebpSupported } from 'react-image-webp/dist/utils';

export const SectionProcess = props => {
    const { t } = useTranslation();

    return (
        <div id="section-process">
            <Container>
                <CardDeck>
                    <Card>
                        <div className="gweet-top-bg-1">
                            <br />
                            <h5><b>{t('gweet.card1-p1')}</b></h5>
                            <h1><b>01</b></h1>
                        </div>
                        <Card.Body>
                            <div className="title-gweet-card">
                                <b>{t('gweet.card1-p2')}</b>
                            </div>
                            <Card.Text as="div">
                                <div className="card-img-gweet">
                                    <img src={isWebpSupported() ? "https://gecorcloud.com/images/icon-card-gweet-1.webp" : "https://gecorcloud.com/images/icon-card-gweet-1.png"} alt="grafic 1" />
                                </div>
                                <p>{t('gweet.card1-p3')}</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card>
                        <div className="gweet-top-bg-2">
                            <br />
                            <h5><b>{t('gweet.card2-p1')}</b></h5>
                            <h1><b>02</b></h1>
                        </div>
                        <Card.Body>
                            <div className="title-gweet-card">
                                <b>{t('gweet.card2-p2')}</b>
                            </div>
                            <Card.Text as="div">
                                <div className="card-img-gweet">
                                    <img src={isWebpSupported() ? "https://gecorcloud.com/images/icon-card-gweet-2.webp" : "https://gecorcloud.com/images/icon-card-gweet-2.png"} alt="grafic 2" />
                                </div>
                                <p>{t('gweet.card2-p3')}</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card>
                        <div className="gweet-top-bg-3">
                            <br />
                            <h5><b>{t('gweet.card3-p1')}</b></h5>
                            <h1><b>03</b></h1>
                        </div>
                        <Card.Body>
                            <div className="title-gweet-card">
                                <b>{t('gweet.card3-p2')}</b>
                            </div>
                            <Card.Text as="div">
                                <div className="card-img-gweet">
                                    <img src={isWebpSupported() ? "https://gecorcloud.com/images/icon-card-gweet-3.png" : "https://gecorcloud.com/images/icon-card-gweet-3.png"} alt="grafic 3" />
                                </div>
                                <p>{t('gweet.card3-p3')}</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card>
                        <div className="gweet-top-bg-4">
                            <br />
                            <h5><b>{t('gweet.card4-p1')}</b></h5>
                            <h1><b>04</b></h1>
                        </div>
                        <Card.Body>
                            <div className="title-gweet-card">
                                <b>{t('gweet.card4-p2')}</b>
                            </div>
                            <Card.Text as="div">
                                <div className="card-img-gweet">
                                    <img src={isWebpSupported() ? "https://gecorcloud.com/images/icon-card-gweet-4.png" : "https://gecorcloud.com/images/icon-card-gweet-4.png"} alt="grafic 4" />
                                </div>
                                <p>{t('gweet.card4-p3')}</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </CardDeck>
            </Container>
        </div>
    );
}

export default SectionProcess;