export const noticesConstants = {
    NOTICES_GETALL_REQUEST: 'NOTICES_GETALL_REQUEST',
    NOTICES_GETALL_SUCCESS: 'NOTICES_GETALL_SUCCESS',
    NOTICES_GETALL_FAILURE: 'NOTICES_GETALL_FAILURE',

    NOTICES_GETBYID_REQUEST: 'NOTICES_GETBYID_REQUEST',
    NOTICES_GETBYID_SUCCESS: 'NOTICES_GETBYID_SUCCESS',
    NOTICES_GETBYID_FAILURE: 'NOTICES_GETBYID_FAILURE',

    NOTICES_GETALL_LOCAL: 'NOTICES_GETALL_LOCAL',
    NOTICES_CLEAR: 'NOTICES_CLEAR'
}