import { take, call, put, fork } from 'redux-saga/effects'
import { difusionesApi } from '../helpers'
import { difusionesConstants } from './../constants'



export function* getDifusionesByAytoID(payload) {
    try {
        const { data } = payload;
        const { AytoID, top, lastDifusionMensajeID } = data;
        const response = yield call(difusionesApi.getDifusionesByAytoID, AytoID, top, lastDifusionMensajeID)
        const difusiones = response

        yield put({ type: difusionesConstants.GET_DIFUSIONES_AYTOID_SUCCESS, difusiones })

        return difusiones
    } catch (error) {
        yield put({ type: difusionesConstants.GET_DIFUSIONES_AYTOID_FAILURE, error: error.message })
        return false
    }
}

export function* getDifusionesByAytoIDFlow() {
    while (true) {
        const payload = yield take(difusionesConstants.GET_DIFUSIONES_AYTOID_REQUEST)
        yield call(getDifusionesByAytoID, payload)
    }
}

export default function* () {
    yield fork(getDifusionesByAytoIDFlow)
}