import React from 'react';
import './CompanyPage.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrophy, faPuzzlePiece, faAddressCard, faHandshake, faCog, faNewspaper } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

export const SectionFeatures = props => {
    const { t } = useTranslation();

    return (
        <div id="section-features">
            <Container>
                {/*<h3 className="card-titulo">{t('cities.title2')}</h3>*/}
                <Row>
                    <Col md={4}>
                        {/*<Image className="img-beneficios" src="https://gecorcloud.com/images/trophy.webp" rounded />*/}
                        <FontAwesomeIcon icon={faTrophy} size="4x" />
                        <h4 dangerouslySetInnerHTML={{ __html: t('company.box2-t1') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: t('company.box2-p1') }}></p>
                    </Col>
                    <Col md={4}>
                        {/*<Image className="img-beneficios" src="https://gecorcloud.com/images/checklist.webp" rounded />*/}
                        <FontAwesomeIcon icon={faAddressCard} size="4x" />
                        <h4 dangerouslySetInnerHTML={{ __html: t('company.box2-t2') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: t('company.box2-p2') }}></p>
                    </Col>
                    <Col md={4}>
                        {/*<Image className="img-beneficios" src="https://gecorcloud.com/images/light-bulb.webp" rounded />*/}
                        <FontAwesomeIcon icon={faHandshake} size="4x" />
                        <h4 dangerouslySetInnerHTML={{ __html: t('company.box2-t3') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: t('company.box2-p3') }}></p>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        {/*<Image className="img-beneficios" src="https://gecorcloud.com/images/puzzle-piece-plugin.webp" rounded />*/}
                        <FontAwesomeIcon icon={faPuzzlePiece} size="4x" />
                        <h4 dangerouslySetInnerHTML={{ __html: t('company.box2-t4') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: t('company.box2-p4') }}></p>
                    </Col>
                    <Col md={4}>
                        {/*<Image className="img-beneficios" src="https://gecorcloud.com/images/settings-cogwheel-button.webp" rounded />*/}
                        <FontAwesomeIcon icon={faCog} size="4x" />
                        <h4 dangerouslySetInnerHTML={{ __html: t('company.box2-t5') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: t('company.box2-p5') }}></p>
                    </Col>
                    <Col md={4}>
                        {/*<Image className="img-beneficios" src="https://gecorcloud.com/images/magnifier.webp" rounded />*/}
                        <FontAwesomeIcon icon={faNewspaper} size="4x" />
                        <h4 dangerouslySetInnerHTML={{ __html: t('company.box2-t6') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: t('company.box2-p6') }}></p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SectionFeatures;