
import { createStore, applyMiddleware, compose } from 'redux';
// import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import DevTools from '../App/DevTools';

import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';

const initialiseSagaMiddleware = createSagaMiddleware();

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const loggerMiddleware = createLogger();

const store = createStore(
  rootReducer,
  storeEnhancers(
    applyMiddleware(initialiseSagaMiddleware),
    DevTools.instrument()
  )
);

initialiseSagaMiddleware.run(rootSaga);

export default store;
