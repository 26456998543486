import React from 'react';
import './HomePage.css';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { isWebpSupported } from 'react-image-webp/dist/utils';

export const SectionHeader = props => {
    const { t } = useTranslation();
    const t1 = t('home.schema');
    const t2 = t('home.schemar');

    return (
        <div id="section-header">
            <Container className="container-boton-nueva" >
                <h3 tabIndex="8">{t('home.title')}</h3>
                <h3><b tabIndex="9">{t('home.subtitle')}</b></h3>
                <Image src={isWebpSupported() ? t1 + ".webp" : t1 + ".png"} fluid alt="Planets" useMap="#planetmap" className="schema-nr" />
                <Image src={isWebpSupported() ? t2 + ".webp" : t2 + ".png"} fluid alt="Planets" useMap="#planetmap" className="schema-yr" />
                <Row className="text-home-head">
                    <Col md={4}>
                        <p>
                           <b tabIndex="10">{t('home.schema-p1')}</b>
                        </p>
                    </Col>
                    <Col md={4}>
                        <p>
                            <b tabIndex="11">{t('home.schema-p2')}</b>
                        </p>
                    </Col>
                    <Col md={4}>
                        <p>
                            <b tabIndex="12">{t('home.schema-p3')}</b>
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SectionHeader;