import React from "react";
import "./HomePage.css";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { forwardTo } from "../../helpers";

export const BannerNotice = props => {
  const { t } = props;

  return (
    <div id="banner-notice">
      <Container>
        <a
          rel="noopener noreferrer"
          href="#"
          onClick={e => {
            e.preventDefault();
            forwardTo("/notice");
          }}
        >
          <Button variant="secondary" size="lg">
            {t("home.box8-btn")}
          </Button>
        </a>
      </Container>
    </div>
  );
};

export default BannerNotice;
