import { poisConstants } from '../constants'
import { poisApi } from './../helpers';
import { createSelector } from "reselect";

// The initial application state
let initialState = {
    poiQuery: "",
    myPois: poisApi.loadPois(),
    categoriesPoi: poisApi.loadCategories(),     
}

//Filter pois
const poisSelector = state => state.pois.myPois;
const searchTermSelector = state => state.pois.poiQuery;
export const filteredPois = createSelector(
  [poisSelector, searchTermSelector],
  (pois, poiQuery) => {
    if (!pois) return undefined;
    if (!poiQuery || poiQuery.length < 2) return pois;
    return pois.filter(
      poi =>
      poi.title.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .indexOf(poiQuery.toLowerCase()) != -1
    );
  }
);


// Takes care of changing the application state
export const pois = (state = initialState, action) => {
    switch (action.type) {
        case poisConstants.SEARCH_POIS:
            return { ...state, poiQuery: action.poiQuery }
        case poisConstants.SEARCH_POIS_CLEAR:
            return { ...state, poiQuery: "" }
        case poisConstants.GET_MY_SUCCESS:
            const myPois = action.myPois;      
            return { ...state, myPois }
        case poisConstants.GET_POI_DETAIL_SUCCESS:
            return { ...state, poiDetail: action.poiDetail }
        case poisConstants.HOVERED_POI:
            return { ...state, poiHovered: action.poi }
        case poisConstants.GET_CATEGORIES_SUCCESS:            
            return { ...state, categoriesPoi: action.categoriesPoi }
        default:
            return state
    }
}

export default pois