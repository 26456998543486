import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import './PrivatePoliticsPage.css';

class PrivatePoliticsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {  
    return (
      <div id="politica-empresa" className="container">
            <div className="boxContact">
              <Card>
                <Card.Header as="h5">Política de Privacidad</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <div className="politics">
                      <div className="adviceBox">
                        <br></br>
                        <p>
                          Este texto legal le da detalles de cómo recopilamos y procesamos sus datos personales a través del uso de nuestro sitio web www.gecorweb.com, incluyendo cualquier información que pueda proporcionarnos a través del sitio cuando realiza la contratación de un servicio se registra a nuestro boletín informativo o proporciona sus datos de contacto a través del formulario habilitado para tal fin.
                        </p>
                        <br></br>
                        <p>Al proporcionarnos los datos, nos garantiza que tiene más de 18 años.</p>
                        <br></br>
                        <h4>1. Responsable del tratamiento.</h4>
                        <br></br>
                        <p>Datos de contacto del responsable: GECOR SYSTEM S.L.U.  con domicilio social en C/Alameda Principal, nº 21 Planta 6 Oficina 603. Málaga. CP 29001. Teléfono: 952028423. E-mail: smarin@gecorweb.com y C.I.F. B93218287</p>
                        <br></br>
                        <p>GECOR SYSTEM, S.L.U., es el responsable de sus datos. (En adelante nosotros o nuestro).</p>
                        <br></br>
                        <h4>2. ¿Qué datos recopilamos?</h4>
                        <br></br>
                        <p>Según el Reglamento General de Protección de Datos 679/2016/UE (RGPD), en su artículo 4, dato personal es toda información sobre una persona física identificada o identificable (interesado), es decir toda la información capaz de identificar a una persona. Esto no incluiría los datos anónimos.</p>
                        <br></br>
                        <p>Podemos procesar ciertos tipos de datos personales, que podrán incluir:</p>
                        <br></br>
                        <div className="legal-table">
                          <Table striped bordered hover variant="dark">
                            <tbody>
                              <tr>
                                <td>Datos de identidad: nombre, apellido y nombre de usuario.</td>
                              </tr>
                              <tr>
                                <td>Datos de contacto: email o dirección de facturación.</td>
                              </tr>
                              <tr>
                                <td>Datos técnicos: datos de inicio de sesión, direcciones de protocolo de Internet, tipo y versión de navegador, configuración y ubicación de la zona horaria, sistema operativo, tipos y versiones de plug-in de navegador, y cualquier otra tecnología en los dispositivos que usa para acceder a nuestra página web.</td>
                              </tr>
                              <tr>
                                <td>Datos de perfil: nombre de usuario y contraseña, comentarios y respuestas a encuestas.</td>
                              </tr>
                              <tr>
                                <td>Datos de uso: información sobre como usa nuestra web, productos y servicios.</td>
                              </tr>
                              <tr>
                                <td>Datos de marketing y comunicaciones: preferencias para recibir comunicaciones de marketing por nuestra parte y medio preferente de comunicación.</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <br></br>
                        <p>No recopilamos ningún dato relativo a categorías especiales de datos personales (aquellos que desvelen su origen étnico o racial, las opiniones políticas, las convicciones religiosas o filosóficas, la afiliación sindical e información sobre su salud, datos genéticos o biométricos).</p>
                        <br></br>
                        <p>En caso de que se le solicite recopilar datos personales por ley o según los términos de contratación entre nosotros y te niegues a facilitárnoslos, es posible que no podamos realizar dicho contrato o prestar el servicio, debiendo comunicárnoslo con antelación.</p>
                        <br></br>
                        <h4>2. ¿Cómo recopilamos sus datos personales?</h4>
                        <br></br>
                        <p>Los medios que usamos para recopilar datos personales son:</p>
                        <br></br>
                        <ul>
                          <li>A través del formulario de nuestra página web, a través de nuestros email de contacto, por teléfono o correo postal, cuando:
                            <ol>
                              <li>Solicita información de nuestros productos o servicios</li>
                              <li>Contrata la prestación de nuestros servicios o productos</li>
                              <li>Solicita presupuestos</li>
                              <li>Se suscribe a alguno de nuestros servicios o publicaciones</li>
                              <li>Envía sus comentarios</li>
                            </ol>
                          </li>
                        </ul>
                        <br></br>
                        <p>Para asegurar la calidad de nuestro portal, tenemos que reservamos el derecho de rechazar cualquier solicitud de registro o bien de suspender o cancelar un registro previamente aceptado si entendemos que no cumple estos requisitos o cualquier otra ley o norma. Si esto ocurre, intentaremos exponer los motivos de nuestra decisión, pero no nos podemos comprometer a hacerlo en todos los casos.</p>
                        <br></br>
                        <ul>
                          <li><b>A través de la tecnología o interacciones automatizadas:</b> en nuestro sitio podemos recopilar automáticamente datos técnicos sobre su equipo, acciones de navegación y patrones de uso. Estos datos son recopilados a través de cookies o tecnologías similares. Si quiere ampliar la información, puede consultar nuestra política de cookies aquí.</li>
                        </ul>
                        <br></br>
                        <ul>
                          <li><b>A través de terceros:</b>
                            <ol>
                              <li><b>Google:</b> datos analíticos o datos de búsqueda. Fuera de la Unión Europea.</li>
                              <li><b>Redes Sociales: (Facebook, Instagram, Twitter, Linkedin…)</b> fuera de la Unión Europea.</li>
                            </ol>
                          </li>
                        </ul>
                        <br></br>
                        <h4>4. Finalidad y legitimidad para el uso de sus datos.</h4>
                        <br></br>
                        <p>Los usos más comunes de sus datos personales son:</p>
                        <br></br>
                        <ul>
                          <li>Para la formalización de un contrato entre GECOR SYSTEM, S.L.U. y usted.</li>
                          <li>Cuando preste su consentimiento en el tratamiento de sus datos</li>
                          <li>Cuando los necesitemos para dar cumplimiento a una obligación legal o regulatoria</li>
                          <li>Cuando sea necesario para nuestro interés legítimo o de un tercero.</li>
                        </ul>
                        <br></br>
                        <p>El Usuario podrá revocar el consentimiento prestado en cualquier momento enviando un correo a smarin@gecorweb.com o consultando el apartado de ejercicio de derechos más adelante.</p>
                        <br></br>
                        <p>A continuación adjuntamos una tabla en la que pueden consultar las formas en las que vamos a utilizar sus datos personales y la legitimidad para su uso, además de saber qué tipo de datos personales vamos a tratar. Podemos procesar algún dato personal por algún motivo legal adicional, por lo que si necesita detalles al respecto puede enviar un correo electrónico a smarin@gecorweb.com</p>
                        <br></br>
                        <div >
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Finalidad</th>
                                <th>Tipos de datos</th>
                                <th>Legitimidad para su tratamiento</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Para solicitar información a través del formulario de contacto</td>
                                <td>
                                  <ul>
                                    <li>Nombre</li>
                                    <li>Apellidos</li>
                                    <li>Email</li>
                                    <li>Página web</li>
                                  </ul>
                                </td>
                                <td>Consentimiento del interesado</td>
                              </tr>
                              <tr>
                                <td>Para gestionar nuestra relación con usted</td>
                                <td>
                                  <ul>
                                    <li>Nombre</li>
                                    <li>Apellidos</li>
                                    <li>Email</li>
                                    <li>Domicilio</li>
                                    <li>Teléfono</li>
                                    <li>Empresa o autónomo</li>
                                  </ul>
                                </td>
                                <td>Formalización del contrato</td>
                              </tr>
                              <tr>
                                <td>Para realizar comunicaciones comerciales, entregándole contenido relevante y que le pueda resultar de interés</td>
                                <td>
                                  <ul>
                                    <li>Datos técnicos</li>
                                    <li>Nombre</li>
                                    <li>Apellidos</li>
                                    <li>Domicilio</li>
                                    <li>Email</li>
                                  </ul>
                                </td>
                                <td>Interés legítimo</td>
                              </tr>
                              <tr>
                                <td>Para prestarle la contratación del servicio o producto	</td>
                                <td>
                                  <ul>
                                    <li>Nombre</li>
                                    <li>Apellidos</li>
                                    <li>Email</li>
                                    <li>Doirección</li>
                                    <li>Teléfono</li>
                                    <li>Datos bancarios</li>
                                  </ul>
                                </td>
                                <td>Formalización de su contrato</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <br></br>
                        <p><b>Comunicaciones comerciales: solo recibirá comunicaciones si</b></p>
                        <br></br>
                        <ul>
                          <li>Nos solicitó información o realizó una contratación con nosotros de un producto o servicio.</li>
                          <li>Si nos proporcionó sus datos, aceptando la casilla habilitada al respecto en nuestro formulario.</li>
                          <li>Siempre y cuando no haya manifestado su voluntad de dejar de recibir dichas comunicaciones.</li>
                        </ul>
                        <br></br>
                        <p>Obtenemos su consentimiento expreso antes de mandarle cualquier comunicación, pudiendo solicitar en cualquier momento que dejemos de mandarle comunicaciones en el email smarin@gecorweb.com</p>
                        <br></br>
                        <p>Cuando opte por dejar de recibir nuestras comunicaciones, sus datos personales seguirán almacenados como resultado de la contratación realizada por usted para ajustarnos a las exigencias legales.</p>
                        <br></br>
                        <p><b>Finalidad:</b> sólo utilizaremos sus datos para los fines para los que los recopilamos, a menos que consideremos razonablemente que debemos usarlo para otro motivo, notificándoselo previamente para que esté informado del motivo legal de su procesamiento y siempre y cuando el fin sea compatible con el propósito original.</p>
                        <br></br>
                        <p><b>Plazo de conservación:</b> El periodo de conservación de los datos personales variará en función del servicio que el Cliente contrate, siendo el mínimo necesario, pudiendo mantenerse:</p>
                        <br></br>
                        <ul>
                          <li>6 años según el artículo 30 del Código de Comercio (libros de contabilidad, facturas…)</li>
                          <li>5 años según el artículo 1964 del Código Civil (acciones personales sin plazo especial)</li>
                          <li>12 meses según el artículo 5 de la ley 25/2007, de conservación de datos relativos a las comunicaciones electrónicas y a las redes públicas de comunicaciones.</li>
                          <li>4 años: Ley 5/2002, sobre Infracciones y Sanciones en el Orden social (obligaciones en materia de afiliación, altas, bajas, pago de salarios). Artículo 66 y siguientes. Ley General Tributaria (libros de contabilidad…)</li>
                        </ul>
                        <br></br>
                        <p><b>Datos de los suscriptores por e-mail o formulario:</b> Desde que el usuario se suscribe hasta que se dé de baja.</p>
                        <br></br>
                        <p><b>Datos de los usuarios subidos por GECOR SYSTEM, S.L.U. a sus Redes Sociales o páginas:</b> Desde que el usuario da su consentimiento hasta que lo retira.</p>
                        <br></br>
                        <h4>5. Sus Derechos en Protección de Datos</h4>
                        <br></br>
                        <p><b>¿Cómo ejercer dichos derechos?</b> Los usuarios podrán dirigir una comunicación al domicilio social de GECOR SYSTEM, S.L.U. o dirección de correo electrónico smarin@gecorweb.com, incluyendo en ambos casos fotocopia de su D.N.I u otro documento de identificación similar, para solicitar el ejercicio de los siguientes derechos:</p>
                        <br></br>
                        <ul>
                          <li><b>Acceso</b> a sus datos personales: podrá preguntar a GECOR SYSTEM, S.L.U. si está usando sus datos personales.</li>
                          <li>A solicitar su <b>rectificación</b>, si no fuesen correctos, o a ejercer el <b>derecho al olvido</b> con respecto a los mismos.</li>
                          <li>A solicitar la <b>limitación del tratamiento</b>, en este caso, solo serán conservados por GECOR SYSTEM, S.L.U. para el ejercicio o defensa de reclamaciones</li>
                          <li>A <b>oponerse</b> a su tratamiento: GECOR SYSTEM, S.L.U. dejará tratar los datos en la forma que nos indiques, salvo que por motivos legítimos o para el ejercicio o defensa de posibles reclamaciones, estos deban seguir tratándose.</li>
                          <li>A la <b>portabilidad</b> de los datos: en caso de que quieras que sus datos sean tratados por otra firma, GECOR SYSTEM, S.L.U., le facilitará la portabilidad de sus datos al nuevo responsable.</li>
                        </ul>
                        <br></br>
                        <p>Podrás utilizar los modelos puestos a su disposición por la Agencia Española de Protección de Datos, para ejercer sus anteriores derechos: <a href="https://www.aepd.es/reglamento/derechos/index.html" target="_blank" rel="noopener noreferrer" className="enlace" >Aquí</a></p>
                        <br></br>
                        <p>Reclamar ante la AEPD: si consideras que hay un problema con la forma en que GECOR SYSTEM, S.L.U. está tratando sus datos, podrá dirigir sus reclamaciones a la autoridad de control correspondiente, siendo en España, la competente para ello: Agencia Española de Protección de Datos.</p>
                        <br></br>
                        <p>Es posible que tengamos que solicitarte información específica para ayudarnos a confirmar su identidad y garantizar su derecho a acceder a sus datos personales (o ejercer cualquier otro de los derechos mencionados anteriormente). Esto es una medida de seguridad para garantizar que los datos personales no se revelen a ninguna persona que no tenga derecho a recibirlos.</p>
                        <br></br>
                        <p>Todas las solicitudes las solventamos en el plazo legal indicado 1 mes. No obstante, puede llevarnos más de un mes si su solicitud es particularmente compleja, o si ya ha realizado una serie de actuaciones previamente. En este caso, le notificaremos y le mantendremos actualizado.</p>
                        <br></br>
                        <h4>6. Cesión de datos personales</h4>
                        <br></br>
                        <p>Es posible que, en el desempeño de nuestro trabajo, debamos ceder sus datos a terceros:</p>
                        <br></br>
                        <ul>
                          <li>Proveedores de servicios que brindan servicios de administración de sistemas y tecnología de la información.</li>
                          <li>Asesores profesionales que incluyen abogados, auditores y aseguradores que brindan servicios de consultoría bancarios, legales, de seguros y de contabilidad.</li>
                        </ul>
                        <br></br>
                        <p>Todas estas cesiones le serán previamente comunicados y le manifestamos que requerimos todos los encargados de tratamiento a quienes transferimos sus datos respeten la seguridad de sus datos personales y los traten de acuerdo al RGPD. Solo permitimos que dichos encargados traten sus datos para fines determinados y de conformidad con nuestras instrucciones.</p>
                        <br></br>
                        <h4>7. Seguridad de Datos</h4>
                        <br></br>
                        <p>Hemos implementado las medidas de seguridad adecuadas para evitar que sus datos personales se pierdan accidentalmente, se usen o accedan de forma no autorizada, se modifiquen o divulguen. Además, limitamos el acceso a sus datos personales a aquellos empleados, agentes contratistas y otros terceros que tengan una necesidad comercial de conocer dichos datos. Solo procesarán sus datos personales según nuestras instrucciones y estarán sujetos a un deber de confidencialidad.</p>
                        <br></br>
                        <p>Hemos implementado procedimientos para tratar cualquier sospecha de violación de sus datos personales y se lo notificaremos a usted y a la Autoridad de Control en caso de que ocurriera, tal como queda regulado en el RGPD en sus artículos 33 y 34, una brecha de seguridad.</p>
                        <br></br>
                        <h4>8. Transferencias internacionales</h4>
                        <br></br>
                        <p>Los países fuera del Espacio Económico Europeo (EEE) no siempre ofrecen los mismos niveles de protección a sus datos personales, por lo que la legislación europea ha prohibido las transferencias de datos personales fuera del EEE a menos que la transferencia cumpla ciertos requisitos.</p>
                        <br></br>
                        <p>Algunos de nuestros proveedores de servicios externos se encuentran fuera del Espacio Económico Europeo (EEE), por lo que le procesamiento de sus datos personales implicará una transferencia de datos fuera de EEE.</p>
                        <br></br>
                        <ul>
                          <li>Redes Sociales: GECOR SYSTEM, S.L.U. hace uso de las redes sociales Facebook, Instagram, LinkedIn y Twitter, todas en EEUU.</li>
                          <li>Correo electrónico: El servicio de correo electrónico de GECOR SYSTEM, S.L.U. se presta usando los servicios de Thunderbird.</li>
                          <li>GECOR SYSTEM, S.L.U. cifra toda la información que recibe y genera con DigiCert Inc.</li>
                        </ul>
                        <br></br>
                        <p>Si necesita ampliación con respecto al mecanismo específico utilizado por nosotros al transferir sus datos fuera del EEE, puede ponerse en contacto con nosotros a través de nuestro correo electrónico smarin@gecorweb.com</p>
                        <br></br>
                        <br></br>
                        <p>Actualización: mayo 2018</p>
                      </div>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
    </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { entities } = state;
  const { entity } = entities;
  return {
    entity
  };
}

const connectedPrivatePoliticsPage = connect(mapStateToProps)(PrivatePoliticsPage);
export { connectedPrivatePoliticsPage as PrivatePoliticsPage };