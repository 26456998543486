import { difusionesConstants } from '../constants'
import { difusionesApi } from './../helpers';


let initialState = {
    difusiones: difusionesApi.loadDifusiones()
}

export const difusiones = (state = initialState, action) => {
    switch (action.type) {
        case difusionesConstants.GET_DIFUSIONES_AYTOID_SUCCESS:
            return { ...state, difusiones: action.difusiones }
        case difusionesConstants.GET_DIFUSIONES_AYTOID_FAILURE:
            return { ...state, difusiones: [] }
        default:
            return state
    }
}

export default difusiones
