import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Container, Image } from 'react-bootstrap'
import './CompanyPage.css';
import { isWebpSupported } from 'react-image-webp/dist/utils';

import { BannerCertificate } from '../../components';
import { withTranslation } from 'react-i18next';
import SectionVideo from './SectionVideo';
import SectionFeatures from './SectionFeatures';
import SectionSchema from './SectionSchema';
import SectionModules from './SectionModules';
import BannerContact from '../../components/BannerContact/BannerContact';
import SectionOpinions from './SectionOpinions';

class CompanyPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        const { t } = this.props;

        return (
            <div id="cities-page">
                <SectionVideo />
                <SectionFeatures />
                <SectionSchema />
                <SectionModules />
                <div id="section-modules-extended">
                    <Container>
                        <h3 className="card-titulo">{t('cities.title6')}</h3>
                        <Row>
                            <Col md={6}>
                                <div className="container-element">
                                    <Image src={isWebpSupported() ? "https://gecorcloud.com/images/gecor_capas.webp" : "https://gecorcloud.com/images/gecor_capas.png"} alt="app empresa"/>
                                    <div className="module-text">
                                        <h5>{t('cities.title6-1e')}</h5>
                                        <li>{t('cities.title6-1-1')}</li>
                                        <li>{t('cities.title6-1-2')}</li>
                                        <li>{t('cities.title6-1-3')}</li>
                                        <li>{t('cities.title6-1-4e')}</li>
                                    </div>
                                </div>
                                <div className="container-element">
                                    <Image src={isWebpSupported() ? "https://gecorcloud.com/images/obreros.webp" : "https://gecorcloud.com/images/obreros.jpg"} alt="app de tecnico"/>
                                    <div className="module-text">
                                        <h5>{t('cities.title6-2')}</h5>
                                        <li>{t('cities.title6-2-1')}</li>
                                        <li>{t('cities.title6-2-2')}</li>
                                        <li>{t('cities.title6-2-3')}</li>
                                        <li>{t('cities.title6-2-4')}</li>
                                    </div>
                                </div>
                                <div className="container-element">
                                    <Image src={isWebpSupported() ? "https://gecorcloud.com/images/gecor-modulos-aplicacion.webp" : "https://gecorcloud.com/images/gecor-modulos-aplicacion.jpg"} alt="web cliente"/>
                                    <div className="module-text">
                                        <h5>{t('cities.title6-3e')}</h5>
                                        <li>{t('cities.title6-3-1e')}</li>
                                        <li>{t('cities.title6-3-2')}</li>
                                        <li>{t('cities.title6-3-3')}</li>
                                        <li>{t('cities.title6-3-4')}</li>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="container-element">
                                    <Image src={isWebpSupported() ? "https://gecorcloud.com/images/flip-company-1.webp" : "https://gecorcloud.com/images/flip-company-1.jpg"} alt="cuadros de mando"/>
                                    <div className="module-text">
                                        <h5>{t('cities.title6-4')}</h5>
                                        <li>{t('cities.title6-4-1')}</li>
                                        <li>{t('cities.title6-4-2')}</li>
                                        <li>{t('cities.title6-4-3')}</li>
                                        <li>{t('cities.title6-4-4')}</li>
                                    </div>
                                </div>
                                <div className="container-element">
                                    <Image src={isWebpSupported() ? "https://gecorcloud.com/images/gweet-schema2.webp" : "https://gecorcloud.com/images/gweet-schema2.png"} alt="redes sociales"/>
                                    <div className="module-text">
                                        <h5>{t('cities.title6-5')}</h5>
                                        <li>{t('cities.title6-5-1')}</li>
                                        <li>{t('cities.title6-5-2')}</li>
                                        <li>{t('cities.title6-5-3')}</li>
                                        <li>{t('cities.title6-5-4')}</li>
                                    </div>
                                </div>
                                <div className="container-element">
                                    <Image src="https://gecorcloud.com/images/opendata.webp" alt="modulo de open data"/>
                                    {/* <Image src="https://www.linkedin.com/media-proxy/ext?w=1200&h=675&hash=rcutvbnvqTDNVwTzk3pQpgXW%2B7Y%3D&ora=1%2CaFBCTXdkRmpGL2lvQUFBPQ%2CxAVta5g-0R6plxVUzgUv5K_PrkC9q0RIUJDPBy-lUiWs_NWfZH7pf8HfZLSiolwVeikHmQw6d-2uRDjhEI69LcLmY4Yx3A" /> */}
                                    <div className="module-text">
                                        <h5>{t('cities.title6-6')}</h5>
                                        <li>{t('cities.title6-6-1')}</li>
                                        <li>{t('cities.title6-6-2')}</li>
                                        <li>{t('cities.title6-6-3')}</li>
                                        <li>{t('cities.title6-6-4')}</li>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <BannerContact />
                <SectionOpinions />


                <div className="Company-Box6">
                    <Row className="logos-container">
                        <Col md={6}>
                            <div className="title-b6">
                                <h3>{t('company.box7-title')}</h3>
                            </div>
                            <p>{t('company.box7-p')}</p>
                            <p>{t('company.box7-p2')}</p>
                        </Col>
                        <Col md={6}>
                            <div className="logos-scrolling">
                                <Row>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tandroid.webp" alt="android" />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tangular.webp" alt="angular" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tionic.webp" alt="ionic" />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tapple.webp" alt="apple" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tmongo.webp" alt="mongo" />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/TSagas.webp" alt="sagas" />
                                    </Col>
                                    <Row>
                                    </Row>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/TSqlS.webp" alt="sql" />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/TNode.webp" alt="node" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/TReact.webp" alt="react" />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tnet.webp" alt="punto net" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/TRedux.webp" alt="redux" />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tboots.webp" alt="boostrap" />
                                    </Col>
                                    <Row>
                                    </Row>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tapple.webp" alt="apple" />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tionic.webp" alt="ionic" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tandroid.webp" alt="android" />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tangular.webp" alt="angular" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tmongo.webp" alt="mongo" />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/TSagas.webp" alt="sagas" />
                                    </Col>
                                    <Row>
                                    </Row>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/TSqlS.webp" alt="sql" />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/TNode.webp" alt="node" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/TReact.webp" alt="react" />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tnet.webp" alt="punto net" />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/TRedux.webp" alt="redux" />
                                    </Col>
                                    <Col xs={6} sm={6} md={6} className="logo">
                                        <img src="https://gecorcloud.com/images/Tboots.webp" alt="boostrap" />
                                    </Col>
                                </Row>
                            </div>
                            <div className="logos">
                                <Row>
                                    <Col className="logo">
                                        <img src="https://gecorcloud.com/images/Tandroid.webp" alt="android" />
                                    </Col>
                                    <Col className="logo">
                                        <img src="https://gecorcloud.com/images/Tangular.webp" alt="angular" />
                                    </Col>
                                    <Col className="logo">
                                        <img src="https://gecorcloud.com/images/Tionic.webp" alt="ionic" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="logo">
                                        <img src="https://gecorcloud.com/images/Tapple.webp" alt="apple" />
                                    </Col>
                                    <Col className="logo">
                                        <img src="https://gecorcloud.com/images/Tmongo.webp" alt="mongo" />
                                    </Col>
                                    <Col className="logo">
                                        <img src="https://gecorcloud.com/images/TSagas.webp" alt="sagas" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="logo">
                                        <img src="https://gecorcloud.com/images/TSqlS.webp" alt="sql" />
                                    </Col>
                                    <Col className="logo">
                                        <img src="https://gecorcloud.com/images/TNode.webp" alt="node" />
                                    </Col>
                                    <Col className="logo">
                                        <img src="https://gecorcloud.com/images/TReact.webp" alt="react" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="logo">
                                        <img src="https://gecorcloud.com/images/Tnet.webp" alt="punto net" />
                                    </Col>
                                    <Col className="logo">
                                        <img src="https://gecorcloud.com/images/TRedux.webp" alt="redux" />
                                    </Col>
                                    <Col className="logo">
                                        <img src="https://gecorcloud.com/images/Tboots.webp" alt="boostrap" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>

                <BannerCertificate t={t} />


            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { users } = state;
    const { user } = users;
    return {
        user
    };
}

const tCompanyPage = withTranslation()(CompanyPage);
const connectedCompanyPage = connect(mapStateToProps)(tCompanyPage);
export { connectedCompanyPage as CompanyPage };
