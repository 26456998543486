import { take, call, put, fork } from 'redux-saga/effects'
import { poisApi } from '../helpers'
import { poisConstants } from './../constants'

/**
 * Effect to handle logging out
 */
export function* getPois() {

    try {
        const response = yield call(poisApi.getPois)
        yield put({ type: poisConstants.GET_MY_SUCCESS, myPois: response })
        return response
    } catch (error) {
        yield put({ type: poisConstants.GET_MY_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

export function* getCategories() {

    try {
        const response = yield call(poisApi.getCategories)
        yield put({ type: poisConstants.GET_CATEGORIES_SUCCESS, categoriesPoi: response })
        return response
    } catch (error) {
        yield put({ type: poisConstants.GET_CATEGORIES_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

export function* getPoiDetail(payload) {

    try {
        const { id } = payload;
        const response = yield call(poisApi.getPoiDetail,id)
        yield put({ type: poisConstants.GET_POI_DETAIL_SUCCESS, poiDetail: response })
        return response
    } catch (error) {
        yield put({ type: poisConstants.GET_POI_DETAIL_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

// FLOWS //
export function* getPoisFlow() {
    while (true) {
        yield take(poisConstants.GET_MY_REQUEST)
        yield call(getPois)
    }
}

export function* getCategoriesFlow() {
    while (true) {
        yield take(poisConstants.GET_CATEGORIES_REQUEST)
        yield call(getCategories)
    }
}

export function* getPoiDetailFlow() {
    while (true) {
        const payload = yield take(poisConstants.GET_POI_DETAIL_REQUEST)
        yield call(getPoiDetail, payload)
    }
}

export default function* () {
    yield fork(getPoisFlow)    
    yield fork(getPoiDetailFlow)
    yield fork(getCategoriesFlow)
}
