import React from 'react';
import './Footer.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { forwardTo, forwardToWithParams  } from '../../helpers'

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faPhone, faLanguage } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
/* ICONS */

import { useTranslation } from 'react-i18next';

export const FooterComponent_294 = props => {
    const { t, i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    return (
        <div id="footer">
            <footer >
                <div className="enviromental-footer">
                    <Container>
                        <Row>
                            <Col sm={4}>
                                <div className="footer-about">
                                    <Row>
                                        <h3 className="footer-informacion">La Línea</h3>
                                    </Row>
                                    <p>{t('footer.lalinea-2')}</p>
                                    <p>Powered by GECOR.</p>
                                    <NavDropdown title={<>
                                        <FontAwesomeIcon icon={faLanguage} /> {t('footer.language')}
                                    </>}
                                        id="collasible-nav-dropdown">
                                        <NavDropdown.Item onClick={() => changeLanguage('es')}>Español</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => changeLanguage('en')}>English</NavDropdown.Item>
                                    </NavDropdown>
                                </div>
                            </Col>
                            <Col sm={5}>
                                <div className="footer-contact" >
                                    <h3>{t("footer.co2")}</h3>
                                    <p>
                                        <FontAwesomeIcon icon={faMapMarker} /><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}>Plaza J. García Cabreros, s/n</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faPhone} /><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}> 956 696 200</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faFacebookSquare} />
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/aytolalinea"> {t("footer.co2-4-1")}</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faTwitterSquare} />
                                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Ayto_LaLinea">{t("footer.co2-4-2")}</a>
                                    </p>
                                    {/*<p><i className="fas fa-envelope"></i> Email: <a href="mailto:hello@domain.com">info@gecorweb.com</a></p>*/}
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className="footer-links">
                                    <Row>
                                        <h3 className="footer-informacion">{t("footer.co3")}</h3>
                                    </Row>
                                    <Row>
                                        <div className="linkFooter">
                                            <Col sm={12}>
                                            <p
                                                    className="legal-advice"
                                                    onClick={() => forwardToWithParams("/aviso-legal", {pdf:"https://gecorcloud.com/pdf/AVISO LEGAL.pdf"})}
                                                >
                                                    <a
                                                    rel="noopener noreferrer"
                                                    href="#"
                                                    onClick={e => e.preventDefault()}
                                                    >
                                                    {t("footer.co3-1")}
                                                    </a>
                                                </p>
                                                <p
                                                    className="legal-advice"
                                                    onClick={() => forwardToWithParams("/privacy-pdf", {pdf:"https://gecorcloud.com/pdf/politica-de-privacidad.pdf"})}
                                                >
                                                    <a
                                                    rel="noopener noreferrer"
                                                    href="#"
                                                    onClick={e => e.preventDefault()}
                                                    >
                                                    {t("footer.co3-2")}
                                                    </a>
                                                </p>
                                                {/*<p className="contact" onClick={() => forwardTo('/notice')}><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}>{t("footer.co3-6")}</a></p>*/}
                                            </Col>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                            <div className="badge-ln">
                                    <p>{t("footer.feder-1")}</p> 
                                    <p>{t("footer.feder-2")}</p>
                                    <br></br>
                                        <p><h2>"{t("footer.feder-3")}"</h2></p>
                                </div>
                            </Col>
                            </Row>
                    </Container>
                </div>
            </footer>
        </div>
    );
}

export default FooterComponent_294;