import './DifusionDetail.css';
import { withTranslation } from 'react-i18next';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faGlobe } from '@fortawesome/free-solid-svg-icons';

class DifusionDetail extends React.PureComponent {

    constructor(props, context) {
        super(props, context);
    }

    render() {
        const { t } = this.props;
        const { state } = this.props.location;
        return (
            <Container id="difusionPage" >
                <div className="container">
                    <Row className="content">
                        <Col md={12}>
                            <h2><b>{state.Titulo}</b></h2>
                            {(state && state.Subtitulo) ? <h3>{state.Subtitulo}</h3> : <></>}
                            <Row className="infoNoticia">
                                <Col md={12}>
                                    {(state.DifusionCanal && state.DifusionCanal.Nombre) ? <span>{state.DifusionCanal.Nombre}</span> : <span></span>}
                                </Col>
                                <Col md={12}>
                                    {(state.Ayuntamiento && state.Ayuntamiento.Nombre) ? <span>{state.Ayuntamiento.Nombre}</span> : <span></span>} | <Moment date={state.FechaCreacion} format="LL" locale={t.language} /> | <Moment date={state.FechaCreacion} format="hh:mm" locale="es" /> | <FontAwesomeIcon icon={faEye} /><span>{state.VecesAbierto}</span>
                                </Col>
                            </Row>
                            <img src={state.UrlImagen} className="rounded img-fluid mx-auto d-block pointer" onClick={() => window.open(state.UrlImagen, "_blank")} />
                            <Row className="cuerpoNoticia">
                                <FontAwesomeIcon icon={faGlobe} /><span className="pointer link" onClick={() => window.open(state.Enlace, "_blank")}>Fuente original</span>
                                <div dangerouslySetInnerHTML={{ __html: state.Descripcion }} ></div>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }
}
const tDifusionDetail = withTranslation()(DifusionDetail);
export { tDifusionDetail as DifusionDetail }