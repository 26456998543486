import React from 'react';
import './BannerContact.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { forwardTo } from '../../helpers';
import { useTranslation } from 'react-i18next';

export const BannerContact = props => {
    const { t } = useTranslation();
    return (
        <div id="banner-contact">
            <Container>
                <p>{t('banner.bc-p')}</p>
                <a onClick={() => forwardTo('/contact')}>
                    <Button variant="secondary" size="lg">
                    {t('banner.bc-btn')}
                    </Button>
                </a>
            </Container>
        </div>
    );
}

export default BannerContact;