import './IncidentDetail.css';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Moment from 'react-moment';
// import { forwardTo } from '../../helpers'
import IncidentProgress from '../IncidentProgress/IncidentProgress';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faHeart, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitterSquare, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'
/* ICONS */
import { IncidentComments } from './IncidentComments'
import { IncidentPhotos } from './IncidentPhoto';
import { IncidentRate } from './IncidentRate';
import { useHistory } from "react-router-dom";

export const IncidentDetail = props => {
    const { incident, user, onRate, onFollowUnfollow, isMobile } = props;

    const shareUrl = `https://gecorapi.gecorcloud.com/ServicioGecorCiudadano/Notice.aspx?a=${incident.UsuarioID}&b=${incident.AvisoID || incident.AvisoCiudadanoID}&c=${incident.Aplicacion}&d=es&e=clukx_APS`;
    const { t } = useTranslation();
    const width = window.innerWidth;
    let history = useHistory();

    return (
        <div id="incident-detail">
            <Row>
                <Col md={12}>
                    <div className="incident">
                        <div className="header">
                            <Row className="row-hed-inc">
                                <Col sm={3}>
                                    {(isMobile || width <= 1200) && <Button className="back-inc-btn" onClick={() => history.goBack()/* forwardTo('/incidents') */}>{t('incicard.p9')}</Button>}
                                </Col>
                                <Col sm={7}>
                                    <div className="header-title">{incident.CodAviso?incident.CodAviso:incident.CodAvisoCiudadano}</div>
                                </Col>
                                <Col sm={2}>
                                    {(incident && user) &&
                                        <div className="actions">
                                            {(incident.CiudadanoID !== user.CiudadanoID) && onFollowUnfollow &&
                                                <div style={incident.Activo ? { textAlign: "left", borderColor: "#F74D4D" } : {}} onClick={(e) => onFollowUnfollow(incident)}>
                                                    {(incident.Activo ?
                                                        <FontAwesomeIcon color="#F74D4D" icon={faHeart} />
                                                        :
                                                        <FontAwesomeIcon icon={faHeart} />
                                                    )}
                                                </div>}
                                            {(incident.AvisoID > 0 || incident.AvisoCiudadanoID>0) && <IncidentComments incident={incident} />}
                                            {/*  {incident.CiudadanoID === user.CiudadanoID && onRate &&
                                        (incident.Valorado === 0 &&
                                            incident.EstadoAvisoID === 12 &&
                                            user.Aplicacion === 'C' &&
                                            <div onClick={(e) => onRate(incident.AvisoID)}>
                                                <FontAwesomeIcon icon={faStar} />
                                            </div>
                                        )}  */}
                                            {(incident.AvisoID > 0 || incident.AvisoCiudadanoID>0) && <IncidentPhotos incident={incident} />}
                                            {(incident.AvisoID > 0 || incident.AvisoCiudadanoID>0) && incident.CiudadanoID === user.CiudadanoID &&
                                                incident.Valorado === 0 &&
                                                incident.EstadoAvisoID === 12 &&
                                                user.Aplicacion === 'C' && <IncidentRate incident={incident} />}
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>
                        <div className="images">
                            {incident ?
                                    (
                                        incident.UltimaRutaFotoAntes ? 
                                            <img src={incident.UltimaRutaFotoAntes} alt={t('incicard.p7')} /> : 
                                            (
                                                incident.RutaFoto ? 
                                                <img src={incident.RutaFoto} alt={t('incicard.p7')} />
                                                :
                                                <div className="no-img"></div>
                                            )
                                    )
                                    :
                                    <div className="no-img"></div>
                            }
                            {incident && incident.UltimaRutaFotoDespues ?
                                <img className="col-sm-6" src={incident.UltimaRutaFotoDespues} alt={t('incicard.p8')} />
                                :
                                <div className="no-img col-sm-6"></div>
                            }
                        </div>
                        <div className="status">
                            <IncidentProgress incident={incident} t={t} />
                        </div>

                        <div className="info">
                            <div className="info-title">{incident.DesTipoElemento} {incident.TipoInc}</div>
                            <div className="info-desc">{incident.DesAveria}</div>
                        </div>
                        <hr />
                        <div className="dates">
                            <p className="info-desc"><strong>{t('incicard.p1')} </strong><Moment format="DD/MM/YYYY">{incident.FechaHoraRegistro}</Moment></p>
                            {incident && incident.EstadoAvisoID === 12 &&
                                <p className="info-desc"><strong>{t('incicard.p2')} </strong><Moment format="DD/MM/YYYY">{incident.Ffin}</Moment></p>
                            }
                        </div>
                        <hr />
                        {incident.Unidad &&
                            <div className="info-desc"><strong>{t('incicard.p10')}: </strong>{incident.Unidad}, <strong>{t('incicard.p11')}: </strong>{incident.Promocion}</div>
                        }

                        <div className="map">
                            <div className="map-title"><FontAwesomeIcon icon={faMapMarkerAlt} /> {incident.DesUbicacion}</div>
                            <img src={`https://maps.googleapis.com/maps/api/staticmap?&zoom=13&size=700x180&markers=${incident.Lat},${incident.Lng}&key=AIzaSyA-89Oq-WqMG2mrwAO76RegV5iUTcDE0gc`} alt="mapa" />
                        </div>
                        <div className="share">
                            <div className="share-title">
                                {t('incicard.p5')}
                            </div>
                            <div className="share-icons">
                                <FacebookShareButton
                                    url={shareUrl}
                                    quote={`${incident.DesTipoElemento} - ${incident.TipoInc} - ${incident.CodAviso}`}>
                                    <FontAwesomeIcon icon={faFacebookSquare} className="facebook" />
                                </FacebookShareButton>
                                <TwitterShareButton
                                    url={shareUrl}
                                    title={`${incident.DesTipoElemento} - ${incident.TipoInc} - ${incident.CodAviso}`}>
                                    <FontAwesomeIcon icon={faTwitterSquare} className="twitter" />
                                </TwitterShareButton>
                                <WhatsappShareButton
                                    url={shareUrl}
                                    title={`${incident.DesTipoElemento} - ${incident.TipoInc} - ${incident.CodAviso}`}>
                                    <FontAwesomeIcon icon={faWhatsappSquare} className="whatsapp" />
                                </WhatsappShareButton>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default IncidentDetail;