import './PoiCategory.css';
import '../../pages/PoisPage/PoisPage.css';
import React from 'react';
import Row from 'react-bootstrap/Row';
import { PoiCard } from './../../components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Fragment } from 'react';


export const PoiCategory = props => {
    const { category, poisCategory, user, onCustomClick, poiChoosed, onHoveredPoi } = props;
    const [showPois, setShowPois] = React.useState(true)
    const onClick = () => { setShowPois(!showPois) }
    return (
        (poisCategory.length>0) && <div id="poi-category">
            <Row className="category-info" onClick={onClick}>
                {showPois ? 
                <FontAwesomeIcon icon={faChevronDown} /> 
                : 
                <FontAwesomeIcon icon={faChevronRight} />
                }
                {category.image ?
                    <img className="category-img" src={category.image} alt="categoria" />
                    :
                    <div className="no-img"></div>
                }
                <div className="category-title">{category.categoria}</div>
            </Row>
            <div className="category-line"></div>
            {(poisCategory && showPois) ? poisCategory.map((poi, i) =>
                <Fragment key={poi.idItem}>
                    <PoiCard
                    
                    poi={poi}
                    user={user}
                    clicked={poiChoosed && poiChoosed.idItem === poi.idItem}
                    onCustomClick={onCustomClick}
                    onHoveredPoi={onHoveredPoi} />
                    {poisCategory.length !== i + 1 && <hr/>}
                    {poisCategory.length === i + 1 && <br/>}
                </Fragment>
            )
                :
                null}
        </div>
    );
}

export default PoiCategory;