import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CardDeck from 'react-bootstrap/CardDeck';
import './EnviromentalEducationPageOn.css';
import { DynamicBackground } from '../../../../components';
import { isWebpSupported } from 'react-image-webp/dist/utils';

class EnviromentalEducationPageOn extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {
    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.1)" background={isWebpSupported() ? "https://gecorcloud.com/images/bgedu.webp" : "https://gecorcloud.com/images/bgedu.jpg"}>
        <div className="EnvEducOn">
          <div className="boxContact">
            <div className="container">
              {/* NUESTRO PLANETA TIERRA */}
              <div className="infoTit">
                <h1><b tabIndex="1">EDUCACIÓN AMBIENTAL</b></h1>
              </div>
              <CardDeck>
                <Card style={{ width: '18rem' }}>
                  <Card.Img tabIndex="6" alt="imagen nuestro planeta tierra" variant="left" src={isWebpSupported() ? "https://gecorcloud.com/images/edu1.webp" : "https://gecorcloud.com/images/edu1.png"} />
                  <Card.Body>
                    <Card.Title><b tabIndex="7">Nuestro Planeta Tierra</b></Card.Title>
                    <Card.Text as="div">
                      <hr className="line" ></hr>
                      <p tabIndex="8" className="text">El medio ambiente comprende de una forma u otra todo nuestro alrededor: LA NATURALEZA, LA SOCIEDAD (AMIGOS, FAMILIARES, CONOCIDOS…), CULTURA, TRABAJO… Somos tantos y necesitamos tantísimo los recursos naturales para vivir que están empezando a agotarse y ensuciarse. Para evitar esto, ¡Necesitamos ponernos manos a la obra!
                      </p>
                    </Card.Text>
                    <a tabIndex="9" href="https://gecorcloud.com/pdf/Nuestro_planeta_tierra.pdf" target="_blank" rel="noopener noreferrer" alt="nuestro planeta tiera" className="enlace"><Button variant="primary">Más información</Button></a>
                  </Card.Body>
                </Card>

                <Card style={{ width: '18rem' }}>
                  <Card.Img tabIndex="10" alt="imagen ciuidar el medio ambiente" variant="left" src={isWebpSupported() ? "https://gecorcloud.com/images/edu2.webp" : "https://gecorcloud.com/images/edu2.png"} />
                  <Card.Body>
                    <Card.Title><b tabIndex="11">Quiero cuidar el Medio Ambiente</b></Card.Title>
                    <Card.Text as="div">
                      <hr className="line" ></hr>
                      <p className="text" tabIndex="12">Gracias a la acción ni más ni menos que de la naturaleza, la basura que arrojamos es reducida o eliminada del medio ambiente natural, pero el proceso puede tardar. ¡Cientos y miles de años! Una acción muy positiva es la de RECICLAR, y lo haremos en todos los ámbitos de nuestra vida, en el cole, en casa, de vacaciones, con los amigos.
                      </p>
                    </Card.Text>
                    <a tabIndex="13" href="https://gecorcloud.com/pdf/Cuidar_medio_ambiente.pdf" target="_blank" rel="noopener noreferrer" className="enlace" ><Button variant="primary">¿Cómo puedo ayudar?</Button></a>
                  </Card.Body>
                </Card>
                <Card style={{ width: '18rem' }}>
                  <Card.Img tabIndex="14" alt="imagen aprende jugando" variant="left" src={isWebpSupported() ? "https://gecorcloud.com/images/edu3.webp" : "https://gecorcloud.com/images/edu3.png"} />
                  <Card.Body>
                    <Card.Title><b tabIndex="15">¡Aprende jugando!</b></Card.Title>
                    <Card.Text as="div">
                      <hr className="line" ></hr>
                      <p className="text" tabIndex="16"> Te enseñamos juegos donde además de divertirte aprenderás solo o con tus amigos todo lo que puedes hacer por cuidar del medio ambiente... escucha y reconoce ruidos procedentes de animales o ambientes sonoros e identifica de dónde vienen, cuáles son verdaderos y cuáles no, etc.</p>
                    </Card.Text>
                    <a tabIndex="13" href="https://gecorcloud.com/pdf/juegos-medio-ambiente.pdf" target="_blank" rel="noopener noreferrer" className="enlace" ><Button variant="primary">Juegos Medio Ambientales</Button></a>
                  </Card.Body>
                </Card>
              </CardDeck>
              <CardDeck>
                <Card style={{ width: '18rem' }}>
                  <Card.Img tabIndex="18" alt="imagen introduccion educacion ambiental" variant="left" src={isWebpSupported() ? "https://gecorcloud.com/images/edu4.webp" : "https://gecorcloud.com/images/edu4.png"} />
                  <Card.Body>
                    <Card.Title><b tabIndex="196">Introducción a la Educación Ambiental</b></Card.Title>
                    <Card.Text as="div">
                      <hr className="line" ></hr>
                      <p tabIndex="20" className="text" > La Educación Ambiental tiene como objetivo fomentar la conciencia ambiental, el conocimiento ecológico y determinadas actitudes y valores encaminados al uso racional de los recursos y desarrollo sostenible. La UNESCO hace especial hincapié en esta herramienta y para ello ha elaborado una serie de manuales educativos disponibles a continuación.
                      </p>
                    </Card.Text>
                    <a tabIndex="21" href="https://gecorcloud.com/pdf/Intro_educacion_ambiental.pdf" target="_blank" rel="noopener noreferrer" className="enlace" ><Button variant="primary">Ver más información</Button></a>
                  </Card.Body>
                </Card>

                <Card style={{ width: '18rem' }}>
                  <Card.Img tabIndex="22" alt="imagen energia" variant="left" src={isWebpSupported() ? "https://gecorcloud.com/images/edu5.webp" : "https://gecorcloud.com/images/edu5.png"} />
                  <Card.Body>
                    <Card.Title><b tabIndex="23">Energía</b></Card.Title>
                    <Card.Text as="div">
                      <hr className="line" ></hr>
                      <p tabIndex="24" className="text">La energía es el motor de todas las actividades y seres vivos. Descubre cómo mostrar a los niños su importancia, construyendo juntos un invernadero, un molinillo de agua y demás inventos sencillos que funcionan con distintas fuentes de energía.
                      </p>
                    </Card.Text>
                    <a tabIndex="25" href="https://www.agenciaandaluzadelaenergia.es/es" target="_blank" rel="noopener noreferrer" className="enlace"><Button variant="primary">Actividades sobre la Energía</Button></a>
                  </Card.Body>
                </Card>

                <Card style={{ width: '18rem' }}>
                  <Card.Img tabIndex="26" alt="imagen paisaje" variant="left" src={isWebpSupported() ? "https://gecorcloud.com/images/envir4.webp" : "https://gecorcloud.com/images/envir4.png"} />
                  <Card.Body>
                    <Card.Title><b tabIndex="27">Paisaje</b></Card.Title>
                    <Card.Text as="div">
                      <hr className="line" ></hr>
                      <p className="text" tabIndex="28">¿Qué factores naturales intervienen en la formación de un paisaje? ¿Por qué cambian en el tiempo y cuáles son los motivos de este cambio? Infórmate y ayuda a que los peques de la casa lo entiendan realizando interesantes y sencillas manualidades.
                      </p>
                    </Card.Text>
                    <a tabIndex="29" href="https://gecorcloud.com/pdf/Nuestro_planeta_tierra.pdf" target="_blank" rel="noopener noreferrer" className="enlace" ><Button variant="primary">Actividades sobre el Paisaje</Button></a>
                  </Card.Body>
                </Card>
              </CardDeck>
            </div>
          </div>
        </div>
      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedEnviromentalEducationPageOn = connect(mapStateToProps)(EnviromentalEducationPageOn);
export { connectedEnviromentalEducationPageOn as EnviromentalEducationPageOn };