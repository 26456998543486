import { incidentsConstants } from '../constants'
import { createSelector } from 'reselect';
import { incidentsApi } from './../helpers';

const incidentsTypesSelector = state => state.incidentsTypes || state.incidents.incidentsTypes;
const elementSelector = state => state.elementType || state.incidents.elementType;

const elementArraySelector = state => state.elements || state.incidents.elements;
const familySelector = state => state.familyType || state.incidents.familyType;

export const filteredElements = createSelector(
    [elementArraySelector, familySelector],
    (elements, familyType) => {
        if (!elements) {
            return [];
        }
        if (!familyType) {
            return [];
        }
        return elements.filter(element => element.FamiliaTipoElementoID == familyType);
        // No cambiar == por === porque no pilla el tipo de incidencia.
    }
);

export const filteredIncidentsType = createSelector(
    [incidentsTypesSelector, elementSelector, familySelector],
    (incidentsTypes, elementType, familyType) => {
        if (!incidentsTypes) {
            return [];
        }
        if (!elementType) {
            return [];
        }
        if (!familyType) {
            return [];
        }
        return incidentsTypes.filter(incidentType => incidentType.TipoElementoID == elementType && incidentType.TipoIncFamilia == familyType);

        // No cambiar == por === porque no pilla el tipo de incidencia.
    }
);

//Filter tipology
const listFamilies = state => state.families || state.incidents.families;
const listElements = state => state.elements || state.incidents.elements;
const listTypeIncidents = state => state.incidentsTypes || state.incidents.incidentsTypes;
const searchTermSelector = state => state.tipologyQuery || state.incidents.tipologyQuery;
export const filteredTipology = createSelector(
    [listFamilies, listElements, listTypeIncidents, searchTermSelector],
    (families, elements, incidentsTypes, tipologyQuery) => {
 
        if (!families || !tipologyQuery) return undefined;
        if (!families || tipologyQuery.length < 2) return undefined;
        let tipologies = [];
        let filterFams = families.filter(fam => fam.Nombre.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .indexOf(tipologyQuery.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) != -1);
        filterFams.forEach(f => {
            if (f.FamiliasTiposElementosID != 0) {
                let tipology = { "index": tipologies.length + 1, "tag": f ?f.Nombre:"", "Icono": f?f.Icono:"", "familiaID": f?f.FamiliasTiposElementosID:0, "familia": f ?f.Nombre:"", "elementoID": 0, "elemento": "", "incidenciaID": 0, "incidencia": "" };
                tipologies.push(tipology);
            }
        });
        let filterElems = elements.filter(elem => elem.DesTipoElemento.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .indexOf(tipologyQuery.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) != -1);

        filterElems.forEach(e => {
            let f = families.find(f => f.FamiliasTiposElementosID == e.FamiliaTipoElementoID);
            let tipology = { "index": tipologies.length + 1, "tag": (f ?f.Nombre:"") + " - " + e.DesTipoElemento, "Icono": f?f.Icono:"", "familiaID": f?f.FamiliasTiposElementosID:0, "familia": f ?f.Nombre:"", "elementoID": e.TipoElementoID, "elemento": e.DesTipoElemento, "incidenciaID": 0, "incidencia": "" }
            tipologies.push(tipology);
        });
        let filterIncs = incidentsTypes.filter(inc => inc.TipoInc.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .indexOf(tipologyQuery.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) != -1);

        filterIncs.forEach(i => {

            let elemento = elements.filter(e => e.TipoElementoID == i.TipoElementoID);
            elemento.forEach(e => {
                let f = families.find(f => f.FamiliasTiposElementosID == e.FamiliaTipoElementoID);

                let tipology = { "index": tipologies.length + 1, "tag": (f ?f.Nombre:"") + " - " + e.DesTipoElemento + " - " + i.TipoInc, "Icono": f?f.Icono:"", "familiaID": f?f.FamiliasTiposElementosID:0, "familia": f ?f.Nombre:"", "elementoID": e.TipoElementoID, "elemento": e.DesTipoElemento, "incidenciaID": i.TipoIncID, "incidencia": i.TipoInc }
                tipologies.push(tipology);
            })
        });
        return tipologies;
     
    }
);

// The initial application state
let initialState = {
    families: incidentsApi.loadFamilies(),
    elements: incidentsApi.loadElements(),
    incidentsTypes: incidentsApi.loadIncidentsTypes(),
    unitsTypes: incidentsApi.loadUnitsTypes(),
    promotionsTypes: incidentsApi.loadPromotionsTypes(),
    nearbyStreets: undefined,
    tipologies: incidentsApi.loadTypology(),
    tipologyQuery: undefined
}
// Takes care of changing the application state
export const incidents = (state = initialState, action) => {
    switch (action.type) {
        case incidentsConstants.INCIDENTS_TIPOGRAFY:
            return { ...state, families: action.families, elements: action.elements, incidentsTypes: action.incidentsTypes }
        case incidentsConstants.TIPOLOGY_SEARCH:
            return { ...state, tipologyQuery: action.tipologyQuery };
        case incidentsConstants.TIPOLOGY_SEARCH_CLEAR:
            return { ...state, tipologyQuery: "" };
        case incidentsConstants.CHANGE_ALL_TYPE:
                return { ...state, familyType: action.familyType, elementType: action.elementType, incidentType: action.incidentType }
        case incidentsConstants.CHANGE_FAMILY_TYPE:
            return { ...state, familyType: action.familyType, elementType: undefined, incidentType: undefined }
        case incidentsConstants.CHANGE_ELEMENT_TYPE:
            return { ...state, elementType: action.elementType, incidentType: undefined }
        case incidentsConstants.CHANGE_INCIDENT_TYPE:
            return { ...state, incidentType: action.incidentType }
        case incidentsConstants.GET_MY_SUCCESS:
            const incidents = action.myIncidents;
            let orderIncidents = incidents.sort((a, b) => {
                if (a.FechaUltimaModificacion === b.FechaUltimaModificacion) {
                    return 0;
                }
                if (a.FechaUltimaModificacion == null) {
                    return 1;
                }
                if (b.FechaUltimaModificacion == null) {
                    return -1;
                }
                return a.FechaUltimaModificacion > b.FechaUltimaModificacion ? 1 : -1;
            })
            const myIncidents = orderIncidents.reverse();
            return { ...state, myIncidents }
        case incidentsConstants.GET_INCIDENT_DETAIL_SUCCESS:
            return { ...state, incidentDetail: action.incidentDetail }
        case incidentsConstants.GET_INCIDENT_PHOTO_DETAIL_SUCCESS:
            return { ...state, incidentPhotos: action.incidentPhotos }
        case incidentsConstants.GET_NEARBY_SUCCESS:
            return { ...state, nearbyIncidents: action.nearbyIncidents }
        case incidentsConstants.GET_FAV_SUCCESS:
            return { ...state, favIncidents: action.favIncidents }
        case incidentsConstants.RATE_SUCCESS:
            return { ...state, rated: action.rated }
        case incidentsConstants.GET_NEARBYSTREETS_SUCCESS:
            return { ...state, nearbyStreets: action.nearbyStreets }
        case incidentsConstants.INCIDENTS_NEARBYSTREETS_CLEAR:
            return { ...state, nearbyStreets: undefined }
        case incidentsConstants.FOLLOW_SUCCESS:
            const { incident, followUnfollow } = action.data;
            const { nearbyIncidents, favIncidents } = state;

            // esto está medio regular...
            let updateNearby
            if (nearbyIncidents) //arreglar error mierda safari
                updateNearby = nearbyIncidents.map(_incident => {
                    if (_incident.AvisoID === incident.AvisoID) {
                        _incident.Activo = followUnfollow;
                    }
                    return _incident
                })
            // para empezar a seguir

            // para dejar de seguir
            let updateFav
            if (followUnfollow)
                updateFav = [...favIncidents, incident]
            else
                updateFav = favIncidents.filter(_incident => _incident.AvisoID !== incident.AvisoID)

            return { ...state, nearbyIncidents: updateNearby, favIncidents: updateFav }
        case incidentsConstants.GET_COMMENTS_SUCCESS:
            return { ...state, incidentComments: action.incidentComments }
        case incidentsConstants.COMMENTS_CLEAR:
            return { ...state, incidentComments: action.data }
        case incidentsConstants.NEW_COMMENTS_SUCCESS:
            return { ...state, newComment: action.newComment }
        case incidentsConstants.GET_HISTORY_SUCCESS:
            return { ...state, incidentHistories: action.incidentHistories }
        case incidentsConstants.NEW_INCIDENT_SUCCESS:
        case incidentsConstants.NEW_INCIDENT_RESET:
            return { ...state, newIncident: action.newIncident }
        case incidentsConstants.INCIDENTS_CLEAR:
            localStorage.removeItem('myIncidents');
            localStorage.removeItem('favIncidents');
            return { ...state, myIncidents: undefined, favIncidents: undefined }
        case incidentsConstants.INCIDENTS_UNITS:
            return { ...state, unitsTypes: action.unitsTypes }
        case incidentsConstants.INCIDENTS_PROMOTIONS:
            return { ...state, promotionsTypes: action.promotionsTypes }
        default:
            return state
    }
}

export default incidents