import * as firebase from "firebase/app";
import "firebase/messaging";

const config = {
    apiKey: "AIzaSyALBJsqygLCX4PLz6WSCVq62MrR-sa4Krs",
    authDomain: "gecor-system.firebaseapp.com",
    databaseURL: "https://gecor-system.firebaseio.com",
    projectId: "gecor-system",
    storageBucket: "gecor-system.appspot.com",
    messagingSenderId: "1060313159714",
    appId: "1:1060313159714:web:72775d5192442a6642527b"

};

let messaging;

if(firebase.messaging.isSupported()) {
    const initializedFirebaseApp = firebase.initializeApp(config);
    messaging = initializedFirebaseApp.messaging();
} else {
    messaging = undefined
}

export { messaging };