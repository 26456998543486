// This file contains the sagas used for async actions in our app. It's divided into
// "effects" that the sagas call (`authorize` and `logout`) and the actual sagas themselves,
// which listen for actions.

// Sagas help us gather all our side effects (network requests in this case) in one place
// import { hashSync } from 'bcryptjs'
import { take, call, put, fork, race } from "redux-saga/effects";
import { authApi } from "../helpers";
import { forwardTo } from "../helpers";
import { userConstants, entityConstants } from "./../constants";
import { socialConstants } from "./../constants/social.constants";

/**
 * Effect to handle authorization
 * @param  {string} username               The username of the user
 * @param  {string} password               The password of the user
 * @param  {object} options                Options
 * @param  {boolean} options.isRegistering Is this a register request?
 */
export function* authorize({
  email,
  password,
  isRegistering,
  mobile = "",
  name = "",
  nie
}) {
  // We send an action that tells Redux we're sending a request
  yield put({ type: userConstants.SENDING_REQUEST, sending: true });

  // We then try to register or log in the user, depending on the request
  try {
    // const salt = genSalt(username)
    // const hash = hashSync(password, salt)
    let response;

    // For either log in or registering, we call the proper function in the `auth`
    // module, which is asynchronous. Because we're using generators, we can work
    // as if it's synchronous because we pause execution until the call is done
    // with `yield`!
    if (isRegistering) {
      response = yield call(authApi.register, email, password, mobile, name, nie);
    } else {
      response = yield call(authApi.login, email, password);
    }

    return response;
  } catch (error) {
    // If we get an error we send Redux the appropiate action and return
    yield put({ type: userConstants.REQUEST_ERROR, error: error.message });

    return false;
  } finally {
    // When done, we tell Redux we're not in the middle of a request any
    yield put({ type: userConstants.SENDING_REQUEST, sending: false });
  }
}

export function* resetPassword({ email }) {
  // We then try to register or log in the user, depending on the request
  console.log("  admnaklodjn  resetPassword({ email }) ")
  try {
    // const salt = genSalt(username)
    // const hash = hashSync(password, salt)
    let response = yield call(authApi.resetPassword, email);
    return response;
  } catch (error) {
    // If we get an error we send Redux the appropiate action and return
    yield put({ type: userConstants.REQUEST_ERROR, error: error.message });
    return false;
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({ type: userConstants.SENDING_REQUEST, sending: false });
  }
}

export function* loginUserExternal({ email, nombre, id, image }) {
  // We send an action that tells Redux we're sending a request
  yield put({ type: userConstants.SENDING_REQUEST, sending: true });

  // We then try to register or log in the user, depending on the request
  try {
    let response = yield call(
      authApi.loginUserExternal,
      email,
      nombre,
      id,
      image
    );
    return response;
  } catch (error) {
    // If we get an error we send Redux the appropiate action and return
    yield put({ type: userConstants.REQUEST_ERROR, error: error.message });

    return false;
  } finally {
    // When done, we tell Redux we're not in the middle of a request any more
    yield put({ type: userConstants.SENDING_REQUEST, sending: false });
  }
}

export function* loadUserData() {
  try {
    const response = yield call(authApi.loadUserData);
    yield put({ type: userConstants.LOADED_USER_DATA, user: response });

    return response;
  } catch (error) {
    // yield put({ type: userConstants.REQUEST_ERROR, error: error.message })
  } /*finally {
        yield put({ type: userConstants.SENDING_REQUEST, sending: false })
    }*/
}

/**
 * Effect to handle logging out
 */
export function* logout() {
  // We tell Redux we're in the middle of a request
  yield put({ type: userConstants.SENDING_REQUEST, sending: true });

  // Similar to above, we try to log out by calling the `logout` function in the
  // `auth` module. If we get an error, we send an appropiate action. If we don't,
  // we return the response.
  try {
    const response = yield call(authApi.logout);
    return response;
  } catch (error) {
    yield put({ type: userConstants.REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: userConstants.SENDING_REQUEST, sending: false });
  }
}

export function* update(data) {
  // We tell Redux we're in the middle of a request
  yield put({ type: userConstants.SENDING_REQUEST, sending: true });

  // Similar to above, we try to log out by calling the `logout` function in the
  // `auth` module. If we get an error, we send an appropiate action. If we don't,
  // we return the response.
  try {
    const response = yield call(authApi.update, data);
    return response;
  } catch (error) {
    yield put({ type: userConstants.REQUEST_ERROR, error: error.message });
  } finally {
    yield put({ type: userConstants.SENDING_REQUEST, sending: false });
  }
}

/**
 * Log in saga
 */
export function* loginFlow() {
  // Because sagas are generators, doing `while (true)` doesn't block our program
  // Basically here we say "this saga is always listening for actions"
  while (true) {
    // And we're listening for `LOGIN_REQUEST` actions and destructuring its payload
    const request = yield take(userConstants.LOGIN_REQUEST);
    const { email, password } = request.data;

    if (email && password) {
      // A `LOGOUT` action may happen while the `authorize` effect is going on, which may
      // lead to a race condition. This is unlikely, but just in case, we call `race` which
      // returns the "winner", i.e. the one that finished first
      const winner = yield race({
        auth: call(authorize, { email, password, isRegistering: false }),
        logout: take(userConstants.LOGOUT)
      });

      const { auth } = winner;

      // If `authorize` was the winner...
      if (auth) {
        const { token } = auth;
        if (token) {
          // ...we send Redux appropiate actions
          yield put({
            type: userConstants.SET_AUTH,
            newAuthState: true,
            user: auth
          }); // User is logged in (authorized)
          yield put({
            type: userConstants.CHANGE_FORM,
            newFormState: { username: "", password: "" }
          }); // Clear form

          yield put({
            type: entityConstants.ENTITY_TIPOLOGY_REQUEST,
            data: auth
          }); // User is logged in (authorized)

          //forwardTo('/new-incident') // Go to dashboard page
          forwardTo("/incidents");
        } else {
          yield put({
            type: userConstants.REQUEST_ERROR,
            error:
              "login.emailIncorrecto"
          });
        }
      }
    } else {
      yield put({
        type: userConstants.REQUEST_ERROR,
        error:
          "login.dataMissing"
      });
    }
  }
}

/**
 * Log in saga
 */
export function* resetPasswordFlow() {
  // Because sagas are generators, doing `while (true)` doesn't block our program
  // Basically here we say "this saga is always listening for actions"
  while (true) {
    // And we're listening for `LOGIN_REQUEST` actions and destructuring its payload
    const request = yield take(userConstants.RESET_PASSWORD_REQUEST);
    const { data } = request;


    // We call the `authorize` task with the data, telling it that we are registering a user
    // This returns `true` if the registering was successful, `false` if not
    const wasSuccessful = yield call(resetPassword, data);

    
    console.log("  resetPasswordFlow()  ", wasSuccessful, data)

    // If we could register a user, we send the appropiate actions
    if (wasSuccessful) {
      yield put({ type: userConstants.SET_AUTH, newAuthState: true }); // User is logged in (authorized) after being registered
      yield put({
        type: userConstants.CHANGE_FORM,
        newFormState: { username: "", password: "" }
      }); // Clear form
      // forwardTo('/') // Go to dashboard page
      yield put({ type: userConstants.RESET_PASSWORD_SUCCESS, response: wasSuccessful }); // User is logged in (authorized) after being registered
    } else {
      // throw error
    }
  }
}

export function* loginUserExternalFlow() {
  // Because sagas are generators, doing `while (true)` doesn't block our program
  // Basically here we say "this saga is always listening for actions"
  while (true) {
    // And we're listening for `LOGIN_REQUEST` actions and destructuring its payload
    const request = yield take(socialConstants.SOCIAL_LOGIN_SUCCESS);
    const { email, name, id, image } = request.user;

    // A `LOGOUT` action may happen while the `authorize` effect is going on, which may
    // lead to a race condition. This is unlikely, but just in case, we call `race` which
    // returns the "winner", i.e. the one that finished first
    const winner = yield race({
      auth: call(loginUserExternal, { email, name, id, image }),
      logout: take(userConstants.LOGOUT)
    });

    // If `authorize` was the winner...
    const { auth } = winner;
    if (auth) {
      const { token } = auth;
      if (token) {
        // ...we send Redux appropiate actions
        yield put({
          type: userConstants.SET_AUTH,
          newAuthState: true,
          user: auth
        }); // User is logged in (authorized)

        yield put({
          type: entityConstants.ENTITY_TIPOLOGY_REQUEST,
          data: auth
        }); // User is logged in (authorized)

        //forwardTo('/new-incident') // Go to dashboard page
        forwardTo("/incidents");
      } else {
        yield put({
          type: userConstants.REQUEST_ERROR,
          error:
            "login.emailIncorrecto"
        });
      }
    } else {
      yield put({
        type: userConstants.REQUEST_ERROR,
        error:
          "login.emailIncorrecto"
      });
    }
  }
}

export function* loadUserDataFlow() {
  while (true) {
    yield take(userConstants.LOAD_USER_DATA);
    yield call(loadUserData);
  }
}

/**
 * Log out saga
 * This is basically the same as the `if (winner.logout)` of above, just written
 * as a saga that is always listening to `LOGOUT` actions
 */
export function* logoutFlow() {
  while (true) {
    yield take(userConstants.LOGOUT);
    yield put({ type: userConstants.SET_AUTH, newAuthState: false });

    yield call(logout);
    // forwardTo('/entities')
  }
}

/**
 * Register saga
 * Very similar to log in saga!
 */
export function* registerFlow() {
  while (true) {
    // We always listen to `REGISTER_REQUEST` actions    
    const request = yield take(userConstants.REGISTER_REQUEST);
    const { email, password, mobile, name, nie } = request.data;

    // We call the `authorize` task with the data, telling it that we are registering a user
    // This returns `true` if the registering was successful, `false` if not
    const wasSuccessful = yield call(authorize, {
      email,
      password,
      isRegistering: true,
      mobile,
      name,
      nie
    });

    // If we could register a user, we send the appropiate actions
    if (wasSuccessful && !wasSuccessful[0].Error) {
      yield put({
        type: userConstants.REGISTER_SUCCESS,
        registered: wasSuccessful[0]
      });
      // forwardTo('/') // Go to dashboard page
    } else {
      // throw error
      yield put({
        type: userConstants.REGISTER_FAILURE,
        error: wasSuccessful[0].Error
      }); // Clear form
    }
  }
}

export function* updateFlow() {
  while (true) {
    // We always listen to `UPDATE_REQUEST` actions
    const request = yield take(userConstants.UPDATE_REQUEST);
    const { data } = request;

    // We call the `authorize` task with the data, telling it that we are registering a user
    // This returns `true` if the registering was successful, `false` if not
    const wasSuccessful = yield call(update, data);

    // If we could register a user, we send the appropiate actions
    if (wasSuccessful) {
      yield put({ type: userConstants.UPDATE_SUCCESS, user: data }); // User is logged in (authorized) after being registered
      // yield put({ type: userConstants.CHANGE_FORM, newFormState: { username: '', password: '' } }) // Clear form
      // forwardTo('/') // Go to dashboard page
    } else {
      yield put({
        type: userConstants.UPDATE_ERROR,
        error: "Comprueba de que todos los campos estén rellenos"
      });
    }
  }
}

// The root saga is what we actually send to Redux's middleware. In here we fork
// each saga so that they are all "active" and listening.
// Sagas are fired once at the start of an app and can be thought of as processes running
// in the background, watching actions dispatched to the store.
export default function* () {
  yield fork(loginFlow);
  yield fork(loginUserExternalFlow);
  yield fork(loadUserDataFlow);
  yield fork(logoutFlow);
  yield fork(registerFlow);
  yield fork(updateFlow);
  yield fork(resetPasswordFlow);
}
