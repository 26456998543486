
import { all } from 'redux-saga/effects'
import entitiesSagas from './entities.sagas';
import userSagas from './user.sagas';
import socialSagas from './social.sagas';
import incidentsSagas from './incidents.sagas';
import contactSagas from './contact.sagas';
import contestSagas from './contest.sagas';
import noticesSagas from './notices.sagas';
import poisSagas from './pois.sagas';
import surveysSagas from './surveys.sagas';
import difusionesSagas from './difusiones.sagas';

export default function* () {
    yield all([
        entitiesSagas(),
        userSagas(),
        socialSagas(),
        incidentsSagas(),
        contactSagas(),
        contestSagas(),
        noticesSagas(),
        poisSagas(),
        surveysSagas(),
        difusionesSagas(),
    ]);
}
