import React from 'react';
import './CitiesPage.css';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { isWebpSupported } from 'react-image-webp/dist/utils';

export const SectionSchema = props => {
    const { t } = useTranslation();
    const t1 = t('cities.schema');
    const t2 = t('cities.schema-r');

    return (
        <div id="section-schema">
            <Container>
                <h3 className="card-titulo">{t('cities.title3')}</h3>
                <Image src={isWebpSupported() ? t1 + ".webp" : t1 + ".png"} fluid className="schema-city" />
                <Image src={isWebpSupported() ? t2 + ".webp" : t2 + ".png"} fluid className="schema-city-r" />
            </Container>
        </div>
    );
}

export default SectionSchema;