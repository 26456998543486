import React from 'react';
import { connect } from 'react-redux';
import './NotFoundPage.css';
import { withTranslation } from 'react-i18next';
import { NotFoundMap } from './NotFoundMap';
import { forwardTo } from '../../helpers'


class NotFoundPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="whateveryouwant">
          <h1>{t('notfound.p1')}</h1>
          <h5>{t('notfound.p2')} <a onClick={() => forwardTo('/')}>{t('notfound.p3')}</a> {t('notfound.p4')}</h5>
        </div>
        <NotFoundMap />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { entities } = state;
  const { entity } = entities;
  return {
    entity
  };
}
const tNotFoundPage = withTranslation()(NotFoundPage);
const connectedNotFoundPage = connect(mapStateToProps)(tNotFoundPage);
export { connectedNotFoundPage as NotFoundPage };