import './IncidentCard.css';
import { useTranslation } from 'react-i18next';
import React from 'react';
import '../../pages/IncidentsPage/IncidentsPage.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Moment from 'react-moment';
import IncidentProgress from '../IncidentProgress/IncidentProgress';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar, faHeart, faImages } from '@fortawesome/free-solid-svg-icons'
import { IncidentComments } from './IncidentComments';
import { IncidentPhotos } from './IncidentPhoto';
import { IncidentRate } from './IncidentRate';
/* ICONS */


export const IncidentCard = props => {
    const { incident, user, onRate, onFollowUnfollow, onCustomClick, clicked } = props;
    const { t } = useTranslation();
    return (
        <div id="incident-card" onClick={(e) => onCustomClick(incident)}>
            <Row className={clicked ? "choosed" : ""}>
                <Col md={12}>
                    <div className="incident">
                        <div className="images">
                            <div>
                                {
                                
                                    incident ?
                                        (
                                            incident.UltimaRutaFotoAntes ? 
                                                <img src={incident.UltimaRutaFotoAntes} alt={t('incicard.p7')} /> 
                                                : (
                                                    incident.RutaFoto ? 
                                                    <img src={incident.RutaFoto} alt={t('incicard.p7')} />
                                                    :
                                                    <div className="no-img"></div>
                                                 )

                                                 /*(
                                                    incident.RutaFoto ? 
                                                    <img src={incident.RutaFoto} alt={t('incicard.p7')} />
                                                    :
                                                    <div className="no-img"></div>
                                                 )*/
                                        )
                                        :
                                        <div className="no-img"></div>
                                }
                                    {/*
                                    (incident.RutaFoto ?
                                        <img src={incident.RutaFoto} alt={t('incicard.p7')} />
                                        :
                                        <div className="no-img"></div>
                                    )
                                    :
                                    (incident.UltimaRutaFotoAntes ?
                                        <img src={incident.UltimaRutaFotoAntes} alt={t('incicard.p7')} />
                                        :
                                        <div className="no-img"></div>
                                    )
                                    
                                    */}
                            </div>
                            <div className="image-after">
                                {incident && incident.UltimaRutaFotoDespues ?
                                    <img src={incident.UltimaRutaFotoDespues} alt={t('incicard.p8')} />
                                    :
                                    <div className="no-img"></div>
                                }
                            </div>
                        </div>
                        <div className="info">
                            <div className="info-title">{incident.CodAviso?incident.CodAviso:incident.CodAvisoCiudadano}</div>
                            <div className="info-desc">{incident.DesTipoElemento} / {incident.TipoInc}</div>
                            {incident.Unidad ?
                                <div className="info-desc">{incident.Unidad},{incident.Promocion} - {incident.DesUbicacion}</div>
                                :
                                <div className="info-desc">{incident.DesUbicacion}</div>
                            }
                        </div>
                        <div className="dates">
                            <div className="date-Reg">
                                <p className="info-desc"><strong>{t('incicard.p1')} </strong><Moment format="DD/MM/YYYY">{incident.FechaHoraRegistro}</Moment></p>
                            </div>
                            {incident && incident.EstadoAvisoID === 12 &&
                                <p className="info-desc"><strong>{t('incicard.p2')} </strong><Moment format="DD/MM/YYYY">{incident.Ffin}</Moment></p>
                            }
                        </div>
                        <div className="status">
                            <IncidentProgress incident={incident} t={t} />
                        </div>
                        <div className="actions">
                            {(incident.CiudadanoID !== user.CiudadanoID) && onFollowUnfollow &&
                                <div title={incident.Activo ? t('incicard.floatNoFav') : t('incicard.floatFav')} style={incident.Activo ? { borderColor: "#F74D4D" } : {}} onClick={(e) => onFollowUnfollow(incident)}>
                                    {(incident.Activo ?
                                        <FontAwesomeIcon color="#F74D4D" icon={faHeart} />
                                        :
                                        <FontAwesomeIcon icon={faHeart} />
                                    )}
                                </div>}
                            {/*<div>
                                <FontAwesomeIcon icon={faComments} />
                            </div>*/}
                            {(incident.AvisoID > 0 || incident.AvisoCiudadanoID>0) && <IncidentComments incident={incident} />}
                            {(incident.AvisoID > 0 || incident.AvisoCiudadanoID>0) && <IncidentPhotos incident={incident} />}
                            {(incident.AvisoID > 0 || incident.AvisoCiudadanoID>0) && incident.CiudadanoID === user.CiudadanoID &&
                                incident.Valorado === 0 &&
                                incident.EstadoAvisoID === 12 &&
                                user.Aplicacion === 'C' && <IncidentRate incident={incident} />}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default IncidentCard;