import React from 'react';


export class HolderImage extends React.PureComponent {
    image = React.createRef();

    async componentDidMount() {
        const { default: holderjs } = await import('holderjs');

        holderjs.run({
            domain: 'holder.js',
            images: this.image.current,
            object: null,
            bgnodes: null,
            stylenodes: null,
        });
        holderjs.setResizeUpdate(this.image.current, false);
    }

    render() {
        const { width = 250, height = 350, text = "", bg = "ffffff", fg = "ffffff", ...rest } = this.props;
        const src = `holder.js/${width}x${height}?auto=yes&text=${text}&bg=${bg}&fg=${fg}`;
        return <img ref={this.image} data-src={src} alt={text} {...rest} />;
    }
}

export default HolderImage;

/*
export const HolderImage = props => {

    return (
        <>
            <img
                className="d-block w-100"
                src="holder.js/200x200?auto=yes&bg=ffffff&fg=ffffff"
            />
            <script src="http://imsky.github.com/holder/holder.js"></script>
        </>
    );
}
*/
