import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Carousel } from 'react-bootstrap';
import { incidentsActions } from '../../actions';
import './IncidentPhoto.css';
import { withTranslation } from 'react-i18next';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImages } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

class IncidentPhotos extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);      
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            show: false
        };
    } 

    handleClose() {
        // this.sendNewComment();
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
        const { incident } = this.props;
        const { AvisoID } = incident;
        this.props.dispatch(incidentsActions.getPhotoDetail(AvisoID));
    }

    handleChange(e) {
        const { error } = this.props;
        if (error) this.props.clearError();

        const { name, value } = e.target;
        this.setState({ [name]: value });
    }


    render() {

        const { incidentPhotos, t } = this.props

        return (
            <>
                <div title={t('incicard.floatPhoto')} onClick={this.handleShow}>
                    <FontAwesomeIcon icon={faImages} />
                </div>

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton className="title-header-incident-photos" >
                        <h4> {t('incmodal.titlePhoto')}</h4>
                    </Modal.Header>
                    <Modal.Body className='photos-incident-body'>
                        {(!incidentPhotos || incidentPhotos.length  == 0 || !incidentPhotos[0]) &&
                            <div>{t('incmodal.noPhotos')}</div>
                        }
                        {incidentPhotos && <Carousel>
                            {incidentPhotos.map((photo, key) =>
                                <Carousel.Item key={key}>
                                    <a href={photo.RutaFoto} target="_blank">                                    
                                    <img className="img-modal responsive-img" alt="Foto incidencia" src={photo.RutaFoto} />
                                    </a>
                                </Carousel.Item>
                            )}
                            </Carousel>
                        }
                    </Modal.Body>
                    <Modal.Footer className="photos-incident-footer">
                             <Button className="boton-incident-photos" waves='light' onClick={this.handleClose} >{t("incmodal.close")}</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { incidents, users } = state;
    const { incidentPhotos } = incidents;
    const { user } = users;
    return {
        incidentPhotos,
        user,
    };
}
const tIncidentPhotos = withTranslation()(IncidentPhotos);
const connectedIncidentPhotos = connect(mapStateToProps)(tIncidentPhotos);
export { connectedIncidentPhotos as IncidentPhotos };
