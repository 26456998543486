import React from "react";
import "./Footer.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { forwardTo, forwardToWithParams } from "../../helpers";
import { isWebpSupported } from "react-image-webp/dist/utils";

/* ICONS */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarker, faPhone, faEnvelopeSquare, faLanguage } from "@fortawesome/free-solid-svg-icons";
import { faFacebookSquare, faTwitterSquare, faYoutubeSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
/* ICONS */

import { useTranslation } from "react-i18next";

export const FooterComponent_ue = props => {
  const { isNotFound, entity } = props;

  const { t, i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
  };

  return (
    <div id="footer">
       {!entity && !isNotFound && (
        <div className="container-eu">

          <Row >
            <Col
              sm={12}
              className="d-flex  "
            >
              <img className="ue-log-jr"
                src="https://gecorcloud.com/images/logo-ue-jr.jpg"
                alt="ue-logo"
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="txt-eu">
                <p><b>Unión Europea</b></p>
              </div>
            </Col>
          </Row>
          <Row className="ue-container">
            <Col
              sm={12}
              className="d-flex  "
            >
              <div className="text-foo-ue-2">
                <p>Gecor System S.L ha sido beneficiaria del Fondo Europeo de Desarrollo Regional cuyo objetivo es mejorar la competitividad de las Pymes y gracias al cual ha puesto en marcha un Plan de Marketing Digital Internacional con el objetivo de mejorar su posicionamiento online en mercados exteriores durante el año 2021. Para ello ha contado con el apoyo del Programa XPANDE DIGITAL de la Cámara de Comercio de Málaga.</p>
                <p>Fondo Europeo de Desarrollo Regional. Una manera de hacer Europa</p>
              </div>
            </Col>
          </Row>
        </div>
      )}
      <footer
        style={{
          backgroundImage: `url("${isWebpSupported() ? "https://gecorcloud.com/images/footer2.webp" : "https://gecorcloud.com/images/footer2.jpg"
            }")`
        }}
      >
        <div className="footer-top">
          <Container>
            <Row>
              <Col sm={4}>
                <div className="footer-about">
                  <Row>
                    <h3 className="footer-informacion">{t("footer.co1")}</h3>
                  </Row>
                  <p>{t("footer.co1-1")}</p>
                  <p>{t("footer.co1-2")}</p>
                  <NavDropdown
                    title={
                      <>
                        <FontAwesomeIcon icon={faLanguage} aria-hidden />
                        {t("footer.language")}
                      </>
                    }
                    id="collasible-nav-dropdown"
                  >
                    <NavDropdown.Item onClick={() => changeLanguage("es")}>
                      Español
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => changeLanguage("en")}>
                      English
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => changeLanguage("pt")}>
                      Português
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </Col>
              <Col sm={4}>
                <div className="footer-contact">
                  <h3>{t("footer.co2")}</h3>
                  <p>
                    <FontAwesomeIcon icon={faMapMarker} />
                    <a
                      target="_blank" rel="noopener noreferrer"
                      href={t("footer.urlGoogle")}
                      title="Dirección"
                    >
                      {t("footer.co2-1")}
                    </a>
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faPhone} /> {t("footer.co2-2")}
                  </p>
                  <div className="social-icons">
                    <a
                      target="_blank" rel="noopener noreferrer"
                      onClick={() => forwardTo("/contact")}
                      title="Contacta con Gecor"
                      href=""
                    >
                      <FontAwesomeIcon onClick={e => e.preventDefault()} icon={faEnvelopeSquare} />
                    </a>
                    <a
                      target="_blank" rel="noopener noreferrer"
                      href="https://es-es.facebook.com/GECOR.Esp"
                      title="Facebook"
                    >
                      <FontAwesomeIcon icon={faFacebookSquare} />
                    </a>
                    <a
                      target="_blank" rel="noopener noreferrer"
                      href="https://twitter.com/gecorweb?lang=es"
                      title="Twitter"
                    >
                      <FontAwesomeIcon icon={faTwitterSquare} />
                    </a>
                    <a
                      target="_blank" rel="noopener noreferrer"
                      href="https://ar.linkedin.com/company/gecorsystem"
                      title="Linkedin"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                    <a
                      target="_blank" rel="noopener noreferrer"
                      href="https://www.youtube.com/channel/UCtgPmIS-NV4_dHz4FZ_YcPQ"
                      title="Youtube"
                    >
                      <FontAwesomeIcon icon={faYoutubeSquare} />
                    </a>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="footer-links">
                  <Row>
                    <h3 className="footer-informacion">{t("footer.co3")}</h3>
                  </Row>
                  <Row>
                    <div className="linkFooter">
                      <p
                        className="legal-advice"
                        onClick={() => forwardToWithParams("/aviso-legal", {pdf:"https://gecorcloud.com/pdf/AVISO LEGAL.pdf"})}
                      >
                        <a
                          rel="noopener noreferrer"
                          href="#"
                          onClick={e => e.preventDefault()}
                        >
                          {t("footer.co3-1")}
                        </a>
                      </p>
                      <p
                        className="legal-advice"
                        onClick={() => forwardToWithParams("/privacy-pdf", {pdf:"https://gecorcloud.com/pdf/politica-de-privacidad.pdf"})}
                      >
                        <a
                          rel="noopener noreferrer"
                          href="#"
                          onClick={e => e.preventDefault()}
                        >
                          {t("footer.co3-2")}
                        </a>
                      </p>
                      <p
                        className="legal-advice"
                        onClick={() => forwardToWithParams("/privacy-pdf", {pdf:"https://gecorcloud.com/pdf/PC-PolíticadeCalidadEd05.pdf"})}
                      >
                        <a
                          rel="noopener noreferrer"
                          href="#"
                          onClick={e => e.preventDefault()}
                        >
                          {t("footer.co3-3")}
                        </a>
                      </p>
                      <p
                        className="legal-advice"
                        onClick={() => forwardToWithParams("/privacy-pdf", {pdf:"https://gecorcloud.com/pdf/Politica_seguridad.pdf"})}
                      >
                        <a
                          rel="noopener noreferrer"
                          href="#"
                          onClick={e => e.preventDefault()}
                        >
                          {t("footer.co3-9")}
                        </a>
                      </p>
                      {/*<p
                        className="contact"
                        onClick={() => forwardTo("/notice")}
                      >
                        <a
                          rel="noopener noreferrer"
                          href="#"
                          onClick={e => e.preventDefault()}
                        >
                          {t("footer.co3-6")}
                        </a>
                  </p>*/}
                      <p
                        className="contact"
                        onClick={e => forwardTo("/faq-g")}
                      >
                        <a
                          rel="noopener noreferrer"
                          href="#"
                          onClick={e => e.preventDefault()}
                        >
                          {t("footer.co3-7")}
                        </a>
                      </p>
                      <p
                        className="contact"
                        onClick={e => forwardTo("/Declaracion-Accesibilidad")}
                      >
                        <a
                          rel="noopener noreferrer"
                          href="#"
                          onClick={e => e.preventDefault()}
                        >
                          {t("footer.declaracionAcc")}
                        </a>
                      </p>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </div>
  );
};

export default FooterComponent_ue;
