
import React, { useState, useEffect } from 'react';
import { faVolumeMute, faVolumeUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

export const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <button className="audio-btn" onClick={toggle}>{playing ? <FontAwesomeIcon icon={faVolumeMute} className="pause" /> : <FontAwesomeIcon icon={faVolumeUp} className="play" />}</button>
  );
};

export default Player;
