export const entityConstants = {
    GETALL_REQUEST: 'ENTITIES_GETALL_REQUEST',
    GETALL_SUCCESS: 'ENTITIES_GETALL_SUCCESS',
    GETALL_FAILURE: 'ENTITIES_GETALL_FAILURE',

    ENTITY_REQUEST: 'ENTITIES_ENTITY_REQUEST',
    ENTITY_SUCCESS: 'ENTITIES_ENTITY_SUCCESS',
    ENTITY_FAILURE: 'ENTITIES_ENTITY_FAILURE',

    ENTITY_TIPOLOGY_REQUEST: 'ENTITY_TIPOLOGY_REQUEST',
    ENTITY_TIPOLOGY_SUCCESS: 'ENTITY_TIPOLOGY_SUCCESS',
    ENTITY_TIPOLOGY_FAILURE: 'ENTITY_TIPOLOGY_FAILURE',

    ENTITY_CHANGE: 'ENTITY_CHANGE',
    ENTITY_CHANGED: 'ENTITY_CHANGED',
    ENTITY_LOAD: 'ENTITY_LOAD',

    ENTITY_SEARCH: 'ENTITY_SEARCH',
    ENTITY_SEARCH_CLEAR: 'ENTITY_SEARCH_CLEAR',

    STREET_SEARCH: 'STREET_SEARCH',
    STREET_SEARCH_CLEAR: 'STREET_SEARCH_CLEAR',

    STREET_SEARCH_REQUEST: 'STREET_SEARCH_REQUEST',
    STREET_SEARCH_SUCCESS: 'STREET_SEARCH_SUCCESS',
    STREET_SEARCH_FAILURE: 'STREET_SEARCH_FAILURE',

    ENTITY_CONFIG: 'ENTITY_CONFIG',
    ENTITY_STREETS: 'ENTITY_STREETS',
    ENTITY_BUILDINGS: 'ENTITY_BUILDINGS',

    ENTITY_INFO_SUCCESS: 'ENTITY_INFO_SUCCESS',
    ENTITY_INFO_FAILURE: 'ENTITY_INFO_FAILURE',

    
    ENTITY_UNIT_REQUEST: 'ENTITY_UNIT_REQUEST',
    ENTITY_UNIT_SUCCESS: 'ENTITY_UNIT_SUCCESS',
    ENTITY_UNIT_FAILURE: 'ENTITY_UNIT_FAILURE',    
    ENTITY_PROMOTION_REQUEST: 'ENTITY_PROMOTION_REQUEST',
    ENTITY_PROMOTION_SUCCESS: 'ENTITY_PROMOTION_SUCCESS',
    ENTITY_PROMOTION_FAILURE: 'ENTITY_PROMOTION_FAILURE',
};