import React from 'react';
import './../HomePage/HomePage.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

export const SectionVideo = props => {
    const { t } = useTranslation();

    return (
        <div id="section-video">
            <div className="section-video-gecor">
                <Container>
                    <h3 className="card-titulo">{t('gweet.box1-title')}</h3>
                    <Row>
                        <Col md={6}>
                            <iframe title="videoo" className="iframe-img" width="100%" height="100%" src="https://www.youtube.com/embed/bLr5wvqq1b4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                        </Col>
                        <Col md={6}>
                            <div className="video-text"> 
                                <p dangerouslySetInnerHTML={{ __html: t('company.box1-p1') }}></p>
                                <p dangerouslySetInnerHTML={{ __html: t('company.box1-p2') }}></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default SectionVideo;