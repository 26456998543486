import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import './LegalAdvicePage.css';
import { DynamicBackground } from './../../components'
import { withTranslation } from 'react-i18next';
import { isWebpSupported } from 'react-image-webp/dist/utils';

class LegalAdvicePage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {

    const { entity, t } = this.props;

    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/michelangelo-71282_960_720.webp" : "https://gecorcloud.com/images/michelangelo-71282_960_720.jpg"}>
        <div className="container">

          <div className="legal-box">
            <div className="tituloContact">
              <h1>{entity.AliasAPP?entity.AliasAPP:"Gecor"}</h1>
              <hr className="line3" ></hr>
            </div>
            <div className="box-container">

              <Card>
                <Card.Header as="h3">{t('legal.t1')}</Card.Header>
                <Card.Body>
                  <Card.Text>
                    <div dangerouslySetInnerHTML={{ __html: entity && entity.AvisoLegal}} ></div>
                    
                    {!entity && "Responsable: GECOR SYSTEM SLU con domicilio en C/Alameda Principal, nº 21 Planta 6 Oficina 603, CP 29001 Málaga. Teléfono 952028423 y Email: info@gecorweb.com. La finalidad es la gestión de los avisos, consultas, sugerencias y reclamaciones que Ud. quiera comunicar, así como informarle sobre las actuaciones llevadas a cabo por el Ayuntamiento para dar respuesta a la misma. No se realizan transferencias internacionales de sus datos, ni se ceden a terceros. Tratamos sus datos en virtud de su consentimiento, así como la ejecución de relaciones contractuales entre el encargado y el responsable y por interés legítimo. Puede acceder, rectificar y suprimir los datos, además del resto de derechos reconocidos en la normativa, puede ejercerlos tanto a la dirección como al email, que constan al inicio de la cláusula. Puede presentar una reclamación a la A.E.P.D., mediante la sede electrónica de la Autoridad o por medios no electrónicos, en el caso que entienda vulnerados sus derechos. Tiene más información sobre nuestra política de privacidad en nuestra web: www.gecorweb.com"}
                  </Card.Text>
                </Card.Body>
              </Card>

            </div>
          </div>
        </div>
        <br></br>
      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { entities } = state;
  const { entity } = entities;
  return {
    entity
  };
}
const tLegalAdvicePage = withTranslation()(LegalAdvicePage);
const connectedLegalAdvicePage = connect(mapStateToProps)(tLegalAdvicePage);
export { connectedLegalAdvicePage as LegalAdvicePage };