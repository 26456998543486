import { incidentsConstants } from '../constants';

export const incidentsActions = {
    getMyIncidents: () => ({ type: incidentsConstants.GET_MY_REQUEST }),
    getFavoritesIncidents: () => ({ type: incidentsConstants.GET_FAV_REQUEST }),
    getNearbyIncidents: (latLng) => ({ type: incidentsConstants.GET_NEARBY_REQUEST, latLng }),
    getIncidentDetail: (id) => ({ type: incidentsConstants.GET_INCIDENT_DETAIL_REQUEST, id }),
    newIncident: (data) => ({ type: incidentsConstants.NEW_INCIDENT_REQUEST, data }),
    newIncidentReset: () => ({ type: incidentsConstants.NEW_INCIDENT_RESET, undefined }),
    searchTipology: (tipologyQuery) => ({ type: incidentsConstants.TIPOLOGY_SEARCH, tipologyQuery }),
    clearSearchTipology: () => ({ type: incidentsConstants.TIPOLOGY_SEARCH_CLEAR }),
    changeAllType: (familyType, elementType, incidentType) => ({ type: incidentsConstants.CHANGE_ALL_TYPE, familyType, elementType, incidentType }),
    changeFamilyType: (familyType) => ({ type: incidentsConstants.CHANGE_FAMILY_TYPE, familyType }),
    changeElementType: (elementType) => ({ type: incidentsConstants.CHANGE_ELEMENT_TYPE, elementType }),
    changeIncidentType: (incidentType) => ({ type: incidentsConstants.CHANGE_INCIDENT_TYPE, incidentType }),    
    changeUnitType: (unitType) => ({ type: incidentsConstants.CHANGE_UNIT_TYPE, unitType }),
    changePromotionType: (promotionType) => ({ type: incidentsConstants.CHANGE_PROMOTION_TYPE, promotionType }),
    rateIncident: (data) => ({ type: incidentsConstants.RATE_REQUEST, data }),
    followUnfollowIncident: (data) => ({ type: incidentsConstants.FOLLOW_REQUEST, data }),
    getComments: (id) => ({ type: incidentsConstants.GET_COMMENTS_REQUEST, id }),
    newComment: (data) => ({ type: incidentsConstants.NEW_COMMENTS_REQUEST, data }),
    commentsClear: (data = []) => ({ type: incidentsConstants.COMMENTS_CLEAR, data }),
    getHistory: (id) => ({ type: incidentsConstants.GET_HISTORY_REQUEST, id }),
    clearIncidents: () => ({ type: incidentsConstants.INCIDENTS_CLEAR }),
    getPhotoDetail: (id) => ({ type: incidentsConstants.GET_INCIDENT_PHOTO_DETAIL_REQUEST, id }),
    getNearbyStreets: (latLng) => ({ type: incidentsConstants.GET_NEARBYSTREETS_REQUEST, latLng }),
    clearNearbyStreets: () => ({ type: incidentsConstants.INCIDENTS_NEARBYSTREETS_CLEAR }),
};