import { apiUrl, infoApiUrl } from "../../constants";

export const entityApi = {
  /**
   * Logs a user in, returning a promise with `true` when done
   */
  getAll(language = "es") {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ language })
    };

    return fetch(`${apiUrl}/Utils/getAyuntamientos`, requestOptions)
      .then(response => response.json())
      .then(entities => {
        localStorage.setItem("entities", JSON.stringify(entities));
        return entities;
      });
  },
  /**
   * Registers a user and then logs them in
   * @param  {string} id The ayuntamientoID
   */
  getEntityById(id, language = "es") {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ language, ayuntamientoID: id })
    };

    return fetch(`${apiUrl}/Utils/getAyuntamientoByAytoID`, requestOptions)
      .then(response => response.json())
      .then(entity => {
        localStorage.setItem("entity", JSON.stringify(entity));
        return entity;
      });
  },
  /**
   * Registers a user and then logs them in
   * @param  {string} address The street address
   */
  getMoreStreetsFromExternalUrl(address) {
    const requestOptions = {
      method: "GET"
      // headers: { 'Content-Type': 'application/json' }
    };

    return fetch(`https://sig.malaga.eu/territorio/direcciones/geojson/?direccion=${address}&epsg=4258`, requestOptions)
      .then(response => response.json())
      .then(resp => {
        return resp;
      });
  },
  /**
   * Registers a user and then logs them in
   * @param  {string} token The ayuntamientoID
   */
  getTipologiaPorAyuntamiento(token, idioma = localStorage.getItem('i18nextLng')) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token, idioma })
    };

    return fetch(
      `${apiUrl}/Typology/getTipologiaPorAyuntamiento`,
      requestOptions
    )
      .then(response => response.json())
      .then(typology => {
        const { Elementos } = typology;        
        const uniqueElementosSet = Elementos?.filter(
          (ele, ind) =>
            ind ===
            Elementos.findIndex(
              elem => elem.TipoElementoID === ele.TipoElementoID
            )
        );
        typology.Elementos = uniqueElementosSet; 
        localStorage.setItem("typology", JSON.stringify(typology));
        return typology;
      });
  },
  getCallesPorAyuntamiento(token) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token })
    };

    return fetch(`${apiUrl}/Utils/getCallesPorAyuntamiento`, requestOptions)
      .then(response => response.json())
      .then(streets => {
        localStorage.setItem("streets", JSON.stringify(streets));
        return streets;
      });
  },
  getUnidadesPorAyuntamiento(token) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token })
    };

    return fetch(`${apiUrl}/Emvisesa/GetUnidad`, requestOptions)
      .then(response => response.json())
      .then(units => {
        if(!units || (units && !Array.isArray(units))){
          units=[];
        }
        localStorage.setItem("unitsTypes", JSON.stringify(units));
        return units;
      });
  },
  getPromocionesPorAyuntamiento(token) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token })
    };

    return fetch(`${apiUrl}/Emvisesa/GetPromocion`, requestOptions)
      .then(response => response.json())
      .then(promotions => {
        if(!promotions || (promotions && !Array.isArray(promotions))){
          promotions=[];
        }
        localStorage.setItem("promotionsTypes", JSON.stringify(promotions));
        return promotions;
      });
  },
  getEdificiosPorAyuntamiento(token) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token })
    };

    return fetch(`${apiUrl}/Utils/getEdificiosPorAyuntamiento`, requestOptions)
      .then(response => response.json())
      .then(buildings => {
        localStorage.setItem("buildings", JSON.stringify(buildings));
        return buildings;
      });
  },
  getMoreData(AyuntamientoID = undefined, user = localStorage.user) {
    // const { AyuntamientoID } = JSON.parse(user);
    if (AyuntamientoID) {
      const requestOptions = {
        method: "GET"
        // headers: { 'Content-Type': 'application/json' }
      };

      return fetch(
        `${apiUrl}/Utils/getAppConfig/${AyuntamientoID}`,
        requestOptions
      )
        .then(response => response.json())
        .then(config => {
          localStorage.setItem("entityConfig", JSON.stringify(config));
          return config;
        });
    } else {
      console.error("entityApi getMoreData", AyuntamientoID);
    }
  },
  getEntityInfo(id) {
    const requestOptions = {
      method: "GET"
      // headers: { 'Content-Type': 'application/json' }
    };

    return fetch(`${infoApiUrl}/Data/${id}`, requestOptions)
      .then(response => response.json())
      .then(entityInfo => {
        localStorage.setItem("entityInfo", JSON.stringify(entityInfo));
        return entityInfo;
      });
  },
  loadEntity() {
    return JSON.parse(localStorage.getItem("entity")) || undefined;
  },
  loadEntityInfo() {
    return JSON.parse(localStorage.getItem("entityInfo")) || undefined;
  },
  loadEntityConfig() {
    return JSON.parse(localStorage.getItem("entityConfig")) || undefined;
  },
  loadEntityStreets() {
    return JSON.parse(localStorage.getItem("streets")) || undefined;
  },
  loadEntityBuildings() {
    return JSON.parse(localStorage.getItem("buildings")) || undefined;
  },
  loadEntities() {
    return JSON.parse(localStorage.getItem("entities")) || undefined;
  },
  changeEntity() {
    localStorage.removeItem("entity");
    localStorage.removeItem("typology");
    localStorage.removeItem("streets");
    localStorage.removeItem("entityConfig");    
    localStorage.removeItem("unitsTypes");
    localStorage.removeItem("promotionsTypes");
    return Promise.resolve(true);
  },
  onChange() {}
};

export default entityApi;
