import React from 'react';
import './Footer.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { forwardTo,forwardToWithParams } from '../../helpers'
import { isWebpSupported } from "react-image-webp/dist/utils";

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faPhone, faLanguage, faEnvelopeSquare } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitterSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';
/* ICONS */

import { useTranslation } from 'react-i18next';

export const Footer_180 = props => {
    const { isNotFound, entity } = props;

    const { t, i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    return (
        <div id="footer">
            {!entity && !isNotFound && (
                <div className="container-eu">
                    <div className="txt-eu">
                        <p>{t("footer.financiado")}</p>
                    </div>
                    <Row className="ue-container">
                        <Col
                            sm={12}
                            className="d-flex  "
                        >
                            <img className="ue-log"
                                src={isWebpSupported() ? "https://gecorcloud.com/images/ue.webp" : "https://gecorcloud.com/images/ue.jpg"}
                                alt="ue-logo"
                            />
                        </Col>
                    </Row>
                </div>
            )}
            <footer
                style={{
                    backgroundImage: `url("${isWebpSupported() ? "https://gecorcloud.com/images/footer2.webp" : "https://gecorcloud.com/images/footer2.jpg"
                        }")`
                }}
            >
                <div className="footer-top">
                    <Container>
                        <Row>
                            <Col sm={4}>
                                <div className="footer-about">
                                    <Row>
                                        <h3 className="footer-informacion">Las Torres mejora contigo</h3>
                                    </Row>
                                    <p>{t("footer.co1-1")}</p>
                                    <p>{t("footer.co1-2")}</p>
                                    <NavDropdown
                                        title={
                                            <>
                                                <FontAwesomeIcon icon={faLanguage} />
                                                {t("footer.language")}
                                            </>
                                        }
                                        id="collasible-nav-dropdown"
                                    >
                                        <NavDropdown.Item onClick={() => changeLanguage("es")}>
                                            Español
                                        </NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => changeLanguage("en")}>
                                            English
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="footer-contact">
                                    <h3>{t("footer.co2")}</h3>
                                    <p>
                                        <FontAwesomeIcon icon={faMapMarker} />
                                        <a
                                            target="_blank" rel="noopener noreferrer"
                                            href="https://goo.gl/maps/o2ZvvjtdALVMgFMR9"
                                        >
                                            Plaza Adolfo Suárez, 1 • 30565 – Las Torres de Cotillas (Murcia)
                                        </a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faPhone} /> 968 626 511
                                    </p>

                                    <div className="social-icons">
                                        <a
                                            rel="noopener noreferrer"
                                            onClick={() => forwardTo("/contact")}
                                        >
                                            {" "}
                                            <FontAwesomeIcon icon={faEnvelopeSquare} />
                                        </a>
                                        <a
                                            target="_blank" rel="noopener noreferrer"
                                            href="https://www.facebook.com/ayuntamientolastorresdecotillas/"
                                        >
                                            <FontAwesomeIcon icon={faFacebookSquare} />
                                        </a>
                                        <a
                                            target="_blank" rel="noopener noreferrer"
                                            href="https://twitter.com/AytoLasTorresC"
                                        >
                                            <FontAwesomeIcon icon={faTwitterSquare} />
                                        </a>
                                        <a
                                            target="_blank" rel="noopener noreferrer"
                                            href="https://www.instagram.com/aytolastorresdecotillas/"
                                        >
                                            <FontAwesomeIcon icon={faInstagramSquare} />
                                        </a>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="footer-links">
                                    <Row>
                                        <h3 className="footer-informacion">{t("footer.co3")}</h3>
                                    </Row>
                                    <Row>
                                        <div className="linkFooter">
                                        <p
                                                className="legal-advice"
                                                onClick={() => forwardToWithParams("/aviso-legal", {pdf:"https://gecorcloud.com/pdf/AVISO LEGAL.pdf"})}
                                            >
                                                <a
                                                rel="noopener noreferrer"
                                                href="#"
                                                onClick={e => e.preventDefault()}
                                                >
                                                {t("footer.co3-1")}
                                                </a>
                                            </p>
                                            <p
                                                className="legal-advice"
                                                onClick={() => window.open("https://www.lastorresdecotillas.es/proteccion-de-datos/", "_blank")}
                                            >
                                                <a
                                                rel="noopener noreferrer"
                                                href="#"
                                                onClick={e => e.preventDefault()}
                                                >
                                                {t("footer.co3-2")}
                                                </a>
                                            </p>
                                            {/*<p
                                                className="contact"
                                                onClick={() => window.open("https://www.lastorresdecotillas.es/noticias/", "_blank")}
                                            >
                                                <a
                                                    rel="noopener noreferrer"
                                                    href="#"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    {t("footer.co3-6")}
                                                </a>
                                    </p>*/}
                                            <p
                                                className="contact"
                                                onClick={() => forwardTo("/faq-g")}
                                            >
                                                <a
                                                    rel="noopener noreferrer"
                                                    href="#"
                                                    onClick={e => e.preventDefault()}
                                                >
                                                    {t("footer.co3-7")}
                                                </a>
                                            </p>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="copy-ban">
                    <p>{t("footer.copyright", { year: 2024 })}</p>
                </div>
            </footer>
        </div>
    );
};
export default Footer_180;