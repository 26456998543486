import { userConstants } from "../constants";
import { authApi } from "../helpers";

// The initial application state
let initialState = {
  formState: {
    username: "",
    password: ""
  },
  error: "",
  resetPassword: undefined,
  currentlySending: false,
  loggedIn: authApi.loggedIn(),
  user: authApi.loadUserData()
};

// Takes care of changing the application state
export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.CHANGE_FORM:
      return { ...state, formState: action.newFormState };
    case userConstants.SET_AUTH:
      return { ...state, loggedIn: action.newAuthState, user: action.user };
    case userConstants.LOADED_USER_DATA:
      return { ...state, user: action.user };
    case userConstants.SENDING_REQUEST:
      return { ...state, currentlySending: action.sending };
    case userConstants.REGISTER_SUCCESS:
      return { ...state, registered: action.registered };
    case userConstants.REGISTER_FAILURE:
      return { ...state, error: action.error, registered: undefined };
    case userConstants.UPDATE_SUCCESS:
      return { ...state, user: action.user, updated: true };
    case userConstants.UPDATE_ERROR:
      return { ...state, error: action.error, updated: false };
    case userConstants.REQUEST_ERROR:
      return { ...state, error: action.error };
    case userConstants.CLEAR_ERROR:
      return { ...state, error: "", updated: false,  registered: undefined };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return { ...state, resetPassword: action.response };
    default:
      return state;
  }
}

export default users;
