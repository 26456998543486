import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { forwardTo } from '../../helpers';
import { ContentSearcher } from './../ContentSearcher/ContentSearcher';
import './header.css';
/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTree, faBook, faSignOutAlt, faUser, faPlusCircle, faMapMarked, faList, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
/* ICONS */
import { useTranslation } from 'react-i18next';
import { incidentsActions } from './../../actions/incidents.actions';
import { Container } from 'react-bootstrap';

export const Header_294 = props => {
  const { users, dispatch } = props;
  const { t } = useTranslation();

  const logOut = () => {
    dispatch(incidentsActions.clearIncidents());
    forwardTo('/login')
  }

  return (
    <div id="header">
      <Navbar className="enviromental-nav fixed-top" expand="lg" collapseOnSelect>
        <Container className='enviromental-nav-container'>
          <Nav className="enviromental-nav-logo">
            <img src="https://gecorsystem.blob.core.windows.net/public/Fotos/2023/06/2023_6_5_9_56_escudo-linea-eu.png" className='enviromental-nav-img-linea'></img>
          </Nav>
          <Navbar.Toggle className="enviromental-buttomToggle" aria-controls="basic-navbar-nav" ></Navbar.Toggle>
          {/*<div className="nav-title">
          <span className="concejalia-name">Concejalía de Sostenibilidad, Movilidad y Renovables</span>
          <span>Ayuntamiento de Alcorcón</span>
         </div>*/}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="enviromental-men" >
              <Nav.Link onClick={() => forwardTo('/menu-ambiental-la-linea')} className='p-2'><FontAwesomeIcon icon={faTree} />Información Ambiental</Nav.Link>
              <NavDropdown title="Consultas e Incidencias" id="nav-dropdown">
                <NavDropdown.Item onClick={() => forwardTo('/contact')}>Consulta</NavDropdown.Item>
                <Nav className="menu2" >
                  {users.loggedIn &&
                    <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faExclamationCircle} />{t("nav.navInc")}</>} id="basic-nav-dropdown">
                      <NavDropdown.Item onClick={() => forwardTo('/new-incident')}><FontAwesomeIcon icon={faPlusCircle} />{t("nav.nav6-1")}</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => forwardTo('/incidents')}><FontAwesomeIcon icon={faList} />{t("nav.nav6-2")}</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => forwardTo('/nearby')}><FontAwesomeIcon icon={faMapMarked} />{t("nav.nav6-3")}</NavDropdown.Item>
                    </NavDropdown>
                  }
                  {!users.loggedIn ?
                    <NavDropdown.Item onClick={() => forwardTo('/login', '?ao=294')}>Incidencias</NavDropdown.Item>
                    :
                    <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faUser} />{t("nav.nav6")}</>} id="basic-nav-dropdown">
                      <NavDropdown.Item onClick={() => forwardTo('/settings')}>{t("nav.nav6-4")}</NavDropdown.Item>
                      <NavDropdown.Item onClick={logOut}><FontAwesomeIcon icon={faSignOutAlt} />{t("nav.nav6-5")}</NavDropdown.Item>
                    </NavDropdown>
                  }
                </Nav>
              </NavDropdown>
              <ContentSearcher />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </div >
  );
}
export default Header_294;