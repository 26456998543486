import React from 'react';
import '../CitiesPage/CitiesPage.css';

export const SectionSuccessCases = (props) => {
    return (
        <div id="section-success-cases">
                
                    <section className="testimonial text-center">
                        <div className="container">

                            <div id="testimonial4" className="carousel slide testimonial4_indicators testimonial4_control_button thumb_scroll_x swipe_x" data-ride="carousel" data-pause="hover" data-interval="5000" data-duration="2000">
                            
                                <div className="carousel-inner" role="listbox">
                                    <div className="carousel-item active">
                                        <div className="testimonial4_slide">
                                            {/* <img alt="img" src="https://transparencia.fuengirola.es/wp-content/uploads/2016/05/Ana-Maria-Mula-Redruello-1-290x290.jpg" className="img-circle img-responsive" /> */}
                                            <img alt="img" src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/2021/11/2021_11_15_9_48_Ana-Maria-Mula-Redruello.jpg" className="img-circle img-responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4> Ana Mula, Alcaldesa de Fuengirola</h4>
                                            <p>"Para nosotros se ha convertido en una herramienta fundamental de trabajo, dentro del Ayuntamiento todo se gestiona por Gecor"</p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="testimonial4_slide">
                                            <img alt="img" src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/2021/11/2021_11_15_9_34_JavierGutierrezAlamo.jpeg" className="img-circle img-responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4> Javier Gutiérrez el Álamo Cabello, Asesor de Alcaldía en asuntos Medioambientales del Ayuntamiento de Málaga</h4>
                                            <p>"Es una aplicación de vanguardia que nos permite resolver las incidencias de la mejor manera posible"</p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="testimonial4_slide">
                                            {/* <img alt="img" src="https://aquimediosdecomunicacion.com/wp-content/uploads/2021/01/alfaz-luis-miguel-morant-concejal-turismo-diciembre-2019-02-1.jpg" className="img-circle img-responsive" />*/}         
                                            <img alt="img" src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/2021/11/2021_11_15_9_46_luis-miguel-morant.jpg" className="img-circle img-responsive" />
                                            <br></br> 
                                            <br></br>
                                            <h4>D. Luis Miguel Morant, Concejal de Turismo del Ayuntamiento de L´alfàs del Pi (Alicante)</h4>
                                            <p>"Yo recomendaría la implementación de Gecor en cualquier municipio que quiera mejorar día a día"</p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="testimonial4_slide">
                                            <img alt="img" src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/2021/11/2021_11_15_9_42_miguelMendez.png" className="img-circle img-responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4>Miguel Méndez (Director General del Área de Playas del Ayuntamiento de Málaga)</h4>
                                            <p>“A través de Gecor, gestionamos el mantenimiento de todos los servicios de las playas. Gracias a ello hemos logrado un tiempo de respuesta a las incidencias de menos de 24 horas.”</p>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="testimonial4_slide">
                                            <img alt="img" src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/2021/11/2021_11_15_9_40_RobertoEscobar.png" className="img-circle img-responsive" />
                                            <br></br>
                                            <br></br>
                                            <h4>Roberto Escobar (Técnico del Área de Participación Ciudadana del Ayuntamiento de Granada)</h4>
                                            <p>"Gracias a su trabajo y gran experiencia, Gecor System ha hecho fácil la puesta en marcha de su plataforma en la ciudad de Granada, integrando numerosos profesionales y servicios."</p>
                                        </div>
                                    </div>
                                </div>
                                <a className="carousel-control-prev" href="#testimonial4" data-slide="prev">
                                    <span className="carousel-control-prev-icon"></span>
                                </a>
                                <a className="carousel-control-next" href="#testimonial4" data-slide="next">
                                    <span className="carousel-control-next-icon"></span>
                                </a>
                            </div>
                        </div>
                    </section>
        </div>
    );
}

export default SectionSuccessCases;