export const poisConstants = {
    GET_MY_REQUEST: 'POIS_GET_MY_REQUEST',
    GET_MY_SUCCESS: 'POIS_GET_MY_SUCCESS',
    GET_MY_FAILURE: 'POIS_GET_MY_FAILURE',

    GET_CATEGORIES_REQUEST: 'POIS_GET_CATEGORIES_REQUEST',
    GET_CATEGORIES_SUCCESS: 'POIS_GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAILURE: 'POIS_GET_CATEGORIES_FAILURE',

    GET_POI_DETAIL_REQUEST: 'POIS_GET_POI_DETAIL_REQUEST',
    GET_POI_DETAIL_SUCCESS: 'POIS_GET_POI_DETAIL_SUCCESS',
    GET_POI_DETAIL_FAILURE: 'POIS_GET_POI_DETAIL_FAILURE',

    SEARCH_POIS: 'SEARCH_POIS',
    SEARCH_POIS_CLEAR: 'SEARCH_POIS_CLEAR',
    HOVERED_POI: 'HOVERED_POI',
};