
import { combineReducers } from 'redux';

import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { entities } from './entities.reducer';
import { social } from './social.reducer';
import { incidents } from './incidents.reducer';
import { contact } from './contact.reducer';
import { contest } from './contest.reducer';
import { news } from './notices.reducer';
import { pois } from './pois.reducer';
import { surveys } from './surveys.reducer';
import {difusiones} from './difusiones.reducer';

const rootReducer = combineReducers({
  users,
  alert,
  entities,
  social,
  incidents,
  contact,
  contest,
  news,
  pois,
  surveys,
  difusiones,
});

export default rootReducer;
