import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { forwardTo } from '../../helpers'
import './header.css';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { faMapMarked, faList, faPlusCircle, faGraduationCap, faPaw, faInfoCircle, faHandHolding, faCommentAlt, faQuestion, faQuestionCircle, faBook, faUser, faSignOutAlt, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import NavDropdown from 'react-bootstrap/NavDropdown';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSeedling, faTree } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

import { incidentsActions } from './../../actions/incidents.actions';
import { useTranslation } from 'react-i18next';

export const Header_10438e = props => {
  const { t } = useTranslation();
  const { users, dispatch } = props;

  const logOut = () => {
    dispatch(incidentsActions.clearIncidents());
    forwardTo('/login')
  }

  return (
    <div id="header">
      <Navbar className="menu " sticky="top" expand="lg">
        <Navbar.Brand className="iconBrand_143" target="_blank" rel="noopener noreferrer" href="https://www.onil.es/es/"><img alt="Logo Onil" width="10%" className="img-Header_268" src={isWebpSupported() ? "https://gecorcloud.com/images/escudo-chiclana.webp" : "https://gecorcloud.com/images/escudo-chiclana.png"} /></Navbar.Brand>
        <Navbar.Toggle className="buttomToggle" aria-controls="basic-navbar-nav" ></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
          </Nav>
          
          <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faSeedling} />{t("nav.nav9")}</>} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => forwardTo('/menu-ambiental-onil')}><FontAwesomeIcon icon={faSeedling} />{t("nav.nav1")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/local-information-page-onil')}><FontAwesomeIcon icon={faInfoCircle} />{t("nav.nav9-1c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/enviromental-information-onil')}><FontAwesomeIcon icon={faPaw} />{t("nav.nav9-2c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/educacion-ambiental-onil')}><FontAwesomeIcon icon={faGraduationCap} />{t("nav.nav9-3c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/practicas-medio-ambiente-onil')}><FontAwesomeIcon icon={faHandHolding} />{t("nav.nav9-4c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/contacto-medioambiente-onil')}><FontAwesomeIcon icon={faQuestionCircle} />{t("nav.nav9-5c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => window.open('https://gecorcloud.com/pdf/Glosario-ambiental-GECOR.pdf')}><FontAwesomeIcon icon={faBook} /><a>{t("nav.nav9-6")}</a></NavDropdown.Item>
          </NavDropdown>

          {users.loggedIn &&
              <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faExclamationCircle}/>{t("nav.navInc")}</>} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => forwardTo('/new-incident')}><FontAwesomeIcon icon={faPlusCircle} />{t("nav.nav6-1")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => forwardTo('/incidents')}><FontAwesomeIcon icon={faList}/>{t("nav.nav6-2")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => forwardTo('/nearby')}><FontAwesomeIcon icon={faMapMarked}/>{t("nav.nav6-3")}</NavDropdown.Item>
              </NavDropdown>
            }
            {!users.loggedIn ?
              <Nav.Link onClick={() => forwardTo('/login')}><FontAwesomeIcon icon={faUser} /> {t("nav.nav5")} </Nav.Link>
              :
              <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faUser} />{t("nav.nav6")}</>} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => forwardTo('/settings')}>{t("nav.nav6-4")}</NavDropdown.Item>
                <NavDropdown.Item onClick={logOut}><FontAwesomeIcon icon={faSignOutAlt} />{t("nav.nav6-5")}</NavDropdown.Item>
              </NavDropdown>
            }


        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header_10438e;