import React from "react";
import { connect } from "react-redux";
import "./UserSettingPage.css";
import { withFormik } from "formik";
import * as Yup from "yup";
import IntlTelTextInput from "react-intl-tel-input";
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { DynamicBackground } from "./../../components";
import {
  loadUserData,
  updateRequest,
  requestError,
  clearError
} from "../../actions";
import { withTranslation } from "react-i18next";
import { isWebpSupported } from "react-image-webp/dist/utils";

import { authApi } from "../../helpers";

class UserSettingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: true,
      showOk: true,
      isSubmitting: false
    };

    this.props.dispatch(loadUserData());

    // this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);

    // INPUT PHONE NUMBER
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.setShowError = this.setShowError.bind(this);
    this.setShowOk = this.setShowOk.bind(this);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(clearError());
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.user) {
      const { user } = nextProps;
      this.setState({
        user: { ...user }
      });

      const { setValues } = this.props;
      setValues(user)
    }
  }

  onChange(status, value, country, number, id, event) {
    const { setFieldValue, setFieldTouched } = this.props;
    setFieldValue("Movil", number);
    setFieldTouched("Movil", true);
  }

  onBlur(status, value, country, number, id, event) {
    const { setFieldTouched, setFieldValue } = this.props;
    setFieldTouched("Movil", true);
  }

  /*
  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    const { dispatch, error } = this.props;

    if (error) dispatch(clearError());

    this.updateState(user, name, value);
  }

  updateState(user, name, value) {
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true });
    const { user } = this.state;
    const { dispatch } = this.props;
    const { data } = dispatch(updateRequest(user));

    this.setState({
      user: data,
      isSubmitting: false
    });
  }
  */

  setShowError(showError) {
    this.setState({ showError });
    if (!showError) this.props.dispatch(requestError(undefined));
  }

  setShowOk(showOk) {
    this.setState({ showOk });
  }

  handleChangeBackground(entity) {
    switch (entity && entity.AyuntamientoID) {
      case 206:
        return "";
      case 180:
        return entity.CabeceraApp;
      case 429:
        return isWebpSupported()
          ? "https://gecorcloud.com/images/turisme1.webp"
          : "https://gecorcloud.com/images/turisme1.jpg";
      default:
        return isWebpSupported()
          ? "https://gecorcloud.com/images/login.webp"
          : "https://gecorcloud.com/images/login.png";
    }
  }

  render() {

    const {
      values,
      touched,
      errors,
      // dirty,
      handleChange,
      // setFieldValue,
      setSubmitting,
      isSubmitting,
      resetForm,
      handleSubmit,
      isValid,
      userOriginal,
      error,
      t,
      entity,
      updated,
      dispatch
    } = this.props;
    const { user, showOk, showError } = this.state;


    return (
      <DynamicBackground
        customOverlay="rgba(0, 0, 0, 0.5)"
        background={this.handleChangeBackground(entity)}
      >
        <div className="containerUser">
          {user && (
            <Row className="user-details box-perfil">
              <Form
                name="SettingsForm"
                className="box-form"
                autoComplete="off"
                // onSubmit={this.handleSubmit}
                onSubmit={handleSubmit} // Formik
              >
                <div className="containerPerfil">
                  {/*
                  {error && (
                    <Alert
                      show={showError}
                      variant="danger"
                      onClose={() => {
                        this.setShowError(false);
                      }}
                      dismissible
                    >
                      <Alert.Heading>{t("register.errorTitle")}</Alert.Heading>
                      <p>{t("profile.update-error-message")}</p>
                    </Alert>
                  )}
                  {updated && (
                    <Alert show={showOk} variant="success">
                      <Alert.Heading>
                        {t("register.signinSuccessTitle")}
                      </Alert.Heading>
                      <p>{t("profile.update-success-message")}</p>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <Button
                          onClick={() => {
                            this.setShowOk(false);
                            setSubmitting(false);
                            dispatch(clearError());
                          }}
                          variant="outline-success"
                        >
                          {t("register.signinSuccessButton")}
                        </Button>
                      </div>
                    </Alert>
                  )}
                        */}

                  <Card className="padding-box">
                    <Card.Title className="title-miPerfil">
                      {t("profile.title")}
                    </Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">
                      <hr className="line2"></hr>
                    </Card.Subtitle>
                    <Row className="center-miPerfil">
                      <Col md={{ span: 8, offset: 0 }}>
                        <Card.Body className="padding-box2">
                          <Card.Text as="div">
                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  {t("register.p1")}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                className="placehol-miPerfil"
                                //placeholder={t("profile.p1")}
                                aria-label="Nombre de usuario"
                                aria-describedby="basic-addon1"
                                type="text"
                                label="Nombre"
                                s={12}
                                error={
                                  isSubmitting && !values.Nombre
                                    ? t("profile.p1e")
                                    : ""
                                }
                                value={values.Nombre}
                                name="Nombre"
                                onChange={handleChange}
                                isInvalid={!!errors.Nombre}
                                isValid={touched.Nombre && !errors.Nombre}
                                disabled={isSubmitting}
                                icon="mode_edit"
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.Nombre}
                              </Form.Control.Feedback>
                            </InputGroup>

                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  {t("profile.p2")}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                //placeholder={t("profile.p2")}
                                aria-label="Apellidos"
                                aria-describedby="basic-addon1"
                                type="text"
                                label="Apellidos"
                                s={12}
                                error={
                                  isSubmitting && !values.Apellidos
                                    ? t("profile.p2e")
                                    : ""
                                }
                                value={values.Apellidos}
                                name="Apellidos"
                                onChange={handleChange}
                                isInvalid={!!errors.Apellidos}
                                isValid={touched.Apellidos && !errors.Apellidos}
                                disabled={isSubmitting}
                                icon="mode_edit"
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.Apellidos}
                              </Form.Control.Feedback>
                            </InputGroup>

                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  {t("profile.pdni")}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                // placeholder="NIF"
                                aria-label="NIF"
                                aria-describedby="basic-addon1"
                                type="text"
                                label="NIF"
                                s={12}
                                error={
                                  isSubmitting && !values.NIF
                                    ? t("profile.pdnie")
                                    : ""
                                }
                                value={values.NIF}
                                name="NIF"
                                onChange={handleChange}
                                isInvalid={!!errors.NIF}
                                isValid={touched.NIF && !errors.NIF}
                                disabled={userOriginal.NIF}
                                icon="mode_edit"
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.NIF}
                              </Form.Control.Feedback>
                            </InputGroup>

                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  {t("profile.p3")}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                //placeholder={t("profile.p3")}
                                //aria-label="Código postal"
                                aria-describedby="basic-addon1"
                                type="text"
                                label="Código Postal"
                                s={12}
                                error={
                                  isSubmitting && !values.CodPostal
                                    ? t("profile.p3e")
                                    : ""
                                }
                                value={values.CodPostal}
                                name="CodPostal"
                                onChange={handleChange}
                                isInvalid={!!errors.CodPostal}
                                isValid={touched.CodPostal && !errors.CodPostal}
                                disabled={isSubmitting}
                                icon="mode_edit"
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.CodPostal}
                              </Form.Control.Feedback>
                            </InputGroup>

                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  {t("profile.p6")}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                //placeholder={t("profile.p6")}
                                aria-label="Dirección"
                                aria-describedby="basic-addon1"
                                type="text"
                                label="Dirección"
                                s={12}
                                error={
                                  isSubmitting && !values.Direccion
                                    ? t("profile.p6e")
                                    : ""
                                }
                                value={values.Direccion}
                                name="Direccion"
                                onChange={handleChange}
                                isInvalid={!!errors.Direccion}
                                isValid={touched.Direccion && !errors.Direccion}
                                disabled={isSubmitting}
                                icon="mode_edit"
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.Direccion}
                              </Form.Control.Feedback>
                            </InputGroup>

                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  {t("profile.email")}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <FormControl
                                //placeholder="Email"
                                aria-label="Email"
                                aria-describedby="basic-addon1"
                                type="text"
                                label="Email"
                                s={12}
                                error={
                                  isSubmitting && !values.Email
                                    ? t("profile.emaile")
                                    : ""
                                }
                                value={values.Email}
                                name="Email"
                                disabled
                                icon="do_not_disturb_alt"
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.Email}
                              </Form.Control.Feedback>
                            </InputGroup>

                            <InputGroup className="mb-3">
                              <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon1">
                                  {t("register.p4")}
                                </InputGroup.Text>
                              </InputGroup.Prepend>
                              <IntlTelTextInput
                                input
                                fieldName="Movil"
                                containerClassName="intl-tel-input"
                                inputClassName="form-control"
                                preferredCountries={["es"]}
                                value={values.Movil || ""}
                                placeholder=" "
                                onPhoneNumberChange={this.onChange}
                                onPhoneNumberBlur={this.onBlur}
                                isInvalid={!!errors.Movil}
                                isValid={touched.Movil && !errors.Movil}
                                disabled={isSubmitting}
                              />
                              <Form.Control.Feedback type="invalid"  className="intl-tel-input">
                                {errors.Movil}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Card.Text>
                        </Card.Body>
                      </Col>
                      <Col sm={4}>
                        <Row>
                          <Col sm={12}>
                            <img
                              className="profile-pic"
                              alt="Profile pic"
                              src={
                                isWebpSupported()
                                  ? "https://gecorcloud.com/images/user-profile.webp"
                                  : "https://gecorcloud.com/images/user-profile.png"
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        {isSubmitting && (<Alert show={showOk} variant="success">
                          <Alert.Heading>
                            {t("register.signinSuccessTitle")}
                          </Alert.Heading>
                          <p>{t("profile.update-success-message")}</p>
                          <div className="d-flex justify-content-end">
                            <Button
                              onClick={() => {
                                this.setShowOk(false);
                                setSubmitting(false);
                                dispatch(clearError());
                              }}
                              variant="outline-success"
                            >
                              {t("register.signinSuccessButton")}
                            </Button>
                          </div>
                        </Alert>)}

                        {error && (<Alert
                          show={showError}
                          variant="danger"
                          onClose={() => {
                            this.setShowError(false);
                          }}
                          dismissible
                        >
                          <Alert.Heading>{t("register.errorTitle")}</Alert.Heading>
                          <p>{t("profile.update-error-message")}</p>
                        </Alert>)}
                      </Col>
                      <Col sm={4}>
                        <Button
                          waves="light"
                          type="submit"
                          disabled={!isValid || isSubmitting}
                        >
                          {t("profile.save")}
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Form>
            </Row>
          )}
        </div>
      </DynamicBackground >
    );
  }
}

const registerValuesFormik = {
  Nombre: "",
  Apellidos: "",
  NIF: "",
  CodPostal: "",
  Direccion: "",
  Email: "",
  Movil: "",
};

const tUserSettingPage = withTranslation()(UserSettingPage);
const formikEnhancer = withFormik({
  enableReinitialize: true,
  validationSchema: ({ t }) => {
    return Yup.lazy(values => {
      return Yup.object().shape({
        Nombre: Yup.string().nullable().required(t("register.required_name")).min(1),
        Apellidos: Yup.string().nullable(),
        NIF: Yup.string().nullable().test({
          name: 'NIF',
          message: t("register.p6t"),
          test(value, ctx) {
            return authApi.validateSpanishId(value);
          }
        }),
        CodPostal: Yup.number().nullable().typeError(t('register.invalide_cp')),
        Direccion: Yup.string().nullable(),
        Movil: Yup.string().nullable().typeError(t('register.p4t')).test({
          name: 'Movil',
          message: t("register.p4t"),
          test(value, ctx) {
            return authApi.validateMobile(values.Movil);
          }
        }),
        Email: Yup.string()
          .required(t("register.required_email"))
          .email(t("register.p2t")),
      });
    });
  },
  mapPropsToValues: () => registerValuesFormik,
  handleSubmit: (payload, { props, f, resetForm }) => {
    props.dispatch(updateRequest(payload));
  },
  displayName: "SettingsForm"
})(tUserSettingPage);

const mapStateToProps = state => {
  const { users, entities } = state;
  const { user, error, updated } = users;
  const { entity } = entities;

  return {
    user,
    userOriginal: user,
    entity,
    error,
    updated
  };
};

const tFormikEnhancer = withTranslation()(formikEnhancer);
const connectedUserSettingPage = connect(mapStateToProps)(tFormikEnhancer);

// const tUserSettingPage = withTranslation()(UserSettingPage);
// const connectedUserSettingPage = connect(mapStateToProps)(tUserSettingPage);

export { connectedUserSettingPage as UserSettingPage };
