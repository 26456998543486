import React from 'react';
import { connect } from 'react-redux';
import './SurveysPage.css';
import { surveysActions } from './../../actions';
import Spinner from 'react-bootstrap/Spinner';
import CardDeck from 'react-bootstrap/CardDeck';
import { DynamicBackground } from './../../components'
import { SurveyHeader } from './../../components/SurveyCard/SurveyHeader';
import { forwardToWithParams } from '../../helpers';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { filteredSurveys, filteredSurveysConducted } from '../../reducers/surveys.reducer';
import { SurveyCard } from './../../components/SurveyCard/SurveyCard';
import { withTranslation } from 'react-i18next';

const intialState = {
    open: false,
    width: 0,
    height: 0,
}

class SurveysPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = intialState;
        this.handleChooseSurvey = this.handleChooseSurvey.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changeVista = this.changeVista.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(surveysActions.getSurveys());
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.dispatch(surveysActions.clearSearchSurvey());
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleChooseSurvey(survey) {
        let id = survey.EncuestaID === 0 || survey.EncuestaID;
        forwardToWithParams(`/survey-detail/${id}`, survey);
    }

    handleChange(e) {
        this.props.dispatch(surveysActions.searchSurvey(e.target.value));
    }

    changeVista(newVista) {
        this.props.dispatch(surveysActions.changeView(newVista));
    }

    render() {
        const { mySurveys, user, entity, vista,t } = this.props;
        return (
            <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={(entity && entity.AyuntamientoID) > 0 ? "" : isWebpSupported() ? "https://gecorcloud.com/images/chicago-690364_960_720.webp" : "https://gecorcloud.com/images/chicago-690364_960_720.jpg"}>
                <div id="survey-page">
                    <SurveyHeader handleChange={this.handleChange} changeVista={this.changeVista} pendientes={vista == "pending"} />
                    {/*  SurveyCard */}
                    <CardDeck>
                        {(user && mySurveys) ? mySurveys.map(survey =>
                            <SurveyCard
                                key={survey.EncuestaID}
                                survey={survey}
                                user={user}
                                onCustomClick={this.handleChooseSurvey} />)
                            :
                            <Spinner className="spinner-survs" animation="border" role="status" variant="primary">
                                <span className="sr-only">Loading...</span>
                            </Spinner>}

                        {user && mySurveys && mySurveys.length == 0 && <div className='info-error'>{t('survey.noSurvey')}</div>}

                    </CardDeck>
                </div>
            </DynamicBackground>
        )
    }
}

const mapStateToProps = (state) => {
    const { entities, surveys, users } = state;
    const { surveyQuery, vista } = surveys;
    const { user } = users;
    const { entity } = entities;

    return {
        mySurveys: vista == "pending" ? filteredSurveys(state) : filteredSurveysConducted(state),
        surveyQuery,
        vista,
        user,
        entity,
    };
}


const tSurveysPage = withTranslation()(SurveysPage);
const connectedSurveysPage = connect(mapStateToProps)(tSurveysPage);
export { connectedSurveysPage as SurveysPage };
