import React from "react";
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import "./NewsCard.css";
import { forwardToWithParams } from "../../helpers";
import { useTranslation } from "react-i18next";
import { blobGecorPath } from "./../../constants/config.constants";

export const NewsCard = props => {
  const { t } = useTranslation();
  const { notices } = props;

  return (
    notices &&
    notices.map(item => (
      <div className="GamesBox" key={item.noticiaID}>
        <div className="notice-box">
          <Col md={4}>
            <Card className="notice-part" style={{ width: "18rem" }}>
              <Card.Img
                className="imgGamesBox"
                variant="left"
                /*src={blobGecorPath + item.urlImage}*/
                src={(item.urlImage).indexOf("https")>-1 ? item.urlImage:blobGecorPath + item.urlImage}
                alt="notice-img"
              />
              <Card.Body className=" ">
                <Card.Title className="GamesBoxTitulo">
                  <b>{item.titulo}</b>
                </Card.Title>
                <hr className="line"></hr>
                <Card.Text className="GamesBoxTexto text">
                  {item.resumen}
                </Card.Text>
                <Button
                  variant="primary"
                  onClick={() =>
                    forwardToWithParams(
                      `/notice-extend/${item.noticiaID}`,
                      item
                    )
                  }
                >
                  {t("newscard.p1")}
                </Button>
              </Card.Body>
            </Card>
          </Col>
          </div>
      </div>
    ))
  );
};

export default NewsCard;
