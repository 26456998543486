import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../HomePage/HomePage.css';

import queryString from 'query-string'
import { TimelineLite } from "gsap/all";

import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import { BannerFaq, SectionHeader, SectionApp, SectionVideo } from '../HomePage';
import { CompanyPage } from '../CompanyPage';

class UsaPage extends Component {

  constructor(props) {
    super(props);

    this.logoTl = new TimelineLite({ paused: true });

    this.content = null;
    this.head = null;
    this.subheadx = null;
    this.feature = null;
    // this.description = null;
    this.icons = [];

    this.state = {
      bkLanguage: i18next.language || window.localStorage.i18nextLng,
      aytoName: '',
      ao: queryString.parse(this.props.location.search),
      show: false,
      modalNeverShow: localStorage.modalNeverShow ? JSON.parse(localStorage.modalNeverShow) : false,
    };

    this.handleSelectedEntity = this.handleSelectedEntity.bind(this);

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };

    this.handleModalNeverShow = () => {
      this.setState({ modalNeverShow: true });
      localStorage.modalNeverShow = true;
      this.handleHide();
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logoTl
      // .set(this.content, { autoAlpha: 1 })// show content div
      // .from(this.head, 0.5, { left: 100, autoAlpha: 0 })
      .from(this.subheadx, 0.5, { left: 100, autoAlpha: 0 }, 0.3) // added -0.25 seconds prior to end this.of timeline
      // .from(this.feature, 0.5, { scale: .5, autoAlpha: 0 }, "feature") // added 0.5 seconds after end of timeline
      // .from(this.description, 0.5, { left: 100, autoAlpha: 0 }, "feature+=0.25")
      .staggerFrom(this.icons, 0.2, { scale: 0, autoAlpha: 0 }, 0.1); //animate all icons with 0.1 second stagger

    this.logoTl.play()

    const { entity } = this.props;
    if (entity) this.setState({ aytoName: entity.Nombre || '' });

    i18next.changeLanguage('en');
    localStorage.removeItem('i18nextLng');
  }

  componentWillLeave() {
    i18next.changeLanguage(this.state.bkLanguage);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
    i18next.changeLanguage(this.state.bkLanguage);
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('modalTrigger');
    if (this.isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this.trackScrolling);
      this.handleShow();
    }
  };

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.entity) {
      const { entity } = nextProps;
      this.setState({ aytoName: entity.Nombre });
    }
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  handleSelectedEntity(entity) {
    this.setState({ aytoName: entity.Nombre });
  }


  render() {
    const { t } = this.props;
    return (
      <div id="home-page" ref={div => this.subheadx = div}>

        <SectionHeader />
        <SectionVideo t={t} />
        <SectionApp {...this.props} />
        <BannerFaq t={t} />

        {/* <CitiesPage /> */}

        <CompanyPage />

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { entities } = state;
  const { entityInfo } = entities;


  return {
    entityInfo,
  };
}

const tUsaPage = withTranslation()(UsaPage);
const connectedUsaPage = connect(mapStateToProps)(tUsaPage);
export { connectedUsaPage as UsaPage };