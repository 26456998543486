import React from 'react';
import './Footer.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { forwardTo,forwardToWithParams } from '../../helpers'

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
/* ICONS */

import { useTranslation } from 'react-i18next';

export const FooterComponent_268 = props => {
    const { t } = useTranslation();

    return (
        <div id="footer">
            <footer >
                <div className="footer-top">
                    <Container>
                        <Row>
                            <Col sm={4}>
                                <div className="footer-about">
                                    <Row>
                                        <h3 className="footer-informacion">Chiclana</h3>
                                    </Row>
                                    <p>{t('footer.chiclana-2')}</p>
                                    <p>Powered by GECOR.</p>

                                </div>
                            </Col>
                            <Col sm={5}>
                                <div className="footer-contact" >
                                    <h3>{t("footer.co2")}</h3>
                                    <p>
                                        <FontAwesomeIcon icon={faMapMarker} /><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}>Alameda Principal 21 , Planta 6 Oficina 603, Málaga, 29001</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faPhone} /><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}> +34 952 02 84 23</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faFacebookSquare} />
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/GECOR.Esp">{t("footer.co2-4-1")}</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faTwitterSquare} />
                                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/gecorweb?lang=es">{t("footer.co2-4-2")}</a>
                                    </p>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className="footer-links">
                                    <Row>
                                        <h3 className="footer-informacion">{t("footer.co3")}</h3>
                                    </Row>
                                    <Row>
                                        <div className="linkFooter">
                                            <Col sm={12}>
                                            <p
                                                className="legal-advice"
                                                onClick={() => forwardToWithParams("/aviso-legal", {pdf:"https://gecorcloud.com/pdf/AVISO LEGAL.pdf"})}
                                            >
                                                <a
                                                rel="noopener noreferrer"
                                                href="#"
                                                onClick={e => e.preventDefault()}
                                                >
                                                {t("footer.co3-1")}
                                                </a>
                                            </p>
                                            <p
                                                className="legal-advice"
                                                onClick={() => forwardToWithParams("/privacy-pdf", {pdf:"https://gecorcloud.com/pdf/politica-de-privacidad.pdf"})}
                                            >
                                                <a
                                                rel="noopener noreferrer"
                                                href="#"
                                                onClick={e => e.preventDefault()}
                                                >
                                                {t("footer.co3-2")}
                                                </a>
                                            </p>
                                                {/*<p className="contact" onClick={() => forwardTo('/notice')}><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}>{t("footer.co3-6")}</a></p>*/}

                                            </Col>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </div>
    );
}

export default FooterComponent_268;