import './SurveyCard.css';
import { useTranslation } from 'react-i18next';
import React from 'react';
import '../../pages/SurveysPage/SurveysPage.css';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'


export const SurveyCard = props => {
    const { survey, onCustomClick } = props;
    const { t } = useTranslation();
    return (
        <div id="survey-card" onClick={(e) => onCustomClick(survey)}>
            <Card className="survey-scard" style={{ width: '30rem' }}>
                <Card.Img variant="top" src={survey.RutaFoto} />
                <Card.Body>
                <Card.Title>{survey.Titulo}</Card.Title>
                <hr></hr>
                <Card.Text>
                    <b>{survey.DescripcionCorta}</b>
                    <hr></hr>
                    {survey.Responsable}
                </Card.Text>
                </Card.Body>
                <Card.Footer>
                <small className="text-muted">
                        {!survey.Completado && <div className="info-desc"> <FontAwesomeIcon icon={faCalendarDay} />{t('survey.p4')} {survey.FechaFin}</div>}
                        {survey.Completado && <div className="info-desc"> <FontAwesomeIcon icon={faCalendarDay} />{t('survey.p6')} {survey.Fecha}</div>}
                        <Badge pill variant="warning">
                            Pendiente
                        </Badge>
                </small>
                </Card.Footer>
            </Card>
        </div>
    );
}

export default SurveyCard;