import React from 'react';
import { connect } from 'react-redux';
import './UserDataTcotillasPage.css';
import { DynamicBackground } from './../../components'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { TimelineLite } from "gsap/all";
import { isWebpSupported } from 'react-image-webp/dist/utils';

class UserDataTcotillasPage extends React.Component {

  constructor(props) {
    super(props);
    this.logoTl = new TimelineLite({ paused: true });
    this.subhead = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logoTl
      .set(this.content, { autoAlpha: 1 })// show content div
      .from(this.head, 0.5, { left: 100, autoAlpha: 0 })
      .from(this.subhead, 0.5, { left: -100, autoAlpha: 0 }, "-=0.25") // added -0.25 seconds prior to end this.of timeline
    this.logoTl.play()
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));

  }

  render() {

    return (

      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/bgLocal.webp" : "https://gecorcloud.com/images/bgLocal.jpg"}>

        <div className="container">
          <div className="infoBox " ref={div => this.subhead = div}>
            {/*   Box 1   */}
            <Card className='card-tcotillas-user-data'>
              <Card.Header as="h5" >Información básica sobre el tratamiento de sus datos personales</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                < Table striped bordered hover className='table-user-data-tcotillas'>
                    <tbody>
                      <tr>
                        <td><b>Responsable</b></td>
                        <td>Ayuntamiento de las Torres de Cotillas.</td>
                      </tr>
                      <tr>
                        <td><b>Finalidad</b></td>
                        <td>Gestionar las solicitudes de ciudadanos que presentan quejas o incidencias respecto de la vía pública en calzada, alumbrado, aceras, jardines y edificios, y el registro del interesado para poder registrarlas, en su caso, así como aquellas otras necesarias para cumplir con la normativa administrativa.</td>
                      </tr>
                      <tr>
                        <td><b>Derechos</b></td>
                        <td>Acceso, rectificación, supresión, oposición, limitación y/o portabilidad, enviando un correo electrónico a: dpd@lastorresdecotillas.es.</td>
                      </tr>
                    </tbody>
                  </Table>

                  
                  <br></br>

                  <h4><b>Información detallada sobre el tratamiento de los datos de carácter personal del interesado: </b></h4>
                  <br></br>

                  <p><b>Cuando el interesado pinche en “aquí”</b> en la capa de información básica, <b>se deberá redirigir al mismo a la siguiente información detallada mediante su apertura en una página web, la descarga de la misma, o ambos:</b> </p>
                  
                  <p><b>Información detallada sobre el tratamiento de sus datos personales:</b></p>
                  <p><b>Responsable: </b>Se informa al interesado/a que sus datos personales van a ser objeto de tratamiento por parte del Ayuntamiento de Las Torres de Cotillas (Responsable del tratamiento), con CIF P-3003800-D, y dirección Plaza Adolfo Suárez, nº1, 30565, Las Torres de Cotillas, Murcia, e incorporados al sistema de tratamiento “Gestión de obras públicas”. </p>
                  <p><b>Delegado de Protección de datos: </b>El Ayuntamiento de las Torres de Cotillas cuenta con el apoyo y nombramiento del Delegado de Protección de datos, cuyos datos de contacto son: dpd@lastorresdecotillas.es.</p>
                  <p><b>Finalidades: </b>Gestionar las solicitudes de ciudadanos que presentan quejas o incidencias respecto de la vía pública en calzada, alumbrado, aceras, jardines y edificios, así como el registro del interesado para poder registrarlas, en su caso. Asimismo, se tratarán los datos personales para gestionar las comunicaciones relacionadas con la solicitud presentada y aquellas otras necesarias para cumplir con la normativa administrativa, como contactar con usted en caso de ser necesario.</p>
                  <p><b>Legitimación: </b>El tratamiento de los datos personales está legitimado en: </p>
                  <p>El cumplimiento de obligaciones legales aplicables al Responsable del tratamiento, a saber: </p>
                  <ul>
                    <li>La Ley 39/2015, de 1 de octubre, del Procedimiento Administrativo Común de las Administraciones Públicas. </li>
                    <li>Ley 13/2015, de 30 de marzo, de ordenación territorial y urbanística de la Región de Murcia. </li>
                  </ul>
                  <p>El cumplimiento de una misión realizada en interés público aplicables al Responsable del tratamiento, a saber: </p>
                  <ul>
                    <li>La Ley 39/2015, de 1 de octubre, del Procedimiento Administrativo Común de las Administraciones Públicas. </li>
                    <li>Ley 7/1985, de 2 de abril, Reguladora de las Bases del Régimen Local. </li>
                  </ul>
                  <p>En relación a la utilización de WhatsApp, el tratamiento de sus datos personales está legitimado en su consentimiento. El envío de la queja o sugerencia vía WhatsApp supone su manifestación expresa de aceptar el tratamiento de datos en base a su consentimiento. No facilitar los datos o información necesaria dará lugar a que no podamos tener en cuenta su solicitud. Tiene derecho a revocar el consentimiento sin que afecte al tratamiento basado en el consentimiento previo a su retirada enviando un WhatsApp con la palabra “BAJA”, o enviando un correo a la dirección dpd@lastorresdecotillas.es.</p>
                  <br></br>
                  <p><b>Plazo de supresión: </b>Los datos se mantendrán durante el tiempo que sea necesario para cumplir con la finalidad para la que se recabaron y para determinar las posibles responsabilidades que se pudieran derivar de dicha finalidad y del tratamiento de los datos. Será de aplicación lo dispuesto en la normativa de archivos y documentación, y en tanto usted no solicite su supresión. </p>
                  <p><b>Destinatarios: </b>Sus datos serán cedidos en los casos legalmente establecidos a aquellas entidades, públicas y/o privadas con las que el Ayuntamiento de Las Torres de Cotillas requiera compartir sus datos para la prestación de la solicitud.</p>
                  <p><b>Transferencias internacionales: </b>No están previstas transferencias internacionales de los datos.</p>
                  <p><b>Decisiones automatizadas: </b>No se realizan decisiones automatizadas.</p>
                  <p><b>Ejercicio de derechos: </b>Para ejercitar los derechos que procedan conforme a la normativa de protección de datos (acceso, rectificación, supresión, oposición, limitación, portabilidad, así como a no ser objeto de decisiones basadas únicamente en el tratamiento automatizado de sus datos, cuando procedan) frente al tratamiento de sus datos personales dirigiendo un escrito al registro general o la sede electrónica del Ayuntamiento de Las Torres de Cotillas, o por correo electrónico a la siguiente dirección: dpd@lastorresdecotillas.es, facilitando copia de su DNI o documento identificativo equivalente. </p>
                  <p><b>AEPD: </b>Podrá dirigirse a la Agencia Española de Protección de Datos (www.aepd.es) para presentar una reclamación cuando no considere debidamente atendida su solicitud.</p>
                </Card.Text>
              </Card.Body>
            </Card>

          </div>
        </div>

      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedUserDataTcotillasPage = connect(mapStateToProps)(UserDataTcotillasPage);
export { connectedUserDataTcotillasPage as UserDataTcotillasPage }; 