import React from 'react';
import { connect } from 'react-redux';
import './GweetPage.css';

import { withTranslation } from 'react-i18next';
import SectionHeader from './SectionHeader';
import SectionProcess from './SectionProcess';
import SectionUseIt from './SectionUseIt';
import BannerContact from '../../components/BannerContact/BannerContact';

class GweetPage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {


        return (
            <div id="gweet-page">

                <SectionHeader />
                <SectionProcess />
                <SectionUseIt />
                <BannerContact />

            </div>


        );
    }
}

const mapStateToProps = (state) => {
    const { users } = state;
    const { user } = users;
    return {
        user
    };
}


const tGweetPage = withTranslation()(GweetPage);
const connectedGweetPage = connect(mapStateToProps)(tGweetPage);
export { connectedGweetPage as GweetPage };

