import React from 'react';
import './Footer.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { forwardTo } from '../../helpers'
import { isWebpSupported } from 'react-image-webp/dist/utils';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitterSquare, faYoutube, faVimeo } from '@fortawesome/free-brands-svg-icons';
/* ICONS */

import { useTranslation } from 'react-i18next';

export const FooterUsaComponent = props => {

    const { isNotFound } = props;
    const { t } = useTranslation();

    return (
        <div id="footer">
            {!isNotFound &&
                <div className="container">
                    <hr />
                    <Row className="ue-container">
                        <Col sm={3} className="d-flex justify-content-center align-self-center">
                            <img alt="Background image" src={isWebpSupported() ? "https://gecorcloud.com/images/ue.webp" : "https://gecorcloud.com/images/ue.jpg"} />
                        </Col>
                        <Col sm={9}>
                            {t("footer.ue-text")}
                        </Col>
                    </Row>
                </div>
            }
            <footer style={{
                backgroundImage: `url("${isWebpSupported() ? 'https://gecorcloud.com/images/footer2.webp' : 'https://gecorcloud.com/images/footer2.jpg'}")`,
            }}>
                <div className="footer-top">
                    <Container>
                        <Row>
                            <Col sm={4}>
                                <div className="footer-about">
                                    <Row>
                                        <h3 className="footer-informacion">{t("footer.co1")}</h3>
                                    </Row>
                                    <p>{t("footer.co1-1")}</p>
                                    <p>{t("footer.co1-2")}</p>
                                </div>
                            </Col>
                            <Col sm={5}>
                                <div className="footer-contact" >
                                    <h3>{t("footer.co2")}</h3>
                                    <p>
                                        <FontAwesomeIcon icon={faMapMarker} /><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}>{t("footer.co2-1")}</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faPhone} /><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}> 952 02 84 23</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faFacebookSquare} />
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/GecorUSA"> {t("footer.co2-4-1")}</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faTwitterSquare} />
                                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/gecorwebusa"> {t("footer.co2-4-2")}</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faYoutube} />
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCE_66IYxH1pMbexvV0-ZMag"> {t("footer.co2-4-3")}</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faVimeo} />
                                        <a target="_blank" rel="noopener noreferrer" href="https://vimeo.com/user100199053"> {t("footer.co2-4-4")}</a>
                                    </p>
                                    {/*<p><i className="fas fa-envelope"></i> Email: <a href="mailto:hello@domain.com">info@gecorweb.com</a></p>*/}
                                    <p className="contact" onClick={() => forwardTo('/contact')}>
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}> {t("footer.co2-5")}</a>
                                    </p>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className="footer-links">
                                    <Row>
                                        <h3 className="footer-informacion">{t("footer.co3")}</h3>
                                    </Row>
                                    <Row>
                                        <div className="linkFooter">
                                            <Col sm={6}>
                                                <p className="legal-advice" onClick={() => forwardTo('/legal-advice')}><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}>{t("footer.co3-1")}</a></p>
                                                <p ><a href="https://gecorcloud.com/pdf/politica-de-privacidad.pdf" target="_blank" rel="noopener noreferrer" className="enlace">{t("footer.co3-2")}</a></p>
                                                <p><a href="https://gecorcloud.com/pdf/pc-poltica-de-calidad-ed-04.pdf" target="_blank" rel="noopener noreferrer" className="enlace" >{t("footer.co3-3")}</a></p>
                                                <p className="contact" onClick={() => forwardTo('/cities')}><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}>{t("footer.co3-4")}</a></p>
                                            </Col>
                                        </div>
                                        <div className="linkFooter">
                                            <Col sm={6}>
                                                <p className="contact" onClick={() => forwardTo('/company')}><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}>{t("footer.co3-5")}</a></p>
                                                {/*<p className="contact" onClick={() => forwardTo('/notice')}><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}>{t("footer.co3-6")}</a></p>*/}
                                                <p className="contact" onClick={() => forwardTo('/faq-g')}><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}>{t("footer.co3-7")}</a></p>
                                            </Col>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </div>
    );
}

export default FooterUsaComponent;