import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card, Button } from 'react-bootstrap'
import './CitizenGuidePageOn.css';
import { DynamicBackground } from './../../../../components'
import { isWebpSupported } from 'react-image-webp/dist/utils';

class CitizenGuidePageOn extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {

    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.1)" background={isWebpSupported() ? "https://gecorcloud.com/images/CitybgGuide.webp" : "https://gecorcloud.com/images/CitybgGuide.jpg"}>
        <div className="practBoxOn">
            <div className="infoTit">
              <h1><b tabIndex="5">PRÁCTICAS SOBRE EL MEDIO AMBIENTE</b></h1>
            </div>
            {/*   Box 2  */}
            <Card>
              <Card.Header tabIndex="6" as="h5">El agua</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 4, offset: 0 }}>
                      <img tabIndex="7" src={isWebpSupported() ? "https://gecorcloud.com/images/envir3.webp" : "https://gecorcloud.com/images/envir3.png"} alt="imagen envir3" />
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="8" className="text"> En este enlace encontrará toda la información referente al agua indicada en el portal ambiental de la Generalitat Valenciana.</p>
                          <hr className="line" ></hr>
                          <a tabIndex="9" href="https://mediambient.gva.es/es/web/agua" target="_blank" rel="noopener noreferrer" className="enlace" ><Button variant="primary">Saber más</Button></a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            {/*   Box 4   */}

            <Card>
              <Card.Header tabIndex="10" as="h5">Biodiversidad</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 4, offset: 0 }}>
                      <img tabIndex="11" src={isWebpSupported() ? "https://gecorcloud.com/images/city4.webp" : "https://gecorcloud.com/images/city4.png"} alt="imagen envir" />
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="12" className="text">La heterogeneidad del paisaje la que propicia la diversificación biológica, ya se sabe que en la variedad está el gusto. Quizás pensaste erróneamente que ya estaba todo descubierto, sin embargo hay infinidad de zonas por explorar y descubrir.
                </p>
                          <hr className="line" ></hr>
                          <a tabIndex="13" href="https://www.ecoembes.com/es/planeta-recicla/blog/por-que-espana-es-el-pais-europeo-con-mas-biodiversidad" target="_blank" rel="noopener noreferrer" className="enlace" ><Button variant="primary">Biodiversidad</Button></a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            {/*   Box 5   */}

            <Card>
              <Card.Header tabIndex="14" as="h5">Buenas prácticas ambientales en la oficina</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 4, offset: 0 }}>
                      <img tabIndex="154" src={isWebpSupported() ? "https://gecorcloud.com/images/city5.webp" : "https://gecorcloud.com/images/city5.png"} alt="envir4" />
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="16" className="text"> Gran parte de los profesionales realizan su trabajo diario en oficinas. Es importante, reducir al máximo el impacto ambiental en este sector, las buenas prácticas que se han de instaurar son tremendamente sencillas y económicas.
                </p>
                          <hr className="line" ></hr>
                          <a tabIndex="17" href="https://www.seo.org/wp-content/uploads/2014/03/Manual-de-Buenas-Pr%C3%A1cticas-en-Oficina.-Ed_1-_01032014.pdf" target="_blank" rel="noopener noreferrer" className="enlace" ><Button variant="primary">Descarga la Guía de Buenas prácticas ambientales en la oficina</Button></a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            {/*   Box 6   

            <Card>
              <Card.Header tabIndex="18" as="h5">Implantación de la Norma ISO 14001 en empresas</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 4, offset: 0 }}>
                      <img tabIndex="19" src={isWebpSupported() ? "https://gecorcloud.com/images/city6.webp" : "https://gecorcloud.com/images/city6.png"} alt="city 6" />
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="20" className="text">Cada vez es más necesario forjar un compromiso entre empresa y medio ambiente, ya sea por necesidades sociales como por necesidades económicas o imposiciones normativas. Este compromiso puede adquirirse certificando tu entidad a través de la norma ISO 14001. Aquí podrás informarte acerca de los beneficios, pasos a seguir y finalidad.
                </p>
                          <hr className="line" ></hr>
                          <a tabIndex="21" href="http://www.lrqa.es/certificaciones/iso-14001-medioambiente/" target="_blank" rel="noopener noreferrer" className="enlace" ><Button variant="primary">"Descarga la Guía de Implantación de la Norma ISO 14001 en empresas"</Button></a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
*/}

            <Card>
              <Card.Header tabIndex="22" as="h5">¿Se puede comprar sin plásticos?</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 4, offset: 0 }}>
                      <img tabIndex="23" src={isWebpSupported() ? "https://gecorcloud.com/images/envir6.webp" : "https://gecorcloud.com/images/envir6.png"} alt="envir 6" />
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="24" className="text" >Mientras se instaura el Día Internacional Sin Bolsas de Plástico, surge el movimiento Zero waste y los jóvenes se revolucionan bajo el lema Fridays for future, la industria alimentaria... </p>
                          <hr className="line" ></hr>
                          <a tabIndex="25" href="https://gecorcloud.com/pdf/sinPlastico.pdf" target="_blank" rel="noopener noreferrer" className="enlace" ><Button variant="primary">¿Se puede comprar sin plásticos?</Button></a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

          </div>
      </DynamicBackground>
    );
  }


}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedCitizenGuidePageOn = connect(mapStateToProps)(CitizenGuidePageOn);
export { connectedCitizenGuidePageOn as CitizenGuidePageOn };
