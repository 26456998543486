import './PoiDetail.css';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import { forwardTo } from '../../helpers';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { Player } from '../Player';
/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitterSquare, faWhatsappSquare } from '@fortawesome/free-brands-svg-icons'


export const PoiDetail = props => {
    const { poi, vista } = props;
    // const lengthText = 500;
    // const [mostrarTexto, setMostrarTexto] = React.useState(false)
    // const onClickText = () => { setMostrarTexto(!mostrarTexto) }
    const shareUrl = `https://gecorweb.com/interest-points-detail/list/${poi.idItem}`;
    const { t } = useTranslation();
    return (
        <div id="poi-detail">
            <Row>
                <Col md={12}>
                    <div className="poi">
                        <div className="header">
                            {window.innerWidth > 768 && < Row className="row-hed-poi">

                                <Col sm={2}>
                                    {vista == "maps" && <Button className="back-poi-btn" onClick={() => forwardTo('/interest-points-map')}>{t('poicard.p3')}</Button>}
                                    {vista == "list" && <Button className="back-poi-btn" onClick={() => forwardTo('/interest-points')}>{t('poicard.p3')}</Button>}
                                </Col>
                                <Col sm={8} md={8} lg={8}>
                                    <div className="header-title">{poi.title}</div>
                                </Col>
                                <Col sm={2} className='float-right'>
                                    {poi.audio && <Player className="float-right" url={poi.audio} />}
                                </Col>
                            </Row>}
                            {window.innerWidth <= 768 && <Row className="row-hed-poi">
                                {vista == "maps" &&
                                    <Col xs={6}>
                                        <Button className="back-poi-btn" onClick={() => forwardTo('/interest-points-map')}>{t('poicard.p3')}</Button>
                                    </Col>
                                }
                                {vista == "list" &&
                                    <Col xs={6}>
                                        <Button className="back-poi-btn" onClick={() => forwardTo('/interest-points')}>{t('poicard.p3')}</Button>
                                    </Col>
                                }
                                {poi.audio && <Col xs={6} className='float-right'>
                                    <Player url={poi.audio} />
                                </Col>}
                                <Col xs={12}>
                                    <div className="header-title">{poi.title}</div>
                                </Col>
                            </Row>}
                        </div>
                        <div className="poi-img-cnt-jr">
                            {poi && poi.Images ?
                                <Carousel>
                                    {poi.Images.map((im, key) => (
                                        <Carousel.Item key={key}>
                                            <img
                                                className="poi-img-jr"
                                                src={im.image_data}
                                                alt="Imagen Carousel"
                                            />
                                        </Carousel.Item>))}
                                </Carousel>
                                :
                                <div className="no-img"></div>
                            }
                        </div>

                        <div className="info">
                            <div className="info-desc">

                            <div dangerouslySetInnerHTML={{ __html: poi.description }}></div>
                            </div>
                        </div>
                        <br />
                        <hr />
                        <div className="datos">
                            <div dangerouslySetInnerHTML={{ __html: poi.horarioVisita }}></div>
                            <div className="info-desc">{poi.precioEntrada && poi.precioEntrada.split('\n').map(function (item, key) {
                                return (
                                    <span key={key}>
                                        {item}
                                        <br />
                                    </span>
                                )
                            })
                            }
                                {poi.rutaComprarEntrada &&
                                    <Button className="comprar-poi-btn" href={poi.rutaComprarEntrada} target="_blank" rel="noopener noreferrer">{t('poicard.p6')}</Button>}
                            </div>
                            <br />

                            {poi.telefono && <div className="info-desc">{t('poicard.p4')}: <a href={`tel:" + ${poi.telefono}`}>{poi.telefono}</a>  </div>}
                            {poi.email && <div className="info-desc">{t('poicard.p8')}: <a href={`mailto:" + ${poi.email}`}>{poi.email}</a> </div>}
                            {poi.web && <div className="info-desc">{t('poicard.p1')}: <a href={poi.web} target="_blank" rel="noopener noreferrer" className="enlace">{poi.web}</a></div>}
                            <Row className="row-url" >
                                {poi.facebook && <Col className="info-desc"><a href={poi.facebook} target="_blank" rel="noopener noreferrer" className="enlace"><FontAwesomeIcon icon={faFacebookSquare} className="facebook" /></a></Col>}
                                {poi.twitter && <Col className="info-desc">
                                    <a href={poi.twitter} target="_blank" rel="noopener noreferrer" className="enlace"> <FontAwesomeIcon icon={faTwitterSquare} className="twitter" /></a></Col>}
                            </Row>
                        </div>
                        {poi.video && <div className="video center">
                            <iframe title="video" className="iframe-img" width="100%" height="100%"
                                src={poi.video} frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen=""></iframe>
                        </div>}
                        <hr />
                        <div className="map">
                            <div className="map-title">
                                <Row>
                                    <Col sm={9}>
                                        <FontAwesomeIcon icon={faMapMarkerAlt} />{poi.ubicacion}
                                    </Col>
                                    <Col sm={3}>
                                        <Button className="navigation-poi-btn float-right" href={`http://maps.google.com/maps?daddr=${poi.latitud},${poi.longitud}&zoom=15`} target="_blank" rel="noopener noreferrer">{t('poicard.p2')}</Button>
                                    </Col>
                                </Row>
                            </div>
                            <img src={`https://maps.googleapis.com/maps/api/staticmap?&zoom=15&size=700x180&markers=${poi.latitud},${poi.longitud}&key=AIzaSyA-89Oq-WqMG2mrwAO76RegV5iUTcDE0gc`} alt="mapa" />
                        </div>
                        <div className="share">
                            <div className="share-title">
                                {t('poicard.p5')}
                            </div>
                            <div className="share-icons">
                                <FacebookShareButton
                                    url={shareUrl}
                                    quote={`${poi.title} : ${poi.descripcionCorta}`}>
                                    <FontAwesomeIcon icon={faFacebookSquare} className="facebook" />
                                </FacebookShareButton>
                                <TwitterShareButton
                                    url={shareUrl}
                                    title={`${poi.title} : ${poi.descripcionCorta}`}>
                                    <FontAwesomeIcon icon={faTwitterSquare} className="twitter" />
                                </TwitterShareButton>
                                <WhatsappShareButton
                                    url={shareUrl}
                                    title={`${poi.title} : ${poi.descripcionCorta}`}>
                                    <FontAwesomeIcon icon={faWhatsappSquare} className="whatsapp" />
                                </WhatsappShareButton>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div >
    );
}

export default PoiDetail;