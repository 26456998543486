import React from 'react';
import './Footer.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { forwardTo,forwardToWithParams } from '../../helpers';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faPhone, faLanguage } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
/* ICONS */

import { useTranslation } from 'react-i18next';

export const FooterComponent_206 = props => {
    const { t, i18n } = useTranslation();

    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    return (
        <div id="footer">
            <div className="footer-banner-granada">
                <Row>
                    <Col sm={3}>
                        <img src="https://gecorcloud.com/images/escudo-grn-1.png" alt="escudo granada" className="escudo-grn-1"></img>
                    </Col>
                    <Col sm={6}>
                        <img src="https://gecorcloud.com/images/feder-grn.png" alt="escudo feder" className="feder-grn"></img>
                    </Col>
                    <Col sm={3}>
                        <img src="https://gecorcloud.com/images/europ-grn.png" alt="escudo europa" className="europ-grn"></img>
                    </Col>
                </Row>
            </div>
            <footer >
                <div className="footer-top">
                    <Container>
                        <Row>
                            <Col sm={4}>
                                <div className="footer-about">
                                    <Row>
                                        <h3 className="footer-informacion">Granada</h3>
                                    </Row>
                                    <p>GRANADA MEJORA</p>
                                    <p>Powered by GECOR.</p>
                                    <NavDropdown title={<>
                                        <FontAwesomeIcon icon={faLanguage} /> {t('footer.language')}
                                    </>}
                                        id="collasible-nav-dropdown">
                                        <NavDropdown.Item onClick={() => changeLanguage('es')}>Español</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => changeLanguage('en')}>English</NavDropdown.Item>
                                    </NavDropdown>
                                </div>
                            </Col>
                            <Col sm={5}>
                                <div className="footer-contact" >
                                    <h3>{t("footer.co2")}</h3>
                                    <p>
                                        <FontAwesomeIcon icon={faMapMarker} /><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}>Plaza del Carmen, 18071 Granada</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faPhone} /><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}> 958 539 697</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faFacebookSquare} />
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/AyuntamientodeGranada">{t("footer.co2-4-1")}</a>
                                    </p>
                                    <p>
                                        <FontAwesomeIcon icon={faTwitterSquare} />
                                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/aytogr">{t("footer.co2-4-2")}</a>
                                    </p>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className="footer-links">
                                    <Row>
                                        <h3 className="footer-informacion">{t("footer.co3")}</h3>
                                    </Row>
                                    <Row>
                                        <div className="linkFooter">
                                            <Col sm={12}>
                                            <p
                                                className="legal-advice"
                                                onClick={() => forwardToWithParams("/privacy-pdf", {pdf:"https://gecorcloud.com/pdf/politica-de-privacidad.pdf"})}
                                            >
                                                <a
                                                rel="noopener noreferrer"
                                                href="#"
                                                onClick={e => e.preventDefault()}
                                                >
                                                {t("footer.co3-2")}
                                                </a>
                                            </p>
                                                {/*<p className="contact" onClick={() => forwardTo('/notice')}><a rel="noopener noreferrer" href='#' onClick={e => e.preventDefault()}>{t("footer.co3-6")}</a></p>*/}
                                            </Col>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </div>
    );
}

export default FooterComponent_206;