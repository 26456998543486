import React, { Component } from 'react';
import { connect } from 'react-redux';
import './HomePage.css';
import queryString from 'query-string'
import { TimelineLite } from "gsap/all";

import { withTranslation } from 'react-i18next';
import SectionHeader from './SectionHeader';
import { SectionVideo } from './SectionVideo';
import { SectionApp } from './SectionApp';
import BannerFaq from './BannerFaq';
import BannerNotice from './BannerNotice';
import SectionNews from './SectionNews';
import SectionSuccessCases from './SectionSuccessCases';
import SectionGreenCities from './SectionGreenCities';
import { EntitySearch, BannerCertificate } from '../../components';
import { noticesActions } from '../../actions';

class HomePage extends Component {

  constructor(props) {
    super(props);

    this.logoTl = new TimelineLite({ paused: true });

    this.content = null;
    this.head = null;
    this.subheadx = null;
    this.feature = null;
    // this.description = null;
    this.icons = [];

    this.state = {
      aytoName: '',
      ao: queryString.parse(this.props.location.search),
      show: false,
      modalNeverShow: localStorage.modalNeverShow ? JSON.parse(localStorage.modalNeverShow) : false,
    };

    this.handleSelectedEntity = this.handleSelectedEntity.bind(this);

    this.handleShow = () => {
      this.setState({ show: true });
    };

    this.handleHide = () => {
      this.setState({ show: false });
    };

    this.handleModalNeverShow = () => {
      this.setState({ modalNeverShow: true });
      localStorage.modalNeverShow = true;
      this.handleHide();
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logoTl
      // .set(this.content, { autoAlpha: 1 })// show content div
      // .from(this.head, 0.5, { left: 100, autoAlpha: 0 })
      .from(this.subheadx, 0.5, { left: 100, autoAlpha: 0 }, 0.3) // added -0.25 seconds prior to end this.of timeline
      // .from(this.feature, 0.5, { scale: .5, autoAlpha: 0 }, "feature") // added 0.5 seconds after end of timeline
      // .from(this.description, 0.5, { left: 100, autoAlpha: 0 }, "feature+=0.25")
      .staggerFrom(this.icons, 0.2, { scale: 0, autoAlpha: 0 }, 0.1); //animate all icons with 0.1 second stagger

    this.logoTl.play()

    const { entity } = this.props;
    if (entity) this.setState({ aytoName: entity.Nombre || '' });

    //if (!this.state.modalNeverShow)
    //document.addEventListener('scroll', this.trackScrolling);

    this.props.dispatch(noticesActions.getAllNotices());
  }



  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById('modalTrigger');
    if (this.isBottom(wrappedElement)) {
      document.removeEventListener('scroll', this.trackScrolling);
      this.handleShow();
    }
  };

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.entity) {
      const { entity } = nextProps;
      this.setState({ aytoName: entity.Nombre });
    }
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  handleSelectedEntity(entity) {
    this.setState({ aytoName: entity.Nombre });
  }


  render() {
    const { t, notices } = this.props;
    const { ao } = this.state;
    return (
      <div id="home-page" ref={div => this.subheadx = div}>

        <EntitySearch className="ayto-borde" redirect={false} onSelected={this.handleSelectedEntity} ao={ao} hide={true} />
        <div className="carousel-ads"></div>
        {/*<SectionGreenCities t={t}/>*/}
        <SectionHeader />
        <SectionVideo t={t} />
        <SectionApp {...this.props} />
        <BannerFaq t={t} />
        <SectionSuccessCases />
        <SectionNews t={t} notices={notices} />
        { notices &&
          <BannerNotice t={t} />
        }
       {/*<BannerCertificate t={t} />*/}  
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { entities, news } = state;
  const { entity, entityInfo } = entities;
  const { notices } = news;

  return {
    entity,
    entityInfo,
    notices,
  };
}

const tHomePage = withTranslation()(HomePage);
const connectedHomePage = connect(mapStateToProps)(tHomePage);
export { connectedHomePage as HomePage };