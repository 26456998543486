import './QuestionCard.css';
import { useTranslation } from 'react-i18next';
import React from 'react';
import '../../pages/SurveysPage/SurveysPage.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk } from '@fortawesome/free-solid-svg-icons'
import { SurveySection } from './SurveySection';

export const QuestionCard = (props) => {
    const { question, type, user, isSubmitting, onChange, getRespuesta} = props;
    const { t } = useTranslation();
    const [respuesta, setRespuesta] = React.useState(isSubmitting ? question.respuesta : getRespuesta(question.PreguntaID));
    const changeValueRange = (value) => {
        setRespuesta(value);
        onChange(question.PreguntaID, type, value);      
    };

    const getDependeRespuesta = () => {         
        const respAux = question && question.ListaRespuestas && question.ListaRespuestas.filter(item => item.RespuestaPosibleID == (isSubmitting ? question.respuesta : getRespuesta(question.PreguntaID)));          
        return respAux && respAux.length>0 && respAux[0].DependeRespuesta;       
    };

    const [dependeRespuesta, setDependeRespuesta] = React.useState(getDependeRespuesta());
  

    const isChecked = (value) => {
        return (respuesta && respuesta.filter(item => item == value).length > 0);
    };

    const onChangeRadioButton = (preguntaID, type, value, dependeRespuestaLista) => {   
     setRespuesta(value);
     const oldDependPreguntaID = dependeRespuesta && dependeRespuesta.Preguntas ? dependeRespuesta.Preguntas.map((question) => question.PreguntaID): [];
     const oldDependPreguntaIDSecciones = dependeRespuesta && dependeRespuesta.Secciones ?dependeRespuesta.Secciones.map((seccion) => seccion.Preguntas && seccion.Preguntas.map((question) => question.PreguntaID)): [];
     const oldDependP = [...(oldDependPreguntaID || []),...(oldDependPreguntaIDSecciones || [])].reduce((acc, curVal) => acc.concat(curVal), []);     
     onChange(preguntaID, type, value, oldDependP);       
     setDependeRespuesta(dependeRespuestaLista);
    };

    const onChangeValue = (preguntaID, type, value) => { 
        type != 6? setRespuesta(value) : respuesta && respuesta.includes(value) ? setRespuesta(respuesta.filter(item => item != value)) : setRespuesta((respuesta || []).concat([value])); 
        onChange(preguntaID, type, value, []);               
    };

    return (
        <div id="question-card">
            <Row>
                <Col xs={12} md={8}>
                    <div className={`question-type${question.TipoID}`}>
                        <Form.Group controlId={question.PreguntaID}>
                            <div className="row">
                                <Form.Label className="col-form-text col-sm-12">{question.TextoPregunta}{question.Obligatoria && <FontAwesomeIcon className="icon-ob" color="red" icon={faAsterisk} />}</Form.Label>
                                {question.Descripcion && <Form.Label className="col-form-desc col-sm-12">{question.Descripcion.split('\n').map(function (item, key) {
                                    return (
                                        <span key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    )
                                })}</Form.Label>}
                                {type == 1 &&
                                    <div className="col-sm-10">
                                        {question.ListaRespuestas && question.ListaRespuestas.map(answer =>
                                            <Form.Check key={`rp-${answer.RespuestaPosibleID}`} disabled={isSubmitting} defaultChecked={answer.RespuestaPosibleID == respuesta} onChange={e => onChangeRadioButton(question.PreguntaID, type, e.target.value, answer.DependeRespuesta)} name={`p-${question.PreguntaID}`} label={answer.Respuesta} type="radio" id={`rp-${answer.RespuestaPosibleID}`} value={answer.RespuestaPosibleID} />
                                        )}
                                    </div>}
                                {type == 2 && respuesta &&
                                    <Form.Control as="select" disabled={isSubmitting} defaultValue={respuesta} onChange={e => onChangeValue(question.PreguntaID, type, e.target.value)} name={`p-${question.PreguntaID}`}>
                                        {question.ListaRespuestas && question.ListaRespuestas.map(answer =>
                                            <option key={answer.RespuestaPosibleID} name={`rp-${answer.RespuestaPosibleID}`} value={answer.RespuestaPosibleID}>{answer.Respuesta}</option>)
                                        }
                                    </Form.Control>}
                                {type == 2 && !respuesta &&
                                    <Form.Control as="select" disabled={isSubmitting} onChange={e => onChangeValue(question.PreguntaID, type, e.target.value)} name={`p-${question.PreguntaID}`}>
                                        <option disabled value={""}> {t('survey.select')}</option>
                                        {question.ListaRespuestas && question.ListaRespuestas.map(answer =>
                                            <option key={answer.RespuestaPosibleID} name={`rp-${answer.RespuestaPosibleID}`} value={answer.RespuestaPosibleID}>{answer.Respuesta}</option>)
                                        }
                                    </Form.Control>}
                                {type == 6 &&
                                    <div className="col-sm-10">
                                        {question.ListaRespuestas && question.ListaRespuestas.map(answer =>
                                            <Form.Check key={`rp-${answer.RespuestaPosibleID}`} disabled={isSubmitting} defaultChecked={isChecked(answer.RespuestaPosibleID)} onChange={e => onChangeValue(question.PreguntaID, type, e.target.value)} name={`p-${question.PreguntaID}`} label={answer.Respuesta} type="checkbox" id={`rp-${answer.RespuestaPosibleID}`} value={answer.RespuestaPosibleID} />
                                        )}
                                    </div>}
                                {type == 3 && <div className="range-field">
                                    <span className="minRange limit">{question.RespNumMin}</span>
                                    {respuesta ?
                                        <Form.Control type="range" disabled={isSubmitting} onChange={e => changeValueRange(e.target.value)} defaultValue={respuesta} min={question.RespNumMin} max={question.RespNumMax} name={`p-${question.PreguntaID}`} />
                                        :
                                        <Form.Control type="range" disabled={isSubmitting} onChange={e => changeValueRange(e.target.value)} min={question.RespNumMin} max={question.RespNumMax} name={`p-${question.PreguntaID}`} />
                                    }
                                    <span className="minRange limit">{question.RespNumMax}</span>
                                    <span className="range-value" >{respuesta}</span>
                                </div>}
                                {type == 4 &&
                                    (respuesta ?
                                        <Form.Control type="text" disabled={isSubmitting} name={`p-${question.PreguntaID}`} defaultValue={respuesta} onChange={e => onChangeValue(question.PreguntaID, type, e.target.value)} />
                                        :
                                        <Form.Control type="text" disabled={isSubmitting} name={`p-${question.PreguntaID}`} onChange={e => onChangeValue(question.PreguntaID, type, e.target.value)} />
                                    )
                                }
                                {type == 5 &&
                                    (respuesta ?
                                        <Form.Control as="textarea" disabled={isSubmitting} name={`p-${question.PreguntaID}`} rows={3} defaultValue={respuesta} onChange={e => onChangeValue(question.PreguntaID, type, e.target.value)} />
                                        :
                                        <Form.Control as="textarea" disabled={isSubmitting} name={`p-${question.PreguntaID}`} rows={3} onChange={e => onChangeValue(question.PreguntaID, type, e.target.value)} />
                                    )
                                }
                            </div>                                    
                        </Form.Group>
                    </div>
                </Col>
                {question.RutaFoto && <Col className="image" md={4} xs={12}>
                    <img src={question.RutaFoto} alt="Ruta Foto"/>
                </Col>}
                  {dependeRespuesta && <div className="row-dependeRespuesta"  md={12} xs={12}>                           
                                {dependeRespuesta.Preguntas && <div className="questions" >
                                    {dependeRespuesta.Preguntas.map(question =>
                                        <QuestionCard
                                            key={question.PreguntaID}
                                            type={question.TipoID}
                                            question={question}
                                            user={user}
                                            isSubmitting={isSubmitting}
                                            onChange={onChange}
                                            getRespuesta={getRespuesta}
                                        />)}
                                 </div>}
                                 {dependeRespuesta.Secciones && dependeRespuesta.Secciones.map((section, index) =>
                                section && <div className="section" key={section.SeccionID}>
                                    <SurveySection
                                        key={section.SeccionID}
                                        section={section}
                                        user={user}
                                        isSubmitting={isSubmitting}
                                        onChange={onChange}
                                        getRespuesta={getRespuesta}
                                    />   </div>    )}
                         </div>}             
            </Row>
        </div >
    );
}

export default QuestionCard;

