import React from 'react';
import './CompanyPage.css';
import Container from 'react-bootstrap/Container';
import { DynamicBackground } from '../../components'
import { isWebpSupported } from 'react-image-webp/dist/utils';

export const SectionOpinions = props => {
    return (
        <DynamicBackground customOverlay="rgba(0, 0, 0, 0.1)" background={isWebpSupported() ? "https://gecorcloud.com/images/company-opinion.webp" : "https://gecorcloud.com/images/company-opinion.png"}>
            <div id="section-opinions">
                <Container>
                    <div className="opinion-box">
                        <div className="p-opinion">

                        </div>
                        <div className="p-nombre">

                        </div>
                        <div className="p-otros">

                        </div>
                    </div>
                </Container>
            </div>
        </DynamicBackground>
    );
}

export default SectionOpinions;