import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string'
import './PrivatePoliticsPage.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PolicyPDF = props => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const { t } = useTranslation();
    let pdf = { localPdf: props.location.state && props.location.state.pdf ? props.location.state.pdf : "https://gecorcloud.com/pdf/politica-de-privacidad-APP.pdf" };

    const params = queryString.parse(props.location.search);
    if(params && params.pdf) {
        pdf = { localPdf: params.pdf}
    }

    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
      setPageNumber(1);
    }
  
    const changePage = (offset) => {
      setPageNumber(prevPageNumber => prevPageNumber + offset);
    }
  
    const previousPage = () => {
      changePage(-1);
    }
    const nextPage = () => {
        changePage(1);
      }
    const localPdf = "https://www.africau.edu/images/default/sample.pdf"
    return (
        <Container id="politica-empresa">
            <Row>
                <Document
                    aria-label={t("pprivacidad.document")}
                    tabIndex="1"
                    file={pdf.localPdf}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={{httpHeaders: { 'Access-Control-Allow-Origin': '*' }}}
                >
                    <Page pageNumber={pageNumber}/>
                    <Row className="info-pdf">
                        <Col md={4} >
                            <p className="pagenumber" >
                                {t("pprivacidad.Page")} {pageNumber || (numPages ? 1 : '--')} {t("pprivacidad.de")} {numPages || '--'}
                            </p>
                        </Col>
                        <Col className="btn-groups">
                            <ButtonGroup className="me-2">
                                <Button disabled={pageNumber <= 1}
                                    tabIndex="2"
                                    onClick={previousPage}                 
                                    variant="outline-primary">
                                    {t("pprivacidad.btnPrevious")}
                                </Button> 
                                <Button disabled={pageNumber >= numPages}
                                    tabIndex="3"
                                    onClick={nextPage}                 
                                    variant="outline-primary">
                                    {t("pprivacidad.btnNext")}
                                </Button> 
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Document>
            </Row>
        </Container>
    );
}

export default PolicyPDF;