import { apiUrl} from "../../constants";

export const difusionesApi = {
    
    getDifusionesByAytoID(AytoID, top, lastDifusionMensajeID) {

        const params = {
            AyuntamientoID: AytoID,
            top: top,
            lastDifusionMensajeID: lastDifusionMensajeID
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Difusion/getDifusionesByAytoID`, requestOptions)
            .then(response => response.json())
            .then(difusiones => {
                if(localStorage.getItem('difusiones') && params.lastDifusionMensajeID){
                    const difusionesLocal = JSON.parse(localStorage.getItem('difusiones'));
                    localStorage.setItem('difusiones', JSON.stringify([...difusionesLocal,...difusiones]));
                }else{
                    localStorage.setItem('difusiones', JSON.stringify(difusiones));
                }
                return JSON.parse(localStorage.getItem('difusiones'));
            });
    },
    loadDifusiones() {
        const difusiones = JSON.parse(localStorage.getItem('difusiones'));
        return difusiones || []
    },
}