
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useTranslation } from 'react-i18next';
import './SurveyHeader.css';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export const SurveyHeader = props => {
    const { surveyQuery, handleChange, changeVista, pendientes } = props;
    const { t } = useTranslation();
    return (
        <div className="survey-tab" id="survey-header">
            {window.innerWidth > 768 && <Row>
                    <Col sm={12}>
                        <div className="header-title">{t('survey.p1')}</div>
                    </Col>
                    <Row className="suv-p2">
                        <Col sm={6}>
                            <Row>
                                <Col sm={6}>
                                    <a rel="noopener noreferrer" className={pendientes? "active" : ""} onClick={() => changeVista("pending")}><Button className="bt-suv-b" variant="primary" size="lg">{t('survey.p2')}</Button></a>
                                </Col>
                                <Col sm={6}>
                                    <a rel="noopener noreferrer" className={!pendientes? "active" : ""} onClick={() => changeVista("conducted")}><Button variant="secondary" size="lg">{t('survey.p3')}</Button></a>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="float-right search-survey-tab" sm={6}>
                            <InputGroup size="lg" className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroup-sizing-lg" ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl placeholder={t('survey.placeholder')} aria-label="Large" aria-describedby="inputGroup-sizing-sm"
                                    type="text" s={12} onChange={handleChange} value={surveyQuery} icon="search" />
                            </InputGroup>
                        </Col>
                    </Row>
            </Row>}

            {window.innerWidth < 768 && <Row>
                <Col className="float-left menu-survey" xs={6} sm={6}>
                    <a rel="noopener noreferrer" className={pendientes? "active" : ""} onClick={() => changeVista("pending")}> {t('survey.p2')}</a>
                </Col>
                <Col className="float-right menu-survey" xs={6} sm={6}>
                    <a rel="noopener noreferrer" className={!pendientes? "active" : ""} onClick={() => changeVista("conducted")}> {t('survey.p3')}</a>
                </Col>            
                <Col className="float-right" xs={12}>
                    <InputGroup size="lg" className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="inputGroup-sizing-lg" ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl placeholder={t('survey.placeholder')} aria-label="Large" aria-describedby="inputGroup-sizing-sm"
                            type="text" s={12} onChange={handleChange} value={surveyQuery} icon="search" />
                    </InputGroup>
                </Col>
            </Row>}
        </div>
    );
}
export default SurveyHeader;
