import React from 'react';
import { connect } from 'react-redux';
import './GecorBlogHomePage.css';
import Row from 'react-bootstrap/Row';


import { withTranslation } from 'react-i18next';

class GecorBlogHomePage extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div id="GecorBlogHome">

                <div className="cabecera-blog">
                    <img src="https://gecorcloud.com/images/blog-head.png" alt="blog"/>
                </div>
                <div className="blog-menu">
                    <div className="blog-menu-link">
                        <Row className="justify-content-sm-center">
                            <a className="url"><b>{("nav.nav1")}</b></a>
                            <a><b>SMARTCITY</b></a>
                            <a><b>{("nav.nav13")}</b></a>
                            <a><b>GECOR</b></a>
                            <a><b>I+D+I</b></a>
                        </Row>
                    </div>
                </div>
                <div className="blog-sec">
                   <div className="sec-box">
                       <div className="name-sec-box">
                            <b>SMARTCITY</b>
                       </div>
                    </div> 
                    <div className="sec-box">
                       <div className="name-sec-box">
                           <b>{("nav.nav13")}</b>
                       </div>
                    </div> 
                    <div className="sec-box">
                       <div className="name-sec-box">
                        <b>GECOR</b>
                       </div>
                    </div> 
                    <div className="sec-box">
                       <div className="name-sec-box">
                           <b>I+D+I</b>
                       </div>
                    </div> 
                </div>

            </div>


        );
    }
}

const mapStateToProps = (state) => {
    const { users } = state;
    const { user } = users;
    return {
        user
    };
}


const tGecorBlogHomePage = withTranslation()(GecorBlogHomePage);
const connectedGecorBlogHomePage = connect(mapStateToProps)(tGecorBlogHomePage);
export { connectedGecorBlogHomePage as GecorBlogHomePage };

