export const userConstants = {
  CHANGE_FORM: "CHANGE_FORM",
  SET_AUTH: "SET_AUTH",
  SENDING_REQUEST: "SENDING_REQUEST",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  REGISTER_REQUEST: "REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",
  UPDATE_REQUEST: "UPDATE_REQUEST",
  UPDATE_SUCCESS: "UPDATE_SUCCESS",
  UPDATE_ERROR: "UPDATE_ERROR",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  LOAD_USER_DATA: "LOAD_USER_DATA",
  LOADED_USER_DATA: "LOADED_USER_DATA",
  LOGOUT: "LOGOUT",
  REQUEST_ERROR: "REQUEST_ERROR",
  CLEAR_ERROR: "CLEAR_ERROR",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS"
};

/*
export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

};
*/
