import React from 'react';
import './Footer.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { isIOS } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhoneAlt, faMapMarked, faCopyright, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { forwardTo } from '../../helpers'



export const FooterComponent_10438 = props => {
    const handleOpenAPPUrl = () => {
        if (isIOS) {
            window.open('https://apps.apple.com/es/app/gecor/id1127259219')
        } else {
            window.open('https://play.google.com/store/apps/details?id=com.gecorsystem.gecorn&hl=es&gl=US&pli=1')
        }
    };

    return (
        <div id="footer10438">
            <footer >
                <div className="footer-top">
                    <Container>
                        <Row>
                            <Col sm={6}>
                                <div className="footer-about">
                                    <Row>
                                    <a href="https://www.onil.es/es/"> <h3 className="footer-informacion-On">Ayuntamiento de Onil</h3></a>
                                    </Row>
                                    <p><FontAwesomeIcon icon={faMapMarked} />Calle Barberán y Collar, 15 - 03430 Onil</p>
                                    <p><a href="tel:+34966544745"><FontAwesomeIcon icon={faPhoneAlt} /> 966 544 745</a></p>
                                    <p><a href="mailto:sac@onil.es"><FontAwesomeIcon icon={faEnvelope} /> sac@onil.es</a></p>
                                    <p><FontAwesomeIcon icon={faCopyright} /> Powered by GECOR.</p>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="badge-ln text-left">
                                    <Row>
                                        <h3>Consultas</h3>
                                    </Row>
                                    <Row>
                                        Recuerde que puede hacer sus consultas a través de:
                                    </Row>
                                    <p className="enlace mt-2" onClick={() => forwardTo('/contacto-medioambiente-onil')}><FontAwesomeIcon icon={faEnvelope} /> Correo Electrónico</p>
                                    <p className="enlace" onClick={handleOpenAPPUrl}><FontAwesomeIcon icon={faMobileAlt} /> App</p>
                                    <p><a href="tel:+34951816736"><FontAwesomeIcon icon={faPhoneAlt} /> 951 816 736</a></p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </div >
    );
}

export default FooterComponent_10438;