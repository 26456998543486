import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${props => (props.width ? props.width : "50px")};
  height: ${props => (props.height ? props.height : "50px")};
  background-image: url(${props => (props.icon ? props.icon : "/images/pin.png")});
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: ${props => (props.onClick ? "pointer" : "default")};
  &:hover {
    z-index: 1;
  }
`;

const Marker = props => (
  <Wrapper
    alt={props.text}
    {...(props.onClick ? { onClick: props.onClick } : {})}
    {...(props.icon ? { icon: props.icon } : {})}
    {...(props.width ? { width: props.width } : {})}
    {...(props.height ? { height: props.height } : {})}
  />
);

Marker.defaultProps = {
  onClick: null
};

Marker.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string
};

export default Marker;
