import React from 'react';
import { connect } from 'react-redux';
import { Col, Row, Card } from 'react-bootstrap'
import './CitizenGuidePageCh.css';
import { DynamicBackground } from '../../../../components'
import { isWebpSupported } from 'react-image-webp/dist/utils';

class CitizenGuidePageCh extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {

    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.1)" background={isWebpSupported() ? "https://gecorcloud.com/images/CitybgGuide.webp" : "https://gecorcloud.com/images/CitybgGuide.jpg"}>
        <div className="container margin-bottom">
          <div className="enviBoxCh">

            <div className="infoTit">
              <h1><b>PRÁCTICAS SOBRE EL MEDIO AMBIENTE</b></h1>
              <hr className="line" ></hr>
            </div>


            {/*   Box 2  */}

            <Card>
              <Card.Header as="h5">El agua</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 4, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/envir3.webp" : "https://gecorcloud.com/images/envir3.png"} alt="envir3" />
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col sm={12}>
                          <p className="text"> En este manual podrás encontrar datos del ciclo del agua, características de la misma, cantidad de agua disponible en la tierra, su ciclo hidrológico, su consumo a nivel mundial y en España, las fuentes de contaminación… y una serie de consejos sobre lo que podemos hacer cada uno de nosotros para ahorrar agua.</p>
                          <hr className="line" ></hr>
                          <a href="https://gecorcloud.com/pdf/Agua.pdf" target="_blank" rel="noopener noreferrer" className="enlace" >"El agua"</a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            {/*   Box 3   */}


            {/*   Box 4   */}

            <Card>
              <Card.Header as="h5">Biodiversidad</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 4, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/city4.webp" : "https://gecorcloud.com/images/city4.png"} alt="envir" />
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col sm={12}>
                          <p className="text">La heterogeneidad del paisaje la que propicia la diversificación biológica, ya se sabe que en la variedad está el gusto .Quizás pensaste erróneamente que ya estaba todo descubierto, sin embargo hay infinidad de zonas por explorar y descubrir.
                </p>
                          <hr className="line" ></hr>
                          <a href="https://www.ecoembes.com/es/planeta-recicla/blog/por-que-espana-es-el-pais-europeo-con-mas-biodiversidad" target="_blank" rel="noopener noreferrer" className="enlace" >"Biodiversidad."</a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            {/*   Box 5   */}

            <Card>
              <Card.Header as="h5">Buenas prácticas ambientales en la oficina</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 4, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/city5.webp" : "https://gecorcloud.com/images/city5.png"} alt="envir4" />
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col sm={12}>
                          <p className="text"> Gran parte de los profesionales realizan su trabajo diario en oficinas. Es importante, reducir al máximo el impacto ambiental en este sector, las buenas prácticas que se han de instaurar son tremendamente sencillas y económicas.
                </p>
                          <hr className="line" ></hr>
                          <a href="https://www.seo.org/wp-content/uploads/2014/03/Manual-de-Buenas-Pr%C3%A1cticas-en-Oficina.-Ed_1-_01032014.pdf" target="_blank" rel="noopener noreferrer" className="enlace" >"Descarga la Guía de Buenas prácticas ambientales en la oficina"</a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            {/*   Box 6   */}

            <Card>
              <Card.Header as="h5">Implantación de la Norma ISO 14001 en empresas</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 4, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/city6.webp" : "https://gecorcloud.com/images/city6.png"} alt="city 6" />
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col sm={12}>
                          <p className="text">Cada vez es más necesario forjar un compromiso entre empresa y medio ambiente, ya sea por necesidades sociales como por necesidades económicas o imposiciones normativas. Este compromiso puede adquirirse certificando tu entidad a través de la norma ISO 14001. Aquí podrás informarte acerca de los beneficios, pasos a seguir y finalidad.
                </p>
                          <hr className="line" ></hr>
                          <a href="http://www.lrqa.es/certificaciones/iso-14001-medioambiente/" target="_blank" rel="noopener noreferrer" className="enlace" >"Descarga la Guía de Implantación de la Norma ISO 14001 en empresas"</a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>


            <Card>
              <Card.Header as="h5">¿Se puede comprar sin plásticos?</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 4, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/envir6.webp" : "https://gecorcloud.com/images/envir6.png"} alt="envir 6" />
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Col sm={12}>
                          <p className="text" >Mientras se instaura el Día Internacional Sin Bolsas de Plástico,
                       surge el movimiento Zero waste y los jóvenes se revolucionan bajo el lema Fridays for future, la industria alimentaria...
            </p>
                          <hr className="line" ></hr>
                          <a href="https://gecorcloud.com/pdf/sinPlastico.pdf" target="_blank" rel="noopener noreferrer" className="enlace" >"¿Se puede comprar sin plásticos?"</a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

          </div>
        </div>

      </DynamicBackground>
    );
  }


}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedCitizenGuidePageCh = connect(mapStateToProps)(CitizenGuidePageCh);
export { connectedCitizenGuidePageCh as CitizenGuidePageCh };
