import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import './MarkerPoi.css';
import { forwardTo } from '../../helpers'
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationArrow, faTicketAlt } from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${props => (props.hovered ? "4em" : "3em")};
  height: ${props => (props.hovered ? "4em" : "3em")};
  background-image: url(${props => (props.category.image)});
  background-size: ${props => (props.hovered ? "25px" : "20px")};
  background-repeat: no-repeat;
  background-color: transparent;
  user-select: none;
  z-index: 1;
  transform: translate(-50%, -50%);
  background-color: ${props => (props.hovered ? "rgb(15, 199, 181)" : "white")};
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 5px 5px #999999;
  box-shadow: 0px 0px 5px 5px #999999;
  background-position-x: ${props => (props.hovered ? "9px" : "6px")};
  background-position-y: ${props => (props.hovered ? "7px" : "5px")};
  cursor: ${props => (props.onClick ? "pointer" : "default")};
  &:hover {
    z-index: 3;
  }
`;

const WrapperInfo = styled.div`
    position: absolute;    
    width: 26em;
    left: -12.5em;
    top: 3em;
    background-size: contain;
    background-repeat: repeat;
    background-color: white;
    user-select: none;
    border-radius: 1em;
    transform: translate(-50%,100px)
    padding: 0.5em;
    position: absolute;    
    z-index: 2;
    box-shadow: 0 2px 7px 1px rgba(0,0,0,0.3);
    cursor: ${props => (props.onClick ? "pointer" : "default")};
    &:hover {
        z-index: 3;
    };  
      `;

const InfoMarker = props => {
    return (
        <WrapperInfo alt={props.nombre} className="center info-marker" >
                <div className="images carousel slide carousel-inner" onClick={() => forwardTo(`interest-points-detail/list/${props.idItem}`)}>
                    {props.images ?
                        <Carousel>
                            {props.images.map((im, key) => (
                                <Carousel.Item key={key}>
                                    <img
                                        className="d-block w-100"
                                        src={im.image_data}
                                        alt="deslizar"
                                    />
                                </Carousel.Item>))}
                        </Carousel>
                        :
                        <div className="no-img"></div>
                    }
                </div>
                <div className="title-marker" onClick={() => forwardTo(`interest-points-detail/list/${props.idItem}`)}>{props.nombre}</div>
               
                <div className="poi-links">
                    {props.rutaComprarEntrada &&
                        <Button className="comprar-poi-btn" href={props.rutaComprarEntrada} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTicketAlt} /></Button>}
                    <Button className="navigation-poi-btn" href={`http://maps.google.com/maps?daddr=${props.lat},${props.lng}&zoom=15`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLocationArrow} /></Button>
                </div> 
        </WrapperInfo>
    )
};

InfoMarker.propTypes = {
    nombre: PropTypes.string.isRequired,
    idItem: PropTypes.number.isRequired,
    image: PropTypes.string,      
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    currentLatLng: PropTypes.object
};

const MarkerPoi = props => {
    return (
        <div className="marker-poi">
            <Wrapper alt={props.nombre}
                onClick={() => props.handleOnToggle(props.idItem)}
                hovered={props.hovered}
                category={props.category}>
            </Wrapper>
            {props.infoMarkerOpened && props.idItem === props.infoMarkerPoiIDOpened ?
                <InfoMarker
                    key={props.idItem}
                    idItem={props.idItem}
                    nombre={props.nombre}
                    image={props.image}
                    images={props.images}
                    lat={props.lat}
                    lng={props.lng}
                    rutaComprarEntrada={props.rutaComprarEntrada}
                    currentLatLng={props.currentLatLng} />
                :
                null
            }
        </div>
    )
};


MarkerPoi.propTypes = {
    onClick: PropTypes.func,
    nombre: PropTypes.string.isRequired,
    image: PropTypes.string,
    idItem: PropTypes.number.isRequired,    
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,    
    currentLatLng: PropTypes.object
};

export default MarkerPoi;



