import React from 'react';
import './CitiesPage.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrophy, faTasks, faPuzzlePiece, faCogs, faSearch, faUsers } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

export const SectionFeatures = props => {
    const { t } = useTranslation();

    return (
        <div id="section-features">
            <Container>
                {/*<h3 className="card-titulo">{t('cities.title2')}</h3>*/}
                <Row>
                    <Col md={4}>
                        {/*<Image className="img-beneficios" src="https://gecorcloud.com/images/trophy.webp" rounded />*/}
                        <FontAwesomeIcon icon={faTrophy} size="4x" />
                        <h4 dangerouslySetInnerHTML={{ __html: t('cities.title2-1-icon1') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: t('cities.title2-icon1') }}></p>
                    </Col>
                    <Col md={4}>
                        {/*<Image className="img-beneficios" src="https://gecorcloud.com/images/checklist.webp" rounded />*/}
                        <FontAwesomeIcon icon={faTasks} size="4x" />
                        <h4 dangerouslySetInnerHTML={{ __html: t('cities.title2-1-icon2') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: t('cities.title2-icon2') }}></p>
                    </Col>
                    <Col md={4}>
                        {/*<Image className="img-beneficios" src="https://gecorcloud.com/images/light-bulb.webp" rounded />*/}
                        <FontAwesomeIcon icon={faUsers} size="4x" />
                        <h4 dangerouslySetInnerHTML={{ __html: t('cities.title2-1-icon3') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: t('cities.title2-icon3') }}></p>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        {/*<Image className="img-beneficios" src="https://gecorcloud.com/images/puzzle-piece-plugin.webp" rounded />*/}
                        <FontAwesomeIcon icon={faPuzzlePiece} size="4x" />
                        <h4 dangerouslySetInnerHTML={{ __html: t('cities.title2-1-icon4') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: t('cities.title2-icon4') }}></p>
                    </Col>
                    <Col md={4}>
                        {/*<Image className="img-beneficios" src="https://gecorcloud.com/images/settings-cogwheel-button.webp" rounded />*/}
                        <FontAwesomeIcon icon={faCogs} size="4x" />
                        <h4 dangerouslySetInnerHTML={{ __html: t('cities.title2-1-icon5') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: t('cities.title2-icon5') }}></p>
                    </Col>
                    <Col md={4}>
                        {/*<Image className="img-beneficios" src="https://gecorcloud.com/images/magnifier.webp" rounded />*/}
                        <FontAwesomeIcon icon={faSearch} size="4x" />
                        <h4 dangerouslySetInnerHTML={{ __html: t('cities.title2-1-icon6') }}></h4>
                        <p dangerouslySetInnerHTML={{ __html: t('cities.title2-icon6') }}></p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SectionFeatures;