import React from 'react';
import { connect } from 'react-redux';
import CardDeck from 'react-bootstrap/CardDeck';
import './NoticePage.css';
import { noticesActions } from '../../actions';
import { DynamicBackground } from './../../components'
import { NewsCard } from './../../components/NewsCard/NewsCard';
import { isWebpSupported } from 'react-image-webp/dist/utils';

class NoticePage extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(noticesActions.getAllNotices());
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {

    const { notices } = this.props

    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.1)" background={isWebpSupported() ? "https://gecorcloud.com/images/notice-bg.webp" : "https://gecorcloud.com/images/notice-bg.jpg"} >
        <div className="boxContact">
          <div className="container">
            <div className="GamesBox">
              <h1><b>NOTICIAS</b></h1>
              <hr className="line" ></hr>
              {/* NUESTRO PLANETA TIERRA */}
              <div className="notices">
                <CardDeck className="GamesBox-notice2">
                  {notices ?
                    <NewsCard notices={notices} />
                    :
                    <NewsCard {...this.state} />
                  }
                </CardDeck>
              </div>
            </div>
          </div>
        </div>
      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users, news } = state;
  const { user } = users;
  const { notices } = news;
  return {
    user,
    notices,
  };
}

const connectedNoticePage = connect(mapStateToProps)(NoticePage);
export { connectedNoticePage as NoticePage };
