import React from 'react';
import './HomePage.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { forwardTo } from '../../helpers'

export const BannerFaq = props => {
    const { t } = props;

    return (
        <div id="banner-faq">
            <Container>
                <p tabIndex="24">{t('home.box3-p')}</p>
                <a tabIndex="25"  href='#' onClick={(e) => {
                    e.preventDefault();
                    forwardTo('/Faq-g')
                }
                }>
                    <Button variant="secondary" size="lg">
                        {t('home.box3-btn')}
                    </Button>
                </a>
            </Container>
        </div>
    );
}

export default BannerFaq;