import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import CardDeck from 'react-bootstrap/CardDeck';
import queryString from 'query-string';
import './MenuTorremolinosPage.css';
import { forwardTo } from "../../../../helpers";
import { EntitySearch, DynamicBackground } from '../../../../components'
import { isWebpSupported } from 'react-image-webp/dist/utils';

class MenuTorremolinosPage extends React.Component {
  //CARGAR AYUNTAMIENTO POR PARAMETRO AO
  constructor(props) {
    super(props);

    this.state = {
      aytoName: '',
      ao: queryString.parse(this.props.location.search),
    };
    this.handleSelectedEntity = this.handleSelectedEntity.bind(this);
  }

  handleSelectedEntity(entity) {
    this.setState({ aytoName: entity.Nombre });
  }
  /*
   * PONER LA SIGUIENTE LINEA EN EL RENDER
   *
   * const { ao } = this.state;
   *
   * PONER LA SIGUIENTE LINEA EN EL RETURN
   * 
   * <EntitySearch className="ayto-borde" redirect={false} onSelected={this.handleSelectedEntity} ao={ao} hide={true} />
   * 
   * IMPORTANTE IMPORTAR EntitySearch
  */
  // END CARGAR AYUNTAMIENTO POR PARAMETRO AO

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {
    const { ao } = this.state;
    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.1)" background={isWebpSupported() ? "https://gecorcloud.com/images/back-cont.jpg" : "https://gecorcloud.com/images/back-cont.jpg"}>
        <div className="envi-menu">
          <EntitySearch className="ayto-borde" redirect={false} onSelected={this.handleSelectedEntity} ao={ao} hide={true} />

          <div className="cabecera-torremolinos">
              <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                      <img src="https://gecorcloud.com/images/car-torremolinos.jpg" className="d-block corouse-torremolinos-1 w-100" alt="..."></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="torremolinos-fase-1">
              <div className="fase-title-torremolinos">
                NOTIFICA TU INCIDENCIA
              </div>
            </div>
            <div className="torremolinos-fase-1v2">
                    <Row className="download-linea-1">
                        <Col md={6} >
                          <Row className="row-linea-1v2">
                            <Col sm={12}>
                              <div className="text-incident">
                                <p tabIndex="6">Para realizar cualquier tipo de incidencia en la vía urbana en el Ayuntamiento de Torremolinos, basta con hacerla a través de la App de GECOR.</p>
                                <p tabIndex="7">Descarga la App, selecciona tu ayuntamiento, y comienza a hacer incidencias. Es muy sencillo, sólo necesitas seleccionar el tipo de incidencia, aportar una imagen y la ubicación.</p>
                                <p tabIndex="8">También tenemos disponible un gestor de incidencias web, para los que no puedan descargar la APP:</p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <a tabIndex="9" href=" https://gecorweb.com/login?ao=143" alt="gestor de incidencias web">
                                <Button tabIndex="10" className="btn-linea" variant="outline-dark">Gestor de incidencias WEB</Button>
                              </a>
                            </Col>
                          </Row>
                          <br></br>
                        </Col>
                        <Col md={{ span: 6, offset: 0 }}>
                          <Row div="download-app-pr">
                            <Col sm={12}>
                              <h3><b tabIndex="11">Descarga la APP de GECOR:</b></h3>
                            </Col>
                          </Row>
                          <br></br>
                          <Row className="row-inci-pr">
                            <Col sm={6}>
                              <img tabIndex="12" src="https://gecorcloud.com/images/QRApple.jpg" className="qr-app-store" alt="qr-app-store" />
                              <br></br>
                              <a tabIndex="13" target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/es/app/gecor/id1127259219?mt=8">
                                <img src="https://gecorcloud.com/images/app-store-ico.png" alt="app-store" className="download-store-btn-ln" />
                              </a>
                            </Col>
                            <Col sm={6}>
                              <img tabIndex="14" src="https://gecorcloud.com/images/QRAndroid.jpg" className="qr-google-play" alt="qr-google-play" />
                              <br></br>
                              <a tabIndex="15" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.gecorsystem.gecorn&hl=es">
                                <img src="https://gecorcloud.com/images/google-play-ico.png" className="download-store-btn-ln" alt="google-play" />
                              </a>
                            </Col>
                          </Row>
                        </Col>
                  </Row>
            </div>
            <div className="torremolinos-fase-1">
              <div className="fase-2-title-torremolinos">
                MENÚ AMBIENTAL
              </div>
              <div className="body-menu-torremolinos-1">
                  <p>
                      Bienvenido a la web medioambiental del Ayuntamiento de Torremolinos.
                  </p>
                  <p>
                      Aquí podrás encontrar gran cantidad de información medioambiental actualizada y solucionar tus dudas poniendote en contacto en el apartado de consultas medioambientales, visitando nuestros glosarios medio ambientales, etc.
                  </p>
                </div>
            </div>

             {/*   Box 1  */}
             <Container>
             <br></br>
            <Row className="cards-ln">
              <Col sm={12}>
                <CardDeck>

                  <Card className="card-ln" style={{ width: '18rem' }}>
                    <Card.Img tabIndex="19" alt="Informacion local ambiental" variant="top" src="https://gecorcloud.com/images/info-ch.png" />
                    <Card.Body>
                      <Card.Title tabIndex="20">Información Local Ambiental</Card.Title>
                      <Card.Text tabIndex="21">
                        Información ambiental del Ayuntamiento de Torremolinos a rápida disposición del ciudadano.
                      </Card.Text>
                      <Button tabIndex="22" className="btn-linea" variant="primary" onClick={() => forwardTo('/informacion-local-ambiental')}>Más información</Button>
                    </Card.Body>
                  </Card>

                  <Card className="card-ln" style={{ width: '18rem' }}>
                    <Card.Img tabIndex="23" alt="icono especies ambientales" variant="top" src="https://gecorcloud.com/images/especies-ch.png" />
                    <Card.Body>
                      <Card.Title tabIndex="24">Especies Ambientales</Card.Title>
                      <Card.Text tabIndex="25">
                        Información general sobre Especies ambientales en España y documentación relevante para la conservación de especies.
                      </Card.Text>
                      <Button tabIndex="26" className="btn-linea" variant="primary" onClick={() => forwardTo('/enviromental-information')}>Más información</Button>
                    </Card.Body>
                  </Card>

                  <Card className="card-ln" style={{ width: '18rem' }}>
                    <Card.Img tabIndex="27" alt="educacion ambiental" variant="top" src="https://gecorcloud.com/images/educacion-ch.png" />
                    <Card.Body>
                      <Card.Title tabIndex="28">Educación Ambiental</Card.Title>
                      <Card.Text tabIndex="29">
                        Documentación medioambiental de importancia para el amprendizaje para un futuro sostenible.
                      </Card.Text>
                      <Button tabIndex="30" className="btn-linea" variant="primary" onClick={() => forwardTo('/educacion-ambiental')}>Más información</Button>
                    </Card.Body>
                  </Card>

                </CardDeck>
              </Col>
            </Row>

            <br></br>

            <Row className="cards-ln">
              <Col sm={12}>
                <CardDeck>

                <Card className="card-ln" style={{ width: '18rem' }}>
                    <Card.Img tabIndex="43" alt="icono glosario ambiental" variant="top" src="https://gecorcloud.com/images/drawing-book.png" />
                    <Card.Body>
                      <Card.Title tabIndex="44">Glosario Ambiental</Card.Title>
                      <Card.Text tabIndex="45">
                        Glosario que contiene palabras, definiciones e imagenes descriptivas de palabras relacionadas con medio ambiente.
                      </Card.Text>
                      <Button tabIndex="46" className="btn-linea" variant="primary" onClick={() => window.open('https://gecorcloud.com/pdf/Glosario-ambiental-GECOR.pdf')}>Más información</Button>
                    </Card.Body>
                  </Card>

                  <Card className="card-ln" style={{ width: '18rem' }}>
                    <Card.Img tabIndex="35" alt="practicas sobre medio ambiente" variant="top" src="https://gecorcloud.com/images/practicas-ch.png" />
                    <Card.Body>
                      <Card.Title tabIndex="36">Prácticas Sobre Medio Ambiente</Card.Title>
                      <Card.Text tabIndex="37">
                        Prácticas del día a día para mejorar nuestro mundo y sostenibilidad medioambiental.
                      </Card.Text>
                      <Button tabIndex="38" className="btn-linea" variant="primary" onClick={() => forwardTo('/practicas-medio-ambiente')}>Más información</Button>
                    </Card.Body>
                  </Card>

                  <Card className="card-ln" style={{ width: '18rem' }}>
                    <Card.Img tabIndex="47" alt="enlaces de interes" variant="top" src="https://gecorcloud.com/images/lista-de-quehaceres.png" />
                    <Card.Body>
                      <Card.Title tabIndex="48">Enlaces de Interés</Card.Title>
                      <Card.Text tabIndex="49">
                        Directorio de enlaces de interés en relación con el Medio Ambiente.
                      </Card.Text>
                      <Button tabIndex="50" className="btn-linea" variant="primary" onClick={() => window.open('https://gecorcloud.com/pdf/enlaces.pdf', '_blank')}>Más información</Button>
                    </Card.Body>
                  </Card>
                
                </CardDeck>
              </Col>
            </Row>


            <br></br>
          </Container>{/**/}

          <div className="torremolinos-fase-1">
              <div className="fase-2-title-torremolinos">
                CONSULTAS MEDIOAMBIENTALES
              </div>
              <div className="body-menu-torremolinos-1">
                <img src="https://gecorcloud.com/images/contacto-ch.png" alt="..." className="icon-consul-torremolinos"></img>
                  <p>
                  Aquí puedes realizar cualquier tipo de consulta medioambiental. Responderemos lo más pronto posible.
                  </p>
                  <p>
                  <Button tabIndex="42" className="btn-linea" variant="primary" onClick={() => forwardTo('/contact')}>Más información</Button>
                  </p>
                </div>
            </div>

        </div>

      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedMenuTorremolinosPage = connect(mapStateToProps)(MenuTorremolinosPage);
export { connectedMenuTorremolinosPage as MenuTorremolinosPage };