import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import './LegalAdviceModal.css';
import { withTranslation } from 'react-i18next';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

class LegalAdviceModal extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false,
        };
    }

    handleClose() {
        // this.sendNewComment();
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }


    render() {

        const { entity, t } = this.props

        return (
            <>
                <a className="legal-advice-link" onClick={this.handleShow}>
                    <b> {t('legal.link')}</b>
                </a>

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton className="title-header-incident-commet" >
                        <h4> {t('legal.title')}</h4>
                    </Modal.Header>
                    <Modal.Body className='coment-incident-body'>
                        <div dangerouslySetInnerHTML={{ __html: entity && entity.AvisoLegal }} ></div>
                        {!entity && "Responsable: GECOR SYSTEM SLU con domicilio en C/Alameda Principal, nº 21 Planta 6 Oficina 603, CP 29001 Málaga. Teléfono 952028423 y Email: info@gecorweb.com. La finalidad es la gestión de los avisos, consultas, sugerencias y reclamaciones que Ud. quiera comunicar, así como informarle sobre las actuaciones llevadas a cabo por el Ayuntamiento para dar respuesta a la misma. No se realizan transferencias internacionales de sus datos, ni se ceden a terceros. Tratamos sus datos en virtud de su consentimiento, así como la ejecución de relaciones contractuales entre el encargado y el responsable y por interés legítimo. Puede acceder, rectificar y suprimir los datos, además del resto de derechos reconocidos en la normativa, puede ejercerlos tanto a la dirección como al email, que constan al inicio de la cláusula. Puede presentar una reclamación a la A.E.P.D., mediante la sede electrónica de la Autoridad o por medios no electrónicos, en el caso que entienda vulnerados sus derechos. Tiene más información sobre nuestra política de privacidad en nuestra web: www.gecorweb.com"}
                    </Modal.Body>
                    <Modal.Footer className="coment-incident-footer">
                        <Button className="boton-incident-commet" waves='light' onClick={this.handleClose} >{t('legal.close')}</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { entities } = state;
    const { entity } = entities;
    return {
        entity
    };
}
const tLegalAdviceModal = withTranslation()(LegalAdviceModal);
const connectedLegalAdviceModal = connect(mapStateToProps)(tLegalAdviceModal);
export { connectedLegalAdviceModal as LegalAdviceModal };
