import React from 'react';
import './Footer.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

export const FooterComponent_268e = props => {
    const { t } = useTranslation();
    
    return (
        <div id="footer">
            <footer >
                <div className="footer-top">
                    <Container>
                        <Row>
                            <Col sm={4}>
                                <div className="footer-about">
                                    <Row>
                                        <h3 className="footer-informacion">Chiclana</h3>
                                    </Row>
                                    <p>{t('footer.chiclana-2')}</p>
                                    <p>Powered by GECOR.</p>
                                    
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </footer>
        </div>
    );
}

export default FooterComponent_268e;