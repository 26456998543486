import React from 'react';
import { connect } from 'react-redux';
import './LocalInformationPageTv.css';
import { DynamicBackground } from '../../../../components'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { TimelineLite } from "gsap/all";
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { faCalendar, faMapMarkerAlt, faPhone, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-bootstrap/Carousel';

class LocalInformationPageTv extends React.Component {

  constructor(props) {
    super(props);
    this.logoTl = new TimelineLite({ paused: true });
    this.subhead = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logoTl
      .set(this.content, { autoAlpha: 1 })// show content div
      .from(this.head, 0.5, { left: 100, autoAlpha: 0 })
      .from(this.subhead, 0.5, { left: -100, autoAlpha: 0 }, "-=0.25") // added -0.25 seconds prior to end this.of timeline
    this.logoTl.play()
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));

  }

  render() {

    return (

      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.5)" background={isWebpSupported() ? "https://gecorcloud.com/images/onil.webp" : "https://gecorcloud.com/images/onil.jpg"}>
        <div className="infoBoxTorrelavega" ref={div => this.subhead = div}>
          <div className="infoTit">
            <h1><b tabIndex="1">INFORMACIÓN LOCAL</b></h1>
          </div>
          {/*   Box 1   */}
          <Card >
            <Card.Body>
              <Card.Text as="div">
                <Row>
                  <Col sm={12}>
                    <div className='local-enviromental-title'>
                      <p>Ordenanzas Medio Ambiente</p>
                      <div class="text-decor"></div>
                    </div>
                  </Col>
                  <Col md={6} >
                  <Row>
                      <Col sm={12}>
                        <p tabIndex="16" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Torrelavega/Ord_Limpieza.pdf')}>
                        Ordenanza sobre protección de los espacios públicos en relación con su limpieza, ornato y retirada de residuos tierras y escombros así como zonas verdes parques y jardines
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="4" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Torrelavega/Ord_Huertos.pdf')}>
                        Ordenanza reguladora de la Red Municipal de Huertos Sostenibles
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="12" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Torrelavega/Ord_otleus.pdf')}>
                        Ordenanzas sobre tramitación de licencias de edificación y uso del suelo (O.T.L.E.U.S.).
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="13" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Torrelavega/Ord_Obras.pdf')}>
                        Ordenanza reguladora del vallado de obras
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={{ span: 6, offset: 0 }}>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="3" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Torrelavega/Ord_Atmosfera.pdf')}>
                        Ordenanzas Municipales de Protección del Medio Ambiente contra la Contaminación Atmosférica
                        </p>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="17" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Torrelavega/Ord_Animales.pdf')}>
                        Ordenanza Reguladora de la Protección y el Bienestar Animal
                        </p>
                      </Col>
                    </Row>                    
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="19" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Torrelavega/Ord_SubvenObras.pdf')}>
                        Ordenanza Municlpal reguladora de la Subvención de Obras en Fachadas de Edificaciones de Carácter Residencial y en Edificios incluidos en el Plan Especial de Protección y Catalogación del Patrimonio Arquitectónico
                        </p>
                      </Col>
                    </Row>
                  </Col>

                </Row>
              </Card.Text>
            </Card.Body>
          </Card>

          {/*   Box 2   */}

          <Card ref={card => this.content = card}>
            <Card.Body>
              <Card.Text as="div">
                <Row>
                  <Col sm={12}>
                    <div className='local-enviromental-title'>
                      <p>Gestión de residuos</p>
                      <div class="text-decor"></div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 6, offset: 0 }} className='center'>
                    <img className="mt-2" src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Torrelavega/Contenedores_Torrelavega.jpg" width="60%" alt="imagen-info3" />
                  </Col>
                  <Col sm={6} className="par">
                    <p tabIndex="22">

                      En Torrelavega actualmente se dispone de una Red de 36 instalaciones fijas de Puntos Limpios para el depósito de los residuos
                      domésticos que, por su volumen, características o peligrosidad, no deben depositarse en los contenedores convencionales
                      de recogida situados en las calles. Estas instalaciones son de carácter supramunicipal por lo que pueden ser utilizadas
                      gratuitamente por todos los cántabros independientemente del municipio de residencia o donde éstas estén ubicadas.</p>

                    <p tabIndex="23">En Torrelavega existe la recogida selectiva para las fracciones:
                      envases, papel/cartón y vidrio, todas ellas con contenedores de carga superior en la vía pública,
                      todos los residuos se administran de forma correcta y eficiente en el punto limpio, admitiendo gran variedad
                      de estos para garantizar un municipio libre de contaminación</p>
                    <p tabIndex="24">Para todos los demás residuos existe tanto el ecoparque fijo como el móvil:</p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <h4 tabIndex="25"> Punto limpio</h4>
                    <Row>
                      <Col sm={6}>
                        <Row>
                          <p tabIndex="26">Situado en la Avenida Balbino Pascual s/n. Junto a las naves nido. . En este recinto se recogen todo tipo de residuos domésticos.</p>
                          <p tabIndex="27">Actualmente esta instalación es gestionada  directamente por MARE y cuenta con dos OPERARIO asignado. . </p>
                        </Row>
                      </Col>
                      <Col sm={6}>
                        <img src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Torrelavega/Punto_Limpio_Torrelavega.jpg" width="100%" height="170px" alt="Ecoparque fijo" />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <iframe title="Punto Limpio Torrelavega" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2902.0252412903415!2d-4.055058824743994!3d43.33466087297617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd493de1d38a40e3%3A0xe53045586ac42c91!2sPunto%20Limpio%20de%20Torrelavega!5e0!3m2!1ses!2ses!4v1710238295101!5m2!1ses!2ses" width="100%" height="170px" styles="border:0;" />
                      </Col>
                      <Col sm={6}>
                        <p tabIndex="28"><FontAwesomeIcon icon={faMapMarkerAlt} /> Av/ Balbino Pascual s/n. Junto a las naves nido. 39300 Torrelavega, Cantabria</p>
                        <p tabIndex="29"><FontAwesomeIcon icon={faCalendar} />De Martes a Viernes, mañanas de 9:30 a 14:30 y tardes de 16:00 a 19:00. 
                        Sabados de 9:00 a 14:30. </p>
                        <p><FontAwesomeIcon icon={faPhone} /> 657 975 260 </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={6}>
                    <h4 tabIndex="30"> Recogida de Muebles y Enseres</h4>
                    <Row>
                      <Col sm={6}>
                        <Row>
                          <p tabIndex="31">Se podrá realizar una recogida de muebles y enseres bajo cita previa en el teléfono 657 975 260 de 9:00 a 14:00 horas, la recogida es de Lunes a Jueves, máximo 2 bultos y que no sean electrodomésticos.

                          </p>
                        </Row>
                      </Col>
                      <Col sm={6}>
                        <img src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Torrelavega/Muebles_Torrelavega.jpg" width="100%" height="170px" alt="Ecoparque fijo" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr className="line" ></hr>
                <Row>
                  <Col sm={12}>
                    <p tabIndex="33" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Torrelavega/Inf_PuntoLimpio.pdf', '_blank')}>
                      Plan Local de Gestión de Residuos Asimilables
                    </p>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>


          {/*   Box 3   */}

          <Card>
            <Card.Body>
              <Card.Text as="div">
                <Row>
                  <Col sm={12}>
                    <div className='local-enviromental-title'>
                      <p tabIndex="35">Trámites</p>
                      <div class="text-decor"></div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col sm={12}>
                        <div className="plocal">
                          <div className="textColorInfo">
                            <p tabIndex="36" className="par">
                              <b>Instrucciones para la recogida de voluminosos:</b>
                            </p>
                            <p> Los peticionarios deberán llamar al teléfono 657 97 52 60, indicando su municipio, indicando su nombre, dirección, Email, móvil y objetos a retirar. </p>
                            <img src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Torrelavega/Objetos_Voluminosos_Torrelavega.jpg" width="100%" alt="Recogida de enseres" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={{ span: 6, offset: 0 }}>
                    <Row>
                      <Col sm={12}>
                        <div className="plocal">
                          <div className="textColorInfo">
                            <p tabIndex="37" className="par">
                              <b>¿Sabías que el aceite también es reciclable?</b>
                            </p>
                            <p>Torrelavega dispone de más de 15 puntos de recogida de aceite doméstico usado, con tu colaboración se evita contaminar las aguas y el medio ambiente que nos rodea.</p>
                            <img src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Torrelavega/Aceite_Torrelavega.jpg" width="100%" alt="Ecoparque fijo" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <p tabIndex="33" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Torrelavega/Inf_Aceite.pdf', '_blank')}>
                      Puntos de recogida del aceite
                    </p>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>


          {/*   Box 4   */}

          <Card>
            <Card.Body>
              <Card.Text as="div">

                <Row>
                  <Col sm={12}>
                    <div className='local-enviromental-title'>
                      <p>PARQUES Y PLAZAS</p>
                      <div class="text-decor"></div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Carousel>
                      <Carousel.Item key={1}>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <img
                                className="d-block w-100  mt-5"
                                src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Torrelavega/PQ_ManuelBarquín.jpg"
                                alt="deslizar"
                              />
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <p tabIndex="38" className="par">
                                <b>Parque Manuel Barquín</b>
                              </p>
                              <p>El parque Manuel Barquín, ocupa una superficie de veinte mil metros cuadrados, se considera la zona más verde de la 
                                ciudad, contiene en su interior numerosas especies tanto vegetales como animales. Algunos de los árboles que contiene 
                                son centenarios y forman parte del rico patrimonio forestal de Cantabria.
                              </p><p>También existe un lugar habilitado para juegos y actividades, alejado del lugar expresamente preparado para el descanso o la comida.
                              </p>
                            </Row>
                          </Col>
                        </Row>
                      </Carousel.Item>
                      <Carousel.Item key={2}>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <img
                                className="d-block w-100  mt-5"
                                src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Torrelavega/PQ_Trevilla.jpg"
                                alt="deslizar"
                              />
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <p tabIndex="39" className="par">
                                <b>Parque José María Gonzalez Trevilla</b>
                              </p>
                              <p>Situado en pleno centro urbano de Torrelavega. Destaca por su alrededor adornado por unas fascinantes enredaderas verdes que al enlazarse entre si
                                otorgan un aspecto de pergola.
                              </p><p>Una escultura de un barrendero sobre una peana de piedra es lo que destaca en esta popular plaza</p>
                            </Row>
                          </Col>
                        </Row>
                      </Carousel.Item>
                      <Carousel.Item key={3}>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <img
                                className="d-block w-100  mt-5"
                                src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Torrelavega/PQ_BaldomeroIglesias.png"
                                alt="deslizar"
                              />
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <p tabIndex="40" className="par">
                                <b>Plaza Baldomero Iglesias</b>
                              </p>
                              <p>Delimitado por la Parroquia de San José Obrero y los soportales de los edificios colindantes, convierte a la 
                                plaza Baldomero Iglesias  en un lugar agradable para disfrutar de sus terrazas, y en el que los niños pueden jugar 
                                a sus anchas sin peligro.
                              </p><p>Recibe su nombre en honor al Capitán Baldomero Iglesias, quien logró evacuar al máximo numero posible de personas
                                antes de que el carguero ingles chocara debido a la niebla.</p>
                            </Row>
                          </Col>
                        </Row>
                      </Carousel.Item>
                      <Carousel.Item key={4}>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <img
                                className="d-block w-100 mt-5"
                                src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Torrelavega/PQ_LaLLama.jpg"
                                alt="deslizar"
                              />
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <p tabIndex="40" className="par">
                                <b>Plaza La Llama</b>
                              </p>
                              <p>Esta plaza la podremos encontrar en el lado este de la ciudad.</p>
                              <p>Hasta 1973 se realizaban las ferias del ganado y el mercado semanal </p>
                              <p>Remodelada en 2005 con una amplia estructura, además de la gran estructura de hierro llamada Savia Nueva</p>
                            </Row>
                          </Col>
                        </Row>
                      </Carousel.Item>
                    </Carousel>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>

        </div>
      </DynamicBackground >
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedLocalInformationPageTv = connect(mapStateToProps)(LocalInformationPageTv);
export { connectedLocalInformationPageTv as LocalInformationPageTv }; 