import React from 'react';
import { connect } from 'react-redux';
import './CitiesPage.css';
import queryString from 'query-string'
import { withTranslation } from 'react-i18next';
import SectionVideo from './SectionVideo';
import SectionFeatures from './SectionFeatures';
import SectionModules from './SectionModules';
import SectionModulesExtended from './SectionModulesExtended';
import SectionSchema from './SectionSchema';

import {BannerCertificate } from '../../components';

/* ICONS */
import SectionSuccessCases from './SectionSuccessCases';
import BannerContact from '../../components/BannerContact/BannerContact';
/* ICONS */

class CitiesPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      aytoName: '',
      ao: queryString.parse(this.props.location.search),
    };

    this.handleSelectedEntity = this.handleSelectedEntity.bind(this);
  }


  componentDidMount() {
    const { entity } = this.props;
    if (entity) this.setState({ aytoName: entity.Nombre || '' });

    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.entity) {
      const { entity } = nextProps;
      this.setState({ aytoName: entity.Nombre });
    }
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  handleSelectedEntity(entity) {
    this.setState({ aytoName: entity.Nombre });
  }


  render() {

    const { t } = this.props;

    return (
      <div id="cities-page">
        <SectionVideo />
        <SectionFeatures />
        {/*<SectionSchema />*/}
        <SectionModulesExtended />
        <SectionModules />
        <SectionSchema />
        <BannerContact />
        {/*<SectionTypeUsers />*/}
        <SectionSuccessCases />
        <BannerCertificate t={t} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { entities } = state;
  const { entity } = entities;

  return {
    entity,
  };
}

const tCitiesPage = withTranslation()(CitiesPage);
const connectedCitiesPage = connect(mapStateToProps)(tCitiesPage);
export { connectedCitiesPage as CitiesPage };