import { history } from './history';

export const forwardTo = (location, search = '') => {
    const extra =  search ? search : history.location.search;
    history.push(location + extra);
}

export const forwardToWithParams = (location, data) => {
    history.push(location + history.location.search, data);
    /*
    history.push({
        pathname: location + history.location.search,
        state: { detail: data }
      });
      */
}

export default forwardTo;