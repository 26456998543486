import React from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import './EnviromentalInformationPageOn.css';
import { DynamicBackground } from './../../../../components'
import { TimelineLite } from "gsap/all";
import { isWebpSupported } from 'react-image-webp/dist/utils';

class EnviromentalInformationPageOn extends React.Component {

  constructor(props) {
    super(props);
    this.logoTx = new TimelineLite({ paused: true });

    this.content = null;
    this.head = null;
    this.subhead = null;
    this.feature = null;
    // this.description = null;
    this.icons = [];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logoTx
      // .set(this.content, { autoAlpha: 1 })// show content div
      //	.from(this.head, 0.5, { left: 100, autoAlpha: 0 })
      .from(this.subhead, 0.5, { left: -100, autoAlpha: 0 }, "-=0.25") // added -0.25 seconds prior to end this.of timeline
      // .from(this.feature, 0.5, { scale: .5, autoAlpha: 0 }, "feature") // added 0.5 seconds after end of timeline
      //.from(this.description, 0.5, { left: 100, autoAlpha: 0 }, "feature+=0.25")
      .staggerFrom(this.icons, 0.2, { scale: 0, autoAlpha: 0 }, 0.1); //animate all icons with 0.1 second stagger

    this.logoTx.play()
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {

    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.1)" background={isWebpSupported() ? "https://gecorcloud.com/images/bgedu.webp" : "https://gecorcloud.com/images/bgedu.jpg"}>
        <div className="envInfOn  margin-bottom" ref={div => this.subhead = div}>

          <div className="infoTit">
            <h1><b tabIndex="1">ESPECIES AMBIENTALES</b></h1>
          </div>

          {/*   Box 1   */}

          <Card className="black-txt-ln">
            <Card.Header tabIndex="4" as="h5">¡En defensa de las AVISPAS!</Card.Header>
            <Card.Body>
              <Card.Text as="div">
                <Row>
                  <Col md={{ span: 3, offset: 0 }}>
                    <img tabIndex="5" alt="imagen avispas" src={isWebpSupported() ? "https://gecorcloud.com/images/envir1.webp" : "https://gecorcloud.com/images/envir1.png"} />
                  </Col>
                  <Col md={9}>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="6" className="text" >Éste artículo va especialmente dirigido a aquellas personas que odian y temen a las avispas, y haré mi mejor esfuerzo por mejorar su terrible
                          imagen de “insectos malvados”. Si usted las detesta, no le culpo, pues desde niños ...
                        </p>
                        <a tabIndex="7" href="https://gecorcloud.com/pdf/avispas.pdf" target="_blank" rel="noopener noreferrer" className="enlace" ><b>Leer más</b></a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>


          {/*   Box 2   */}

          <Card className="black-txt-ln">
            <Card.Header tabIndex="8" as="h5">Animales en peligro de extinción</Card.Header>
            <Card.Body>
              <Card.Text as="div">
                <Row>
                  <Col md={{ span: 3, offset: 0 }}>
                    <img tabIndex="9" alt="Imagen animales en peligro" src={isWebpSupported() ? "https://gecorcloud.com/images/envir2.webp" : "https://gecorcloud.com/images/envir2.png"} />
                  </Col>
                  <Col md={9}>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="10" className="text" >Aunque la vida silvestre en España es bastante rica y
                          abarca gran parte del país existen entre sus ejemplares aquellos que se encuentran en peligro de extinción bien ...
                        </p>
                        <a tabIndex="11" href="https://gecorcloud.com/pdf/5especies.pdf" target="_blank" rel="noopener noreferrer" className="enlace" ><b>Leer más</b></a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>

          {/*   Box 3   */}

          <Card className="black-txt-ln">
            <Card.Header as="h5" tabIndex="12">Cuatro acciones que ayudarán a “resucitar” el planeta</Card.Header>
            <Card.Body>
              <Card.Text as="div">
                <Row>
                  <Col md={{ span: 3, offset: 0 }}>
                    <img alt="imagen planeta" src={isWebpSupported() ? "https://gecorcloud.com/images/envir3.webp" : "https://gecorcloud.com/images/envir3.png"} />
                  </Col>
                  <Col md={9}>
                    <Row>
                      <Col sm={12}>
                        <p className="text" tabIndex="13">
                          Una motivación similar debería llevar a los ciudadanos a generar un cambio para revivir un planeta ...
                        </p>
                        <a tabIndex="14" href="https://gecorcloud.com/pdf/Cuatro acciones que ayudaran.pdf" target="_blank" rel="noopener noreferrer" className="enlace" ><b>Leer más</b></a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>

          {/*   Box 4   */}

          <Card className="black-txt-ln">
            <Card.Header tabIndex="15" as="h5">El arte de no ser detectado</Card.Header>
            <Card.Body>
              <Card.Text as="div">
                <Row>
                  <Col md={{ span: 3, offset: 0 }}>
                    <img tabIndex="16" alt="imagen camuflaje" src={isWebpSupported() ? "https://gecorcloud.com/images/envir9.webp" : "https://gecorcloud.com/images/envir9.png"} />
                  </Col>
                  <Col md={9}>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="17" className="text"> El mimetismo y el camuflaje han existido desde que los primeros animales evolucionaron,
                          y han formado parte de la naturaleza por siempre como una herramienta de supervivencia...
                        </p>
                        <a tabIndex="18" href="https://gecorcloud.com/pdf/ArteNoSerDetectado.pdf" target="_blank" rel="noopener noreferrer" className="enlace" ><b>Leer más</b></a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>


          {/*   Box 5   */}

          <Card className="black-txt-ln">
            <Card.Header tabIndex="19" as="h5">Insectos que adoran los libros</Card.Header>
            <Card.Body>
              <Card.Text as="div">
                <Row>
                  <Col md={{ span: 3, offset: 0 }}>
                    <img tabIndex="20" alt="imagen libros" src={isWebpSupported() ? "https://gecorcloud.com/images/envir5.webp" : "https://gecorcloud.com/images/envir5.png"} />
                  </Col>
                  <Col md={9}>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="21" className="text" >Los pececillos de plata son insectos que pueden literalmente devorar libros y revistas,
                          aunque también gustan del papel tapiz y de algunas de nuestras más preciadas y antiguas fotografías que cuelgan en...
                        </p>
                        <a tabIndex="22" href="https://www.20minutos.es/noticia/1484056/0/insectos-casa/como-acabar-con/pececillos-plata/" target="_blank" rel="noopener noreferrer" className="enlace" ><b>Leer más</b></a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>

          {/*   Box 6   */}

          <Card className="black-txt-ln">
            <Card.Header tabIndex="23" as="h5">Conciencia ambiental</Card.Header>
            <Card.Body>
              <Card.Text as="div">
                <Row>
                  <Col md={{ span: 3, offset: 0 }}>
                    <img tabIndex="24" alt="imagen ambiente" src={isWebpSupported() ? "https://gecorcloud.com/images/envir4.webp" : "https://gecorcloud.com/images/envir4.png"} />
                  </Col>
                  <Col md={9}>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="25" className="text" >El desarrollo sostenible es la capacidad de una sociedad para cubrir las necesidades
                          básicas de las personas sin perjudicar el ecosistema ni ocasionar daños en el medio ambiente. De este modo...
                        </p>
                        <a tabIndex="26" href="https://gecorcloud.com/pdf/ConcienciaAmbiental.pdf" target="_blank" rel="noopener noreferrer" className="enlace" ><b>Leer más</b></a>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </DynamicBackground >
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedEnviromentalInformationPageOn = connect(mapStateToProps)(EnviromentalInformationPageOn);
export { connectedEnviromentalInformationPageOn as EnviromentalInformationPageOn };