export const surveysConstants = {
    GET_MY_REQUEST: 'SURVEYS_GET_MY_REQUEST',
    GET_MY_SUCCESS: 'SURVEYS_GET_MY_SUCCESS',
    GET_MY_FAILURE: 'SURVEYS_GET_MY_FAILURE',

    GET_SURVEY_DETAIL_REQUEST: 'SURVEYS_GET_SURVEY_DETAIL_REQUEST',
    GET_SURVEY_DETAIL_SUCCESS: 'SURVEYS_GET_SURVEY_DETAIL_SUCCESS',
    GET_SURVEY_DETAIL_FAILURE: 'SURVEYS_GET_SURVEY_DETAIL_FAILURE',

    GET_SURVEY_DETAIL_CONDUCTED_REQUEST: 'SURVEYS_GET_SURVEY_DETAIL_CONDUCTED_REQUEST',
    GET_SURVEY_DETAIL_CONDUCTED_SUCCESS: 'SURVEYS_GET_SURVEY_DETAIL_CONDUCTED_SUCCESS',
    GET_SURVEY_DETAIL_CONDUCTED_FAILURE: 'SURVEYS_GET_SURVEY_DETAIL_CONDUCTED_FAILURE',
    
    SEARCH_SURVEYS: 'SEARCH_SURVEYS',
    SEARCH_SURVEYS_CLEAR: 'SEARCH_SURVEYS_CLEAR',

    SEND_SURVEY_REQUEST: 'SEND_SURVEY_REQUEST',
    SEND_SURVEY_SUCCESS: 'SEND_SURVEY_SUCCESS',
    SEND_SURVEY_FAILURE: 'SEND_SURVEY_FAILURE',
    SEND_SURVEY_RESET: 'SEND_SURVEY_RESET',

    SURVEY_CHANGE_VIEW: 'SURVEY_CHANGE_VIEW',
};