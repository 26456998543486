import React from "react";
import Col from 'react-bootstrap/Col';
import { forwardTo } from "./../helpers";
import { useTranslation } from "react-i18next";

export const SocialLogin = ({ loginGoogle, loginFacebook, entity }) => {
  const { t } = useTranslation();
  
  const {AliasAPP} = entity;
  //const [entityName, setEntityName] = React.useState(entity && entity.AyuntamientoID == 429 ?'Viladecans' : 'Gecor');
  return (
    <React.Fragment>
      <Col s={12} m={12}>
        <button
          className="waves-effect loginBtn loginBtn--gecor"
          onClick={() => forwardTo("/register")}
        >
          {t("social.p1", {entityName: AliasAPP?AliasAPP:"Gecor"})}
        </button>
      </Col>
      <Col s={12} m={12}>
        <button
          type="submit"
          className="waves-effect loginBtn loginBtn--google"
          onClick={loginGoogle}
        >
          {t("social.p2")}
        </button>
      </Col>
      {/*<Col s={12} m={12}>
        <button
          type="submit"
          className="waves-effect loginBtn loginBtn--facebook"
          onClick={loginFacebook}
        >
          {t("social.p3")}
        </button>
  </Col>*/}
    </React.Fragment>
  );
};
