import React from 'react';
import './Footer.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { isIOS } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhoneAlt, faMapMarked, faCopyright, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { forwardTo } from '../../helpers'



export const FooterComponent_10423 = props => {
    const handleOpenAPPUrl = () => {
        if (isIOS) {
            window.open('https://apps.apple.com/es/app/gecor/id1127259219')
        } else {
            window.open('https://play.google.com/store/apps/details?id=com.gecorsystem.gecorn&hl=es&gl=US')
        }
    };

    return (
        <div id="footer430">
            <footer >
                <div className="footer-top">
                    <Container>
                        <Row>
                            <Col sm={6}>
                                <div className="footer-about">
                                    <Row>
                                        <h3 className="footer-informacion-Ln">Web de medio ambiente Ayuntamiento de Rojales</h3>
                                    </Row>
                                    <p><FontAwesomeIcon icon={faMapMarked} /> Carrer del, Mal. de la Encantá, 1, 03170 Rojals, Alacant</p>
                                    <p><FontAwesomeIcon icon={faPhoneAlt} /> 966 71 50 01</p>
                                    <p><FontAwesomeIcon icon={faEnvelope} /> info@rojales.es</p>
                                    <p><FontAwesomeIcon icon={faCopyright} /> Powered by GECOR.</p>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className="badge-ln text-left">
                                    <Row>
                                        <h3>Consultas</h3>
                                    </Row>
                                    <Row>
                                        Recuerde que puede hacer sus consultas a través de:
                                    </Row>
                                    <p className="enlace" onClick={() => forwardTo('/contact')}><FontAwesomeIcon icon={faEnvelope} /> Correo Electrónico</p>
                                    <p className="enlace" onClick={handleOpenAPPUrl}><FontAwesomeIcon icon={faMobileAlt} /> App</p>
                                    <hr />
                                    <Row>
                                        Y también llamando al teléfono gratuito:
                                    </Row>
                                    <p><FontAwesomeIcon icon={faPhoneAlt} /> 966715001</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </div >
    );
}

export default FooterComponent_10423;