import React from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { surveysActions } from './../../actions';
import { SurveyDetail } from './../../components/SurveyCard/SurveyDetail';
import { withTranslation } from 'react-i18next';

class SurveyDetailPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            EncuestaID: this.props.match.params.id,
        };

        this.handleSubmitPage = this.handleSubmitPage.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(surveysActions.getSurveyDetail(this.state.EncuestaID));

    }

    handleSubmitPage(encuestaID, respuestas) {
        const data = { encuestaID, preguntas: respuestas };
        this.props.dispatch(surveysActions.sendSurvey(data));
    }

    render() {
        const survey = this.props.location.state;
        return (
            <Container className="container-survey-detail">
                {survey && <SurveyDetail survey={survey ? survey : this.props.surveyDetail} handleSubmitPage={this.handleSubmitPage} />}
            </Container>
        )
    }

}

const mapStateToProps = (state) => {
    const { surveys } = state;
    const { surveyDetail } = surveys;
    return {
        surveyDetail,
    };
}

const tSurveyDetailPage = withTranslation()(SurveyDetailPage);
const connectedSurveyDetailPage = connect(mapStateToProps)(tSurveyDetailPage);
export { connectedSurveyDetailPage as SurveyDetailPage };