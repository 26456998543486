import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import CardGroup from 'react-bootstrap/CardGroup';
import { forwardToWithParams, forwardTo } from "../../../../helpers";
import './MenuValverdePage.css';
import { EntitySearch, DynamicBackground } from '../../../../components'
import { isWebpSupported } from 'react-image-webp/dist/utils';
import "react-multi-carousel/lib/styles.css";
import { NoticesCarousel } from "./../../../../components/NoticesCarousel/NoticesCarousel"

class MenuValverdePage extends React.Component {
  //CARGAR AYUNTAMIENTO POR PARAMETRO AO
  constructor(props) {
    super(props);

    this.state = {
      aytoName: '',
      ao: { ao: 10411 },
    };
    this.handleSelectedEntity = this.handleSelectedEntity.bind(this);
  }

  handleSelectedEntity(entity) {
    this.setState({ aytoName: entity.Nombre });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {
    const backgroundImage = {
      backgroundImage: 'url(\'https://gecorcloud.com/images/valverde/banner-valverde.jpg\')',
    };
    const { ao } = this.state;
    
    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0)" background={isWebpSupported() ? "" : ""}>
        <div className="envi-menu2" id="home-alcala">
          <EntitySearch className="ayto-borde" redirect={false} onSelected={this.handleSelectedEntity} ao={ao} hide={true} />
          <div className="container-main-img" style={backgroundImage}>
            <Image src="https://gecorcloud.com/images/valverde/logo-valverde.png" className="escudo"></Image>
            <div className="container-main-img-r">
              <h3>AYUNTAMIENTO DE VALVERDE DEL CAMINO</h3>
              <hr />
              <h5>PORTAL</h5>
              <h5>MEDIOAMBIENTAL</h5>
            </div>
          </div>
          <div id="homeAlc">
            {/*INICIO NOTICIAS*/}
            <Container id="noticias">
              <Row className="cards-alc">
                <Col md={12}>
                  <NoticesCarousel AytoID={this.state.ao.ao} />
                </Col>
              </Row>
            </Container>
            {/*FIN NOTICIAS*/}
            {/*INICIO INFORMACION AMBIENTAL*/}
            <Row className="section-info-ambiental-valverde" id="info-ambiental">
              <Col sm={12}>
                  <div className="title-valverde-2">
                    <h1>Información local</h1>
                    <hr></hr>
                  </div>
              </Col>
            </Row>
            <Row className="section-info-ambiental-valverde" id="info-ambiental">
              <Col sm={12}>
                <Container>
                  <CardGroup>
                    <Card className="card-valverde">
                      <Card.Img variant="top" src="https://gecorcloud.com/images/valverde/rutas.jpg" />
                      <Card.Body>
                        <Card.Title>Rutas senderismo</Card.Title>
                        <Card.Text>
                        Rutas y mapas de senderismo por el término municipal de Valverde del Camino.
                        </Card.Text>
                        <Button variant="success" onClick={() => forwardToWithParams('/valverde-informacion', {eventKey: 'first'})}>Leer mas</Button>
                      </Card.Body>
                    </Card>
                    <Card className="card-valverde">
                      <Card.Img variant="top" src="https://gecorcloud.com/images/valverde/fauna.png" />
                      <Card.Body>
                        <Card.Title>Fauna</Card.Title>
                        <Card.Text>
                          Información sobre la fauna local de Valverde del Camino por categorías.
                        </Card.Text>
                        <Button variant="success" onClick={() => forwardToWithParams('/valverde-informacion', {eventKey: 'second'})}>Leer mas</Button>
                      </Card.Body>
                    </Card>
                    <Card className="card-valverde">
                      <Card.Img variant="top" src="https://gecorcloud.com/images/valverde/flora.jpg" />
                      <Card.Body>
                        <Card.Title>Flora</Card.Title>
                        <Card.Text>
                          Información sobre la flora local de Valverde del Camino y documentación asociada.
                        </Card.Text>
                        <Button variant="success" onClick={() => forwardToWithParams('/valverde-informacion', {eventKey: 'third'})}>Leer mas</Button>
                      </Card.Body>
                    </Card>
                    <Card className="card-valverde">
                      <Card.Img variant="top" src="https://gecorcloud.com/images/valverde/hidrologia.jpg" />
                      <Card.Body>
                        <Card.Title>Hidrología</Card.Title>
                        <Card.Text>
                          Información sobre la hidrología y fuentes disponible de Valverde del Camino.
                        </Card.Text>
                        <Button variant="success" onClick={() => forwardToWithParams('/valverde-informacion', {eventKey: 'four'})}>Leer mas</Button>
                      </Card.Body>
                    </Card>
                  </CardGroup>
                </Container>
              </Col>
            </Row>
            <Row className="section-info-ambiental-valverde" id="info-ambiental">
              <Col sm={12}>
                <Container>
                  <CardGroup>
                    <Card className="card-valverde">
                      <Card.Img variant="top" src="https://gecorcloud.com/images/valverde/Micologia.jpg" />
                      <Card.Body>
                        <Card.Title>Micología</Card.Title>
                        <Card.Text>
                          Documentación que recoge información sobre los hongos en Valverde del Camino.
                        </Card.Text>
                        <Button variant="success" onClick={() => forwardToWithParams('/valverde-informacion', {eventKey: 'five'})}>Leer mas</Button>
                      </Card.Body>
                    </Card>
                    <Card className="card-valverde">
                      <Card.Img variant="top" src="https://gecorcloud.com/images/valverde/patrimonio.jpg" />
                      <Card.Body>
                        <Card.Title>Patrimonio</Card.Title>
                        <Card.Text>
                           Información sobre el patrimonio y su documentación local de Valverde del Camino.
                        </Card.Text>
                        <Button variant="success" onClick={() => forwardToWithParams('/valverde-informacion', {eventKey: 'six'})}>Leer mas</Button>
                      </Card.Body>
                    </Card>
                    <Card className="card-valverde">
                      <Card.Img variant="top" src="https://gecorcloud.com/images/valverde/didactico.jpg" />
                      <Card.Body>
                        <Card.Title>Unidad didáctica</Card.Title>
                        <Card.Text>
                          Información didactica para aprender mas sobre Valverde del Camino.
                        </Card.Text>
                        <Button variant="success" onClick={() => forwardToWithParams('/valverde-informacion', {eventKey: 'seven'})}>Leer mas</Button>
                      </Card.Body>
                    </Card>
                    <Card className="card-valverde">
                      <Card.Img variant="top" src="https://gecorcloud.com/images/valverde/punto-limpio.jpg" />
                      <Card.Body>
                        <Card.Title>Punto limpio</Card.Title>
                        <Card.Text>
                          Información sobre los puntos limpios y sus horarios de Valverde del Camino.
                        </Card.Text>
                        <Button variant="success" onClick={() => forwardToWithParams('/valverde-informacion', {eventKey: 'eigth'})}>Leer mas</Button>
                      </Card.Body>
                    </Card>
                  </CardGroup>
                </Container>
              </Col>
            </Row>
            {/*FIN INFORMACION AMBIENTAL*/}
            {/*INICIO ENLACES INTERÉS*/}
            <Row className="section-enlaces-interes" id="enlacesInteres" >
              <Col md={12}>
                <div className="infoTitAlc">
                  <h1 ><b>Enlaces de interés</b></h1>
                </div>
                <div className="container-enlaces-interes">
                <div className="item-enlace-interes">
                    <Image src="https://gecorcloud.com/images/logo_gecor.png" alt="Imagen gecor" />
                    <div className="item-enlace-interes-des" onClick={() => forwardTo('/login', '?ao=10411')}>
                      <h4>GECOR</h4>
                      <h5>Participa en la gestión de incidencias en la vía pública con la app GECOR.</h5>
                    </div>
                  </div>
                  <div className="item-enlace-interes">
                    <Image src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/2021/01/banner protocolo NOX_mod.jpg" alt="imagen protocolo NOx" />
                    <div className="item-enlace-interes-des" onClick={() => window.open('https://www.valverdedelcamino.es/', '_blank')}>
                      <h4>Web del Ayuntamiento</h4>
                      <h5>Enlace a la web del ayuntamiento y a todos sus servicios</h5>
                    </div>
                  </div>
                  <div className="item-enlace-interes">
                    <Image src="https://gecorcloud.com/images/pl-alc-med.png" alt="Imagen punto limpio móvil" />
                    <div className="item-enlace-interes-des" onClick={() => forwardToWithParams('/valverde-informacion', {eventKey: 'eigth'})}>
                      <h4>Punto limpio</h4>
                      <h5>Los ciudadanos que voluntaria y gratuitamente depositen sus residuos en el Punto Limpio estarán colaborando de forma significativa en la mejora del Medio Ambiente</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/*FIN ENLACES INTERÉS*/}
          </div>
        </div>
      </DynamicBackground >
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedMenuValverdePage = connect(mapStateToProps)(MenuValverdePage);
export { connectedMenuValverdePage as MenuValverdePage };