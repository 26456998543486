import React from 'react';
import { connect } from 'react-redux';
import { filteredEntities } from '../../reducers/entities.reducer';
import { EntitySearch } from '../../components';
import './EntitiesPage.css';
import { DynamicBackground } from './../../components';
import { withTranslation } from 'react-i18next';
import { isWebpSupported } from 'react-image-webp/dist/utils';

class EntitiesPage extends React.Component {

    render() {
        const { t } = this.props;
        return (
            <DynamicBackground background={isWebpSupported() ? "https://gecorcloud.com/images/bg2.webp" : "https://gecorcloud.com/images/bg2.jpg"}>
                <div className="containerFind">
                    <h1>{t('aytoselect.t')}</h1>
                    <hr className="line5" ></hr>
                    <EntitySearch redirect={true} />
                </div>
            </DynamicBackground>
        );
    }
}

const mapStateToProps = (state) => {
    const { entities } = state;
    const { entityQuery } = entities;
    return {
        entities: { entities: filteredEntities(state) },
        entityQuery,
    };
}
const tEntitiesPage = withTranslation()(EntitiesPage);
const connectedEntitiesPage = connect(mapStateToProps)(tEntitiesPage);
export { connectedEntitiesPage as EntitiesPage };