import { apiUrl, apiUrlFuengirola, apiUrlVera } from "../../constants";
import fetchIntercept from 'fetch-intercept';

const unregister = fetchIntercept.register({
  request: function (url, config) {
    var urlSplit=url.split("/");
    var metodo = urlSplit[urlSplit.length-1];   
    var clase = urlSplit[urlSplit.length-2];

    if (metodo != undefined) {
      switch (localStorage.getItem("Server")) {
        case 'vera':
          url = apiUrlVera + '/'+clase+ '/'  +metodo;
          break;
        case 'fuengirola':
          url = apiUrlFuengirola + '/'+clase+ '/'  +metodo;
          break;
        case 'emvisesa':
          if (metodo == "nuevaIncidencia"){
            url = apiUrl + "/Emvisesa/nuevaIncidencia";
          }  else if (metodo == "nuevoUsuario"){
            url = apiUrl + "/Emvisesa/nuevoUsuario";
          }                 
          break;
        default:
          url = url;
      }
    }
    return [url, config];
  },

  requestError: function (error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error);
  },

  response: function (response) {
    // Modify the reponse object
    return response;
  },

  responseError: function (error) {
    // Handle an fetch error
    return Promise.reject(error);
  }
});

export const authApi = {
  loadUserData() {
    return JSON.parse(localStorage.getItem("user")) || undefined;
  },
  /**
   * Logs the current user out
   */
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("config");
    return Promise.resolve(true);
  },
  /**
   * Checks if a user is logged in
   */
  loggedIn() {
    return !!localStorage.getItem("user");
  },
  /**
   * Logs a user in, returning a promise with `true` when done
   * @param  {string} email The username of the user
   * @param  {string} password The password of the user
   */
  login(
    email,
    password,
    entity = localStorage.entity,
    deviceToken = localStorage.token,
    dispositivo = "WebCiudadano"
  ) {
    if (authApi.loggedIn()) return Promise.resolve(true);

    const ayuntamientoID = JSON.parse(entity).AyuntamientoID;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        password,
        deviceToken,
        dispositivo,
        ayuntamientoID
      })
    };

    return (
      fetch(`${apiUrl}/User/loginUser`, requestOptions)
        // .then(handleResponse)
        .then(response => response.json())
        .then(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          if (user && user.token && user.UsuarioID && user.Activo)
            localStorage.setItem("user", JSON.stringify(user));
          //return Promise.resolve(true)
          return user;
        })
    );
  },
  /**
   * Logs a user in, returning a promise with `true` when done
   * @param  {string} email The username of the user
   * @param  {string} password The password of the user
   */
  loginUserExternal(
    Email,
    Nombre = "",
    GoogleID = "",
    UrlImage = "",
    entity = localStorage.entity,
    DeviceToken = localStorage.token,
    Dispositivo = "WebCiudadanoN",
    Aplicacion = "C",
    Idioma = "es",
    ModeloMovil = "",
    FacebookID = "",
    FacebookAcessToken = ""
  ) {
    if (authApi.loggedIn()) return Promise.resolve(true);

    const { AyuntamientoID } = JSON.parse(entity);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Email,
        AyuntamientoID,
        Nombre,
        GoogleID,
        UrlImage,
        DeviceToken,
        Dispositivo,
        Aplicacion,
        Idioma,
        ModeloMovil,
        FacebookID,
        FacebookAcessToken
      })
    };

    return (
      fetch(`${apiUrl}/User/loginUserExternal`, requestOptions)
        // .then(handleResponse)
        .then(response => response.json())
        .then(user => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("user", JSON.stringify(user));
          // return Promise.resolve(true)
          return user;
        })
    );
  },
  /**
   * Registers a user and then logs them in
   * @param  {string} name The username of the user
   * @param  {string} mobilePhone The username of the user
   * @param  {string} email The username of the user
   * @param  {string} password The password of the user
   */
  register(
    email,
    password,
    mobilePhone,
    name,
    nie,
    idioma = "es",
    aplication = "C",
    entity = localStorage.entity
  ) {
    const { AyuntamientoID } = JSON.parse(entity);
    const params = {
      Nombre: name,
      //Apellidos: surname,
      Email: email,
      Password: password,
      //Telefono = phone;
      Movil: mobilePhone.replace(/\s/g, ""),
      //Direccion: direccion,
      NIF: nie,
      //CodPostal: postal,
      Idioma: idioma,
      Aplicacion: aplication,
      AyuntamientoID
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params)
    };

    return fetch(`${apiUrl}/User/nuevoUsuario`, requestOptions)
      .then(response => response.json())
      .then(user => {
        return user;
      });
    //.then(() => authApi.login(email, password));
  },
  /**
   * Logs a user in, returning a promise with `true` when done
   * @param  {string} email The username of the user
   * @param  {string} password The password of the user
   */
  resetPassword(
    email,
    entity = localStorage.entity,
    idioma = "es",
    user = localStorage.user
  ) {
    const { AyuntamientoID, UsuarioIDCiudadano } = JSON.parse(entity);

    const params = {
      Email: email,
      Idioma: idioma,
      AyuntamientoID,
      UsuarioID: UsuarioIDCiudadano
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params)
    };

    return (
      fetch(`${apiUrl}/User/restaurarPass`, requestOptions)
        // .then(handleResponse)
        .then(response => response.json())
        .then(resp => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          //localStorage.setItem('user', JSON.stringify(user));
          return Promise.resolve(resp);
          // return user;
        })
    );
  },
  update(data, user = localStorage.user) {
    const { token, AyuntamientoID, CiudadanoID, UrlImage } = JSON.parse(user);

    const Movil = data.Movil.replace(/\s/g, "");

    const {
      Email,
      Nombre,
      Apellidos,
      Telefono,
      NIF,
      Direccion,
      CodPostal
    } = data;

    const params = {
      token,
      CiudadanoID,
      AyuntamientoID,
      Email,
      Nombre,
      Apellidos,
      Telefono,
      Movil,
      NIF,
      Direccion,
      CodPostal,
      UrlImage,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params)
    };

    return (
      fetch(`${apiUrl}/User/updateUser`, requestOptions)
        // .then(handleResponse)
        .then(response => response.json())
        .then(resp => {
          if (resp) {
            localStorage.setItem("user", JSON.stringify(data));
          }
          return Promise.resolve(resp);
        })
    );
  },
  onChange() { },
  validateSpanishId(str, entityConfig = localStorage.getItem("entityConfig")) {
    let parsedEntityConfig = entityConfig ? JSON.parse(entityConfig) : undefined;
    if (str != undefined) {
      let DNI_REGEX = /^(\d{8})([A-Z])$/;
      let CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
      let NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;

      // Ensure upcase and remove whitespace
      str = str.toUpperCase().replace(/\s/, '');

      let valid = false;
      let type;
      if (str.match(DNI_REGEX)) {
        type = 'dni';
      }
      if (str.match(CIF_REGEX)) {
        type = 'cif';
      }
      if (str.match(NIE_REGEX)) {
        type = 'nie';
      }

      switch (type) {
        case 'dni':
          valid = authApi.validDNI(str);
          break;
        case 'nie':
          // Change the initial letter for the corresponding number and validate as DNI
          let nie_prefix = str.charAt(0);
          switch (nie_prefix) {
            case 'X': nie_prefix = 0; break;
            case 'Y': nie_prefix = 1; break;
            case 'Z': nie_prefix = 2; break;
          }
          valid = authApi.validDNI(nie_prefix + str.substr(1));
          break;
      }

      return valid;
    } else {
      if (parsedEntityConfig && parsedEntityConfig.register && parsedEntityConfig.register.nif && parsedEntityConfig.register.nif.validate)
        return false;
      else
        return true
    }
  },
  validDNI(dni) {
    let dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
    let letter = dni_letters.charAt(parseInt(dni, 10) % 23);

    return letter == dni.charAt(8);
  },
  validateMobile(str, entityConfig = localStorage.getItem("entityConfig")) {
    let parsedEntityConfig = entityConfig ? JSON.parse(entityConfig) : undefined;
    if (str != undefined) {
      let MOBILE_REGEX = /^((\+34|0034|34)?[ -]*(6|7)([0-9]){2}[ -]?(([0-9]){2}[ -]?([0-9]){2}[ -]?([0-9]){2}|([0-9]){3}[ -]?([0-9]){3}))|(([0-9]){3})$/;
      let valid = MOBILE_REGEX.test(str);
      return valid;
    } else {
      if (parsedEntityConfig && parsedEntityConfig.register && parsedEntityConfig.register.mobile && parsedEntityConfig.register.mobile.validate)
        return false;
      else
        return true
    }
  },
};

export default authApi;
