import React from 'react';
import { connect } from 'react-redux';
import { incidentsActions } from '../../actions';
import './TipologySearch.css';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { filteredTipology } from '../../reducers/incidents.reducer';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

import { withTranslation } from 'react-i18next';
import { incidentsConstants } from '../../constants';

class TipologySearch extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleSelectTipology(tipology,tipologyQuery) {
        return (e) => {
            this.fetchEntity(tipology);          
        };
    }

    handleSearchTipology() {
        return (e) => {this.props.dispatch(incidentsActions.searchTipology(e.target.value)) }
    }

    fetchEntity(tipology) {
        const { onSelected } = this.props;
        onSelected(tipology);
        this.props.dispatch(incidentsActions.clearSearchTipology())
    }

    render() {
        const { tipologyQuery, tipologies, t } = this.props;
        return (

            <div className="search-tipology search-container">
                <InputGroup size="lg" className="mb-3">
                    <InputGroup.Prepend>
                        <InputGroup.Text id="inputGroup-sizing-lg" ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl placeholder={t('tipologySearch.placeholder')} aria-label="Large" aria-describedby="inputGroup-sizing-sm" type="text" s={12} onChange={this.handleSearchTipology()} value={tipologyQuery || ''} icon="search" />
                </InputGroup>
                {(tipologyQuery?.length>2 && (!tipologies || !tipologies.tipologies || tipologies.tipologies.length == 0)) &&
                    <div>{t('tipologySearch.not-found')}</div>
                }
                {tipologies && tipologies.tipologies && tipologies.tipologies.length > 0 &&
                    <Card className="search-tipology-items">
                        <ListGroup variant="flush">
                            {
                                tipologies.tipologies.map((tipology) =>
                                    <ListGroup.Item className="tipology" key={tipology.index} onClick={this.handleSelectTipology(tipology,tipologyQuery)}>
                                        <img src={tipology.Icono} alt="logo" />
                                        <span>{tipology.tag}</span>
                                    </ListGroup.Item>
                                )
                            }
                        </ListGroup>
                    </Card>}
            </div>
        );
    }
}

const mapStateToProps = (state, customProp) => {
    const { incidents } = state;
    const { tipologies, tipologyQuery } = incidents;
    const { onSelected } = customProp;
    return {
        tipologies: { tipologies: filteredTipology(state) },
        tipologyQuery,
        onSelected
    };
}

const tTipologySearch = withTranslation()(TipologySearch);
const connectedTipologySearch = connect(mapStateToProps)(tTipologySearch);
export { connectedTipologySearch as TipologySearch };