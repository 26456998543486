import React from 'react';
import './DeclaracionAcc.css';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'


class DeclaracionAccPage extends React.Component {
    render() {
        return (
            <Container id="declaracion-acc-container" className="text-justify float-center">
                <h1 className="text-center" tabIndex="1">Declaración de Accesibilidad.</h1>
                <h3 tabIndex="2">Política de accesibilidad y cumplimiento de los estándares.</h3>
                <p tabIndex="3">Gecor System S.L.U tiene el objetivo de hacer que todas las personas puedan hacer uso de su plataforma sin encontrar barreras físicas, tecnológicas o intelectuales. Por esa razón, trabajamos para conseguir cumplir el nivel AA de las Directrices de Accesibilidad.</p>
                <h4 className="text-left" tabIndex="4">Características de accesibilidad</h4>
                <h5 className="text-left" tabIndex="5">Estructura de las páginas</h5>
                <p tabIndex="6"><FontAwesomeIcon icon={faArrowRight} />Las páginas del portal están estructuradas correctamente, separando el contenido y la presentación mediante el uso de hojas de estilo (CSS). Eso permite acceder a los contenidos mediante navegadores no visuales o cuando no se quieran usar las hojas de estilo predeterminadas.</p>
                <p tabIndex="7"><FontAwesomeIcon icon={faArrowRight} />Se utiliza un lenguaje HTML válido y bien etiquetado, con una estructura de encabezados que permite una navegación fácil por el contenido.</p>
                <h5 className="text-left" tabIndex="8">Texto.</h5>
                <p tabIndex="9">El tamaño del texto puede redimensionarse según las preferencias del usuario. Para ello, puede utilizar las teclas CTRL+ y CTRL-. De la misma manera, en los “ajustes” de su navegador puede definir una fuente mayor y la página seguirá, en la medida de lo posible, siendo legible.</p>
                <h5 className="text-left" tabIndex="10">Enlaces.</h5>
                <p tabIndex="11">Trabajamos para que todos los enlaces sean comprensibles y claros, incluso fuera de contexto.</p>
                <h5 className="text-left" tabIndex="12">Imágenes y otros elementos multimedia.</h5>
                <p tabIndex="13">Hacemos todo lo posible para que se pueda acceder al portal sin necesidad de ver imágenes u otros elementos multimedia. Para ello, proporcionamos un texto alternativo cuando es necesario.</p>
                <h5 className="text-left" tabIndex="14">Dependencia de dispositivo.</h5>
                <p tabIndex="15">El contenido es operable a través de teclado sin un límite de tiempo específico para realizar las pulsaciones de las teclas.</p>
                <h5 className="text-left" tabIndex="16">Consideraciones.</h5>
                <p tabIndex="17">La información contenida en el portal ha sido creada progresivamente. Gecor System trabaja activamente cada día para conseguir un nivel consistente de accesibilidad. Por eso, nuestra intención es ir aplicando las mejoras conforme al proceso natural de actualización de contenidos (por ejemplo, mejoras en imágenes y contraste de color).</p>
                <h5 className="text-left" tabIndex="18">Contacto.</h5>
                <p tabIndex="19">La accesibilidad es un trabajo continuo y todas las opiniones o sugerencias que puedan ayudar en el proceso de mejora son bienvenidas. Puedes contactar con nosotros a través de nuestro servicio de atención al cliente.</p>
            </Container>
        );
    }

}

export { DeclaracionAccPage }