import { take, call, put, fork } from 'redux-saga/effects'
import { contactApi } from './../helpers'
import { contactConstants } from './../constants'

export function* sendMail({ name, phone, email, subject, body, to }) {
    // We send an action that tells Redux we're sending a request
    yield put({ type: contactConstants.CONTACT_SENDING_REQUEST, sending: true })

    // We then try to register or log in the user, depending on the request
    try {
        let response = yield call(contactApi.sendMail, subject, `${body} \n Datos de contacto: \n Nombre: ${name} \n Email: ${email} \n Tel.: ${phone}`, to)
        return response
    } catch (error) {
        // If we get an error we send Redux the appropiate action and return
        yield put({ type: contactConstants.CONTACT_SEND_FAILURE, error: error.message })

        return false
    } finally {
        // When done, we tell Redux we're not in the middle of a request any more
        yield put({ type: contactConstants.CONTACT_SENDING_REQUEST, sending: false })
    }
}

export function* sendMailFlow() {
    while (true) {
        const request = yield take(contactConstants.CONTACT_SEND_REQUEST)
        const { data } = request
        const wasSuccessful = yield call(sendMail, data)

        if (wasSuccessful) {
            yield put({ type: contactConstants.CONTACT_SEND_SUCCESS, sended: true })
            yield put({ type: contactConstants.CONTACT_FORM_CLEAR })
        } else {
            // throw error
            yield put({ type: contactConstants.CONTACT_SEND_FAILURE, sended: false })
        }
    }
}

export default function* () {
    yield fork(sendMailFlow)
}
