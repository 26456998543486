import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Carousel from "react-multi-carousel";
import LinesEllipsis from 'react-lines-ellipsis'
import { difusionesActions } from './../../actions'
import { forwardToWithParams } from "../../helpers";
import { Fragment } from 'react';
import Moment from 'react-moment';
import "react-multi-carousel/lib/styles.css";
import './NoticesCarousel.css';
import 'moment/locale/es';

class NoticesCarousel extends React.PureComponent {
    constructor(props, context) {
        super(props, context);
        this.props.dispatch(difusionesActions.getDifusionesByAytoID({ AytoID: props.AytoID }));
        this.cargaMasNoticias = this.cargaMasNoticias.bind(this);
    }

    cargaMasNoticias(siguienteSlide, noticias) {
        if (noticias.length - ((siguienteSlide - 1) + 3) < 3) {
            this.props.dispatch(difusionesActions.getDifusionesByAytoID({ AytoID: this.props.AytoID, top: 10, lastDifusionMensajeID: noticias.lastItem.DifusionMensajeID }))
        }
    }

    render() {
        const { difusiones, t } = this.props;
        return (
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable={true}
                infinite={false}
                itemClass="carousel-noticia-item"
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                responsive={{
                    desktop: {
                        breakpoint: {
                            max: 3000,
                            min: 1024
                        },
                        items: 3,
                        partialVisibilityGutter: 40
                    },
                    mobile: {
                        breakpoint: {
                            max: 464,
                            min: 0
                        },
                        items: 1,
                        partialVisibilityGutter: 30
                    },
                    tablet: {
                        breakpoint: {
                            max: 1024,
                            min: 464
                        },
                        items: 2,
                        partialVisibilityGutter: 30
                    }
                }}
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
                beforeChange={(nextSlide) => {
                    this.cargaMasNoticias(nextSlide, difusiones);
                }}
            >
                {(difusiones) ? difusiones.map((difusion) =>
                    <Col md={12} key={difusion.DifusionMensajeID}>
                        <Card className="card-concej-alc text-left item-noticia">
                            <Card.Img variant="top" className="img-fluid pointer img-noticia-carousel" onClick={() => window.open(difusion.UrlImagen, '_blank')} src={difusion.UrlImagen} />
                            <small className="text-muted text-center"><Moment format="LL" locale={t.language}>{difusion.FechaCreacion}</Moment></small>
                            <Card.Body className="carousel-noticia-body">
                                <Card.Title>{difusion.Titulo}</Card.Title>
                                <LinesEllipsis
                                    text={difusion.Subtitulo}
                                    maxLine='3'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters'
                                    className='carousel-noticia-subtitle'
                                />
                            </Card.Body>
                            <Card.Footer className="carousel-noticia-footer">
                                <Card.Link target="_blank" rel="noopener noreferrer" className="pointer" onClick={() => forwardToWithParams(`/difusion/${difusion.DifusionMensajeID}/${difusion.Titulo.replace(/ /g, '-').replace(/\//g, '-').toLowerCase()}`, difusion)}>LEER MÁS</Card.Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                )
                    : <Fragment></Fragment>
                }
            </Carousel>
        );
    }

}
const mapStateToProps = (state) => {
    const { difusiones } = state;
    return {
        difusiones: difusiones.difusiones,
    };
}
const tNoticesCarousel = withTranslation()(NoticesCarousel);
const connectedNoticesCarousel = connect(mapStateToProps)(tNoticesCarousel);
export { connectedNoticesCarousel as NoticesCarousel };