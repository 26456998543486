import React from 'react';
import './DevelopersPage.css';
import { forwardTo } from "../../helpers";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

class DevelopersPage extends React.Component {

    render() {

        const backgroundImage = {
            backgroundImage: `url(https://gecorcloud.com/images/developers.png)`
        };
      
        return (
            <div className="residuos-component mt-0" ref={div => this.subhead = div} id="developers">
                <div className="container-main-img" style={backgroundImage}>
                    <h1>DEVELOPERS</h1>
                    <Breadcrumb className="d-flex justify-content-center">
                        <Breadcrumb.Item onClick={() => forwardTo('/')}>Inicio</Breadcrumb.Item>
                        <Breadcrumb.Item active>Developers</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Container>
                    <Tab.Container defaultActiveKey={this.props.eventKey ? this.props.eventKey : "first"}>
                        <Row>
                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">Introducción</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">Angular</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={9}>
                                <Tab.Content className="box-txt-dev">
                                    <Tab.Pane eventKey="first">
                                        <div>
                                            <Card>
                                                <Card.Header>Introducción</Card.Header>
                                                <Card.Body>
                                                    <Card.Text as="div">
                                                        <Row>
                                                            <Col sm={12}>
                                                                <p>La integración del inicio de sesión con Gecor en su aplicación o sitio web le permite usar los ciudadanos ya existentes en un ayuntamiento
                                                                sin necesidad de gestionar y almacenar las credenciales.</p>
                                                                <p>Llegados a este punto necesitará ciertos parámetros que serán proporcionados por Gecor o por su ayuntamiento, se hará mención
                                                                más adelante de dichos parámetro marcando de manera explícita los que necesitan ser proporcionados.</p>
                                                                <p>Enumeraremos y explicaremos brevemente los parámetros que se encontrará en cualquier tecnología que use: Angular, Android
                                                                , etc. Tenga en cuenta que estos parámetros están definidos por el propio estándar OAuth y la capa OpenID Connect que puede consultar
                                                                <a href="https://openid.net/specs/openid-connect-core-1_0.html"> aquí</a></p>
                                                                <ul>
                                                                    <li><b>Servidor de Autorización: </b>En definitiva es la URL del servicio que tenemos que consumir para autenticarnos
                                                                    y conseguir autorización para acceder a los diferentes recursos de Gecor. La URL es: <b>https://gecorauthserver.azurewebsites.net</b></li>
                                                                    <li><b>URL de redirección: </b>Esta URL esta definida por usted y su aplicación, con este parámetro debe de responder a la pregunta
                                                                    ¿A donde redirijo al usuario cuando haga login?</li>
                                                                    <li><b>Url de redirección en el logout: </b>Es igual que el caso anterior pero esta vez es ¿a donde redirijo el usuario cuando haga
                                                                    logout?, normalmente es al propio login</li>
                                                                    <li><b>Cliente ID: </b>Este es su identificador único y deberá de ser proporcionado por Gecor</li>
                                                                    <li><b>Scope: </b>El scope es el mecanismo que usa OAuth para limitar su acceso como cliente a los recursos e información, este scope
                                                                    reclamado por usted será validado por el usuario en el proceso de login, puede profundizar en esto a través de la 
                                                                    <a href="https://openid.net/specs/openid-connect-core-1_0.html#ScopeClaims"> documentación oficial</a>. Por defecto y para empezar puede usar
                                                                    el siguiente scope: <b>openid profile</b></li>
                                                                    <li><b>Tipo de respuesta: </b>Este parámetro define el tipo de flujo de autenticación que va seguir su aplicación, 
                                                                    <a href="https://openid.net/specs/openid-connect-core-1_0.html#Authentication"> aquí </a> está la información detallada. Según el tipo
                                                                    de autorización que elija puede que necesite un nuevo parametro, la <b>clave secreta</b> que es única y tendrá que ser proporcionada por Gecor,
                                                                    aunque en los ejemplos de esta documentación no se hace uso de este tipo de flujo.</li>
                                                                </ul>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div>
                                            <Card ref={card => this.content = card}>
                                                <Card.Header>Creando un proyecto</Card.Header>
                                                <Card.Body>
                                                    <Card.Text as="div">
                                                        <Row>
                                                            <Col md={12}>
                                                                <p>
                                                                    En esta guía de como integrar el login de Gecor dentro de su aplicación Angular empezaremos creando una aplicación
                                                                    de ejemplo, puede seguir la guía paso a paso para entender todo el proceso y a posterior traspasar el trabajo
                                                                    a su proyecto real. O directamente trabajar sobre su proyecto real, si ese es el caso, no es necesario que ejecute los
                                                                    pasos que a continuación se describen para crear una nueva aplicación.
                                                                </p>
                                                                <p>
                                                                    En esta guía nos hemos apoyado en la propia <a href="https://angular.io/guide/setup-local" target="_blank" rel="noopener noreferrer"> documentación de Angular</a>
                                                                    , aquí encontrarás los detalles mas relevantes pero es recomendable leer la documentación oficial para evitar confusiones.
                                                                    Empezando, uno de los puntos a comprobar es que cumplimos los <a href="https://angular.io/guide/setup-local#prerequisites" target="_blank" rel="noopener noreferrer"> prerequisitos</a> que nos exige Angular.
                                                                </p>
                                                                <p>
                                                                    Ahora que cumplimos los prerequisitos debemos de instalar, si no lo tenemos, Angular CLI. A través de una consola terminal ejecutaremos
                                                                    el siguiente comando para instalarlo:
                                                                </p>
                                                                <div className="code-example">npm install -g @angular/cli</div>
                                                                <p>Ahora vamos a crear nuestra aplicación en blanco, donde empezaremos a trabajar para lograr la integración con la autorización de Gecor</p>
                                                                <div className="code-example">ng new angular-gecor-auth-example</div>
                                                                <p>
                                                                    En este momento ya tenemos nuestra aplicación creada pero como el objetivo es integrarla con el login de Gecor vamos a crear un componente
                                                                    "Login" para meter la funcionalidad de la autorización y un componente "Home" al que el usuario será redirigido al completarse el login,
                                                                    lo normal que es que haya rutas protegidas y no sean accesibles si el usuario no está logueado, no trataremos este punto en esta guía.
                                                                </p>
                                                                <div className="code-example">
                                                                    ng generate component login
                                                                    <br />
                                                                    ng generate component home
                                                                </div>
                                                                <p>
                                                                    El siguiente paso es crear las rutas necesarias para visualizar ambos componentes, para ello tenemos que trabajar con el fichero
                                                                    <b>app-routing.module.ts</b> para que quede de la siguiente manera:
                                                                </p>
                                                                <img alt="img" src="https://gecorcloud.com/images/developers/ng-routing-file.PNG"></img>
                                                                <p>
                                                                    Cuando lanzamos la aplicación veremos que sale información de Angular, para borrarla hay que entrar en el fichero <b>app.component.html</b> y
                                                                    seguir las instrucciones.
                                                                </p>
                                                                <p>
                                                                    Daremos por finalizado los primeros pasos cuando consigamos lanzar la aplicación y ver ambos componentes
                                                                </p>
                                                                <img alt="img" src="https://gecorcloud.com/images/developers/ng-viewing-components.PNG"></img>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                            <Card ref={card => this.content = card}>
                                                <Card.Header>La librería que necesitamos</Card.Header>
                                                <Card.Body>
                                                    <Card.Text as="div">
                                                        <Row>
                                                            <Col md={12}>
                                                                <p>
                                                                    La integración con Gecor nos permite securizar nuestra aplicación, como el STS (Service Token Security) de Gecor
                                                                    está basado en OAuth2.0 y OpenID Connect utilizaremos la biblioteca angular de <a href="https://www.npmjs.com/package/angular-auth-oidc-client" target="_blank" rel="noopener noreferrer">OAuth2/OIDC </a>
                                                                    destinada a ello.
                                                                </p>                                         
                                                                <p>
                                                                    Al igual que en el paso anterior, nos basaremos en la documentación oficial de la librería que está en su propia página de Github. Aún así,
                                                                    es recomendable visitar dicha documentación por si existen peculiaridades en su solución. En esta guía encontrará únicamente lo necesario
                                                                    para el ejemplo.                                                         
                                                                </p>                       
                                                                <p>Primero empezaremos instalando la librería:</p>
                                                                <div className="code-example">npm install angular-auth-oidc-client</div>
                                                                <p>
                                                                    Una vez instalada la librería vamos a trabajar con el fichero app.module.ts e importaremos el módulo y los servicios. Esto 
                                                                    se ha hecho siguiendo la <a href="https://github.com/damienbod/angular-auth-oidc-client/blob/main/docs/quickstart.md">documentación</a> de la librería.
                                                                    Además, según la tecnología hay preparados diferentes que puede consultar <a href="https://github.com/damienbod/angular-auth-oidc-client/blob/main/docs/samples.md">aquí</a>.
                                                                </p>
                                                                <p>
                                                                    A continuación como tiene que quedar el fichero app.module.ts, en este paso lo que hemos hecho es importar la librería
                                                                    e iniciar el módulo para conectarse al STS de Gecor.
                                                                </p>
                                                                <img alt="img" src="https://gecorcloud.com/images/developers/ng-app-module.PNG"></img>
                                                                <p>
                                                                    <b>CLIENTE ID</b> es un valor proporcionado por Gecor System y es único.
                                                                </p>
                                                                <p>
                                                                    Ahora vamos a inicializar la librería, vamos al fichero app.component.ts y lo dejaremos así.
                                                                </p>
                                                                <img alt="img" src="https://gecorcloud.com/images/developers/app-component.PNG"></img>
                                                                <p>
                                                                    El objetivo ahora es implementar un botón para loguearnos y un botón para desloguearnos. Para ello vamos a trabajar en los componentes
                                                                    que creamos antes: login y home.
                                                                </p>
                                                                <img alt="img" src="https://gecorcloud.com/images/developers/login-component-html.PNG"></img>
                                                                <img alt="img" src="https://gecorcloud.com/images/developers/login-component.PNG"></img>
                                                                <img alt="img" src="https://gecorcloud.com/images/developers/home-component-html.PNG"></img>
                                                                <img alt="img" src="https://gecorcloud.com/images/developers/home-component.PNG"></img>
                                                                <p>
                                                                    Con estos pasos ya hemos terminado, lo podemos probar lanzando la aplicación Angular y usando el botón login que hemos creado. Se nos abrirá
                                                                    esta ventana:
                                                                </p>
                                                                <img alt="img" src="https://gecorcloud.com/images/developers/login-gecor.PNG"></img>
                                                                <p>
                                                                    Al hacer login en Gecor con nuestras credenciales, automáticamente nos redirigirá al componente home y habremos 
                                                                    terminado nuestro proceso de login.
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </div >
        );
    }
}
export { DevelopersPage }