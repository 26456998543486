import './PoiCard.css';
import { useTranslation } from 'react-i18next';
import React from 'react';
import '../../pages/PoisPage/PoisPage.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faLocationArrow, faMapMarkerAlt, faTicketAlt } from '@fortawesome/free-solid-svg-icons'


export const PoiCard = props => {
    const { poi, onCustomClick, clicked, onHoveredPoi } = props;
    const { t } = useTranslation();
    return (
        <div id="poi-card"  onMouseOver={(e) => onHoveredPoi(poi)}>
            <Row className={clicked ? "choosed" : ""}>
                <Col md={12}>
                    <div className="poi">
                        <div onClick={(e) => onCustomClick(poi)} className="images carousel slide carousel-inner">
                            {poi && poi.Images ?
                                <Carousel>
                                    {poi.Images.map((im, key) => (
                                        <Carousel.Item key={key}>
                                            <img
                                                className="d-block w-100"
                                                src={im.image_data}
                                                alt="deslizar"
                                            />
                                        </Carousel.Item>))}
                                </Carousel>
                                :
                                <div className="no-img-p"></div>
                            }
                        </div>
                        <div className="info">
                            <div onClick={(e) => onCustomClick(poi)}>
                                <div className="info-desc">{poi.descripcionCorta} </div>
                                <div className="info-title">{poi.title}</div>      
                                <hr/>                      
                                <div className="info-desc"> <FontAwesomeIcon icon={faMapMarkerAlt} />{poi.ubicacion}</div>
                                <div className="info-desc horario"><FontAwesomeIcon icon={faCalendarAlt} />{poi.horarioVisita && poi.horarioVisita.split('\n').map(function (item, key) {
                                    return (
                                        <span key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    )
                                    })} <br /> 
                                </div>
                            </div>
                            <div className="poi-links">
                                {poi.rutaComprarEntrada &&
                                    <Button className="comprar-poi-btn" href={poi.rutaComprarEntrada} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTicketAlt} />{t('poicard.p6')}</Button>}
                                <Button className="navigation-poi-btn" href={`http://maps.google.com/maps?daddr=${poi.latitud},${poi.longitud}&zoom=15`} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLocationArrow} />{t('poicard.p2')}</Button>
                            </div>                            
                        </div>                        
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default PoiCard;