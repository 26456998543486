import React from 'react';
import { connect } from 'react-redux';
import './LocalInformationPagePr.css';
import { DynamicBackground } from '../../../../components'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { TimelineLite } from "gsap/all";
import { isWebpSupported } from 'react-image-webp/dist/utils';

class LocalInformationPagePr extends React.Component {

  constructor(props) {
    super(props);
    this.logoTl = new TimelineLite({ paused: true });
    this.subhead = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logoTl
      .set(this.content, { autoAlpha: 1 })// show content div
      .from(this.head, 0.5, { left: 100, autoAlpha: 0 })
      .from(this.subhead, 0.5, { left: -100, autoAlpha: 0 }, "-=0.25") // added -0.25 seconds prior to end this.of timeline
    this.logoTl.play()
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));

  }

  render() {

    return (

      <DynamicBackground customOverlay="rgba(255, 255, 255, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/iglesia-nuestra-senora-del-rosario.webp" : "https://gecorcloud.com/images/iglesia-nuestra-senora-del-rosario.jpg"}>

        <div className="container">
          <div className="infoBoxPr " ref={div => this.subhead = div}>
            <div className="infoTit">
              <h1><b>INFORMACIÓN LOCAL</b></h1>
              <hr className="line" ></hr>
            </div>
            {/*   Box 1   */}
            <Card >
              <Card.Header as="h5" >Trámites</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={6} >
                      <Row>
                        <Col sm={12} >
                          <p> {/* ref={ p => this.description = p } */}
                              -  licencias para el transporte de inertes
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <a href="https://sede.puertodelrosario.org/sta/CarpetaPublic/doEvent?APP_CODE=STA&PAGE_CODE=PTS_CATSERV">
                            <Button variant="outline-dark">Ir a Trámites Medio Ambiente</Button>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 6, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/info1.webp" : "https://gecorcloud.com/images/info1.png"} alt="info1" ref={img => this.feature = img} />
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            {/*   Box 2   */}

            <Card ref={card => this.content = card}>
              <Card.Header as="h5" ref={h5 => this.head = h5}>Información y educación ambiental</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 6, offset: 0 }}>
                    <img src={isWebpSupported() ? "https://gecorcloud.com/images/info3.webp" : "https://gecorcloud.com/images/info3.png"} alt="info3" />
                    </Col>
                    <Col md={6} >
                      <Row>
                        <Col sm={12}>
                          <p>
                            Campañas de información y sensibilización
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Celebración de efemérides ambientales
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Exposiciones, talleres, conferencias y publicaciones
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <a href=" http://www.puertodelrosario.org/index.php/es/2013-07-02-12-47-05/medio-ambiente">
                            <Button variant="outline-dark">Ir a Información y educación ambiental</Button>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>


            {/*   Box 3   */}

            <Card>
              <Card.Header as="h5">Residuos</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <p>
                              Para temas relacionados con la recogida de enseres domesticos y electrodomesticos dirigirse a la seccion de servicios del ayuntamiento
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Horario de 9:00 a 13:00 al Teléfono 928850110 Ext 204-205
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Para temas relacionados con el reciclaje dirigirse a las dependencias del cabildo de fuerteventura.
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Telefono: 928859049
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <a href="tel:928859049">
                            <Button variant="outline-dark">Llamar</Button>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 6, offset: 0 }}>
                    <img src={isWebpSupported() ? "https://gecorcloud.com/images/info2.webp" : "https://gecorcloud.com/images/info2.png"} alt="info2" />
                      
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>


            {/*   Box 4   

            <Card>
              <Card.Header as="h5">PARQUES Y JARDINES</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 6, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/info4.webp" : "https://gecorcloud.com/images/info4.png"} alt="info4" />
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <p>Parques Públicos</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Pinares
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Áreas de juegos
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <a href="http://www.puertodelrosario.org/index.php/es/2013-07-02-12-47-05/parques-y-zonas-verdes">
                            <Button variant="outline-dark">Ir a Parques y Jardines</Button>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            */}

          </div>
        </div>

      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedLocalInformationPagePr = connect(mapStateToProps)(LocalInformationPagePr);
export { connectedLocalInformationPagePr as LocalInformationPagePr }; 