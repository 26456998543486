import React from "react";
import { geolocated } from "react-geolocated";

class Gecolocate extends React.Component {
  render() {    
    const {t} = this.props;
    return !this.props.isGeolocationAvailable ? (
      <div className="gelolocate-info">
      <p dangerouslySetInnerHTML={{ __html: t('geolocation.not-support') }}></p> 
      </div>
    ) : !this.props.isGeolocationEnabled ? (
      <div  className="gelolocate-info">
        <p dangerouslySetInnerHTML={{ __html: t('geolocation.disabled') }}></p> 
      </div>
    ) : this.props.coords ? (
      <em className="hidden gelolocate-info">
        {" "}
        {this.props.coords.latitude}, {this.props.coords.longitude}
      </em>
    ) : (
      <div className="hidden gelolocate-info"><p dangerouslySetInnerHTML={{ __html: t('geolocation.data') }}></p>  </div> 
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: Infinity
  },
  watchPosition: false,
  userDecisionTimeout: null,
  suppressLocationOnMount: false,
  geolocationProvider: navigator.geolocation,
  isOptimisticGeolocationEnabled: true
})(Gecolocate);