import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import React, { useState } from 'react';
import data from './data/searcher_430.json';
import './ContentSearcher.css';
import { forwardToWithParams } from "../../helpers";

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

export const ContentSearcher = props => {

    const [filter, setFilter] = useState('');

    let navHeight = document.getElementsByTagName("nav")[0] ? document.getElementsByTagName("nav")[0].clientHeight : 0;

    return (
        <div className="content-searcher">
            <Form inline>
                <FormControl type="text" placeholder="Buscar..." className="mr-sm-2"
                    value={filter} onChange={event => setFilter(event.target.value)} />
            </Form>
            <FontAwesomeIcon icon={faSearch} />
            {filter && <div className="result-container" style={{ top: navHeight }}>
                <h5>Resultados:</h5>
                {data && filter && data.filter(item => item.title.toUpperCase().includes(filter.toUpperCase())
                    || item.keys.toUpperCase().includes(filter.toUpperCase())).length === 0
                    && < div className="no-result-item">No existen resultados</div>
                }
                {data && filter && data.filter(item => item.title.toUpperCase().includes(filter.toUpperCase())
                    || item.keys.toUpperCase().includes(filter.toUpperCase())
                ).map(item => {
                    return (
                        <>
                            <hr />
                            <div className="result-item" onClick={() => {
                                forwardToWithParams(item.url, item.params);
                                setFilter('');
                            }}>
                                {item.title}
                            </div>
                        </>
                    )
                })}
            </div>}
        </div >
    );
}

export default ContentSearcher;