
import React from 'react';
import { connect } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { DynamicBackground } from './../../components'
import { incidentsActions } from '../../actions';
import './NearbyIncidentsPage.css';
import Geolocate from '../../components/Geolocate';
import { IncidentCard } from './../../components';
import { IncidentDetail } from './../../components/IncidentCard/IncidentDetail';
import { isMobile } from 'react-device-detect';
import { forwardTo } from '../../helpers';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { withTranslation } from 'react-i18next';

class NearbyIncidentsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tabActiveKey: "nearby",
            incidentChoosed: undefined,
            width: 0
        }

        this.props.dispatch(incidentsActions.getFavoritesIncidents())

        this.handleGeolocation = this.handleGeolocation.bind(this)
        this.handleFollowUnfollow = this.handleFollowUnfollow.bind(this)
        this.handleChooseIncident = this.handleChooseIncident.bind(this)
        this.handleTabsSelect = this.handleTabsSelect.bind(this)
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(incidentsActions.getFavoritesIncidents());

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    handleGeolocation(data) {
        const { coords } = data;
        const { latitude, longitude } = coords;
        this.props.dispatch(incidentsActions.getNearbyIncidents({ lat: latitude, lng: longitude }));
    }

    handleFollowUnfollow(incident) {
        const followUnfollow = !incident.Activo;
        this.props.dispatch(incidentsActions.followUnfollowIncident({ incident, followUnfollow }));
    }

    handleChooseIncident(incident) {
        let id = incident.AvisoID === 0 || !incident.AvisoID ? incident.AvisoCiudadanoID : incident.AvisoID;
        const { isMobile, width } = this.state;
        if (isMobile || width <= 1200) {
            forwardTo(`/detail/${id}`);
        } else {
            this.setState({ incidentChoosed: incident });
        }
    }

    handleTabsSelect(key) {
        this.setState({ tabActiveKey: key })
        if (!isMobile) {
            const { nearbyIncidents, favIncidents } = this.props;
            let incidentChoosed = undefined;

            if (key === "nearby") {
                if (nearbyIncidents) {
                    incidentChoosed = nearbyIncidents[0]
                } else {
                    incidentChoosed = undefined
                }
            } else if (key === "following") {
                if (favIncidents) {
                    incidentChoosed = favIncidents[0]
                } else {
                    incidentChoosed = undefined
                }
            }

            this.setState({ incidentChoosed });
        }
    }

    render() {

        const { nearbyIncidents, favIncidents, user, entity, t } = this.props;
        const { incidentChoosed } = this.state.incidentChoosed ? this.state : this.props;
        const { width } = this.state;
        return (
            <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={(entity && entity.AyuntamientoID) > 0 ? "" : isWebpSupported() ? "https://gecorcloud.com/images/chicago-690364_960_720.webp" : "https://gecorcloud.com/images/chicago-690364_960_720.jpg"}>
                <div id="nearby-incidents-page">
                    <Row>
                        <Col className="col-left" md={8}>                           
                            <Tabs activeKey={this.state.tabActiveKey} onSelect={this.handleTabsSelect}>
                                <Tab title={<span onClick={this.showNearby}>{t("newincident.nearby")}</span>} eventKey="nearby">
                                    <Geolocate onSuccess={this.handleGeolocation} t={t} />
                                      {user && nearbyIncidents && nearbyIncidents.length != 0 && nearbyIncidents.map((incident) =>
                                        incident.AvisoID != 0 ? <IncidentCard key={incident.AvisoID}
                                            incident={incident} user={user}
                                            clicked={incident.AvisoID !== 0 ?
                                                incidentChoosed && incidentChoosed.AvisoID === incident.AvisoID
                                                :
                                                incidentChoosed && incidentChoosed.AvisoCiudadanoID === incident.AvisoCiudadanoID}
                                            onFollowUnfollow={this.handleFollowUnfollow}
                                            onCustomClick={this.handleChooseIncident} />
                                            : <div className='info-error'>{t('nearby.noNearby')}</div>)
                                    }
                                    {user && nearbyIncidents && nearbyIncidents.length == 0 && <div className='info-error'>{t('nearby.noNearby')}</div>}

                                    {!user && <Spinner className="spinner-incs" animation="border" role="status" variant="primary">
                                        <span className="sr-only">{t('nearby.loading')}</span>
                                    </Spinner>
                                    }
                                </Tab>
                                <Tab title={<span onClick={this.showFav}>{t("newincident.fav")}</span>} eventKey="following">
                                    {user && favIncidents && favIncidents.length != 0 && favIncidents.map((incident) =>
                                        incident.AvisoID != 0 ? <IncidentCard key={incident.AvisoID}
                                            incident={incident} user={user}
                                            clicked={incident.AvisoID !== 0 ?
                                                incidentChoosed && incidentChoosed.AvisoID === incident.AvisoID
                                                :
                                                incidentChoosed && incidentChoosed.AvisoCiudadanoID === incident.AvisoCiudadanoID}
                                            onFollowUnfollow={this.handleFollowUnfollow}
                                            onCustomClick={this.handleChooseIncident} /> 
                                            : 
                                            <div className='info-error'>{t('nearby.noFavoritos')}</div>)}
                                    {user && favIncidents && favIncidents.length == 0 && <div className='info-error'>{t('nearby.noFavoritos')}</div>}
                                    {(!user || !favIncidents) && <Spinner className="spinner-incs" animation="border" role="status" variant="primary">
                                        <span className="sr-only">{t('nearby.loadingFav')}</span>
                                    </Spinner>
                                    }
                                </Tab>
                            </Tabs>
                        </Col>
                        {width > 1200 &&  <Col className="col-right" md={4}>
                            {(incidentChoosed && user && incidentChoosed.AvisoID!=0) && <IncidentDetail incident={incidentChoosed} user={user} />}
                        </Col>}
                    </Row>
                </div>
            </DynamicBackground>
        )
    }

}

const mapStateToProps = (state) => {
    const { entities, incidents, users } = state;
    const { nearbyIncidents, favIncidents } = incidents;
    const { user } = users;
    const { entity } = entities;

    let incidentChoosed = undefined;
    if (nearbyIncidents) incidentChoosed = nearbyIncidents[0];

    return {
        nearbyIncidents,
        favIncidents,
        user,
        incidentChoosed,
        entity,
    };
}
const tNearbyIncidentsPage = withTranslation()(NearbyIncidentsPage);
const connectedNearbyIncidentsPage = connect(mapStateToProps)(tNearbyIncidentsPage);
export { connectedNearbyIncidentsPage as NearbyIncidentsPage };

