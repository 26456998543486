import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import queryString from 'query-string'
import { forwardTo } from "../../../../helpers";
import './MenuPage.css';
import { EntitySearch, DynamicBackground } from '../../../../components'
import { isWebpSupported } from 'react-image-webp/dist/utils';

class MenuPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      aytoName: '',
      ao: queryString.parse(this.props.location.search),
    };

    this.handleSelectedEntity = this.handleSelectedEntity.bind(this);

  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  handleSelectedEntity(entity) {
    this.setState({ aytoName: entity.Nombre });
  }

  render() {
    const { ao } = this.state
    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.1)" background={isWebpSupported() ? "https://gecorcloud.com/images/back-cont.jpg" : "https://gecorcloud.com/images/back-cont.jpg"}>
        <div className="envi-menu-2">
          <EntitySearch redirect={false} onSelected={this.handleSelectedEntity} ao={ao} hide={true} />

          <div className="cabecera-chiclana">
              <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                      <img src="https://gecorcloud.com/images/car-chiclana.jpg" className="d-block corouse-chiclana-1 w-100" alt="..."></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="chiclana-fase-1">
              <div className="fase-title-chiclana">
                NOTIFICA TU INCIDENCIA
              </div>
            </div>
            <div className="chiclana-fase-1v2">
                    <Row className="download-chiclana-1">
                        <Col md={6} >
                          <Row className="row-chiclana-1v2">
                            <Col sm={12}>
                              <div className="text-incident">
                                <p tabIndex="6">Para realizar cualquier tipo de incidencia en la vía urbana en el Ayuntamiento de Chiclana, basta con hacerla a través de la App de GECOR.</p>
                                <p tabIndex="7">Descarga la App, selecciona tu ayuntamiento, y comienza a hacer incidencias. Es muy sencillo, sólo necesitas seleccionar el tipo de incidencia, aportar una imagen y la ubicación.</p>
                                <p tabIndex="8">También tenemos disponible un gestor de incidencias web, para los que no puedan descargar la APP:</p>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12}>
                              <a tabIndex="9" href=" https://gecorweb.com/login?ao=268" alt="gestor de incidencias web">
                                <Button tabIndex="10" className="btn-linea" variant="outline-dark">Gestor de incidencias WEB</Button>
                              </a>
                            </Col>
                          </Row>
                          <br></br>
                        </Col>
                        <Col md={{ span: 6, offset: 0 }}>
                          <Row div="download-app-pr">
                            <Col sm={12}>
                              <h3><b tabIndex="11">Descarga la APP de GECOR:</b></h3>
                            </Col>
                          </Row>
                          <br></br>
                          <Row className="row-inci-pr">
                            <Col sm={6}>
                              <img tabIndex="12" src="https://gecorcloud.com/images/QRApple.jpg" className="qr-app-store" alt="qr-app-store" />
                              <br></br>
                              <a tabIndex="13" target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/es/app/gecor/id1127259219?mt=8">
                                <img src="https://gecorcloud.com/images/app-store-ico.png" alt="app-store" className="download-store-btn-ln" />
                              </a>
                            </Col>
                            <Col sm={6}>
                              <img tabIndex="14" src="https://gecorcloud.com/images/QRAndroid.jpg" className="qr-google-play" alt="qr-google-play" />
                              <br></br>
                              <a tabIndex="15" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.gecorsystem.gecorn&hl=es">
                                <img src="https://gecorcloud.com/images/google-play-ico.png" className="download-store-btn-ln" alt="google-play" />
                              </a>
                            </Col>
                          </Row>
                        </Col>
                  </Row>
            </div>
            <div className="chiclana-fase-1">
              <div className="fase-2-title-chiclana">
                MENÚ AMBIENTAL
              </div>
              <div className="body-menu-chiclana-1">
                  <p>
                      Bienvenido a la web medioambiental de Chiclana Natura.
                  </p>
                  <p>
                      Aquí podrás encontrar gran cantidad de información medioambiental actualizada y solucionar tus dudas poniendote en contacto en el apartado de consultas medioambientales, visitando nuestros glosarios medio ambientales, etc.
                  </p>
                </div>
            </div>

          <Container>
            <Row>
              <Col sm={6}>
                <div className="rec-box-med" onClick={() => forwardTo('/local-information-page-chiclana')}>
                  <img src="https://gecorcloud.com/images/info-ch.png" alt="informacion local ambiental" className="rec-box-img"></img>
                  <div className="title-box-med">
                    Información Local Ambiental
                  </div>
                </div>
              </Col>

              <Col sm={6}>
                <div className="rec-box-med" onClick={() => forwardTo('/enviromental-information-chiclana')}>
                  <img src="https://gecorcloud.com/images/especies-ch.png" alt="Especies Ambientales" className="rec-box-img"></img>
                  <div className="title-box-med">
                    Especies Ambientales
                    </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={6}>
                <div className="rec-box-med" onClick={() => forwardTo('/educacion-ambiental-chiclana')}>
                  <img src="https://gecorcloud.com/images/educacion-ch.png" alt="educacion ambiental chiclana" className="rec-box-img"></img>
                  <div className="title-box-med">
                    Educación Ambiental
                  </div>
                </div>
              </Col>

              <Col sm={6}>
                <div className="rec-box-med" onClick={() => forwardTo('/CitizenGuidePage-chiclana')}>
                  <img src="https://gecorcloud.com/images/practicas-ch.png" alt="prácticas ambientales" className="rec-box-img"></img>
                  <div className="title-box-med">
                    Prácticas Sobre el Medio Ambiente
                  </div>
                </div>
              </Col>
            </Row>

          </Container>

          <div className="chiclana-fase-1">
              <div className="fase-2-title-chiclana">
                CONSULTAS MEDIOAMBIENTALES
              </div>
              <div className="body-menu-chiclana-1">
                <img src="https://gecorcloud.com/images/contacto-ch.png" alt="consultas medioambientales" className="icon-consul-chiclana"></img>
                  <p>
                  Aquí puedes realizar cualquier tipo de consulta medioambiental. Responderemos lo más pronto posible.
                  </p>
                  <p>
                  <Button tabIndex="42" className="btn-linea" variant="primary" onClick={() => forwardTo('/contacto-medioambiente-chiclana')}>Más información</Button>
                  </p>
                </div>
            </div>

        </div>

      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedMenuPage = connect(mapStateToProps)(MenuPage);
export { connectedMenuPage as MenuPage };