import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";

import { REACT_APP_MAP_KEY } from "../../constants";

const Wrapper = styled.main`
  width: 100%;
  height: 100%;  
  height: ${props => (props.height ? props.height : "350px")};
`;


const GoogleMap = ({ children, ...props }) => (
  <Wrapper  {...(props.height ? { height: props.height } : {})}>
    <GoogleMapReact 
      bootstrapURLKeys={{
        key: REACT_APP_MAP_KEY
      }}
      {...props}
    >
      {children}
    </GoogleMapReact>
  </Wrapper>
);

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  height: PropTypes.string
};

GoogleMap.defaultProps = {
  children: null
};

export default GoogleMap;
