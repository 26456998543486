import { apiUrl } from '../../constants';

export const incidentsApi = {
    /**
    * Logs a user in, returning a promise with `true` when done    
    */
    getMyIncidents(user = localStorage.user) {

        const { token } = JSON.parse(user);

        const params = {
            token,
            filter: undefined,
            lastAvisoID: undefined,
            fechaUltimaModificacion: undefined,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Incident/getMisIncidencias`, requestOptions)
            .then(response => response.json())
            .then(incidents => {
                localStorage.setItem('myIncidents', JSON.stringify(incidents));
                return incidents
            });
    },
    getIncidentDetail(avisoID/*, user = localStorage.user*/) {
        // const { token } = JSON.parse(user);
        /*
        const params = {
            token,
            avisoID,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Incident/getHistoricoAviso`, requestOptions)
            .then(response => response.json())
            .then(histories => {
                // localStorage.setItem('entity', JSON.stringify(entity));
                return histories
            });
            */

        let incident = undefined
        const _incidents = incidentsApi.loadMyIncidents()
        const filteredIncidents = _incidents.filter(incident => incident.AvisoID === 0 || !incident.AvisoID ? incident.AvisoCiudadanoID === parseInt(avisoID) : incident.AvisoID = parseInt(avisoID))

        incident = filteredIncidents[0];
        if (!incident) {
            const _incidentsNearby = incidentsApi.loadNearbyIncidents();
            const filteredNearby = _incidentsNearby.filter(incident => incident.AvisoID === 0 || !incident.AvisoID ? incident.AvisoCiudadanoID === parseInt(avisoID) : incident.AvisoID === parseInt(avisoID))
            incident = filteredNearby[0];

            if (!incident) {
                const _incidentsFav = incidentsApi.loadFavIncidents();
                const filteredFav = _incidentsFav.filter(incident => incident.AvisoID === 0 || !incident.AvisoID ? incident.AvisoCiudadanoID === parseInt(avisoID) : incident.AvisoID === parseInt(avisoID))
                incident = filteredFav[0];
            }
        }

        return incident
    },
    //return entities.filter(entity => entity.Nombre.match(new RegExp(query, 'i')));
    /**
   * Logs a user in, returning a promise with `true` when done    
   */
    addIncident(data, user = localStorage.user, entity = localStorage.entity) {

        const { token, Nombre, Email, Movil, CiudadanoID } = JSON.parse(user);
        const { TokenAyuntamiento, ProcedenciaWeb, AyuntamientoID, UsuarioID, Nombre: entityName } = JSON.parse(entity);
        const { description, elementType, elementTypeValue, incidentType, incidentTypeValue, photos, streetOptions } = data;
        const { unitType, unitTypeValue, promotionType, promotionTypeValue } = data;
        const { calleID, desUbicacion, edificioID, nomCalle, nombreEdificio, numCalle, x, y } = streetOptions
        const params = {
            token,
            tokenAyto: TokenAyuntamiento,
            tipoProcedenciaID: ProcedenciaWeb,
            ayuntamientoID: AyuntamientoID,
            estadoAvisoID: -1,
            usuarioID: UsuarioID,
            tipoElementoID: elementType,
            tipoIncID: incidentType,
            desAveria: description,
            nombrePeticionario: Nombre,
            email: Email,
            movil: Movil,
            desTipoElemento: elementTypeValue,
            tipoInc: incidentTypeValue,
            x,
            y,
            calleID,
            nomCalle,
            numCalle,
            desUbicacion,
            ciudadanoID: CiudadanoID,
            edificioID,
            nombreEdificio,
            fotos: photos,
            uni_cod: unitType,
            uni_direc: unitTypeValue,
            pro_cod: promotionType,
            pro_nomb: promotionTypeValue
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Incident/nuevaIncidencia`, requestOptions)
            .then(response => response.json())
            .then(incident => {
                // localStorage.setItem('myIncidents', JSON.stringify(incidents));
                incident = incident[0];
                return { ...incident, entityName }
            });
    },
    /**
    * Logs a user in, returning a promise with `true` when done    
    */
    getFavoritesIncidents(user = localStorage.user) {

        const { token } = JSON.parse(user);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token })
        };

        return fetch(`${apiUrl}/Incident/getIncidenciasFavoritas`, requestOptions)
            .then(response => response.json())
            .then(favIncidents => {
                localStorage.setItem('favIncidents', JSON.stringify(favIncidents));
                return favIncidents
            });
    },
    /**
    * Logs a user in, returning a promise with `true` when done    
    */
    getNearbyIncidents(data, user = localStorage.user, distance = 1000) {
        const { latLng } = data;
        const earth = 6378.137, //radius of the earth in kilometer
            pi = Math.PI,
            m = 1 / (((2 * pi) / 360) * earth) / 1000,
            latitude = parseFloat(latLng.lat),
            longitude = parseFloat(latLng.lng); //1 meter in degree

        const new_latitude0 = latitude + distance * m;
        const new_longitude0 = longitude + (distance * m) / Math.cos(latitude * (pi / 180));

        const new_latitude1 = latitude + (distance * -1) * m;
        const new_longitude1 = longitude + ((distance * -1) * m) / Math.cos(latitude * (pi / 180));

        const lat1 = incidentsApi.convertToGps(new_latitude0);
        const lat2 = incidentsApi.convertToGps(new_latitude1);
        const lng1 = incidentsApi.convertToGps(new_longitude0);
        const lng2 = incidentsApi.convertToGps(new_longitude1);

        const { token } = JSON.parse(user);

        const params = {
            token,
            lat1,
            lat2,
            lng1,
            lng2
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Incident/getIncidenciasCercanas`, requestOptions)
            .then(response => response.json())
            .then(nearbyIncidents => {
                localStorage.setItem('nearbyIncidents', JSON.stringify(nearbyIncidents));
                return nearbyIncidents
            });
    },
    /**
    * Registers a user and then logs them in
    * @param  {string} byteFoto The ayuntamientoID
    */
    savePhotoBase64(byteFoto, user = localStorage.user) {

        const { token } = JSON.parse(user);

        const params = {
            token,
            byteFoto,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Incident/guardarFotoBase64`, requestOptions)
            .then(response => response.json())
            .then(urlPhoto => {
                // localStorage.setItem('entity', JSON.stringify(entity));
                return urlPhoto
            });
    },
    /**
    * Registers a user and then logs them in
    * @param  {string} byteFoto The ayuntamientoID
    */
    rateIncident(data, user = localStorage.user) {

        const { token } = JSON.parse(user);
        const { AvisoID, valoracion } = data

        const params = {
            token,
            AvisoID,
            valoracion,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Incident/valorarAviso`, requestOptions)
            .then(response => response.json())
            .then(ratedResp => {
                // localStorage.setItem('entity', JSON.stringify(entity));
                return ratedResp
            });
    },
    /**
    * Registers a user and then logs them in
    * @param  {string} byteFoto The ayuntamientoID
    */
    followUnfollowIncident(data, user = localStorage.user) {
        const { token } = JSON.parse(user);
        const { incident, followUnfollow } = data;
        const { AvisoID, AvisoSeguidorID } = incident;

        const params = {
            token,
            followUnfollow,
            avisoID: AvisoID,
            avisoSeguidorID: AvisoSeguidorID,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Incident/followUnfollowAviso`, requestOptions)
            .then(response => response.json())
            .then(followedResp => {
                // localStorage.setItem('entity', JSON.stringify(entity));
                return followedResp
            });
    },
    /**
    * Registers a user and then logs them in
    * @param  {string} byteFoto The ayuntamientoID
    */
    newCommentIncident(avisoID, message, publico = true, user = localStorage.user) {

        const { token } = JSON.parse(user);

        const params = {
            token,
            avisoID,
            message,
            publico,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Incident/newComentarioAviso`, requestOptions)
            .then(response => response.json())
            .then(comment => {
                // localStorage.setItem('entity', JSON.stringify(entity));
                return comment
            });
    },
    /**
    * Registers a user and then logs them in
    * @param  {string} byteFoto The ayuntamientoID
    */
    getCommentsIncident(avisoID, user = localStorage.user) {
        const { token } = JSON.parse(user);

        const params = {
            token,
            avisoID,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Incident/getComentariosAviso`, requestOptions)
            .then(response => response.json())
            .then(comments => {
                // localStorage.setItem('entity', JSON.stringify(entity));
                return comments
            });
    },
     /**
    * Registers a user and then logs them in
    * @param  {string} byteFoto The ayuntamientoID
    */
     getPhotosIncident(avisoID, user = localStorage.user) {
        const { token } = JSON.parse(user);

        const params = {
            token,
            avisoID,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Incident/getPhotosFromIncident`, requestOptions)
            .then(response => response.json())
            .then(photos => {
                 console.log('getPhotosIncident response', photos) ;         
                
                return photos
            });
    }, /**
    * Registers a user and then logs them in
    * @param  {string} byteFoto The ayuntamientoID
    */
    getHistoryIncident(avisoID, user = localStorage.user) {

        const { token } = JSON.parse(user);

        const params = {
            token,
            avisoID,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Incident/getHistoricoAviso`, requestOptions)
            .then(response => response.json())
            .then(histories => {
                // localStorage.setItem('entity', JSON.stringify(entity));
                return histories
            });
    },
    getNearbyStreets(latLng, user = localStorage.user, distance = 50) {

        const earth = 6378.137, //radius of the earth in kilometer
            pi = Math.PI,
            m = 1 / (((2 * pi) / 360) * earth) / 1000,
            latitude = parseFloat(latLng.lat),
            longitude = parseFloat(latLng.lng); //1 meter in degree

        const new_latitude0 = latitude + distance * m;
        const new_longitude0 = longitude + (distance * m) / Math.cos(latitude * (pi / 180));

        const new_latitude1 = latitude + (distance * -1) * m;
        const new_longitude1 = longitude + ((distance * -1) * m) / Math.cos(latitude * (pi / 180));

        const lat1 = incidentsApi.convertToGps(new_latitude0);
        const lat2 = incidentsApi.convertToGps(new_latitude1);
        const lng1 = incidentsApi.convertToGps(new_longitude0);
        const lng2 = incidentsApi.convertToGps(new_longitude1);

        const { token } = JSON.parse(user);

        const params = {
            token,
            lat1,
            lat2,
            lng1,
            lng2,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${apiUrl}/Utils/getCallesPorAyuntamientoGeoreferenciadas`, requestOptions)
            .then(response => response.json())
            .then(streets => {
                // localStorage.setItem('entity', JSON.stringify(entity));
                return streets
            });
    },
    loadMyIncidents() {
        return JSON.parse(localStorage.getItem('myIncidents')) || undefined;
    },
    loadFavIncidents() {
        return JSON.parse(localStorage.getItem('favIncidents')) || undefined;
    },
    loadNearbyIncidents() {
        return JSON.parse(localStorage.getItem('nearbyIncidents')) || undefined;
    },
    loadFamilies() {
        const typology = JSON.parse(localStorage.getItem('typology'));
        return typology ? typology.Familias : [];
    },
    loadElements() {
        const typology = JSON.parse(localStorage.getItem('typology'));
        return typology ? typology.Elementos : [];
    },
    loadIncidentsTypes() {
        const typology = JSON.parse(localStorage.getItem('typology'));
        return typology ? typology.Incidencias : [];
    },

    loadUnitsTypes() {
        const unitsTypes = JSON.parse(localStorage.getItem('unitsTypes'));
        return unitsTypes ? unitsTypes : [];
    },

    loadPromotionsTypes() {
        const promotionsTypes = JSON.parse(localStorage.getItem('promotionsTypes'));
        return promotionsTypes ? promotionsTypes : [];
    },

    loadTypology() {
        return JSON.parse(localStorage.getItem("typology")) || undefined;
      },
      
    convertToGps(data) {
        return Math.trunc(data) * 100 + (data - Math.trunc(data)) * 60.0;
    },
    onChange() { }
}

export default incidentsApi