import { entityConstants } from "../constants";
import { entityApi } from "../helpers";
import { createSelector } from "reselect";

//Filter entities
const entitiesSelector = state => state.entities.entities;
const searchTermSelector = state => state.entities.entityQuery;
export const filteredEntities = createSelector(
  [entitiesSelector, searchTermSelector],
  (entities, entityQuery) => {
    if (!entities) return undefined;
    if (!entityQuery || entityQuery.length < 2) return undefined;
    return entities.filter(
      entity =>
        entity.Nombre.normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .indexOf(entityQuery.toLowerCase().normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")) != -1 ||
        (entity.Tags &&
          entity.Tags.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .indexOf(entityQuery.toLowerCase().normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")) != -1)
      // entity.Nombre.match(new RegExp(entityQuery, "i"))
    );
  }
);

//Filter streets
const streetsSelector = state => state.entities.streets;
const searchStreetSelector = state => state.entities.streetQuery;
export const filteredStreets = createSelector(
  [streetsSelector, searchStreetSelector],
  (streets, streetQuery) => {
    if (!streets) return undefined;
    if (!streetQuery || streetQuery.length < 2) return undefined;
    const filtered = streets.filter(street => {
      if (
        street.TipoVia.toLowerCase().indexOf(streetQuery.toLowerCase()) != -1
      ) {
        return true;
      } else if (
        street.Nombre
          ? street.Nombre.toLowerCase().indexOf(streetQuery.toLowerCase()) != -1
          : street.nomCalle.toLowerCase().indexOf(streetQuery.toLowerCase()) !=
            -1
      ) {
        return true;
      } else if (
        street.Nombre
          ? `${street.TipoVia} ${street.Nombre}`
              .toLowerCase()
              .indexOf(streetQuery.toLowerCase()) != -1
          : `${street.TipoVia} ${street.nomCalle}`
              .toLowerCase()
              .indexOf(streetQuery.toLowerCase()) != -1
      ) {
        return true;
      } else {
        return false;
      }

      // || street.TipoVia.match(new RegExp(streetQuery, 'i'))
      // || street.Numero.match(new RegExp(streetQuery, 'i'))
    });
    return filtered;
  }
);

//Filter buildings
const buildingsSelector = state => state.entities.buildings;
const searchBuildingSelector = state => state.entities.streetQuery;
export const filteredBuildings = createSelector(
  [buildingsSelector, searchBuildingSelector],
  (buildings, streetQuery) => {
    if (!buildings) return undefined;
    if (!streetQuery || streetQuery.length < 2) return undefined;
    const filtered = buildings.filter(
      building =>
        building.Nombre.toLowerCase().indexOf(streetQuery.toLowerCase()) != -1
      // || street.TipoVia.match(new RegExp(streetQuery, 'i'))
      // || street.Numero.match(new RegExp(streetQuery, 'i'))
    );
    return filtered;
  }
);

// The initial application state
let initialState = {
  entities: entityApi.loadEntities(),
  entity: entityApi.loadEntity(),
  entityInfo: entityApi.loadEntityInfo(),
  entityConfig: entityApi.loadEntityConfig(),
  streets: entityApi.loadEntityStreets(),
  externalStreets: [],
  buildings: entityApi.loadEntityBuildings()
};

// Takes care of changing the application state
export const entities = (state = initialState, action) => {
  switch (action.type) {
    case entityConstants.ENTITY_SEARCH:
      return { ...state, entityQuery: action.entityQuery };
    case entityConstants.ENTITY_SEARCH_CLEAR:
      return { ...state, entityQuery: "" };
    case entityConstants.STREET_SEARCH:
      return { ...state, streetQuery: action.streetQuery };
    case entityConstants.STREET_SEARCH_SUCCESS:
      return { ...state, externalStreets: action.externalStreets };
    case entityConstants.STREET_SEARCH_FAILURE:
      return { ...state, externalStreets: [] };
    case entityConstants.STREET_SEARCH_CLEAR:
      return { ...state, streetQuery: "", externalStreets: [] };
    case entityConstants.GETALL_REQUEST:
      return { ...state, loading: action.loading };
    case entityConstants.GETALL_SUCCESS:
      return { ...state, entities: action.entities };
    case entityConstants.ENTITY_FAILURE:
    case entityConstants.GETALL_FAILURE:
      return { ...state, error: action.error };
    case entityConstants.ENTITY_SUCCESS:
    case entityConstants.ENTITY_CHANGED:
      return { ...state, entity: action.entity };
    case entityConstants.ENTITY_INFO_SUCCESS:
      return { ...state, entityInfo: action.entityInfo };
    case entityConstants.ENTITY_STREETS:
      return { ...state, streets: action.streets };
    case entityConstants.ENTITY_BUILDINGS:
      return { ...state, buildings: action.buildings };
    case entityConstants.ENTITY_CONFIG:
      return { ...state, entityConfig: action.entityConfig };
    case entityConstants.CLEAR_ERROR:
      return { ...state, error: "" };
    default:
      return state;
  }
};

export default entities;
