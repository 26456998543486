import React from 'react';
import { connect } from 'react-redux';
import { entityActions } from '../../actions';
import './EntitySearch.css';
import { filteredEntities } from '../../reducers/entities.reducer';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { isWebpSupported } from 'react-image-webp/dist/utils';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSearch } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

import { withTranslation } from 'react-i18next';

class EntitySearch extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(entityActions.getAllEntities());

        const { redirect, ao } = this.props;

        if (redirect) this.props.dispatch(entityActions.changeEntity());
        if (ao && ao.ao) this.fetchEntity({ AyuntamientoID: ao.ao });
    }

    handleSelectEntity(entity) {
        return (e) => {
            this.fetchEntity(entity)
        };
    }

    handleSearchEntity() {        
        return (e) => this.props.dispatch(entityActions.searchEntity(e.target.value))
    }

    fetchEntity(entity) {
        const { redirect, onSelected } = this.props;
        this.props.dispatch(entityActions.getEntityById({ id: entity.AyuntamientoID, redirect }))

        if (!redirect && onSelected) {
            onSelected(entity)
        }
    }

    render() {
        const { entityQuery, entities, ao, t, hide } = this.props;

        return (
            <>
                {((ao && !ao.ao) || !ao) && !hide &&
                    <div className="search-container">
                        <InputGroup size="lg" className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-lg" ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl placeholder={t('entitySearch.placeholder')} aria-label="Large" aria-describedby="inputGroup-sizing-sm" type="text" label={t('entity.p1')} s={12} onChange={this.handleSearchEntity()} value={entityQuery || ''} icon="search" />
                            {entities.loading && <em>{t('entitySearch.loading')}</em>}
                            {entities.error && <span className="text-danger">ERROR: {entities.error}</span>}

                        </InputGroup>
                        <Card style={{ width: '100%', backgroundColor: 'transparent' }}>
                            <ListGroup variant="flush">
                            {/*  Ya no dejamos acceder a la entidad mundial
                                   {entityQuery &&
                                    <ListGroup.Item className="ayto" onClick={this.handleSelectEntity({ AyuntamientoID: 19 })}>
                                        <img src={isWebpSupported() ? "https://gecorcloud.com/images/information.webp" : "https://gecorcloud.com/images/information.png"} alt="logo" />
                                        <span className="title">{t("entitySearch.not-found")}</span>
                                        <Button waves='light' ><FontAwesomeIcon icon={faCheck} /></Button>
                                    </ListGroup.Item>
                                } */}
                                
                                {entities.entities &&
                                    entities.entities.map((entity) =>
                                        <ListGroup.Item className="ayto" key={entity.AyuntamientoID} onClick={this.handleSelectEntity(entity)}>
                                            <img src={entity.Logo} alt="logo" />
                                            <span className="title">{entity.Nombre}</span>
                                            <Button waves='light' ><FontAwesomeIcon icon={faCheck} /></Button>
                                        </ListGroup.Item>
                                    )
                                }
                            </ListGroup>
                        </Card>
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = (state, customProp) => {
    const { entities } = state;
    const { entityQuery } = entities;
    const { redirect, onSelected, ao } = customProp;
    return {
        entities: { entities: filteredEntities(state) },
        entityQuery,
        redirect,
        onSelected,
        ao,
    };
}

const tEntitySearch = withTranslation()(EntitySearch);
const connectedEntitySearch = connect(mapStateToProps)(tEntitySearch);
export { connectedEntitySearch as EntitySearch };