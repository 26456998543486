import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { entityApi } from "./../helpers";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { forwardTo } from "./../helpers/forward";

export const PrivateRouteWithCheckFields = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("user") ? (
        <>
          <Component {...props} />
          <RequiredFields />
        </>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            search: props.location.search,
            state: { from: props.location, loginRequired: true }
          }}
        />
      )
    }
  />
);

const RequiredFields = () => {
  const entityConfig = entityApi.loadEntityConfig();
  const user = JSON.parse(localStorage.getItem("user"));
  const { newIncident } = entityConfig || {};
  const [show, setShow] = useState(
    newIncident &&
    (newIncident.mobile &&
      newIncident.mobile.validate === true ||
      newIncident.nif &&
      newIncident.nif.validate === true) &&
    user &&
    (!user.Movil || !user.NIF)
  );

  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
    forwardTo("/settings");
  };
  //const handleShow = () => setShow(true);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t('newincident.error-user-title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('newincident.error-user-text')}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t('newincident.error-user-button')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
