import { apiUrl } from '../../constants';

export const contestApi = {

    /**
    * Registers a user and then logs them in
    * @param  {string} id The ayuntamientoID
    */
    newConcursoParticipacion(email, telefono, observaciones) {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, telefono, observaciones })
        };

        return fetch(`${apiUrl}/Utils/newConcursoParticipacion`, requestOptions)
            //.then(response => response.json())
            .then(resp => {
                return resp
            }, error => console.error("newConcursoParticipacion", error));
    }
}

export default contestApi