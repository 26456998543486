
import React from 'react';
import { connect } from 'react-redux';
import './PoisPage.css';
import { poisActions } from './../../actions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { PoiCategory } from './../../components/PoiCard/PoiCategory';
import { PoiSearch } from './../../components/PoiCard/PoiSearch';
import { PoisMapPage } from './../PoisMapPage/PoisMapPage';
import { forwardTo } from '../../helpers';
import { filteredPois } from '../../reducers/pois.reducer';
import styled from "styled-components";

const intialState = {
    open: false,
    poiChoosed: undefined,
    width: 0,
    height: 0,
}

const Wrapper = styled.main`
    overflow-y: auto;
    height: ${props => (props.height ? props.height : "350px")};
`;

class PoisPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = intialState;

        this.handleChoosePoi = this.handleChoosePoi.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleHoveredPoi = this.handleHoveredPoi.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(poisActions.getPois());
        this.props.dispatch(poisActions.getCategories());
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.dispatch(poisActions.clearSearchPoi());
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    handleChoosePoi(poi) {
        let id = poi.idItem === 0 || poi.idItem;
        forwardTo(`/interest-points-detail/list/${id}`);
    }


    handleHoveredPoi(poi) {
        // this.setState({ poiHovered: poi });
        this.props.dispatch(poisActions.setHoveredPoi(poi));
    }

    handleChange(e) {
        this.props.dispatch(poisActions.searchPoi(e.target.value));
    }

    render() {
        const { myPois, user, categoriesPoi } = this.props;
        const { width } = this.state;
        let { poiChoosed } = this.state.poiChoosed ? this.state : this.props;
        const h = (window.innerHeight - 86.77) + "px";

        return (
            <div id="poi-page">
                <Row>
                    <Col className="col-left" xl={6}>
                        <PoiSearch handleChange={this.handleChange} list={true} />
                        <Wrapper id="poi-list-container" {...(h ? { height: h } : {})}>
                            {(user && myPois) ? categoriesPoi && categoriesPoi.map(category =>
                                <PoiCategory
                                    key={category.idCategoria}
                                    category={category}
                                    poisCategory={myPois.filter(poi => poi.idCategoria == category.idCategoria)}
                                    user={user}
                                    poiChoosed={poiChoosed}
                                    onCustomClick={this.handleChoosePoi}
                                    onHoveredPoi={this.handleHoveredPoi} />)
                                :
                                <Spinner className="spinner-incs" animation="border" role="status" variant="primary">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>}
                        </Wrapper>
                    </Col>
                    {width > 1200 &&
                        <Col className="col-right" xl={6}>
                            {/*poiChoosed && <PoiDetail poi={poiChoosed} />*/}
                            {<PoisMapPage />}
                        </Col>
                    }
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { entities, pois, users } = state;
    const { myPois, categoriesPoi } = pois;
    let poiChoosed = undefined;
    if (myPois) poiChoosed = filteredPois(state)[0];
    const { user } = users;
    const { entity } = entities;
    const { poiQuery } = pois;

    return {
        myPois: filteredPois(state),
        poiQuery,
        categoriesPoi,
        user,
        poiChoosed,
        entity,
    };
}

const connectedPoiPage = connect(mapStateToProps)(PoisPage);
export { connectedPoiPage as PoisPage };