import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import './NoticeExtendPage.css';
import { noticesActions } from '../../actions';
import { DynamicBackground } from './../../components'
import { forwardTo } from '../../helpers'
import { isWebpSupported } from 'react-image-webp/dist/utils';

import { blobGecorPath } from "./../../constants/config.constants";
/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
/* ICONS */



class NoticeExtendPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      noticeId: this.props.match.params.id,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.dispatch(noticesActions.getNoticeById(this.state.noticeId));
  }

  render() {

    // const { item } = this.props.location.state;
    const { notice, t } = this.props;


    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.1)" background={isWebpSupported() ? "https://gecorcloud.com/images/notice-bg.webp" : "https://gecorcloud.com/images/notice-bg.jpg"} >
        <Container>
          <div className="boxContact"></div>
          <div className="container">
            {notice &&
              <div className="extendsCard" >
                <Row>
                  <Card>
                    <Card.Header as="h5">{notice.titulo}</Card.Header>
                    <Card.Body>
                      <Card.Text as="div">
                        <img src={(notice.urlImage).indexOf("https") > -1 ? notice.urlImage : blobGecorPath + notice.urlImage} alt="foto detalle" />
                        <br></br>
                        <p className="text" >
                          <div dangerouslySetInnerHTML={{ __html: notice.descripcion }} ></div>
                          <br /><br />
                          {notice && notice.url && <a href={notice.url} target="_blank" rel="noopener noreferrer" rel="noreferrer noopener"> <FontAwesomeIcon icon={faGlobe} /> Fuente</a>}
                        </p>
                        <br />
                        <Button variant="primary" onClick={() => forwardTo('/notice')}>{t('news.p1')}</Button>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Row>
              </div>
            }

          </div>
        </Container>
      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { news } = state;
  const { notice } = news;
  return {
    notice
  };
}

const tNoticeExtendPage = withTranslation()(NoticeExtendPage);
const connectedNoticeExtendPage = connect(mapStateToProps)(tNoticeExtendPage);
export { connectedNoticeExtendPage as NoticeExtendPage };
