export const incidentsConstants = {
    GET_MY_REQUEST: 'INCIDENTS_GET_MY_REQUEST',
    GET_MY_SUCCESS: 'INCIDENTS_GET_MY_SUCCESS',
    GET_MY_FAILURE: 'INCIDENTS_GET_MY_FAILURE',

    GET_FAV_REQUEST: 'INCIDENTS_GET_FAV_REQUEST',
    GET_FAV_SUCCESS: 'INCIDENTS_GET_FAV_SUCCESS',
    GET_FAV_FAILURE: 'INCIDENTS_GET_FAV_FAILURE',

    GET_NEARBY_REQUEST: 'INCIDENTS_GET_NEARBY_REQUEST',
    GET_NEARBY_SUCCESS: 'INCIDENTS_GET_NEARBY_SUCCESS',
    GET_NEARBY_FAILURE: 'INCIDENTS_GET_NEARBY_FAILURE',

    GET_INCIDENT_DETAIL_REQUEST: 'INCIDENTS_GET_INCIDENT_DETAIL_REQUEST',
    GET_INCIDENT_DETAIL_SUCCESS: 'INCIDENTS_GET_INCIDENT_DETAIL_SUCCESS',
    GET_INCIDENT_DETAIL_FAILURE: 'INCIDENTS_GET_INCIDENT_DETAIL_FAILURE',
    
    GET_INCIDENT_PHOTO_DETAIL_REQUEST: 'INCIDENTS_GET_INCIDENT_PHOTO_DETAIL_REQUEST',
    GET_INCIDENT_PHOTO_DETAIL_SUCCESS: 'INCIDENTS_GET_INCIDENT_PHOTO_DETAIL_SUCCESS',
    GET_INCIDENT_PHOTO_DETAIL_FAILURE: 'INCIDENTS_GET_INCIDENT_PHOTO_DETAIL_FAILURE',

    NEW_INCIDENT_REQUEST: 'INCIDENTS_NEW_INCIDENT_REQUEST',
    NEW_INCIDENT_SUCCESS: 'INCIDENTS_NEW_INCIDENT_SUCCESS',
    NEW_INCIDENT_FAILURE: 'INCIDENTS_NEW_INCIDENT_FAILURE',
    NEW_INCIDENT_RESET: 'NEW_INCIDENT_RESET',

    RATE_REQUEST: 'INCIDENTS_RATE_REQUEST',
    RATE_SUCCESS: 'INCIDENTS_RATE_SUCCESS',
    RATE_FAILURE: 'INCIDENTS_RATE_FAILURE',

    FOLLOW_REQUEST: 'INCIDENTS_FOLLOW_REQUEST',
    FOLLOW_SUCCESS: 'INCIDENTS_FOLLOW_SUCCESS',
    FOLLOW_FAILURE: 'INCIDENTS_FOLLOW_FAILURE',

    GET_COMMENTS_REQUEST: 'INCIDENTS_GET_COMMENTS_REQUEST',
    GET_COMMENTS_SUCCESS: 'INCIDENTS_GET_COMMENTS_SUCCESS',
    GET_COMMENTS_FAILURE: 'INCIDENTS_GET_COMMENTS_FAILURE',
    COMMENTS_CLEAR: 'INCIDENTS_GET_COMMENTS_CLEAR',

    GET_HISTORY_REQUEST: 'INCIDENTS_GET_HISTORY_REQUEST',
    GET_HISTORY_SUCCESS: 'INCIDENTS_GET_HISTORY_SUCCESS',
    GET_HISTORY_FAILURE: 'INCIDENTS_GET_HISTORY_FAILURE',

    NEW_COMMENTS_REQUEST: 'INCIDENTS_NEW_COMMENTS_REQUEST',
    NEW_COMMENTS_SUCCESS: 'INCIDENTS_NEW_COMMENTS_SUCCESS',
    NEW_COMMENTS_FAILURE: 'INCIDENTS_NEW_COMMENTS_FAILURE',

    CHANGE_ALL_TYPE: 'CHANGE_ALL_TYPE',
    CHANGE_FAMILY_TYPE: 'INCIDENTS_CHANGE_FAMILY_TYPE',
    CHANGE_ELEMENT_TYPE: 'INCIDENTS_CHANGE_ELEMENT_TYPE',
    CHANGE_INCIDENT_TYPE: 'INCIDENTS_CHANGE_INCIDENT_TYPE',
    CHANGE_UNIT_TYPE: 'INCIDENTS_CHANGE_UNIT_TYPE',
    CHANGE_PROMOTION_TYPE: 'INCIDENTS_CHANGE_PROMOTION_TYPE',

    INCIDENTS_TIPOGRAFY: 'INCIDENTS_TIPOGRAFY',
    INCIDENTS_CLEAR: 'INCIDENTS_CLEAR',

    GET_NEARBYSTREETS_REQUEST: 'INCIDENTS_GET_NEARBYSTREETS_REQUEST',
    GET_NEARBYSTREETS_SUCCESS: 'INCIDENTS_GET_NEARBYSTREETS_SUCCESS',
    GET_NEARBYSTREETS_FAILURE: 'INCIDENTS_GET_NEARBYSTREETS_FAILURE',
    INCIDENTS_NEARBYSTREETS_CLEAR: 'INCIDENTS_NEARBYSTREETS_CLEAR',

    INCIDENTS_UNITS: 'INCIDENTS_UNITS',
    INCIDENTS_PROMOTIONS: 'INCIDENTS_PROMOTIONS',
    
    TIPOLOGY_SEARCH: 'TIPOLOGY_SEARCH',
    TIPOLOGY_SEARCH_CLEAR: 'TIPOLOGY_SEARCH_CLEAR',

};