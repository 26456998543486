import React from 'react';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { DynamicBackground } from './../../components'
import './FaqGecorPage.css';
import { forwardTo } from "../../helpers";
import { withTranslation } from 'react-i18next';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import BannerContact from '../../components/BannerContact/BannerContact';

class FaqGecorPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            open: true,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleDeleteUser(id) {
        //return (e) => this.props.dispatch(userActions.delete(id));
    }



    render() {
        const { t } = this.props;
        return (
            <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/bgFaq.webp" : "https://gecorcloud.com/images/bgFaq.jpg"}>
                <div className="container">
                    <div className="TituloFAQ">
                        <p>
                            <h1>{t('faq.title')}</h1>
                            <hr className="line" ></hr>
                        </p>
                    </div>
                    <div className="containerFAQ">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col className="padding-box-info" sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item tabIndex="1">
                                            <Nav.Link eventKey="first">{t('faq.o1')}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item tabIndex="13">
                                            <Nav.Link eventKey="second">{t('faq.o2')}</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col className="padding-box-info" sm={9}>
                                    <Tab.Content>

                                        <Tab.Pane eventKey="first">
                                            <Accordion defaultActiveKey="0">
                                                <Card tabIndex="3">
                                                    <Accordion.Toggle as={Card.Header} eventKey="1">
                                                        {t('faq.a1')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body>
                                                            <p>{t('faq.p1-1')}</p>
                                                            <p>{t('faq.p1-2')}</p>
                                                            <p>{t('faq.p1-3')}<b className="link-faq"><a onClick={() => forwardTo('/new-incident')}>{t('faq.link')}.</a></b></p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="4">
                                                    <Accordion.Toggle as={Card.Header} eventKey="2">
                                                        {t('faq.a2')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="2">
                                                        <Card.Body>
                                                            <p>{t('faq.p2-1')} <b ><a className="link-faq" href="https://apps.apple.com/es/app/gecor/id1127259219" >App Store.</a></b></p>
                                                            <p>{t('faq.p2-2')} <b ><a className="link-faq" href='https://play.google.com/store/apps/details?id=com.gecorsystem.gecorn&hl=es' >Google Play Store.</a></b></p>
                                                            <p>{t('faq.p2-3')}</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="5">
                                                    <Accordion.Toggle as={Card.Header} eventKey="5">
                                                        {t('faq.a3')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="5">
                                                        <Card.Body>
                                                            <p>{t('faq.p3-1')}</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="6">
                                                    <Accordion.Toggle as={Card.Header} eventKey="3">
                                                        {t('faq.a4')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="3">
                                                        <Card.Body>
                                                            <p>{t('faq.p4-1')}</p>
                                                            <p>{t('faq.p4-2')}</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="7">
                                                    <Accordion.Toggle as={Card.Header} eventKey="6">
                                                        {t('faq.a5')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="6">
                                                        <Card.Body>
                                                            <p>{t('faq.p5-1')}</p>
                                                            <p>{t('faq.p5-2')}</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="8">
                                                    <Accordion.Toggle as={Card.Header} eventKey="7">
                                                        {t('faq.a6')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="7">
                                                        <Card.Body>
                                                            <p>{t('faq.p6-1')}</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="9">
                                                    <Accordion.Toggle as={Card.Header} eventKey="8">
                                                        {t('faq.a7')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="8">
                                                        <Card.Body>
                                                            <p>{t('faq.p7-1')}</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="10">
                                                    <Accordion.Toggle as={Card.Header} eventKey="15">
                                                        {t('faq.a8')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="15">
                                                        <Card.Body>
                                                            <p>{t('faq.p8-1')}</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="11">
                                                    <Accordion.Toggle as={Card.Header} eventKey="16">
                                                        {t('faq.a9')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="16">
                                                        <Card.Body>
                                                            <p>{t('faq.p9-1')}</p>
                                                            <p>{t('faq.p9-2')}</p>
                                                            <p><b className="link-faq"><a onClick={() => forwardTo('/contact')}>{t('faq.contact')}</a></b></p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="12">
                                                    <Accordion.Toggle as={Card.Header} eventKey="17">
                                                        {t('faq.a10')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="17">
                                                        <Card.Body>
                                                            <p>{t('faq.p10-1')}</p>
                                                            <p><b className="link-faq"><a onClick={() => forwardTo('/contact')}>{t('faq.contact')}</a></b></p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Tab.Pane>

                                        {/* SECOND TAB */}

                                        <Tab.Pane eventKey="second">
                                            <Accordion defaultActiveKey="0">
                                                <Card tabIndex="14">
                                                    <Accordion.Toggle as={Card.Header} eventKey="18">
                                                        {t('faq.a11')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="18">
                                                        <Card.Body>
                                                            <p>{t('faq.p11-1')}</p>
                                                            <p>{t('faq.p11-2')}<b className="link-faq"><a onClick={() => forwardTo('/login')}>{t('faq.log')}</a></b></p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="15">
                                                    <Accordion.Toggle as={Card.Header} eventKey="19">
                                                        {t('faq.a12')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="19">
                                                        <Card.Body>
                                                            <p>{t('faq.p12-1')}</p>
                                                            <p>{t('faq.p12-2')}</p>
                                                            <p>{t('faq.p12-3')}</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="16">
                                                    <Accordion.Toggle as={Card.Header} eventKey="9">
                                                        {t('faq.a14')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="9">
                                                        <Card.Body>
                                                            <p>{t('faq.p14-1')}</p>
                                                            <p>{t('faq.p14-2')}</p>
                                                            <p>{t('faq.p14-3')}</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="17">
                                                    <Accordion.Toggle as={Card.Header} eventKey="21">
                                                        {t('faq.a15')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="21">
                                                        <Card.Body>
                                                            <p>{t('faq.p15-1')}</p>
                                                            <p>{t('faq.p15-2')}</p>
                                                            <p>{t('faq.p15-3')}</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="18">
                                                    <Accordion.Toggle as={Card.Header} eventKey="22">
                                                        {t('faq.a16')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="22">
                                                        <Card.Body>
                                                            <p>{t('faq.p16-1')}</p>
                                                            <p>{t('faq.p16-2')}</p>
                                                            <p><b className="link-faq"><a onClick={() => forwardTo('/contact')}>{t('faq.contact')}</a></b></p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="19">
                                                    <Accordion.Toggle as={Card.Header} eventKey="23">
                                                        {t('faq.a17')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="23">
                                                        <Card.Body>
                                                            <p>{t('faq.p17-1')}</p>
                                                            <p>{t('faq.p17-2')} </p>
                                                            <p>{t('faq.p17-3')} </p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="20">
                                                    <Accordion.Toggle as={Card.Header} eventKey="24">
                                                        {t('faq.a18')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="24">
                                                        <Card.Body>
                                                            <p>{t('faq.p18-1')}</p>
                                                            <p>{t('faq.p18-2')}</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card tabIndex="21">
                                                    <Accordion.Toggle as={Card.Header} eventKey="25">
                                                        {t('faq.a19')}
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="25">
                                                        <Card.Body>
                                                            <p>{t('faq.p19-1')}</p>
                                                            <p><b className="link-faq"><a onClick={() => forwardTo('/contact')}>{t('faq.contact')}</a></b></p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
                <BannerContact />
            </DynamicBackground>
        );
    }
}

const mapStateToProps = (state) => {
    const { users } = state;
    const { user } = users;
    return {
        user
    };
}
const tFaqGecorPage = withTranslation()(FaqGecorPage);
const connectedFaqGecorPage = connect(mapStateToProps)(tFaqGecorPage);
export { connectedFaqGecorPage as FaqGecorPage };
