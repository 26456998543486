
import React from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useTranslation } from 'react-i18next';
import './PoiSearch.css';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

export const PoiSearch = props => {
    const { poiQuery, handleChange } = props;
    const { t } = useTranslation();
    return (
        <div id="poi-search">
            <InputGroup size="lg" className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text id="inputGroup-sizing-lg" ><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl placeholder={t('poicard.placeholder')} aria-label="Large" aria-describedby="inputGroup-sizing-sm"
                    type="text" s={12} onChange={handleChange} value={poiQuery} icon="search" />
            </InputGroup>
        </div>
    );
}
export default PoiSearch;
