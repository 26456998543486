import { entityConstants } from '../constants';

export const entityActions = {
    getAllEntities: () => ({ type: entityConstants.GETALL_REQUEST }),
    getEntityById: (data) => ({ type: entityConstants.ENTITY_REQUEST, data }),
    getLocalEntity: () => ({ type: entityConstants.ENTITY_LOAD }),
    changeEntity: () => ({ type: entityConstants.ENTITY_CHANGE }),
    searchEntity: (entityQuery) => ({ type: entityConstants.ENTITY_SEARCH, entityQuery }),
    searchStreet: (streetQuery) => ({ type: entityConstants.STREET_SEARCH, streetQuery }),
    searchExternalStreet: (streetQuery) => ({ type: entityConstants.STREET_SEARCH_REQUEST, streetQuery }),
    clearSearchStreet: () => ({ type: entityConstants.STREET_SEARCH_CLEAR }),
};