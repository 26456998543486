import React from "react";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { entityActions } from "../../actions";
import { LocationNumberConfirm } from "./LocationNumberConfirm";

export class LocationStreetEngine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      value: props.value,
      open: false,
      filter: {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_2: "short_name",
        country: "long_name",
        postal_code: "short_name"
      },
      hasStreet: false,
      typingTimeout: 0
    };

    this.handleSelectStreet = this.handleSelectStreet.bind(this);
  }

  /*
  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.value !== this.state.value)
      this.setState({ value: nextProps.value });
  }
  */

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return { value: prevState.value };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      //Perform some operation here
      this.setState({ value: this.props.value });
      //this.classMethod();
    }
  }

  handleTextInputChange = e => {
    e.preventDefault();
    this.setState({
      search: e.target.value,
      value: e.target.value,
      hasStreet: false,
      street_number: ""
    });
  };

  handleChange = name => event => {
    event.preventDefault();
    if (name === "street_number") {
      if (event.target.value == 0)
        this.setState({ hasStreet: true, googleCantFindStreetNumber: false });
      else this.setState({ googleCantFindStreetNumber: false });
    }
    this.setState({
      [name]: event.target.value
    });
  };

  handleOpen = (open = true) => {
    this.setState({ open });
  };

  handleClose = () => event => {
    event.preventDefault();

    let { street, street_number } = this.state;
    street.Numero = street_number;

    this.handleSelectStreet(street);
  };

  handleSearchStreet = e => {
    e.preventDefault();

    const self = this;
    if (self.state.beforeTypingTimeout) {
      clearTimeout(self.state.beforeTypingTimeout);
    }
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      search: e.target.value,
      value: e.target.value,
      hasStreet: false,
      street_number: "",
      beforeTypingTimeout: setTimeout(() => {
        self.props.onSearchGecorStreet(undefined, self.state.value);
      }, 100),
      typingTimeout: setTimeout(() => {
        self.props.onSearchGecorStreet(self.state.value);
      }, 500)
    });
  };

  handleSelectStreet = street => {
    const self = this;

    const { open } = this.state;

    if (!street.Numero) {
      if (open) this.setState({ googleCantFindStreetNumber: true });
      this.setState(
        {
          googleCantFindStreetNumber: true,
          street
        },
        () => {
          self.handleOpen(true);
        }
      );
    } else {
      this.handleOpen(false);
      this.setState(
        {
          googleCantFindStreetNumber: false,
          search: "",
          route: street.Nombre,
          numCalle: street.Numero || 0,
          street_number: street.Numero || 0,
          value: `${street.TipoVia} ${street.Nombre} ${street.Numero || 0} ${
            street.Bis
            }`,
          hasStreet: true,
          x: 0,
          y: 0,
          streetId: street.CalleID || -1,
          buildingId: street.EdificioID || -1
        },
        () => {
          self.props.onSuccessLocation(self.state);
          self.props.dispatch(entityActions.clearSearchStreet());
        }
      );
    }
  };

  handleSelectBuilding = building => e => {
    const self = this;

    this.setState(
      {
        search: "",
        route: building.Nombre,
        numCalle: building.NumCalle || 0,
        street_number: building.NumCalle || 0,
        value: `${building.Nombre} - ${building.NomCalle} ${building.NumCalle ||
          0}`,
        hasStreet: true,
        x: 0,
        y: 0,
        streetId: building.CalleID || -1,
        buildingId: building.EdificioID || -1
      },
      () => {
        self.props.onSuccessLocation(self.state);
        self.props.dispatch(entityActions.clearSearchStreet());
      }
    );
  };

  render() {
    const { value, search, googleCantFindStreetNumber } = this.state;
    const {
      errors,
      touched,
      isSubmitting,
      t,
      // entityConfig,
      streets,
      buildings
    } = this.props;
    // const { reqMapNewInc } = entityConfig || {};

    return (
      <div>
        <Form.Control
          s={12}
          icon="filter_3"
          placeholder={t("location.street-placeholder")}
          value={value}
          onChange={this.handleSearchStreet}
          disabled={isSubmitting}
          isInvalid={
            !!errors.streetName ||
            (errors.streetOptions &&
              !!errors.streetOptions?.x &&
              !!errors.streetOptions?.y)
          }
          isValid={
            touched.streetName &&
            !errors.streetName &&
            (errors.streetOptions &&
              !errors.streetOptions?.x &&
              !errors.streetOptions?.y)
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors.streetName}
          {!errors.streetName && errors.streetOptions
            ? errors.streetOptions?.x
            : ""}
        </Form.Control.Feedback>
        <div className="custom-prediction-wrapper">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              {search && (
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">{t("location.streets")}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">{t("location.buildings")}</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              )}
              <Col sm={9}>
                <Tab.Content className="geo-streets">
                  <Tab.Pane eventKey="first">
                    <ListGroup variant="flush">
                      {streets &&
                        streets.map((street, id) => (
                          <ListGroup.Item
                            className="ayto"
                            key={id}
                            onClick={() => this.handleSelectStreet(street)}
                          >
                            <span className="title">
                              {street.TipoVia} {street.Nombre} {street.Numero}{" "}
                              {street.Bis}
                            </span>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <ListGroup variant="flush">
                      {buildings &&
                        buildings.map((building, id) => (
                          <ListGroup.Item
                            className="ayto"
                            key={id}
                            onClick={this.handleSelectBuilding(building)}
                          >
                            <span className="title">
                              {building.Nombre} - {building.NomCalle}{" "}
                              {building.NumCalle}
                            </span>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>

        <LocationNumberConfirm
          {...this.props}
          googleCantFindStreetNumber={googleCantFindStreetNumber}
          street_number={this.state.street_number}
          open={this.state.open}
          handleChange={this.handleChange("street_number")}
          handleClose={this.handleClose()}
        />
      </div>
    );
  }
}

export default LocationStreetEngine;
