import React from 'react';
import './GweetPage.css';
import Container from 'react-bootstrap/Container';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';
import { forwardTo } from '../../helpers'
import { isWebpSupported } from 'react-image-webp/dist/utils';

export const SectionUseIt = props => {
    const { t } = useTranslation();

    return (
        <div id="section-use-it">
            <Container>
                <div className="titleb5">
                    <h2>{t('gweet.box5-title')}</h2>
                </div>
                <div className="Company-BoxG4">
                    <CardDeck>
                        <Card className="gweet-card-form" onClick={() => forwardTo('/gecor-form')}>
                            <Card.Body>
                                <div className="title-use-card"><h3>{t('gweet.box5-c1-tit')}</h3></div>
                                <Card.Text as="div">
                                    <Row>
                                        <Col sm={6}>
                                            <div className="img-gweet-use">
                                                <img src={isWebpSupported() ? "https://gecorcloud.com/images/FooterLogo.webp" : "https://gecorcloud.com/images/FooterLogo.png"} alt="grafic 1" />
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <p>{t('gweet.box5-c1-p')}</p>
                                        </Col>
                                    </Row>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card className="gweet-card-form" onClick={() => forwardTo('/gweet-form')}>
                            <Card.Body>
                                <div className="title-use-card"><h3>{t('gweet.box5-c2-tit')}</h3></div>
                                <Card.Text as="div">
                                    <Row>
                                        <Col sm={6}>
                                            <div className="img-gweet-use">
                                                <img src={isWebpSupported() ? "https://gecorcloud.com/images/gweet-logo-2.webp" : "https://gecorcloud.com/images/gweet-logo-2.png"} alt="grafic 1" />
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <p>{t('gweet.box5-c2-p')}</p>
                                        </Col>
                                    </Row>

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </div>
            </Container>
        </div>
    );
}

export default SectionUseIt;