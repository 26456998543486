import React from "react";
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import { entityActions } from "../../actions";

export class LocationStreetEngineGeoreferenced extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      value: props.value,
      filter: {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_2: "short_name",
        country: "long_name",
        postal_code: "short_name"
      },
      hasStreet: false,
      typingTimeout: 0
    };
  }

  /*
  componentWillReceiveProps(nextProps) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    if (nextProps.value !== this.state.value)
      this.setState({ value: nextProps.value });
  }
  */

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.value) {
      return { value: prevState.value };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      //Perform some operation here
      this.setState({ value: this.props.value });
      //this.classMethod();
    }
  }

  handleTextInputChange = e => {
    e.preventDefault();
    this.setState({
      search: e.target.value,
      value: e.target.value,
      hasStreet: false,
      street_number: ""
    });
  };

  handleChange = name => event => {
    event.preventDefault();
    if (name === "street_number") {
      if (event.target.value == 0)
        this.setState({ hasStreet: true, googleCantFindStreetNumber: false });
      else this.setState({ googleCantFindStreetNumber: false });
    }
    this.setState({
      [name]: event.target.value
    });
  };

  handleSearchStreet = e => {
    e.preventDefault();

    const self = this;
    if (self.state.beforeTypingTimeout) {
      clearTimeout(self.state.beforeTypingTimeout);
    }
    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }
    self.setState({
      search: e.target.value,
      value: e.target.value,
      hasStreet: false,
      street_number: "",
      beforeTypingTimeout: setTimeout(() => {
        self.props.onSearchGecorStreet(undefined, self.state.value);
      }, 100),
      typingTimeout: setTimeout(() => {
        self.props.onSearchGecorStreet(self.state.value);
      }, 500)
    });
  };

  handleSelectStreet = street => e => {
    const self = this;

    this.setState(
      {
        search: "",
        route: street.Nombre,
        numCalle: street.Numero || 0,
        street_number: street.Numero || 0,
        value: `${street.TipoVia} ${street.Nombre} ${street.Numero || 0} ${
          street.Bis
          }`,
        hasStreet: true,
        x: 0,
        y: 0,
        streetId: street.CalleID || -1,
        buildingId: street.EdificioID || -1
      },
      () => {
        self.props.onSuccessLocation(self.state);
        self.props.dispatch(entityActions.clearSearchStreet());
      }
    );
  };

  render() {
    const { search, value } = this.state;
    const {
      errors,
      touched,
      isSubmitting,
      t,
      // entityConfig,
      streets,
      handleStreetNotFound
    } = this.props;
    // const { reqMapNewInc } = entityConfig || {};

    return (
      <div>
        <Form.Control
          s={12}
          icon="filter_3"
          placeholder={t("location.street-placeholder")}
          value={value}
          onChange={this.handleSearchStreet}
          disabled={isSubmitting}
          isInvalid={
            !!errors.streetName ||
            (errors.streetOptions &&
              !!errors.streetOptions?.x &&
              !!errors.streetOptions?.y)
          }
          isValid={
            touched.streetName &&
            !errors.streetName &&
            (errors.streetOptions &&
              !errors.streetOptions?.x &&
              !errors.streetOptions?.y)
          }
        />
        <Form.Control.Feedback type="invalid">
          {errors.streetName}
          {!errors.streetName && errors.streetOptions
            ? errors.streetOptions?.x
            : ""}
        </Form.Control.Feedback>
        <div className="geo-streets custom-prediction-wrapper">
          <ListGroup variant="flush">
            {search && (
              <ListGroup.Item className="ayto" onClick={handleStreetNotFound}>
                <span className="title"> {t("location.not-find")}</span>
              </ListGroup.Item>
            )}
            {streets &&
              streets.map((street, id) => (
                <ListGroup.Item
                  className="ayto"
                  key={id}
                  onClick={this.handleSelectStreet(street)}
                >
                  <span className="title  search-street">
                    {street.TipoVia} {street.Nombre} {street.Numero}{" "}
                    {street.Bis}
                  </span>
                </ListGroup.Item>
              ))}
          </ListGroup>
        </div>
      </div>
    );
  }
}

export default LocationStreetEngineGeoreferenced;
