import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { incidentsActions } from '../../actions';
import './IncidentComments.css';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';
import { isWebpSupported } from "react-image-webp/dist/utils";

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

class IncidentComments extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.sendNewComment = this.sendNewComment.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            show: false,
            message: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        let { incidentComments, newComment } = this.props
        if (nextProps.newComment !== newComment) {
            const { newComment } = nextProps;
            incidentComments.unshift(newComment);
        }
    }

    sendNewComment() {
        const { incident } = this.props;
        const { AvisoID } = incident;
        const { message } = this.state;
        this.props.dispatch(incidentsActions.newComment({ avisoID: AvisoID, message }));
        this.setState({ message: '' });
    }

    handleClose() {
        // this.sendNewComment();
        this.props.dispatch(incidentsActions.commentsClear());
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
        const { incident } = this.props;
        const { AvisoID } = incident;
        this.props.dispatch(incidentsActions.getComments(AvisoID));
    }

    handleChange(e) {
        const { error } = this.props;
        if (error) this.props.clearError();

        const { name, value } = e.target;
        this.setState({ [name]: value });
    }


    render() {

        const { incidentComments, t } = this.props
        const { message } = this.state;

        return (
            <>
                <div title={t('incicard.floatCommet')} onClick={this.handleShow}>
                    <FontAwesomeIcon icon={faComments} />
                </div>

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton className="title-header-incident-commet" >
                        <h4> {t('incicard.p3')}</h4>
                    </Modal.Header>
                    <Modal.Body className='coment-incident-body'>
                        {(!incidentComments || incidentComments.length  == 0 || !incidentComments[0]) &&
                            <div>{t('incmodal.nocommet')}</div>
                        }
                        {incidentComments && incidentComments.map(coment =>
                            <Card key={coment.AvisoComentarioID} className="card-incident-commet">
                                <Card.Header>
                                    <Row>
                                        <Col xs={1} className="d-none d-sm-block">
                                            <img
                                                className="profile-image-comment"
                                                alt="Profile pic"
                                                src={coment?.UrlImage ? coment.UrlImage :
                                                    isWebpSupported()
                                                        ? "https://gecorcloud.com/images/user-profile.webp"
                                                        : "https://gecorcloud.com/images/user-profile.png"
                                                }
                                            />
                                        </Col>
                                        <Col xs={11} className="custom-user-comment">
                                            {coment.NombreCiudadano}
                                            <Moment className="date-incident-commet" format="DD/MM/YYYY">{coment.FechaComentario}</Moment>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        {coment.Comentario}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        )}
                    </Modal.Body>
                    <Modal.Footer className="coment-incident-footer">
                        <Form.Control className="coment-incident-commet" size="lg" type="text" placeholder={t('incmodal.phcommet')} value={message} name="message" onChange={this.handleChange} />
                        <Button className="boton-incident-commet" waves='light' onClick={this.sendNewComment} >{t('incicard.p4')}</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { incidents, users } = state;
    const { incidentComments, newComment } = incidents;
    const { user } = users;
    return {
        incidentComments,
        newComment,
        user,
    };
}
const tIncidentComments = withTranslation()(IncidentComments);
const connectedIncidentComments = connect(mapStateToProps)(tIncidentComments);
export { connectedIncidentComments as IncidentComments };
