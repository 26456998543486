import React from 'react';
import './HomePage.css';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { NewsCard } from './../../components/NewsCard/NewsCard';


export const SectionNews = props => {
    const { t, notices } = props;

    return (
        <div id="section-news">
            {notices && <Container>
                <h3 tabIndex="26" className="card-titulo">{t('home.box4-title')}</h3>
                {notices &&
                    <Row>
                        <NewsCard notices={notices.slice(0, 3)} />
                    </Row>
                }
            </Container>}
        </div>
    );
}

export default SectionNews;