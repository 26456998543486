import React from 'react';
import { connect } from 'react-redux';
import './IncidentsPageLn.css';
import { DynamicBackground } from './../../components'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { TimelineLite } from "gsap/all";
import { isWebpSupported } from 'react-image-webp/dist/utils';

class IncidentsPageLn extends React.Component {

  constructor(props) {
    super(props);
    this.logoTl = new TimelineLite({ paused: true });
    this.subhead = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logoTl
      .set(this.content, { autoAlpha: 1 })// show content div
      .from(this.head, 0.5, { left: 100, autoAlpha: 0 })
      .from(this.subhead, 0.5, { left: -100, autoAlpha: 0 }, "-=0.25") // added -0.25 seconds prior to end this.of timeline
    this.logoTl.play()
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {

    return (

      <DynamicBackground customOverlay="rgba(255, 255, 255, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/iglesia-nuestra-senora-del-rosario.webp" : "https://gecorcloud.com/images/iglesia-nuestra-senora-del-rosario.jpg"}>

        <div className="container">
          <div className="infoBoxLnx " ref={div => this.subhead = div}>
            <div className="infoTit">
              <h1><b></b></h1>
            </div>
            {/*   Box 1   */}


            <Card ref={card => this.content = card}>
              <Card.Header tabIndex="5" as="h5" ref={h5 => this.head = h5}>Notifica tu incidencia</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={6} >
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="6">Para realizar cualquier tipo de incidencia en la vía urbana en el Ayuntamiento de la Línea de la Concepción, basta con hacerla a través de la App de GECOR.</p>
                          <p tabIndex="7">Descarga la App, selecciona tu ayuntamiento, y comienza a hacer incidencias. Es muy sencillo, sólo necesitas seleccionar el tipo de incidencia, aportar una imagen y la ubicación.</p>
                          <p tabIndex="8">También tenemos disponible un gestor de incidencias web, para los que no puedan descargar la APP:</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <a tabIndex="9" href="https://lalineaentumano.es/login?ao=294" alt="gestor de incidencias web">
                            <Button className="btn-linea" variant="outline-dark">Gestor de incidencias WEB</Button>
                          </a>
                        </Col>
                      </Row>
                      <br></br>
                    </Col>
                    <Col md={{ span: 6, offset: 0 }}>
                      <Row div="download-app-pr">
                        <Col  sm={12}>
                          <h3 tabIndex="10">Descarga la APP de GECOR:</h3>
                        </Col>
                      </Row>
                      <br></br>
                      <Row className="row-inci-pr">
                      <Col sm={6}>
                      <img tabIndex="11" src="https://gecorcloud.com/images/QRApple.jpg" className="qr-app-store" alt="qr-app-store" />
                      <br></br>
                        <a tabIndex="12" target="_blank" rel="noopener noreferrer" href= "https://itunes.apple.com/es/app/gecor/id1127259219?mt=8">
                          <img src="https://gecorcloud.com/images/app-store-ico.png" alt="app-store" />
                        </a>
                        </Col>
                      <Col sm={6}>
                      <img tabIndex="13" src="https://gecorcloud.com/images/QRAndroid.jpg" className="qr-google-play" alt="qr-google-play"/>
                      <br></br>
                        <a tabIndex="14" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.gecorsystem.gecorn&hl=es">
                          <img src="https://gecorcloud.com/images/google-play-ico.png" alt="google-play"/>
                        </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>




          </div>
        </div>

      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedIncidentsPageLn = connect(mapStateToProps)(IncidentsPageLn);
export { connectedIncidentsPageLn as IncidentsPageLn }; 