import './NewIncidentCard.css';
import { useTranslation } from 'react-i18next';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { forwardTo } from '../../helpers'

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
/* ICONS */


export const NewIncidentCard = props => {
    const { t } = useTranslation();
    return (
        <div id="new-incident-card" onClick={() => forwardTo('/new-incident')}>
            <Row>
                <Col md={12}>
                    <FontAwesomeIcon icon={faPlusCircle} /> {t('incicard.p6')}
                </Col>
            </Row>
        </div>
    );
}

export default NewIncidentCard;