import React from 'react';
import { connect } from 'react-redux';
import './LocalInformationPageCh.css';
import { DynamicBackground } from './../../../../components'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { TimelineLite } from "gsap/all";
import { isWebpSupported } from 'react-image-webp/dist/utils';

class LocalInformationPageCh extends React.Component {

  constructor(props) {
    super(props);
    this.logoTl = new TimelineLite({ paused: true });
    this.subhead = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logoTl
      .set(this.content, { autoAlpha: 1 })// show content div
      .from(this.head, 0.5, { left: 100, autoAlpha: 0 })
      .from(this.subhead, 0.5, { left: -100, autoAlpha: 0 }, "-=0.25") // added -0.25 seconds prior to end this.of timeline
    this.logoTl.play()
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));

  }

  render() {

    return (

      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/bgLocal.webp" : "https://gecorcloud.com/images/bgLocal.jpg"}>

        <div className="container">
          <div className="infoBoxCh " ref={div => this.subhead = div}>
            <div className="infoTit">
              <h1><b>INFORMACIÓN LOCAL</b></h1>
              <hr className="line" ></hr>
            </div>
            {/*   Box 1   */}
            <Card >
              <Card.Header as="h5" >Trámites Medio Ambiente</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={6} >
                      <Row>
                        <Col sm={12} >
                          <p> {/* ref={ p => this.description = p } */}
                              > Animales abandonados.
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                              >Autorización para uso de megafonía en vía pública. 
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                              > Información sobre fumigaciones
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                              > Ocupación de vía pública.
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                              > Ocupación de zona de dominio público para instalaciones en la zona de playa 
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                              > Ocupación de las zonas verdes
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <a href="https://chiclananatural.com/oficina-virtual/tramites-medio-ambiente/">
                            <Button variant="outline-dark">Ir a Trámites Medio Ambiente</Button>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 6, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/info1.webp" : "https://gecorcloud.com/images/info1.png"} alt="info1" ref={img => this.feature = img} />
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            {/*   Box 2   */}

            <Card ref={card => this.content = card}>
              <Card.Header as="h5" ref={h5 => this.head = h5}>Información y educación ambiental</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 6, offset: 0 }}>
                    <img src={isWebpSupported() ? "https://gecorcloud.com/images/info3.webp" : "https://gecorcloud.com/images/info3.png"} alt="info3" />
                    </Col>
                    <Col md={6} >
                      <Row>
                        <Col sm={12}>
                          <p>
                          Programa de Educación Ambiental en la Escuela
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            Campañas de información y sensibilización
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Celebración de efemérides ambientales
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Exposiciones, conferencias, y publicaciones
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <a href="https://chiclananatural.com/medio-ambiente/educacion-y-concienciacion-ambiental/">
                            <Button variant="outline-dark">Ir a Información y educación ambiental</Button>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>


            {/*   Box 3   */}

            <Card>
              <Card.Header as="h5">Residuos</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <p>
                              Servicio de recogida de RU
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Servicio de recogida selectiva
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Recogida de pilas
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Servicios especiales: Servicio de retirada de muebles y enseres y  Servicio de recogida de restos de jardinería
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <a href="https://chiclananatural.com/medio-ambiente/residuos/">
                            <Button variant="outline-dark">Ir a Residuos</Button>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 6, offset: 0 }}>
                    <img src={isWebpSupported() ? "https://gecorcloud.com/images/info2.webp" : "https://gecorcloud.com/images/info2.png"} alt="info2" />
                      
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>


            {/*   Box 4   */}

            <Card>
              <Card.Header as="h5">PARQUES Y JARDINES</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 6, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/info4.webp" : "https://gecorcloud.com/images/info4.png"} alt="info4" />
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <p>Parques Públicos</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Pinares
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                          Áreas de juegos
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <a href="https://chiclananatural.com/medio-ambiente/parques-y-jardines/">
                            <Button variant="outline-dark">Ir a Parques y Jardines</Button>
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

          </div>
        </div>

      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedLocalInformationPageCh = connect(mapStateToProps)(LocalInformationPageCh);
export { connectedLocalInformationPageCh as LocalInformationPageCh }; 