import './index.css';
import 'core-js';
import 'react-app-polyfill/ie9'; // Provides Map, which Opera 12.16 lacks
import 'react-app-polyfill/stable';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import React, { Suspense } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { render } from "react-dom";
import { Provider } from "react-redux";
import store from "./js/store";
import { App } from "./js/App";
// import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Cookies from 'js-cookie'

import './js/App/i18n';
import 'typeface-montserrat';

// loading component for suspense fallback
const Loader = () => (
  <div className="App">
    <Spinner animation="grow" variant="info" size="lg" />
  </div>
);

//const cargaGA = !Cookies.get('cookie-rejected')? true: false
//console.log("cargaGA ", cargaGA)
render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </Provider>,
  // The target element might be either root or app,
  // depending on your development environment
  // document.getElementById("app")
  document.getElementById("root")
);

ReactGA.initialize('UA-151713803-1');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToAnalytics({ id, name, value }) {
  ReactGA.ga('send', 'event', {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  });
}

  reportWebVitals(sendToAnalytics);
