import React from 'react';
import { QuestionCard } from './QuestionCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../pages/SurveysPage/SurveysPage.css';

export const SurveySection = props => {
    const { section, user, isSubmitting, onChange,getRespuesta} = props;

    return (
        <div id={`section-${section.SeccionID}`}>
            <Row>
                {section.RutaFoto && <Col xs={12} md={4}>
                    <div className="image">
                        <img src={section.RutaFoto} alt="ruta foto"/>
                    </div>
                </Col>}
                <Col xs={12} md={8}>
                    <div className="info">
                        <div className="info-title">{section.Titulo}</div>
                        <div className="info-desc">
                            {section.Descripcion && section.Descripcion.split('\n').map(function (item, key) {
                                return (
                                    <span key={key}>
                                        {item}
                                        <br />
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                </Col>
                {section.Preguntas && <div className="questions">
                    {section.Preguntas.map(question =>                    
                        <QuestionCard
                            key={`${section.SeccionID}-${question.PreguntaID}`}                            
                            type={question.TipoID}
                            question={question}
                            user={user}
                            isSubmitting={isSubmitting}   
                            getRespuesta={getRespuesta}                
                            onChange={onChange}
                        />)}
                </div>}           
            </Row>
        </div >
    );
}

export default SurveySection;