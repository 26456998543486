import { userConstants } from '../constants';

export const userLogin = {
    login: (payload) => ({ type: userConstants.LOGIN_REQUEST, payload }),
    success: (user) => ({ type: userConstants.LOGIN_SUCCESS, user }),
    failure: (error) => ({ type: userConstants.LOGIN_FAILURE, error })
};

export const userRegister = {
    register: (payload) => ({ type: userConstants.REGISTER_REQUEST, payload }),
    success: (user) => ({ type: userConstants.REGISTER_SUCCESS, user }),
    failure: (error) => ({ type: userConstants.REGISTER_FAILURE, error })
};

export const loadUserData = () => ({ type: userConstants.LOAD_USER_DATA })

export const userLogout = () => ({ type: userConstants.LOGOUT })

/**
 * Tells the app we want to register a user
 * @param  {object} data          The data we're sending for registration
 * @param  {string} data.username The username of the user to register
 * @param  {string} data.password The password of the user to register
 */
export const registerRequest = (data) => ({ type: userConstants.REGISTER_REQUEST, data })

/**
 * Tells the app we want to register a user
 * @param  {object} data          The data we're sending for registration
 * @param  {string} data.username The username of the user to register
 * @param  {string} data.password The password of the user to register
 */
export const updateRequest = (data) => ({ type: userConstants.UPDATE_REQUEST, data })


/**
   * Tells the app we want to log in a user
   * @param  {object} data          The data we're sending for log in
   * @param  {string} data.username The username of the user to log in
   * @param  {string} data.password The password of the user to log in
   */
export const loginRequest = (data) => ({ type: userConstants.LOGIN_REQUEST, data });

/**
   * Tells the app we want to log in a user
   * @param  {object} data          The data we're sending for log in
   * @param  {string} data.username The username of the user to log in
   * @param  {string} data.password The password of the user to log in
   */
export const resetPasswordRequest = (data) => ({ type: userConstants.RESET_PASSWORD_REQUEST, data });

/**
 * Sets the `currentlySending` state, which displays a loading indicator during requests
 * @param  {boolean} sending True means we're sending a request, false means we're not
 */
export const sendingRequest = (sending) => ({ type: userConstants.SENDING_REQUEST, sending });

/**
 * Sets the authentication state of the application
 * @param  {boolean} newAuthState True means a user is logged in, false means no user is logged in
 */
export const setAuthState = (newAuthState) => ({ type: userConstants.SET_AUTH, newAuthState });

/**
 * Sets the form state
 * @param  {object} newFormState          The new state of the form
 * @param  {string} newFormState.username The new text of the username input field of the form
 * @param  {string} newFormState.password The new text of the password input field of the form
 */
export const changeForm = (newFormState) => ({ type: userConstants.CHANGE_FORM, newFormState });

/**
 * Sets the `error` state as empty
 */
export const clearError = () => ({ type: userConstants.CLEAR_ERROR })

/**
 * Sets the `error` state to the error received
 * @param  {object} error The error we got when trying to make the request
 */
export const requestError = (error) => ({ type: userConstants.REQUEST_ERROR, error })
