import React, { useState } from "react";
import { LocationStreetEngine } from "./LocationStreetEngine";
import { LocationStreetEngineGeoreferenced } from "./LocationStreetEngineGeoreferenced";
import { LocationExternalStreetEngine } from "./LocationExternalStreetEngine";
import { LocationGoogle } from "./LocationGoogle";

import { defaultAyuntamientoID } from '../../constants';

export const LocationSearchInput = props => {
  const [cantFindStreet, setCantFindStreet] = useState(false);

  const { entityConfig } = props;
  const { reqMapNewInc } = entityConfig || {};

  if (defaultAyuntamientoID == 1) {
    return (
      <LocationExternalStreetEngine
        {...props}
      />
    );
  } else if (reqMapNewInc && !cantFindStreet) {
    if (reqMapNewInc.useStreetEngine)
      return <LocationStreetEngine {...props} />;
    else if (reqMapNewInc.useStreetEngineGeoreferenced)
      return (
        <LocationStreetEngineGeoreferenced
          {...props}
          handleStreetNotFound={() => setCantFindStreet(true)}
        />
      );
    else return <LocationGoogle {...props} />;
  } else {
    return <LocationGoogle {...props} />;
  }
};
