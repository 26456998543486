import React from 'react'; 
import './HomePage.css';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { forwardTo } from '../../helpers'
import { isWebpSupported } from 'react-image-webp/dist/utils';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { faAppStore, faGooglePlay } from '@fortawesome/free-brands-svg-icons'
/* ICONS */

export const SectionApp = props => {
    const { entity, t } = props;

    return (
        <div id="section-app">
            <div className="section-download">
                <Row>
                    <Col xs={12} md={5} className="left">
                        <div className="left-container">
                            <h3 tabIndex="18" className="card-titulo">{t('home.box2-title')}</h3>
                            <p tabIndex="19">{t('home.box2-p')}</p>
                            <div className="buttons-app">
                                <a tabIndex="20" target="_blank" rel="noopener noreferrer" href={entity && entity.RutaAppiOS ? entity.RutaAppiOS : "https://itunes.apple.com/es/app/gecor/id1127259219?mt=8"}>
                                    <Button className="apple-button" variant="secondary" size="lg">
                                        <FontAwesomeIcon icon={faAppStore} /> {t('home.box2-btn1')}
                                    </Button>
                                </a>
                                <a tabIndex="21" target="_blank" rel="noopener noreferrer" href={entity && entity.RutaAppAndroid ? entity.RutaAppAndroid : "https://play.google.com/store/apps/details?id=com.gecorsystem.gecorn&hl=es"}>
                                    <Button className="google-button" variant="secondary" size="lg">
                                        <FontAwesomeIcon icon={faGooglePlay} /> {t('home.box2-btn2')}
                                    </Button>
                                </a>
                            </div>
                            <a tabIndex="22" rel="noopener noreferrer" href='#' onClick={(e) => { e.preventDefault(); forwardTo('/new-incident') }}>
                                <Button className="new-incidence" variant="secondary" size="lg">
                                    <FontAwesomeIcon icon={faPlusCircle} /> {t('home.box2-btn3')}
                                </Button>
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} md={7} className="col-img-app">
                        <Image tabIndex="23" src={isWebpSupported() ? "https://gecorcloud.com/images/mano_gecor_2.webp" : "https://gecorcloud.com/images/mano_gecor_2.png"} fluid alt="image-download" />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default SectionApp;