import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { forwardTo } from '../../helpers'
import './header.css';
import { isWebpSupported } from 'react-image-webp/dist/utils';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faPlusCircle, faSignOutAlt, faMapMarked, faMapSigns, faList, faExclamationCircle, faSeedling, faInfoCircle, faPaw, faGraduationCap, faHandHolding, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

import { useTranslation } from 'react-i18next';
import { incidentsActions } from './../../actions/incidents.actions';


export const Header_268 = props => {
  const { users, dispatch } = props;

  const { t } = useTranslation();

  const logOut = () => {
    dispatch(incidentsActions.clearIncidents());
    forwardTo('/login')
  }

  return (
    <div id="header">
      <Navbar className="menu " sticky="top" expand="lg">
        <Navbar.Brand className="iconBrand_143" target="_blank" rel="noopener noreferrer" href="https://chiclananatural.com/"><img alt="Logo Chiclana" className="img-header_268" src={isWebpSupported() ? "https://gecorcloud.com/images/escudo-chiclana.webp" : "https://gecorcloud.com/images/escudo-chiclana.png"} /></Navbar.Brand>
        <Navbar.Toggle className="buttomToggle" aria-controls="basic-navbar-nav" ></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
          </Nav>

          <Nav className="menu2" >

          <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faSeedling} />{t("nav.nav9")}</>} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => forwardTo('/menu-ambiental')}><FontAwesomeIcon icon={faSeedling} />{t("nav.nav1")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/local-information-page-chiclana')}><FontAwesomeIcon icon={faInfoCircle} />{t("nav.nav9-1c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/enviromental-information-chiclana')}><FontAwesomeIcon icon={faPaw} />{t("nav.nav9-2c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/educacion-ambiental-chiclana')}><FontAwesomeIcon icon={faGraduationCap} />{t("nav.nav9-3c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/CitizenGuidePage-chiclana')}><FontAwesomeIcon icon={faHandHolding} />{t("nav.nav9-4c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/contacto-medioambiente-chiclana')}><FontAwesomeIcon icon={faQuestionCircle} />{t("nav.nav9-5c")}</NavDropdown.Item>
            {/* <NavDropdown.Item onClick={() => forwardTo('/nearby')}><FontAwesomeIcon icon={faBook} />{t("nav.nav9-6c")}</NavDropdown.Item> */}
          </NavDropdown>
          {users.loggedIn && <Nav.Link onClick={() => forwardTo('/interest-points')}><FontAwesomeIcon icon={faMapSigns} />{t("nav.nav8")}</Nav.Link>}
            <Nav.Link onClick={() => window.open('https://portal.gecorcloud.com/mapaciudadano.aspx?user=wLjoXUbTYMc=', '_blank')}><FontAwesomeIcon icon={faMapMarked} />{t("nav.nav12")}</Nav.Link>
            {users.loggedIn &&
              <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faExclamationCircle} />{t("nav.navInc")}</>} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => forwardTo('/new-incident')}><FontAwesomeIcon icon={faPlusCircle} />{t("nav.nav6-1")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => forwardTo('/incidents')}><FontAwesomeIcon icon={faList} />{t("nav.nav6-2")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => forwardTo('/nearby')}><FontAwesomeIcon icon={faMapMarked} />{t("nav.nav6-3")}</NavDropdown.Item>
              </NavDropdown>
            }

            {!users.loggedIn ?
              <Nav.Link onClick={() => forwardTo('/login')}><FontAwesomeIcon icon={faUser} />{t("nav.nav5")}</Nav.Link>
              :
              <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faUser} />{t("nav.nav6")}</>} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => forwardTo('/settings')}>{t("nav.nav6-4")}</NavDropdown.Item>
                <NavDropdown.Item onClick={logOut}><FontAwesomeIcon icon={faSignOutAlt} />{t("nav.nav6-5")}</NavDropdown.Item>
              </NavDropdown>
            }

            <Nav.Link className="nuevo-aviso" onClick={() => forwardTo('/new-incident')}><FontAwesomeIcon icon={faPlusCircle} />{t("nav.nav7")}</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header_268;