import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';

export class ConfirmStatusChange extends React.Component {
    state = {
        open: false,
        callback: null
    }

    show = callback => event => {
        // prevent default event action, e.g: form submission
        event.preventDefault()

        // we need to destructure the event
        // and get the current target value (e.g: select value
        event = {
            ...event,
            target: { ...event.target, value: event.target.value }
        }
        this.setState({
            open: true,
            // save original callback with event in closure
            callback: () => callback(event)
        })
    }

    hide = () => this.setState({ open: false, callback: null })

    confirm = () => {
        const { onConfirm } = this.props;
        onConfirm();
        this.state.callback()
        this.hide()
    }

    render() {
        const { t, newIncident } = this.props;
        
        return (
            <React.Fragment>
                {this.props.children(this.show)}

                {newIncident && (
                    <Modal
                        show={this.state.open}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        className="resumen-inc"
                        centered
                    >
                        <Modal.Body>
                            <h3 className="resumen-inc">{this.props.title}</h3>
                            <p>{this.props.description}</p>
                            {newIncident.photos && (
                                <Carousel>
                                    {newIncident.photos.map((photo, key) =>
                                        <Carousel.Item key={key}>
                                            <img className="img-modal responsive-img" alt={t('newincident.alt-foto')} src={photo.src} />
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                            )}
                            <br />
                            <p>
                                <strong>{t('newincident.rp9')} </strong>
                                <label>{newIncident.familyTypeValue}</label>
                            </p>
                            <p>
                                <strong>{t('newincident.rp3')} </strong>
                                <label>{newIncident.elementTypeValue}</label>
                            </p>
                            <p>
                                <strong>{t('newincident.rp4')} </strong>
                                <label>{newIncident.incidentTypeValue}</label>
                            </p>
                            {newIncident.unitType && <p>
                                <span className="col-sm-6">
                                    <strong >{t("newincident.rp7")} </strong>
                                    <label>{newIncident.unitTypeValue}</label>
                                    . </span>  <span className="col-sm-6">
                                    <strong>{t("newincident.rp8")} </strong>
                                    <label>{newIncident.promotionTypeValue}</label>
                                </span>
                            </p>}

                            <p>
                                <strong>{t('newincident.rp5')} </strong>
                                <label>{newIncident.streetName}</label>
                            </p>
                            <p>
                                <strong>{t('newincident.rp6')} </strong>
                                <label>{newIncident.description}</label>
                            </p>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.hide}>{t('newincident.edit-btn')}</Button>
                            <Button variant="secondary" onClick={this.confirm}>{t('newincident.confirm-btn')}</Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </React.Fragment>
        )
    }
}

export default ConfirmStatusChange