import React from "react";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const LocationNumberConfirm = props => {
  const { t, googleCantFindStreetNumber, street_number, open } = props;

  return (
    <Modal size="sm" show={open} aria-labelledby="example-modal-sizes-title-sm">
      <Modal.Body>
        <label>{t("location.error-number")}</label>
        <Row>
          <Col s={12} m={{ span: 6, offset: 3 }}>
            <Form.Control
              className="center-align"
              name="streetNumber"
              placeholder={t("location.number-placeholder")}
              value={street_number}
              onChange={props.handleChange}
              isInvalid={!!googleCantFindStreetNumber}
              isValid={googleCantFindStreetNumber}
            />
            <Form.Control.Feedback type="invalid">
              {t("location.error-google-number", {
                street_number: street_number
              })}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.handleClose}>{t("location.ok-btn")}</Button>
      </Modal.Footer>
    </Modal>
  );
};
