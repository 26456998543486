import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import CardDeck from 'react-bootstrap/CardDeck';
import { forwardTo } from "../../../../helpers";
import queryString from 'query-string';
import './MenuPuertoRosarioPage.css';
import { EntitySearch, DynamicBackground } from '../../../../components'
import { isWebpSupported } from 'react-image-webp/dist/utils';

class MenuPuertoRosarioPage extends React.Component {
  //CARGAR AYUNTAMIENTO POR PARAMETRO AO
  constructor(props) {
    super(props);

    this.state = {
      aytoName: '',
      ao: queryString.parse(this.props.location.search),
    };
    this.handleSelectedEntity = this.handleSelectedEntity.bind(this);
  }

  handleSelectedEntity(entity) {
    this.setState({ aytoName: entity.Nombre });
  }
  /*
   * PONER LA SIGUIENTE LINEA EN EL RENDER
   *
   * const { ao } = this.state;
   *
   * PONER LA SIGUIENTE LINEA EN EL RETURN
   * 
   * <EntitySearch className="ayto-borde" redirect={false} onSelected={this.handleSelectedEntity} ao={ao} hide={true} />
   * 
   * IMPORTANTE IMPORTAR EntitySearch
  */
  // END CARGAR AYUNTAMIENTO POR PARAMETRO AO

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {
    const { ao } = this.state;
    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.5)" background={isWebpSupported() ? "https://gecorcloud.com/images/pr-bg.webp" : "https://gecorcloud.com/images/pr-bg.jpg"}>
        <div className="envi-menu">
          <EntitySearch className="ayto-borde" redirect={false} onSelected={this.handleSelectedEntity} ao={ao} hide={true} />
          <Container>

            <br></br>

            <Row className="cards-pr">
              <Card className="card-menu-pr-rosario">
                <Card.Body>
                  <div className="title-menu-pr">
                    <h2>Menú Ambiental</h2>
                  </div>
                  <div className="body-menu-pr">
                    <p>
                      Bienvenido a la web medioambiental del Ayuntamiento de Puerto del Rosario.
                  </p>
                    <p>
                      Aquí podrás encontrar gran cantidad de información medioambiental actualizada y solucionar tus dudas poniendote en contacto en el apartado de consultas medioambientales, visitando nuestros glosarios medio ambientales, etc.
                  </p>
                  </div>
                </Card.Body>
              </Card>
            </Row>

            <br></br>

            <Row className="cards-pr">
              <Col sm={12}>
                <CardDeck>

                  <Card className="card-pr" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src="https://gecorcloud.com/images/info-ch.png" />
                    <Card.Body>
                      <Card.Title>Información Local Ambiental</Card.Title>
                      <Card.Text>
                        Información ambiental del Ayuntamiento de Puerto del Rosario a rápida disposición del ciudadano.
                      </Card.Text>
                      <Button variant="primary" onClick={() => forwardTo('/local-information-page-puertorosario')}>Más información</Button>
                    </Card.Body>
                  </Card>

                  <Card className="card-pr" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src="https://gecorcloud.com/images/especies-ch.png" />
                    <Card.Body>
                      <Card.Title>Especies Ambientales</Card.Title>
                      <Card.Text>
                        Información general sobre Especies ambientales en España y documentación relevante para la conservación de especies.
                      </Card.Text>
                      <Button variant="primary" onClick={() => forwardTo('/enviromental-information-puerto-rosario')}>Más información</Button>
                    </Card.Body>
                  </Card>

                  <Card className="card-pr" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src="https://gecorcloud.com/images/educacion-ch.png" />
                    <Card.Body>
                      <Card.Title>Educación Ambiental</Card.Title>
                      <Card.Text>
                        Documentación medioambiental de importancia para el amprendizaje para un futuro sostenible.
                      </Card.Text>
                      <Button variant="primary" onClick={() => forwardTo('/educacion-ambiental-puerto-rosario')}>Más información</Button>
                    </Card.Body>
                  </Card>

                </CardDeck>
              </Col>
            </Row>

            <br></br>

            <Row className="cards-pr">
              <Col sm={12}>
                <CardDeck>

                  <Card className="card-pr" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src="https://gecorcloud.com/images/alerta.png" />
                    <Card.Body>
                      <Card.Title>Notifica tu incidencia</Card.Title>
                      <Card.Text>
                        Notifica una incidencia en la vía pública a través del gestor de incidencias de Puerto del Rosario GECOR.
                      </Card.Text>
                      <Button variant="primary" onClick={() => forwardTo('/incidencias-puerto-rosario')}>Más información</Button>
                    </Card.Body>
                  </Card>

                  <Card className="card-pr" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src="https://gecorcloud.com/images/practicas-ch.png" />
                    <Card.Body>
                      <Card.Title>Prácticas Sobre Medio Ambiente</Card.Title>
                      <Card.Text>
                        Prácticas del día a día para mejorar nuestro mundo y sostenibilidad medioambiental.
                      </Card.Text>
                      <Button variant="primary" onClick={() => forwardTo('/CitizenGuidePage-pr')}>Más información</Button>
                    </Card.Body>
                  </Card>

                  <Card className="card-pr" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src="https://gecorcloud.com/images/contacto-ch.png" />
                    <Card.Body>
                      <Card.Title>Consultas Medio Ambiente</Card.Title>
                      <Card.Text>
                        Aquí puedes realizar cualquier tipo de consulta medioambiental. Responderemos lo más pronto posible.
                      </Card.Text>
                      <Button variant="primary" onClick={() => forwardTo('/contacto-medioambiente-pr')}>Más información</Button>
                    </Card.Body>
                  </Card>



                </CardDeck>
              </Col>
            </Row>

            <br></br>

            <Row className="cards-pr">
              <Col sm={12}>
                <CardDeck>

                  <Card className="card-pr" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src="https://gecorcloud.com/images/reporte-de-salud.png" />
                    <Card.Body>
                      <Card.Title>Salud Pública</Card.Title>
                      <Card.Text>
                        Información sobre salúd pública del municipio de Puerto del Rosario.
                      </Card.Text>
                      <a className="url-s-p-pr" href="http://www.puertodelrosario.org/index.php/es/2013-07-02-12-47-05/salud-publica" alt="salud-publica"><Button variant="primary">Más información</Button></a>
                    </Card.Body>
                  </Card>

                  <Card className="card-pr" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src="https://gecorcloud.com/images/beach-umbrella.png" />
                    <Card.Body>
                      <Card.Title>Playas</Card.Title>
                      <Card.Text>
                        Información sobre Playas de Puerto del Rosario y el estado de las mismas en tiempo real.
                      </Card.Text>
                      <a className="url-s-p-pr" href="http://www.puertodelrosario.org/index.php/es/2013-07-02-12-47-05/playas" alt="salud-publica"><Button variant="primary">Más información</Button></a>
                    </Card.Body>
                  </Card>

                  <Card className="card-pr" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src="https://gecorcloud.com/images/live-news.png" />
                    <Card.Body>
                      <Card.Title>Noticias Medio Ambiente</Card.Title>
                      <Card.Text>
                        Noticias de interés sobre medio ambiente, avances tecnológicos y descubrimientos actualizados.
                      </Card.Text>
                      <a className="url-s-p-pr" href="https://www.cienciasambientales.com/es/noticias-ambientales/" alt="salud-publica"><Button variant="primary">Más información</Button></a>
                    </Card.Body>
                  </Card>

                </CardDeck>
              </Col>
            </Row>

            <br></br>

            <Row className="cards-pr">
              <Col sm={12}>
                <CardDeck>

                  <Card className="card-pr" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src="https://gecorcloud.com/images/costumer-service.png" />
                    <Card.Body>
                      <Card.Title>Servicios Municipales</Card.Title>
                      <Card.Text>
                        índice de los servicios municipales con información detallada de los mismos.
                      </Card.Text>
                      <a className="url-s-p-pr" href="http://www.puertodelrosario.org/index.php/es/2013-07-02-12-47-05/servicios-municipales" alt="salud-publica"><Button variant="primary" >Más información</Button></a>
                    </Card.Body>
                  </Card>

                  <Card className="card-pr" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src="https://gecorcloud.com/images/drawing-book.png" />
                    <Card.Body>
                      <Card.Title>Glosario Ambiental</Card.Title>
                      <Card.Text>
                        Glosario que contiene palabras, definiciones e imagenes descriptivas de palabras relacionadas con medio ambiente.
                      </Card.Text>
                      <Button variant="primary" onClick={() => window.open('https://gecorcloud.com/pdf/Glosario-ambiental-GECOR.pdf')}>Más información</Button>
                    </Card.Body>
                  </Card>

                  <Card className="card-pr" style={{ width: '18rem' }}>
                    <Card.Img variant="top" src="https://gecorcloud.com/images/lista-de-quehaceres.png" />
                    <Card.Body>
                      <Card.Title>Enlaces de Interés</Card.Title>
                      <Card.Text>
                        Directorio de enlaces de interés en relación con el Medio Ambiente.
                      </Card.Text>
                      <Button variant="primary" onClick={() => window.open('https://gecorcloud.com/pdf/enlaces.pdf', '_blank')}>Más información</Button>
                    </Card.Body>
                  </Card>

                </CardDeck>
              </Col>
            </Row>
          </Container>

        </div>

      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedMenuPuertoRosarioPage = connect(mapStateToProps)(MenuPuertoRosarioPage);
export { connectedMenuPuertoRosarioPage as MenuPuertoRosarioPage };