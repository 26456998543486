import React from 'react';
import './CitiesPage.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import { isWebpSupported } from 'react-image-webp/dist/utils';

export const SectionModulesExtended = props => {
    const { t } = useTranslation();

    return (
        <div id="section-modules-extended">
            <Container>
                <h3 className="card-titulo">{t('cities.title6')}</h3>
                <Row>
                    <Col md={6}>
                        <div className="container-element">
                            <Image src={isWebpSupported() ? "https://gecorcloud.com/images/gecor_capas.webp" : "https://gecorcloud.com/images/gecor_capas.png"} alt="app ciudadana"/>
                            <div className="module-text">
                                <h5>{t('cities.title6-1')}</h5>
                                <li>{t('cities.title6-1-1')}</li>
                                <li>{t('cities.title6-1-2')}</li>
                                <li>{t('cities.title6-1-3')}</li>
                                <li>{t('cities.title6-1-4')}</li>
                            </div>
                        </div>
                        <div className="container-element">
                            <Image src={isWebpSupported() ? "https://gecorcloud.com/images/obreros.webp" : "https://gecorcloud.com/images/obreros.jpg"} alt="app tecnico"/>
                            <div className="module-text">
                                <h5>{t('cities.title6-2')}</h5>
                                <li>{t('cities.title6-2-1')}</li>
                                <li>{t('cities.title6-2-2')}</li>
                                <li>{t('cities.title6-2-3')}</li>
                                <li>{t('cities.title6-2-4')}</li>
                            </div>
                        </div>
                        <div className="container-element">
                            <Image src={isWebpSupported() ? "https://gecorcloud.com/images/gecor-modulos-aplicacion.webp" : "https://gecorcloud.com/images/gecor-modulos-aplicacion.jpg"} alt="Web ciudadana" />
                            <div className="module-text">
                                <h5>{t('cities.title6-3')}</h5>
                                <li>{t('cities.title6-3-1')}</li>
                                <li>{t('cities.title6-3-2')}</li>
                                <li>{t('cities.title6-3-3')}</li>
                                <li>{t('cities.title6-3-4')}</li>
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="container-element">
                            <Image src={isWebpSupported() ? "https://gecorcloud.com/images/flip-company-1.webp" : "https://gecorcloud.com/images/flip-company-1.jpg"} alt="Cuadros de mando" />
                            <div className="module-text">
                                <h5>{t('cities.title6-4')}</h5>
                                <li>{t('cities.title6-4-1')}</li>
                                <li>{t('cities.title6-4-2')}</li>
                                <li>{t('cities.title6-4-3')}</li>
                                <li>{t('cities.title6-4-4')}</li>
                            </div>
                        </div>
                        <div className="container-element">
                            <Image src={isWebpSupported() ? "https://gecorcloud.com/images/gweet-schema2.webp" : "https://gecorcloud.com/images/gweet-schema2.png"} alt="redes sociales"/>
                            <div className="module-text">
                                <h5>{t('cities.title6-5')}</h5>
                                <li>{t('cities.title6-5-1')}</li>
                                <li>{t('cities.title6-5-2')}</li>
                                <li>{t('cities.title6-5-3')}</li>
                                <li>{t('cities.title6-5-4')}</li>
                            </div>
                        </div>
                        <div className="container-element">
                        <Image src={isWebpSupported() ? "https://gecorcloud.com/images/opendata.webp" : "https://gecorcloud.com/images/opendata.jpg"} alt="modulo de open data"/>
                            <div className="module-text">
                                <h5>{t('cities.title6-6')}</h5>
                                <li>{t('cities.title6-6-1')}</li>
                                <li>{t('cities.title6-6-2')}</li>
                                <li>{t('cities.title6-6-3')}</li>
                                <li>{t('cities.title6-6-4')}</li>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SectionModulesExtended;