import { infoApiUrl } from '../../constants';

export const poisApi = {
    
    /**
    * Dado el id de un ayuntamiento, nos devuelve sus puntos de interes    
    */
   getPois() {
    // const { AyuntamientoID } = JSON.parse(entity);
       const entity = JSON.parse(localStorage.getItem('entity'));
        /*
        const params = {
            entity.AyuntamientoID,
        }
        */
        const requestOptions = {
            method: 'GET',
        };
        return fetch(`${infoApiUrl}/getItemsInterestPoints/${entity.AyuntamientoID}`, requestOptions)
        /* return fetch(`${infoApiUrl}/getItemsInterestPoints/0`, requestOptions) */
            .then(response => response.json())
            .then(pois => {
                localStorage.setItem('myPois', JSON.stringify(pois));
                return pois
            });
    },

    /**
    * Nos devuelve las categorías de los puntos de interes    
    */
   getCategories() {
        const requestOptions = {
            method: 'GET',
        };
        return fetch(`${infoApiUrl}/getCategorias`, requestOptions)
            .then(response => response.json())
            .then(categoriesPoi => {
                localStorage.setItem('categoriesPoi', JSON.stringify(categoriesPoi));
                return categoriesPoi
            });
    },

    getPoiDetail(poiID) {
        const _pois = poisApi.loadPois()
        const filteredPoi = _pois.filter(poi => poi.idItem === 0 ||  poi.idItem === parseInt(poiID))   
        return filteredPoi[0]
    },

    loadPois() {
        return JSON.parse(localStorage.getItem('myPois')) || undefined;
    }, 
    loadCategories() {
        return JSON.parse(localStorage.getItem('categoriesPoi')) || undefined;
    },
    onChange() { }
}

export default poisApi
