import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { forwardTo } from '../../helpers'
import './header.css';
import { isWebpSupported } from 'react-image-webp/dist/utils';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGraduationCap, faHandHolding, faInfoCircle, faPaw, faQuestionCircle, faSeedling } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

import { useTranslation } from 'react-i18next';
import { NavDropdown } from 'react-bootstrap';

export const Header_268e = props => {
  const { t } = useTranslation();

  return (
    <div id="header">
      <Navbar className="menu " sticky="top" expand="lg">
        <Navbar.Brand className="iconBrand_143" target="_blank" rel="noopener noreferrer" href="https://chiclananatural.com/"><img alt="Logo Chiclana" width="10%" className="img-Header_268" src={isWebpSupported() ? "https://gecorcloud.com/images/escudo-chiclana.webp" : "https://gecorcloud.com/images/escudo-chiclana.png"} /></Navbar.Brand>
        <Navbar.Toggle className="buttomToggle" aria-controls="basic-navbar-nav" ></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="menu2 mr-auto">
          </Nav>

          <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faSeedling} />{t("nav.nav9")}</>} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => forwardTo('/menu-ambiental')}><FontAwesomeIcon icon={faSeedling} />{t("nav.nav1")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/local-information-page-chiclana')}><FontAwesomeIcon icon={faInfoCircle} />{t("nav.nav9-1c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/enviromental-information-chiclana')}><FontAwesomeIcon icon={faPaw} />{t("nav.nav9-2c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/educacion-ambiental-chiclana')}><FontAwesomeIcon icon={faGraduationCap} />{t("nav.nav9-3c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/CitizenGuidePage-chiclana')}><FontAwesomeIcon icon={faHandHolding} />{t("nav.nav9-4c")}</NavDropdown.Item>
            <NavDropdown.Item onClick={() => forwardTo('/contacto-medioambiente-chiclana')}><FontAwesomeIcon icon={faQuestionCircle} />{t("nav.nav9-5c")}</NavDropdown.Item>
            {/* <NavDropdown.Item onClick={() => forwardTo('/nearby')}><FontAwesomeIcon icon={faBook} />{t("nav.nav9-6c")}</NavDropdown.Item> */}
          </NavDropdown>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header_268e;