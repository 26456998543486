import React from 'react';
import './SecurityPolicyPage.css';
import Container from 'react-bootstrap/Container';

class SecurityPolicyPage extends React.Component {
    render() {
        return (
            <Container id="declaracion-acc-container" className="text-justify float-center">
                <div>
                    <h1 className='header'>Política de seguridad de la información</h1>
                    <h2 className='titulo1'>1. Aprobación y entrada en vigor</h2>
                    <p className='parrafo'>
                        Texto aprobado el día de 07 de julio de 2023 por Salvador Marín Mellado. 
                        Esta Política de Seguridad de la Información es efectiva desde dicha fecha y hasta que sea 
                        reemplazada por una nueva Política.
                    </p>
                    <h2 className='titulo1'>2. Introducción</h2>
                    <p className='parrafo'>
                        Gecor System S.L.U. depende de los sistemas TIC (Tecnologías de Información y 
                        Comunicaciones) para alcanzar sus objetivos. 
                    </p>
                    <p className='parrafo'>
                        Estos sistemas deben ser administrados con diligencia y técnicas procedimentales, tomando las medidas adecuadas para 
                        protegerlos frente a daños accidentales o deliberados que puedan afectar a la disponibilidad, integridad o confidencialidad de la información tratada 
                        o los servicios prestados.
                    </p>
                    <p className='parrafo'>
                        El objetivo de la seguridad de la información es garantizar la calidad de la información y 
                        la prestación continuada de los servicios, actuando preventivamente, supervisando la 
                        actividad diaria y reaccionando rápida y eficazmente a los incidentes. 
                        Los sistemas TIC deben estar protegidos contra amenazas de rápida evolución con potencial para incidir 
                        en la confidencialidad, integridad, disponibilidad, uso previsto y valor de la información 
                        y los servicios. 
                    </p>
                    <p className='parrafo'>
                        Para defenderse de estas amenazas, se requiere una estrategia que se 
                        adapte a los cambios en las condiciones del entorno para garantizar la prestación 
                        continua de los servicios. Esto implica que los departamentos deben aplicar las medidas 
                        mínimas de seguridad exigidas por el Esquema Nacional de Seguridad, así como realizar 
                        un seguimiento continuo de los niveles de prestación de servicios, seguir y analizar las 
                        vulnerabilidades reportadas, y preparar una respuesta efectiva a los incidentes para 
                        garantizar la continuidad de los servicios prestados.
                    </p>
                    <p className='parrafo'> 
                        Los diferentes departamentos deben cerciorarse de que la seguridad TIC es una parte 
                        integral de cada etapa del ciclo de vida del sistema, desde su concepción hasta su 
                        retirada de servicio, pasando por las decisiones de desarrollo o adquisición y las 
                        actividades de explotación. Los requisitos de seguridad y las necesidades de 
                        financiación, deben ser identificados e incluidos en la planificación, en la solicitud de 
                        ofertas, y en pliegos de condiciones de licitación para proyectos de TIC.
                    </p>
                    <h3 className='titulo2'>2.1. Prevención</h3>
                    <p className='parrafo'> 
                        Los departamentos deben evitar, o al menos prevenir en la medida de lo posible, que la 
                        información o los servicios se vean perjudicados por incidentes de seguridad. Para ello 
                        los departamentos deben implementar las medidas mínimas de seguridad 
                        determinadas por el ENS, así como cualquier control adicional identificado a través de 
                        una evaluación de amenazas y riesgos.
                    </p>
                    <p className='parrafo'> 
                        Estos controles, y los roles y responsabilidades 
                        de seguridad de todo el personal, deben estar claramente definidos y documentados. 
                        Para garantizar el cumplimiento de la política, los departamentos deben: 
                    </p>
                    <ul className='listaSimple'>
                        <li>Autorizar los sistemas antes de entrar en operación <b>(Según la IT06 de Aceptación y Puesta en Servicio).</b></li>
                        <li>Evaluar regularmente la seguridad, incluyendo evaluaciones de los cambios de configuración realizados de forma rutinaria 
                        <b>(Según PO10 de Gestión de Cambios y realización da análisis de riesgos siguiendo el PG09 de Gestión del Riesgo).</b> </li>
                        <li>Solicitar la revisión periódica por parte de terceros con el fin de obtener una evaluación independiente 
                        <b>(Certificación del cumplimiento del ENS mediante empresa externa que demanda revisiones anuales del sistema).</b></li>
                    </ul>
                    <h3 className='titulo2'>2.2. Detección</h3>
                    <p className='parrafo'> 
                        Dado que los servicios se pueden degradar rápidamente debido a incidentes, que van 
                        desde una simple desaceleración hasta su detención, estos deben monitorizar la 
                        operación de manera continua para detectar anomalías en los niveles de prestación de 
                        los servicios y actuar en consecuencia según lo establecido en el Artículo 9 del ENS.
                    </p>
                    <p className='parrafo'>
                        Se establecerán mecanismos de detección, análisis y reporte que sean analizados por los 
                        responsables regularmente y especialmente cuando se produce una desviación 
                        significativa de los parámetros que se hayan preestablecido como normales. 
                    </p>
                    <h3 className='titulo2'>2.3. Respuesta</h3>
                    <p className='parrafo'>
                        Los departamentos deben establecer mecanismos para responder eficazmente a los incidentes de seguridad. 
                    </p>
                    <h3 className='titulo2'>2.4. Recuperación</h3>
                    <p className='parrafo'>
                        Para garantizar la disponibilidad de los servicios críticos, los departamentos deben 
                        desarrollar planes de continuidad de los sistemas TIC como parte de su plan general de 
                        continuidad de negocio y actividades de recuperación.
                    </p>
                    <h2 className='titulo1'>3. Alcance</h2>
                    <p className='parrafo'>
                        Esta política se aplica a todos los sistemas TIC de y a todos los miembros de la 
                        organización, sin excepciones.  
                    </p>
                    <h2 className='titulo1'>4. Misión</h2>
                    <p className='parrafo'>
                        Gecor System trabaja para ofrecer un servicio seguro y de calidad a la ciudadanía, 
                        poniéndola en contacto con las administraciones públicas, para juntos mejorar la calidad 
                        de vida en las ciudades
                    </p>
                    <h2 className='titulo1'>5. Marco informativo</h2>
                    <ul>
                        <li><b>RD 311/2022</b> del 3 de mayo, por el que se regula el Esquema Nacional de Seguridad</li>
                        <li><b>Ley Orgánica 3/2018</b>, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales</li>
                        <li> <b>Ley 9/2017</b>, de 8 de noviembre, de Contratos del Sector Público, por la que se transponen al ordenamiento jurídico español las 
                            Directivas del Parlamento Europeo y del Consejo 2014/23/UE y 2014/24/UE, de 26 de febrero de 2014.</li>
                    </ul>
                    <p className='parrafo'>
                        El procedimiento de identificación de la normativa aplicable se denomina <b>PG 05 Requisitos Legales.</b>
                    </p>
                    <p className='parrafo'>
                    El resto de leyes, reglamentos y otras normativas, nacionales o internacionales, a la que la actividad de Gecor System S.L.U. 
                    está sujeta se encuentran en el registro <b>“PG 05.01 Listado de Legislación”.</b>
                    </p>
                    <h2 className='titulo1'>6. Organización de la seguridad</h2>
                    <h3 className='titulo2'>6.1. Comités: funciones y responsabilidades</h3>
                    <p className='parrafo'>
                        El Comité de Seguridad TIC estará formado por:
                    </p>
                    <ul>
                        <li>Resp. De Calidad, Medio Ambiente y Seguridad.</li>
                        <li>Director de Tecnología, Responsable del Sistema</li>
                    </ul>
                    <p className='parrafo'>
                        El Comité de Seguridad TIC tendrá las siguientes funciones:
                    </p>
                    <ul>
                        <li>Redacción y aprobación de la documentación en materia de seguridad que forma parte del sistema.</li>
                        <li>Aprobación de cambios críticos en el sistema de gestión de la seguridad.</li>
                        <li>Adquisición de nuevos componentes que afecten al sistema de gestión de la seguridad.</li>
                    </ul>
                    <h3 className='titulo2'>6.2. Roles: funciones y responsabilidades</h3>
                    <p className='parrafo'>
                        El rol principal dentro del sistema de gestión de la seguridad de la información de Gecor 
                        System es el de Responsable de Seguridad de la Información, habitualmente referido en 
                        la documentación como Responsable de Seguridad.
                        Las funciones de este Responsable de Seguridad son:
                    </p>
                    <ul>
                        <li>Desarrollar, operar y mantener el Sistema de Información durante todo su ciclo de vida, de sus especificaciones, 
                            instalación y verificación de su correcto funcionamiento.</li>
                        <li>Definir la topología y sistema de gestión del Sistema de Información estableciendo los criterios de uso y los servicios disponibles en el mismo.</li>
                        <li>Valorar y determinar la categoría de seguridad del Sistema de Información en base a lo descrito por el Esquema Nacional de Seguridad.</li>
                        <li>Cerciorarse de que las medidas específicas de seguridad se integren adecuadamente dentro del marco general de seguridad.</li>
                        <li>El Responsable del Sistema puede acordar, junto con el Comité de Seguridad, la suspensión del manejo de una cierta información o 
                            la prestación de un cierto servicio si es informado de deficiencias graves de seguridad que pudieran afectar a la 
                            satisfacción de los requisitos establecidos.</li>
                    </ul>
                    <h3 className='titulo2'>6.3. Procedimientos de designación</h3>
                    <p className='parrafo'>
                        El Responsable de Seguridad de la Información es nombrado por Dirección teniendo en 
                        cuenta las recomendaciones y las capacidades de los miembros del departamento de 
                        tecnología. El nombramiento se revisará cada 2 años o cuando el puesto quede vacante.
                    </p>
                    <h3 className='titulo2'>6.4. Política de seguridad de la información</h3>
                    <p className='parrafo'>
                        Será misión del Comité de Seguridad TIC la revisión anual de esta Política de Seguridad 
                        de la Información y la propuesta de revisión o mantenimiento de la misma. La Política 
                        será aprobada y posteriormente difundida para que la conozcan todas las partes 
                        afectadas. 
                    </p>
                    <h2 className='titulo1'>7. Concienciación y formación</h2>
                    <p className='parrafo'>
                        Se realizará una reunión de formación anual, que podrá integrarse o no dentro de la ya 
                        planificada en materia de calidad, en la que se recordarán los deberes y obligaciones de cada miembro de la organización en materia de seguridad 
                        siguiendo los requisitos marcados por el Esquema Nacional de Seguridad.
                    </p>
                    <p className='parrafo'>
                        Así mismo, se realizarán comunicaciones anuales en las que se difundirán tanto la 
                        presente Política de Seguridad como la localización de la documentación pertinente 
                        dentro del servidor interno de archivos compartidos.
                    </p>
                    <p className='parrafo'>
                        El objetivo, es lograr la plena conciencia respecto a que la seguridad de la información 
                        afecta a todos los miembros de Gecor System S.L.U. y a todas las actividades, de acuerdo 
                        al principio de Seguridad Integral recogido en el <b>Artículo 5 del ENS</b>, así como la 
                        articulación de los medios necesarios para que todas las personas que intervienen en el 
                        proceso y sus responsables jerárquicos tengan una sensibilidad hacia los riesgos que se 
                        corren.
                    </p>
                    <h2 className='titulo1'>8. Gestión de Riesgos</h2>
                    <p className='parrafo'>
                        El análisis de riesgos será la base para determinar las medidas de seguridad que se 
                        deben adoptar además de los mínimos establecidos por el Esquema Nacional de 
                        Seguridad, según lo previsto en el <b>Artículo 6 del ENS.</b>
                    </p>
                    <p className='parrafo'>
                        Todos los sistemas sujetos a esta Política deberán realizar un análisis de riesgos, 
                        evaluando las amenazas y los riesgos a los que están expuestos. Este análisis se repetirá: 
                        regularmente (al menos una vez al año), cuando cambie la información manejada, 
                        cuando cambien los servicios prestados, cuando ocurra un incidente grave de seguridad 
                        o cuando se reporten vulnerabilidades graves.
                    </p>
                    <p className='parrafo'>
                        Para la armonización de los análisis de riesgos, el Comité de Seguridad TIC establecerá 
                        una valoración de referencia para los diferentes tipos de información manejados y los 
                        diferentes servicios prestados.
                    </p>
                    <h2 className='titulo1'>9. Desarrollo de la política de seguridad de la información</h2>
                    <p className='parrafo'>
                        Esta Política de Seguridad de la Información complementa las normativas de seguridad
                        en las diferentes materias:
                    </p>
                    <ul>
                        <li>MBPS-Manual de Buenas Prácticas de Seguridad en Uso de Móviles.</li>
                        <li>NS01-Control de Acceso.</li>
                        <li>NS02-Normativa de Gestión del Puesto de Trabajo.</li>
                        <li>NS03-Normativa de Gestión de Copias de Seguridad.</li>
                        <li>NS04-Limpieza de Metadatos.</li>
                        <li>NS05-Normativa de Uso del Correo Electrónico.</li>
                        <li>NS06-Normativa de Calificación de la Información.</li>
                        <li>NS07-Normativa de Interconexión de Sistemas.</li>
                        <li>NS08-Normativa de uso de la firma electrónica.</li>
                        <li>NS09–Normativa de Navegación Segura.</li>
                    </ul>
                    <p className='parrafo'>
                        La normativa de seguridad estará a disposición de todos los miembros de la organización 
                        que necesiten conocerla, en particular para aquellos que utilicen, operen o administren 
                        los sistemas de información y comunicaciones. La normativa de seguridad estará 
                        disponible en la intranet de Gecor System S.L.U.
                    </p>
                    <p className='parrafo'>
                        Esta normativa deberá ser revisada y actualizada con carácter periódico anual.
                        Para una accesibilidad a toda la documentación interna, los integrantes de Gecor System 
                        S.L.U serán autorizados manualmente. Podrán acceder mediante el siguiente enlace al 
                        OneDrive donde se encuentra la misma: <a href='https://gecorwebcom.sharepoint.com/sites/SIGGecor/Documentos%20compartidos/Forms/AllItems.aspx?id=%2Fsites%2FSIGGecor%2FDocumentos%20compartidos%2F2023%20PDF&p=true&ga=1' target='_blank'>documentación interna.</a>
                    </p>
                    <p className='parrafo'>
                        Será siempre aprobada en última instancia por la Dirección de Gecor System S.L.U.                    
                    </p>
                    <div className='firma'>
                        <p className='firma'>
                            Aprobado por:
                        </p>
                        <img src='https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/2023/GecorWeb/img-politica.png'/>          
                    </div>
                </div>
            </Container>
        );
    }

}

export { SecurityPolicyPage }