import React from 'react';
import './IncidentProgress.css';

export class IncidentProgress extends React.PureComponent {

    isActiveStepOne(key) {
        if (key && key !== 0) return "item active";
    }

    isActiveStepTwo(key) {
        switch (key) {
            case 9:
            case 13:
            case 11:
            case 12:
            case 18:
                return "item active";
            default:
                return "item";
        }
    }

    isActiveStepThree(key) {
        switch (key) {
            case 12:
                return "active";
            default:
                break;
        }
    }
    isActiveStepTwoDenied(key) {
        switch (key) {
            case 15:
            case 16:
            case 17:
            case 2:
                return "active";
            default:
                break;
        }
    }

    render() {
        const { incident, t } = this.props;
        return (
            <div id="incident-progress">
                {incident &&
                    <div className="progressbar" >
                        <div className="progressbar-item">
                            <div className="item active">1</div>
                            <span>{t('progress.p1')}</span>
                        </div>
                        {this.isActiveStepTwoDenied(incident.EstadoAvisoID!=0?incident.EstadoAvisoID:incident.Estado) === "active" && <div className="progressbar-item">
                            <div className={this.isActiveStepTwoDenied(incident.EstadoAvisoID!=0?incident.EstadoAvisoID:incident.Estado)}>2</div>
                            <span>{t('progress.p4')}</span>
                        </div>
                        }
                        {this.isActiveStepTwoDenied(incident.EstadoAvisoID!=0?incident.EstadoAvisoID:incident.Estado) !== "active" && <div className="progressbar-item">
                            <div className={this.isActiveStepTwo(incident.EstadoAvisoID!=0?incident.EstadoAvisoID:incident.Estado)}>2</div>
                            <span>{t('progress.p2')}</span>
                        </div>
                        }
                        {this.isActiveStepTwoDenied(incident.EstadoAvisoID!=0?incident.EstadoAvisoID:incident.Estado) !== "active" && <div className="progressbar-item">
                            <div className={this.isActiveStepThree(incident.EstadoAvisoID!=0?incident.EstadoAvisoID:incident.Estado)}>3</div>
                            <span>{t('progress.p3')}</span>
                        </div>
                        }
                    </div>
                }
            </div>
        )
    }
}

export default IncidentProgress;