
import React from 'react';
import { connect } from 'react-redux';
import ReactStars from 'react-stars'
import './IncidentsPage.css';
import { IncidentCard } from './../../components';
import { incidentsActions } from './../../actions';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import { DynamicBackground } from './../../components'
import { IncidentDetail } from './../../components/IncidentCard/IncidentDetail';
import { NewIncidentCard } from './../../components/IncidentCard/NewIncidentCard';
import { isMobile } from 'react-device-detect';
import { forwardTo } from '../../helpers';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { withTranslation } from 'react-i18next';

const intialState = {
    open: false,
    openPhotos: false,
    //incidentToRate: undefined,
    //starToRate: 0,
    incidentChoosed: undefined,
    width: 0,
    height: 0,
}

class IncidentsPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = intialState;

        // this.ratingChanged = this.ratingChanged.bind(this);
        // this.handleRate = this.handleRate.bind(this);
        this.handleChooseIncident = this.handleChooseIncident.bind(this);

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(incidentsActions.getMyIncidents());

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    /*    ratingChanged(starToRate) {
            this.setState({ starToRate })
        }
    
        handleRate(incidentToRate) {
            this.setState({ open: true, incidentToRate })
        }
    
        handleRatedModal() {
            this.props.dispatch(incidentsActions.rateIncident({ AvisoID: this.state.incidentToRate, valoracion: this.state.starToRate }))
            this.setState(intialState);
        }
    */
    handleChooseIncident(incident) {
        let id = incident.AvisoID === 0 || !incident.AvisoID ? incident.AvisoCiudadanoID : incident.AvisoID;
        const { isMobile, width } = this.state;
        if (isMobile || width <= 1200) {
            forwardTo(`/detail/${id}`);
        } else {
            this.setState({ incidentChoosed: incident });
        }
    }

    handleChangeBackground(entity) {
        switch (entity && entity.AyuntamientoID) {
            case 206:
                return "";
            case 180:
                return entity.CabeceraApp;
            case 429:
                return isWebpSupported()
                    ? "https://gecorcloud.com/images/turisme1.webp"
                    : "https://gecorcloud.com/images/turisme1.jpg";
            default:
                return isWebpSupported()
                    ? "https://gecorcloud.com/images/login.webp"
                    : "https://gecorcloud.com/images/login.png";
        }
    }

    render() {

        const { myIncidents, user, entity, t } = this.props;
        const { width } = this.state;
        let { incidentChoosed } = this.state.incidentChoosed ? this.state : this.props;

        return (
            <DynamicBackground
                customOverlay="rgba(0, 0, 0, 0.5)"
                background={this.handleChangeBackground(entity)}
            >
                <div id="incidents-page" className='incident-pages-div'>
                    <Row>
                        <Col className="col-left" xl={8}>
                            <NewIncidentCard />
                            {(user && myIncidents) ? myIncidents.map((incident) => {
                                return (incident.AvisoID !== 0 ||  incident.AvisoCiudadanoID!==0) ? <IncidentCard
                                    key={incident.AvisoID !== 0 ? incident.AvisoID : incident.AvisoCiudadanoID}
                                    incident={incident}
                                    user={user}
                                   /* onRate={this.handleRate} */
                                    clicked={incident.AvisoID !== 0 ?
                                        incidentChoosed && incidentChoosed.AvisoID === incident.AvisoID
                                        :
                                        incidentChoosed && incidentChoosed.AvisoCiudadanoID === incident.AvisoCiudadanoID}
                                    onCustomClick={this.handleChooseIncident} />                                    
                                    : <div className='info' key={0} > {t("incicard.notFound")}</div> 
                                   }                            
                            )
                                :
                                <Spinner className="spinner-incs" animation="border" role="status" variant="primary">
                                    <span className="sr-only">{t("incmodel.loading")}</span>
                                </Spinner>
                            }
                            {user && myIncidents && myIncidents.length == 0  &&  <div className='info' key={0} > {t("incicard.first")}</div> }
                            {/* 
                            <Modal
                                size="sm"
                                show={this.state.open}
                                aria-labelledby="example-modal-sizes-title-sm"
                            >
                                <Modal.Body>
                                    <div className="rate">
                                        <ReactStars value={this.state.starToRate} count={5}
                                            onChange={this.ratingChanged} size={54}
                                            half={false} color2={'#1b9696'} />
                                    </div>
                                    <p>{t("incicard.gracias-val")}</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button onClick={() => this.handleRatedModal()}>{t("incicard.valorar")}</Button>
                                </Modal.Footer>
                            </Modal>*/}
                        </Col>
                        {width > 1200 &&
                            <Col className="col-right" xl={4}>
                                {(incidentChoosed && (incidentChoosed.AvisoID != 0 || incidentChoosed.AvisoCiudadanoID != 0 ) && user) && <IncidentDetail incident={incidentChoosed}
                                    user={user}
                                  /*  onRate={this.handleRate} */ />}
                            </Col>
                        }
                    </Row>
                    <br></br>
                </div>
            </DynamicBackground>
        )
    }
}

const mapStateToProps = (state) => {
    const { entities, incidents, users } = state;
    const { myIncidents } = incidents;
    let incidentChoosed = undefined;
    if (myIncidents) incidentChoosed = myIncidents[0];
    const { user } = users;
    const { entity } = entities;

    if (!user) forwardTo('/login');

    return {
        myIncidents,
        user,
        incidentChoosed,
        entity,
    };
}
const tIncidentsPage = withTranslation()(IncidentsPage);
const connectedIncidentPage = connect(mapStateToProps)(tIncidentsPage);
export { connectedIncidentPage as IncidentsPage };
