import React from 'react';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { DynamicBackground } from './../../components'
import './FaqPageCh.css';

import BannerContact from '../../components/BannerContact/BannerContact';

class FaqPageCh extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            open: true,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    handleDeleteUser(id) {
        //return (e) => this.props.dispatch(userActions.delete(id));
    }



    render() {

        return (
            <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/bgFaq.webp" : "https://gecorcloud.com/images/bgFaq.jpg"}>
                <div className="container">
                    <div className="TituloFAQ">
                        <h1>PREGUNTAS FRECUENTES</h1>
                        <hr className="line" ></hr>
                    </div>
                    <div className="containerFAQ">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col className="padding-box-info" sm={3}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Licencias</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Actividades</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Solicitudes</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="four">Información de interés</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col className="padding-box-info" sm={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <Accordion defaultActiveKey="0">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                                        ¿Cuándo tengo que solicitar o notificar una actividad potencialmente contaminadoras de la atmósfera?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>
                                                            <p>El Real Decreto 100/2011, de 28 de enero, por el que se actualiza el catálogo de actividades potencialmente contaminadoras de la atmósfera y se establecen las disposiciones básicas para su aplicación, enumera las actividades consideradas potencialmente contaminadoras de la atmósfera. </p>
                                                            <p>En general, se deberá presentar una notificación o una solicitud de autorización a la Delegación Territorial correspondiente, en función de cómo esté catalogado el foco A, B o C, teniendo en cuenta si la actuación está sujeta o no a autorización ambiental integrada o a autorización ambiental unificada.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="1">
                                                        ¿Es necesario tener licencia para tener animales potencialmente peligrosos?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body>
                                                            <p>
                                                                Sí, en el Decreto 42/2008, de 12 de febrero, por el que se regula la tenencia de animales potencialmente peligrosos en la Comunidad Autónoma de Andalucía, se puede consultar los animales que se consideran potencialmente peligrosos y sus características, así como los requisitos necesarios para la obtención de las licencias que habilitan para tener animales potencialmente peligrosos y fijar las medidas de seguridad para el manejo de los mismos.
                                                                Para más información sobre esta licencia se ha de dirigir a la Consejería de Justicia e Interior.
                                    </p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="2">
                                                        ¿Cómo y dónde puedo plantar árboles?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="2">
                                                        <Card.Body>
                                                            <p>Si desea plantar en un terreno público, ha de dirigirse al organismo o entidad que lo tutele (Ayuntamiento, Consejería de Medio Ambiente y Ordenación del Territorio, Confederación Hidrográfica, etc). En el caso de un terreno privado, hay que obtener permiso del propietario. </p>
                                                            <p>Asimismo, se debe tener en cuenta en qué lugar se va a plantar, qué especies habitan de forma natural en ese lugar, qué cuidados precisará y, si es en el medio natural, que no se trate de una especie exótica invasora. </p>
                                                            <p>Las especies autóctonas más frecuentes son las encinas, alcornoques, majuelos, sabinas, enebros, pinos, manzanos, perales, endrinos, olmos, sauces, álamos..... </p>
                                                            <p>Podrán ser objeto de ayuda por parte de la Consejería los trabajos, obras y estudios que se realicen en terrenos forestales y se ajusten a los criterios del Plan Forestal Andaluz. Se recomienda la consulta del apartado de Subvenciones, Becas y Premios del canal de Administración Electrónica.</p></Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="3">
                                                        ¿Puedo practicar actividades de turismo activo, uso público y ecoturismo en parques naturales?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="3">
                                                        <Card.Body>
                                                            <p>Sí, dependiendo de la actividad se requerirá la obtención de una autorización o realizar una comunicación previa.</p>
                                                            <p>En caso de que la actividad en cuestión afecte a terrenos de propiedad privada, se necesitará contar con la autorización expresa de los titulares de los mismos.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="4">
                                                        ¿Está permitida la acampada en parques naturales?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="4">
                                                        <Card.Body>
                                                            <p>En Andalucía no está permitida la acampada libre en parques naturales, entendida como la instalación de albergues móviles, caravanas, tiendas de campaña u otros elementos análogos, fuera de los campamentos de turismo y siempre que no se trate de uno de los supuestos siguientes:</p>
                                                            <p>•	Los albergues juveniles de la Administración de la Junta de Andalucía.</p>
                                                            <p>•	Los centros y colonias escolares.</p>
                                                            <p>•	Cualquier establecimiento similar a los anteriores donde el servicio del alojamiento turístico se realice de manera ocasional y sin ánimo de lucro.</p>
                                                            <p>•	Las acampadas y campamentos juveniles que se organicen conforme al Decreto 45/2000, de 31 de enero, sobre la organización de acampadas y campamentos juveniles en Andalucía y su normativa de desarrollo.</p>
                                                            <p>•	Las acampadas para la realización de actividades de educación ambiental de acuerdo con Orden de 13 de julio de 1999 de la Consejería de Medio Ambiente.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                            </Accordion>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <Accordion defaultActiveKey="0">

                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="5">
                                                        ¿Puedo circular en bicicletas por los Espacios Naturales Protegidos?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="5">
                                                        <Card.Body>
                                                            <p>En general, la circulación de bicicletas en los Espacios Naturales Protegidos está prohibida:</p>
                                                            <p>•	Campo a través.</p>
                                                            <p>•	En las zonas excluidas en aplicación de la normativa de protección de especies y hábitat.</p>
                                                            <p>•	 En senderos de uso público peatonales ofertados por la Consejería de Medio Ambiente y Ordenación del Territorio y señalizados al efecto.</p>
                                                            <p>Con carácter general, el acceso y tránsito de visitantes será libre por la red pública de caminos, exceptuando los que presenten señalización que indiquen una restricción o limitación al paso. </p>
                                                            <p>La Consejería de Medio Ambiente y Ordenación del Territorio podrá limitar o restringir a los visitantes en general o a cierto tipo de medios de transporte, de forma eventual o permanente, el acceso por cualquier camino cuando exista causa justificada por incompatibilidad del uso con la conservación, con los trabajos forestales o con el aprovechamiento de los recursos, así como por motivos de riesgo a las personas. </p>
                                                            <p>Si se desea realizar cicloturismo por caminos, pistas forestales u otros espacios donde exista limitación de acceso o de uso dentro de un parque natural, se ha de obtener previamente una autorización para la realización de actividades de uso público, turismo activo y ecoturismo en Parques Naturales.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="6">
                                                        ¿Puedo realizar pesca marítima de recreo en los Espacios Naturales Protegidos?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="6">
                                                        <Card.Body>
                                                            <p>En algunos Espacios Naturales Protegidos del litoral andaluz se requiere una autorización para poder llevar a cabo el ejercicio de la pesca marítima, por lo que, si se desea pescar en alguno de ellos, se recomienda que contacte con la Oficina del mismo o la Delegación Territorial correspondiente para que le informen si se necesita tramitar alguna autorización adicional, además de las ya otorgadas.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="7">
                                                        ¿Es posible pasear con mi mascota en las playas?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="7">
                                                        <Card.Body>
                                                            <p>Durante la temporada de baño queda prohibido el acceso de animales domésticos a las aguas y zonas de baño, con excepción del que resulte preciso para el desarrollo de actividades debidamente autorizadas por la autoridad sanitaria competente.</p>
                                                            <p>Se considera temporada de baño, el período de tiempo en que puede preverse una afluencia importante de bañistas, teniendo en cuenta los usos y costumbres locales. A efectos de la prohibición anterior, se considerará temporada de baño el período comprendido entre el 1 de junio y el 30 de septiembre de cada año. </p>
                                                            <p>Fuera de la temporada de baño, se sugiere contactar con el Ayuntamiento donde se encuentre la playa, al ser el organismo que posee las competencias en materia de limpieza, ordenación de actividades y seguridad de las playas, así como las relacionadas con animales de compañía. </p>
                                                            <p>En caso de tratarse de una playa sometida a una protección especial, por encontrarse en algún espacio natural protegido, se ha de consultar lo establecido en los instrumentos de planificación correspondientes. Si se trata de un espacio que no posee instrumento alguno, puede ponerse en contacto con la Delegación Territorial de la provincia donde esté ubicada la playa en cuestión.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="8">
                                                        ¿Se puede realizar buceo deportivo sin autorización?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="8">
                                                        <Card.Body>
                                                            <p>No está permitida la práctica de buceo o de baño en:</p>
                                                            <p>•	Canales balizados de lanzamiento y varada de embarcaciones en playa.</p>
                                                            <p>•	Puertos y dársenas.</p>
                                                            <p>•	Canales de acceso donde es prioritaria la maniobra de embarcaciones.</p>
                                                            <p>Por otro lado, estarán sujetas a autorización administrativa previa las actividades en las que concurran circunstancias especiales de intensidad, peligrosidad o rentabilidad, siendo el órgano competente en su tramitación la Delegación Territorial de la Consejería de Medio Ambiente y Ordenación del Territorio de la provincia correspondiente.</p>
                                                            <p>En caso de realizar buceo deportivo en un espacio natural protegido, se atenderá a lo establecido en los instrumentos de planificación del mismo. Si se trata de un espacio que no posee instrumento alguno, se deberá consultar si es necesario solicitar un permiso o autorización a la Delegación Territorial.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                            </Accordion>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">

                                            <Accordion defaultActiveKey="0">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="9">
                                                        ¿Cómo puedo solicitar información ambiental?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="9">
                                                        <Card.Body>
                                                            <p>Se puede solicitar: </p>
                                                            <p>•	Telemáticamente, a través del trámite de Solicitud de Información Ambiental del canal de Administración Electrónica.</p>
                                                            <p>•	De manera presencial, presentando un escrito dirigido a la Vicenconsejería de la Consejería de Medio Ambiente y Ordenación del Territorio. Avda. Manuel Siurot, 50 41071-Sevilla. En este caso, se recomienda que utilice el modelo de solicitud disponible en el trámite telemático.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="10">
                                                        ¿Cómo puedo obtener ayudas para instalar placas solares?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="10">
                                                        <Card.Body>
                                                            <p>Las cuestiones en materia de energía, incluyendo las energías renovables, son competencia de la Agencia Andaluza de la Energía de la Consejería de Empleo, Empresa y Comercio.</p>
                                                            <p>Se puede contactar con la citada Consejería a través del teléfono de su Servicio de Atención al Ciudadano 955 063 910.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="11">
                                                        ¿Dónde puedo solicitar mapas y guías de los Espacios Naturales Protegidos?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="11">
                                                        <Card.Body>
                                                            <p>Los mapas y guías de Espacios Naturales Protegidos se pueden solicitar, a través del distribuidor oficial de esta Consejería.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="12">
                                                        ¿Cómo puedo obtener información sobre las actividades que se realizan en los espacios naturales?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="12">
                                                        <Card.Body>
                                                            <p>En la “Ventana del Visitante de los Espacios Naturales”, se puede consultar el programa de actividades que se lleva a cabo en los espacios naturales: “Jornadas de puertas abiertas”, “La Naturaleza y tú”  o “Naturaleza para todos”.</p>
                                                            <p>Además se puede obtener información, consultando el programa de visitas de la Agencia de Medio Ambiente y Agua de Andalucía o contactando con esta Agencia a través del teléfono 955 26 00 00 o del servicio La Agencia atiende.</p>
                                                            <p>El horario de atención al público es de lunes a viernes de 9:00 a 14:00 h.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                            </Accordion>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="four">
                                            <Accordion defaultActiveKey="0">
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="13">
                                                        ¿Cómo y dónde puedo plantar árboles?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="13">
                                                        <Card.Body>
                                                            <p>Si desea plantar en un terreno público, ha de dirigirse al organismo o entidad que lo tutele (Ayuntamiento, Consejería de Medio Ambiente y Ordenación del Territorio, Confederación Hidrográfica, etc). En el caso de un terreno privado, hay que obtener permiso del propietario. </p>
                                                            <p>Asimismo, se debe tener en cuenta en qué lugar se va a plantar, qué especies habitan de forma natural en ese lugar, qué cuidados precisará y, si es en el medio natural, que no se trate de una especie exótica invasora. </p>
                                                            <p>Las especies autóctonas más frecuentes son las encinas, alcornoques, majuelos, sabinas, enebros, pinos, manzanos, perales, endrinos, olmos, sauces, álamos..... </p>
                                                            <p>Podrán ser objeto de ayuda por parte de la Consejería los trabajos, obras y estudios que se realicen en terrenos forestales y se ajusten a los criterios del Plan Forestal Andaluz. Se recomienda la consulta del apartado de Subvenciones, Becas y Premios del canal de Administración Electrónica.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="14">
                                                        ¿Puedo denunciar un delito ambiental ante la Consejería?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="14">
                                                        <Card.Body>
                                                            <p>Cualquier ciudadano que tenga conocimiento de la comisión de un hecho que pudiera ser constitutivo de infracción medioambiental podrá ponerlo en conocimiento de la Consejería, a través de su Servicio de Atención Ciudadana, indicando detalladamente los hechos que pudieran constituir la infracción, fecha e identificación clara del lugar de comisión de la misma, así como la identificación de los presuntos infractores, en su caso.</p>
                                                            <p>No obstante, aclarar que este tipo de actuaciones por parte de la ciudadanía no tendrán la consideración de una denuncia formal, ya que éstas han de realizarse mediante el correspondiente escrito de denuncia.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="15">
                                                        ¿Cómo puedo reforestar un terreno?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="15">
                                                        <Card.Body>
                                                            <p>Conforme a la Ley Forestal de Andalucía, la reforestación de terrenos deforestados precisará de un proyecto de repoblación o Plan Técnico aprobado por la Consejería de Medio Ambiente y Ordenación del Territorio o, en su defecto, de una autorización de la misma. </p>
                                                            <p>Para la concesión de autorizaciones se tendrá en cuenta:</p>
                                                            <p>•	Los valores ecológicos protectores, paisajísticos y sociales de la vegetación y recursos existentes o los que existieran con anterioridad en caso de incendio forestal u otro siniestro.</p>
                                                            <p>•	La pendiente del terreno.</p>
                                                            <p>•	Los procesos de desertificación y de grave erosión.</p>
                                                            <p>Esta Consejería podrá establecer con entidades públicas o privadas y particulares cuantos convenios, acuerdos o contratos estime convenientes, siempre que no sean contrarios al ordenamiento jurídico o al interés público. </p>
                                                            <p>Podrán ser objeto de ayuda los trabajos, obras y estudios que se realicen o refieran a predios forestales y se ajusten a los criterios del Plan Forestal Andaluz, pudiendo ser beneficiarios, tanto los propietarios de los terrenos como aquellas personas, naturales o jurídicas, a las que los propietarios hayan cedido el uso o disfrute de sus terrenos o establecido acuerdos que impliquen la mejora de conservación y de la producción mediante trabajos forestales.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey="16">
                                                        ¿Cómo puedo solicitar una autorización para emplear fuego?
                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey="16">
                                                        <Card.Body>
                                                            <p>La solicitud dependerá de la ubicación del terreno y de la época en que se quiera realizar el empleo del fuego:</p>
                                                            <p>•	En terrenos forestales y zonas de influencia forestal (400 m circundantes), la quema de matorral, pastos y residuos procedentes de trabajos forestales, así como la quema de rastrojos o residuos en labores agrícolas, requiere una Autorización para el uso del fuego en terreno forestal y zona de influencia forestal.</p>
                                                            <p>•	En zonas de peligro, el uso del fuego para la quema de rastrojos, pastos, residuos, carboneo o para cualquier otra actividad agraria, requiere una Comunicación del uso del fuego para actividades agrarias en zonas de peligro, fuera de terrenos forestales y de la zona de influencia forestal a la Delegación Territorial de la Consejería, con una antelación mínima de diez días al uso del fuego, siempre y cuando se pretenda realizar en época de peligro medio y/o alto y se encuentre fuera de terrenos forestales y zona de influencia forestal.</p>
                                                            <p>Indicar que desde el 1 de junio al 15 de octubre de cada año estará prohibido el uso del fuego salvo para las actividades contempladas en el trámite Autorización excepcional para realizar actividades en terrenos forestales y zonas de influencia forestal del 1 de junio al 15 de octubre.</p>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>

                                            </Accordion>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
                <BannerContact />
            </DynamicBackground>
        );
    }
}

const mapStateToProps = (state) => {
    const { users } = state;
    const { user } = users;
    return {
        user
    };
}

const connectedFaqPageCh = connect(mapStateToProps)(FaqPageCh);
export { connectedFaqPageCh as FaqPageCh };
