import React from 'react';
import { connect } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { incidentsActions } from './../../actions';
import { IncidentDetail } from './../../components/IncidentCard/IncidentDetail';
import { withTranslation } from 'react-i18next';

class IncidentDetailPage extends React.Component {

    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            incidentId: this.props.match.params.id,
            tags: [
                { DesEstadoAviso: t('details.t1') },
                { Responsable: t('details.t2') },
                { DesTipoElemento: t('details.t3') },
                { TipoInc: t('details.t4') },
                { NegociadoNombre: t('details.t5') },
                { Material: t('details.t6') },
                { UltimaRutaFotoAntes: t('details.t7') },
                { UltimaRutaFotoDespues: t('details.t8') },
                { MotivoRechazo: "" },
                { MotivoRetraso: "" },
                { DesPrioridad: t('details.t9') },
                { TipoAviso: t('details.t10') },
                { ProveedorAviso: t('details.t11') },
            ]
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(incidentsActions.getIncidentDetail(this.state.incidentId));
        this.props.dispatch(incidentsActions.getComments(this.state.incidentId));
        this.props.dispatch(incidentsActions.getPhotoDetail(this.state.incidentId));
        this.props.dispatch(incidentsActions.getHistory(this.state.incidentId));
    }

    render() {
        const { incidentDetail, incidentPhotos, user } = this.props;
        return (
            <Container>
                {(incidentDetail && user) && user && <IncidentDetail incident={incidentDetail}  incidentPhotos = {incidentPhotos}
                    user={user}
                    onRate={this.handleRate}
                    onShowPhotos={this.handlePhotos}  />}
            </Container>
        )
    }

}

const mapStateToProps = (state) => {
    const { incidents, users } = state;
    const { incidentDetail, incidentComments, incidentHistories, incidentPhotos  } = incidents;
    const { user } = users;

    return {
        incidentDetail,
        incidentComments,
        incidentHistories,
        incidentPhotos,
        user,
    };
}

const tIncidentDetailPage = withTranslation()(IncidentDetailPage);
const connectedIncidentDetailPage = connect(mapStateToProps)(tIncidentDetailPage);
export { connectedIncidentDetailPage as IncidentDetailPage };