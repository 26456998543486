import React, { Component } from "react";
import styled from "styled-components";
import Form from 'react-bootstrap/Form';
import { LocationNumberConfirm } from "./../../pages/NewIncidentPage/LocationNumberConfirm";

const Wrapper = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 0px 20px 0px;
`;

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      filter: {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_2: "short_name",
        country: "long_name",
        postal_code: "short_name"
      },
      hasStreet: false,
      typingTimeout: 0
    };
    this.clearSearchBox = this.clearSearchBox.bind(this);
  }

  componentDidMount({ map, mapApi, onMapClick, places } = this.props) {
    /*
    this.searchBox = new mapApi.places.SearchBox(this.searchInput);
    this.searchBox.addListener("places_changed", this.onPlacesChanged);
    */
    this.searchBox = new mapApi.places.Autocomplete(this.searchInput, {
      types: ["address"]
    });
    this.searchBox.addListener("place_changed", this.onPlacesChanged);
    this.searchBox.bindTo("bounds", map);
  }

  componentWillUnmount({ mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.searchInput);
  }

  handleTextInputChange = e => {
    e.preventDefault();
    this.setState({
      // search: e.target.value,
      value: e.target.value,
      hasStreet: false,
      street_number: ""
    });
  };

  handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
    let hasStreet = false;
    if (originalPrediction)
      this.searchInput.value = geocodedPrediction.formatted_address;

    this.setState({ search: "", value: geocodedPrediction.formatted_address });

    const { filter } = this.state;
    const { address_components, geometry } = geocodedPrediction;

    if (geometry) {
      this.setState({ x: geometry.location.lat() });
      this.setState({ y: geometry.location.lng() });
    }

    if (address_components)
      for (let i = 0; i < address_components.length; i++) {
        let addressType = address_components[i].types[0];
        if (addressType === "street_number") {
          hasStreet = true;
        }
        if (filter[addressType]) {
          this.setState({
            [addressType]: address_components[i][filter[addressType]]
          });
        }
      }

    this.setState({ hasStreet });
    const { open } = this.state;

    if (!hasStreet) {
      if (open) this.setState({ googleCantFindStreetNumber: true });
      this.handleOpen(true);
      this.searchInput.blur();
    } else {
      this.setState({ googleCantFindStreetNumber: false });
      this.handleOpen(false);
      this.props.onSuccessLocation(this.state);
      this.searchInput.blur();
    }
  };

  handleChange = name => event => {
    event.preventDefault();
    if (name === "street_number") {
      if (event.target.value == 0)
        this.setState({ hasStreet: true, googleCantFindStreetNumber: false });
      else this.setState({ googleCantFindStreetNumber: false });
    }
    this.setState({
      [name]: event.target.value
    });
  };

  handleClose = event => {
    event.preventDefault();
    if (this.state.street_number == 0) {
      this.searchInput.value = `${this.state.street_number}, ${this.searchInput.value}`;
      this.setState({
        hasStreet: true,
        googleCantFindStreetNumber: false,
        search: "",
        value: this.searchInput.value
      });
      this.handleOpen(false);
      this.props.onSuccessLocation(this.state);
    } else {
      const address = this.state.street_number + ` ${this.searchInput.value}`;
      const geocoder = new this.props.mapApi.Geocoder();

      geocoder.geocode({ address }, (results, status) => {
        if (status === this.props.mapApi.GeocoderStatus.OK) {
          const { map, addplace } = this.props;
          const { 0: place } = results;
          if (!place.geometry) return;
          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
          }

          this.handleSelectSuggest(place);

          addplace(place);

          this.searchInput.value = place.formatted_address;
          this.searchInput.blur();
        } else {
          console.error("Geocode error: " + status);
        }
      });
    }
  };

  handleOpen = (open = true) => {
    this.setState({ open, street_number: "" });
  };

  onPlacesChanged = ({ map, addplace } = this.props) => {
    /*
    const selected = this.searchBox.getPlaces();
    const { 0: place } = selected;
    */

    const place = this.searchBox.getPlace();
    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    this.handleSelectSuggest(place);

    addplace(place);
    this.searchInput.blur();
  };

  clearSearchBox() {
    this.searchInput.value = "";
    this.setState({
      value: "",
      hasStreet: false,
      street_number: ""
    });
  }

  render() {
    const { googleCantFindStreetNumber } = this.state;
    const { errors, touched, isSubmitting, t } = this.props;
    return (
      <Wrapper>
        <Form.Control
          s={12}
          icon="filter_3"
          placeholder={t("location.street-placeholder")}
          ref={ref => {
            this.searchInput = ref;
          }}
          onFocus={this.clearSearchBox}
          isInvalid={
            !!errors.streetName ||
            (errors.streetOptions &&
              !!errors.streetOptions?.x &&
              !!errors.streetOptions?.y)
          }
          isValid={
            touched.streetName &&
            !errors.streetName &&
            (errors.streetOptions &&
              !errors.streetOptions?.x &&
              !errors.streetOptions?.y)
          }
          disabled={isSubmitting}
        />
        <Form.Control.Feedback type="invalid">
          {errors.streetName}
          {!errors.streetName && errors.streetOptions?.x}
        </Form.Control.Feedback>

        <LocationNumberConfirm
          {...this.props}
          googleCantFindStreetNumber={googleCantFindStreetNumber}
          street_number={this.state.street_number}
          open={this.state.open}
          handleChange={this.handleChange("street_number")}
          handleClose={this.handleClose}
        />
      </Wrapper>
    );
  }
}

export default SearchBox;
