import React from 'react';
import './CitiesPage.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

import { HolderImage } from './HolderImage';
export const SectionSuccessCases = props => {
    const { t } = useTranslation();

    return (
        <div id="section-success-cases">
            <Container>
                <h3 className="card-titulo">{t('cities.title7')}</h3>
                <Row>
                    <Col md={3} xs={12}>
                        <h4 className="slider__caption">{t('cities.title7-t1')}</h4>
                        <Carousel>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t1-p1')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t1-p2')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t1-p3')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t1-p4')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t1-p5')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t1-p6')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t1-p7')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    <Col md={3} xs={12}>
                        <h4 className="slider__caption">{t('cities.title7-t2')}</h4>
                        <Carousel>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t2-p1')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t2-p2')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t2-p3')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    <Col md={3} xs={12}>
                        <h4 className="slider__caption">{t('cities.title7-t3')}</h4>
                        <Carousel>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t3-p1')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t3-p2')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t3-p3')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    
                    
                    <Col md={3} xs={12}>
                        <h4 className="slider__caption">{t('cities.title7-t4')}</h4>
                        <Carousel>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t4-p1')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t4-p2')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t4-p3')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
                <Row>
                <Col md={3} xs={12}>
                        <h4 className="slider__caption">{t('cities.title7-t5')}</h4>
                        <Carousel>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t5-p1')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t5-p2')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t5-p3')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    <Col md={3} xs={12}>
                        <h4 className="slider__caption">{t('cities.title7-t6')}</h4>
                        <Carousel>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t6-p1')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t6-p2')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t6-p3')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    
                    
                    <Col md={3} xs={12}>
                        <h4 className="slider__caption">{t('cities.title7-t7')}</h4>
                        <Carousel>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t7-p1')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t7-p2')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t7-p3')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    <Col md={3} xs={12}>
                        <h4 className="slider__caption">{t('cities.title7-t8')}</h4>
                        <Carousel>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t8-p1')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t8-p2')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                            <Carousel.Item>
                                <HolderImage />
                                <Carousel.Caption>
                                    <p className="slider__txt">{t('cities.title7-t8-p3')}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default SectionSuccessCases;