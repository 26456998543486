import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import './ClickDigitalPage.css';

class ClickDigitalPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {
  
    return (

        <div className="boxContact">
            <div className='digitalizadores-box-banner'>
              <img className='digitalizador-img' src="https://gecorcloud.com/images/Logo-digitalizadores.png" alt="logo-digitalizadores"></img>
            </div>
            <div className='digitalizadores-box'>
              <div className='digitalizadores-title-1'>
                <p className='title-fincas'>GECOR-FINCAS</p>
                <hr></hr>
                <h2>CATEGORÍAS DE LA SOLUCIÓN:</h2>
                <h3><b>GESTIÓN DE PROCESOS/BUSINESS INTELLIGENCE Y ANALÍTICA</b></h3>
              </div>
              <div className='digitalizadores-content'>
                <Container>
                <Row>
                  <Col className="digitalizadores-card" sm={6}>
                    <Card className='digitalizadores-card-2' style={{ width: '30rem' }}>
                      <Card.Body>
                        <Card.Text>
                        <img className='logo-fincas-img' src="https://gecorcloud.com/images/logo-fincas-v2.png" alt="logo-digitalizadores"></img>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col id="card-text-categoria" className="digitalizadores-card" sm={6}>
                    <Card className='digitalizadores-card-1' style={{ width: '30rem' }}>
                      <Card.Body>
                        <Card.Text>
                          GECOR-FINCAS es un software que permite la digitalización y gestión de todos los procesos y tareas relacionadas con las INCIDENCIAS de las instalaciones de las comunidades de propietarios de forma transversal, integrando a administradores de fincas, proveedores de servicios de las comunidades y propietarios.
                        </Card.Text>
                        <Card.Text>
                          Supone una plataforma de business intelligence y analítica de datos en tanto que permite la explotación automatizada de toda la información generada en el proceso de gestión del servicio.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                <div className='digitalizadores-title-1'>
                  <h2>OTRAS CARACTERÍSTICAS</h2>
                </div>
                <Row>
                  <Col className="digitalizadores-card" sm={4}>
                    <Card className='digitalizadores-card-1' style={{ width: '30rem' }}>
                      <Card.Body>
                        <Card.Header>GESTIÓN DE TAREAS</Card.Header>
                        <Card.Text>
                          GECOR-FINCAS es una plataforma en la nube que facilita de una manera muy sencilla y eficaz el alta, gestión y análisis de todas las incidencias y tareas relacionadas con la actividad de una comunidad de propietarios.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col className="digitalizadores-card" sm={4}>
                    <Card className='digitalizadores-card-1' style={{ width: '30rem' }}>
                      <Card.Body>
                        <Card.Header>OMNICANAL</Card.Header>
                        <Card.Text>
                          Cualquiera de los usuarios (administradores, proveedores y propietarios) puede gestionar las incidencias en cualquier momento y cualquier lugar mediante la app y web de la plataforma. 
                        </Card.Text>
                        <Card.Text>
                          Igualmente, entre otras funcionalidades, permite adjuntar fotos y documentos, incluir comentarios, detallar materiales usados, facturas, tiempos de resolución, personal o empresas que intervienen, etc.
                        </Card.Text>
                      </Card.Body>
                    </Card>  
                  </Col>
                  <Col className="digitalizadores-card" sm={4}>
                    <Card className='digitalizadores-card-1' style={{ width: '30rem' }}>
                      <Card.Body>
                      <Card.Header>BUSINESS INTELLIGENCE</Card.Header>
                        <Card.Text>
                          Con la digitalización de toda la información contenida en GECOR-FINCAS, además se consigue realizar las consultas y análisis de datos, en tiempo real, indispensable para cualquier empresa independientemente de su tamaño.
                        </Card.Text>
                        <Card.Text>
                          Permite explotación de datos automatizada para facilitar la toma de decisión.
                        </Card.Text>
                      </Card.Body>
                    </Card>   
                  </Col>
                </Row>
                </Container>
                <div className='digitalizadores-title-1'>
                  <h2>RANGO DE PRECIOS (*Para ambas categorías de solución)</h2>
                  <hr></hr>
                  <h2>ADMINISTRADORES DE FINCAS</h2>
                  <h3>(Instalación, configuración y subscripción un año)</h3>
                  <br></br>
                  <div className='digitalizadores-table'>
                    <Table striped bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th>Rango de precios</th>
                          <th>Nº Usuarios</th>
                          <th>Nº Empleados</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Desde 200€ hasta 500€</td>
                          <td>1 Usuario</td>
                          <td>3 Empleados</td>
                        </tr>
                        <tr>
                          <td>Desde 900€ hasta 1.500€</td>
                          <td>Hasta 3 Usuarios</td>
                          <td>Desde 3 hasta 9 empleados</td>
                        </tr>
                        <tr>
                          <td>Desde 3.000€ hasta 5.000€</td>
                          <td>Hasta 10 Usuarios</td>
                          <td>Desde 10 Hasta 50 empleados</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <hr></hr>
                  <h2>PROVEEDORES DE SERVICIOS A COMUNIDADES</h2>
                  <h3>(Instalación, configuración y subscripción de ocho meses)</h3>
                  <br></br>
                  <div className='digitalizadores-table' >
                    <Table striped bordered hover variant="dark">
                      <thead>
                        <tr>
                          <th>Rango de precios</th>
                          <th>Nº Usuarios</th>
                          <th>Nº Empleados</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Desde 200€ hasta 600€</td>
                          <td>1 Usuario</td>
                          <td>3 Empleados</td>
                        </tr>
                        <tr>
                          <td>Desde 600€ hasta 1.800€</td>
                          <td>Hasta 3 Usuarios</td>
                          <td>Desde 3 hasta 9 empleados</td>
                        </tr>
                        <tr>
                          <td>Desde 2.000€ hasta 6.000€</td>
                          <td>Hasta 10 Usuarios</td>
                          <td>Desde 10 Hasta 50 empleados</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <hr></hr>
                  <p>¿Desea mas información?</p>
                  <a href= "https://www.gecorfincas.com/">
                    <Button variant="secondary">Ir a Gecor Fincas</Button>
                  </a>
                </div>
              </div>
            </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedClickDigitalPage = connect(mapStateToProps)(ClickDigitalPage);
export { connectedClickDigitalPage as ClickDigitalPage };