import React from 'react';
import './PrivacyPolicyAppPage.css';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


class PrivacyPolicyAppPage extends React.Component {
    render() {
        return (
            <Container id="declaracion-acc-container" className="text-justify float-center">
               <h1 style={{paddingTop: '3pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Política de Privacidad</h1>
               <p style={{paddingTop: '8pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}><a href="http://www.gecorweb.com/" className="s1" target="_blank">Este texto legal le da detalles de cómo recopilamos y procesamos sus datos personales a través del uso de nuestro sitio web </a>http://www.gecorweb.com, incluyendo cualquier información que pueda proporcionarnos a través del sitio cuando solicita información o proporciona sus datos de contacto a través del formulario habilitado para tal fin.</p>
               <p style={{paddingTop: '8pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Al proporcionarnos los datos, le informamos que nuestros servicios no son posibles para aquellas personas que la normativa les impide prestar consentimiento, por lo que cuando nos remite los formularios nos garantiza que tiene la capacidad suficiente para otorgar consentimiento.</p>
               <p style={{paddingTop: '8pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>A continuación le informamos sobre la política de protección de datos de: <b>Gecor System S.L.U</b></p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <ol id="l1">
                  <li data-list-text="1.">
                     <h1 style={{paddingLeft: '22pt',textIndent: '-11pt',textAlign: 'left'}}>Responsable del tratamiento.</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Datos de contacto del responsable:</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Gecor System S.L.U, <a href="mailto:info@gecorweb.com" className="s1" target="_blank">con CIF: B93218287 y domicilio en: Calle Alameda Principal nº 21, Planta 6, Oficina 603, 29001 Málaga y teléfono: 952028423. Email: info@gecorweb.com</a></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Inscrita en el Registro Mercantil de Málaga, el día 26 de Diciembre de 2012, al Tomo 5079, Folio 65, Hoja 116090.</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Gecor System S.L<span className="p">, es el responsable de sus datos. (En adelante nosotros o nuestro).</span></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                  </li>
                  <li data-list-text="2.">
                     <h1 style={{paddingLeft: '22pt',textIndent: '-12pt',textAlign: 'left'}}>¿Qué datos recopilamos?</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>El Reglamento General de Protección de Datos, define los <b>datos personales </b>como toda información sobre una persona física identificada o identificable, es decir cualquier información capaz de identificar a una persona. Esto no incluiría los datos anónimos, ni porcentuales.</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '106%',textAlign: 'justify'}}>Los datos de carácter personal que se pudieran recabar directamente del interesado, serán tratados de forma confidencial y quedarán incorporados a las correspondientes actividades del tratamiento, titularidad de <b>Gecor System S.L.U</b></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>En nuestra Web podemos procesar ciertos tipos de datos personales, que podrán incluir:</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <ul id="l2">
                        <li data-list-text="">
                           <p style={{paddingLeft: '46pt',textIndent: '-18pt',textAlign: 'left'}}>Datos de identidad: nombre.</p>
                        </li>
                        <li data-list-text="">
                           <p style={{paddingTop: '2pt',paddingLeft: '46pt',textIndent: '-18pt',textAlign: 'left'}}>Datos de contacto: email y teléfono.</p>
                        </li>
                        <li data-list-text="">
                           <p style={{paddingTop: '2pt',paddingLeft: '46pt',textIndent: '-18pt',textAlign: 'left'}}>Ubicación (para crear, mostrar y gestionar las incidencias)</p>
                        </li>
                        <li data-list-text="">
                           <p style={{paddingLeft: '46pt',textIndent: '-18pt',textAlign: 'left'}}>Perfil de usuario: nombre, apellidos, correo electrónico, móvil, dirección, NIF. Contraseña</p>
                           <p style={{paddingTop: '3pt',paddingLeft: '10pt',textIndent: '0pt',lineHeight: '106%',textAlign: 'justify'}}>No recopilamos ningún dato relativo a categorías especiales de datos personales (aquellos que desvelen su origen étnico o racial, las opiniones políticas, las convicciones religiosas o filosóficas, la afiliación sindical e información sobre su salud, datos genéticos o biométricos).</p>
                           <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                           <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '106%',textAlign: 'justify'}}>En caso de que se le solicite recopilar datos personales por ley o según los términos de contratación entre nosotros y se niegue a facilitárnoslos, es posible que no podamos realizar dicho contrato o prestar el servicio, debiendo comunicárnoslo con antelación.</p>
                           <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                        </li>
                     </ul>
                  </li>
                  <li data-list-text="3.">
                     <h1 style={{paddingLeft: '22pt',textIndent: '-12pt',textAlign: 'left'}}>¿Cómo recopilamos sus datos personales?</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Los medios que usamos para recopilar datos personales son:</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <ul id="l3">
                        <li data-list-text="">
                           <p style={{paddingLeft: '46pt',textIndent: '-18pt',lineHeight: '112%',textAlign: 'justify'}}>A través del formulario de registro en nuestra aplicación, o en la actualización de su perfil de usuario para la comunicación de incidencias<span className="p">.</span></p>
                        </li>
                        <li data-list-text="">
                           <p style={{paddingLeft: '46pt',textIndent: '-18pt',lineHeight: '112%',textAlign: 'justify'}}>A través de la tecnología o interacciones automatizadas<a href="https://gecorweb.com/cookies-policy" className="s1" target="_blank">: en nuestro sitio podemos recopilar automáticamente datos técnicos sobre su equipo, acciones de navegación y patrones de uso. Estos datos son recopilados a través de cookies o tecnologías similares. Si quiere ampliar la información, puede consultar nuestra política de cookies </a><span style={{ color: '#00F', fontstyle: 'normal', fontWeight: 'normal', textDecoration: 'underline', fontSize: '12pt'}}>aquí</span><span className="p">.</span></p>
                        </li>
                        <li data-list-text="">
                           <p style={{paddingLeft: '46pt',textIndent: '-18pt',textAlign: 'justify'}}>A través de terceros:</p>
                           <ul id="l4">
                              <li data-list-text="o">
                                 <p style={{paddingTop: '1pt',paddingLeft: '82pt',textIndent: '-18pt',textAlign: 'justify'}}>Google: <span className="p">datos analíticos o datos de búsqueda. Fuera de la Unión Europea.</span></p>
                                 <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                              </li>
                           </ul>
                        </li>
                     </ul>
                  </li>
                  <li data-list-text="4.">
                     <h1 style={{paddingLeft: '22pt',textIndent: '-12pt',textAlign: 'left'}}>Finalidad y legitimidad para el uso de sus datos.</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Los usos más comunes de sus datos personales son:</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <ul id="l5">
                        <li data-list-text="-">
                           <p style={{paddingLeft: '46pt',textIndent: '-18pt',lineHeight: '113%',textAlign: 'left'}}>Para la gestión de incidencias y comunicación posterior al Ayuntamiento seleccionado (Responsable del Tratamiento)</p>
                        </li>
                        <li data-list-text="-">
                           <p style={{paddingLeft: '46pt',textIndent: '-18pt',textAlign: 'left'}}>Cuando preste su consentimiento en el tratamiento de sus datos.</p>
                        </li>
                        <li data-list-text="-">
                           <p style={{paddingTop: '2pt',paddingLeft: '46pt',textIndent: '-18pt',textAlign: 'left'}}>Cuando los necesitemos para dar cumplimiento a una obligación legal o regulatoria.</p>
                        </li>
                        <li data-list-text="-">
                           <p style={{paddingTop: '3pt',paddingLeft: '46pt',textIndent: '-18pt',textAlign: 'left'}}>Cuando sea necesario para nuestro interés legítimo o de un tercero.</p>
                           <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                           <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '106%',textAlign: 'justify'}}><a href="mailto:info@gecorweb.com" className="s1" target="_blank">El Usuario podrá revocar el consentimiento prestado en cualquier momento enviando un correo a </a>info@gecorweb.com o consultando el apartado de ejercicio de derechos más adelante.</p>
                           <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                           <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '106%',textAlign: 'justify'}}><a href="mailto:info@gecorweb.com" className="s1" target="_blank">A continuación adjuntamos una tabla en la que pueden consultar las formas en las que vamos a utilizar sus datos personales y la legitimidad para su uso, además de saber qué tipo de datos personales vamos a tratar. Podemos procesar algún dato personal por algún motivo legal adicional, por lo que si necesita detalles al respecto puede enviar un correo electrónico a info@gecorweb.com</a></p>
                           <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                           <table style={{borderCollapse:'collapse',marginLeft:'10.85pt'}} cellspacing="0">
                              <tr style={{height:'51pt'}}>
                                 <td style={{width:'167pt',borderBottomStyle:'solid',borderBottomWidth:'2pt',borderBottomColor:'#E3E3E3'}}>
                                    <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                                    <p className="s3" style={{textIndent: '0pt',textAlign: 'left'}}>Finalidad</p>
                                 </td>
                                 <td style={{width:'125pt',borderBottomStyle:'solid',borderBottomWidth:'2pt',borderBottomColor:'#E3E3E3'}}>
                                    <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                                    <p className="s3" style={{paddingLeft: '11pt',textIndent: '0pt',textAlign: 'left'}}>Tipo de datos</p>
                                 </td>
                                 <td style={{width:'134pt',borderBottomStyle:'solid',borderBottomWidth:'2pt',borderBottomColor:'#E3E3E3'}}>
                                    <p className="s3" style={{paddingLeft: '34pt',textIndent: '0pt',lineHeight: '13pt',textAlign: 'left'}}>Legitimidad</p>
                                    <p className="s3" style={{paddingLeft: '34pt',textIndent: '0pt',textAlign: 'left'}}>para    su</p>
                                    <p className="s3" style={{paddingTop: '1pt',paddingLeft: '34pt',textIndent: '0pt',textAlign: 'left'}}>tratamiento</p>
                                 </td>
                              </tr>
                              <tr style={{height:'55pt'}}>
                                 <td style={{width:'167pt',borderTopStyle:'solid',borderTopWidth:'2pt',borderTopColor:'#E3E3E3'}}>
                                    <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                                    <p className="s4" style={{paddingTop: '9pt',paddingRight: '6pt',textIndent: '0pt',textAlign: 'left'}}>Para solicitar información a través del formulario de contacto</p>
                                 </td>
                                 <td style={{width:'125pt',borderTopStyle:'solid',borderTopWidth:'2pt',borderTopColor:'#E3E3E3'}}/>
                                 <td style={{width:'134pt',borderTopStyle:'solid',borderTopWidth:'2pt',borderTopColor:'#E3E3E3'}}>
                                    <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                                    <p className="s4" style={{paddingTop: '9pt',paddingLeft: '34pt',paddingRight: '23pt',textIndent: '0pt',textAlign: 'left'}}>Consentimiento del interesado</p>
                                 </td>
                              </tr>
                           </table>
                           <ul id="l6">
                              <li data-list-text="-">
                                 <p className="s4" style={{paddingTop: '6pt',paddingLeft: '47pt',textIndent: '-18pt',textAlign: 'left'}}>Nombre</p>
                              </li>
                              <li data-list-text="-">
                                 <p className="s4" style={{paddingTop: '2pt',paddingLeft: '47pt',textIndent: '-18pt',textAlign: 'left'}}>Email</p>
                              </li>
                              <li data-list-text="-">
                                 <p className="s4" style={{paddingTop: '2pt',paddingLeft: '47pt',textIndent: '-18pt',lineHeight: '13pt',textAlign: 'left'}}>Teléfono</p>
                              </li>
                           </ul>
                           <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                           <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '1pt',textAlign: 'left'}}/>
                           <p className="s5" style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '12pt',textAlign: 'justify'}}>Para actualizar el perfil de usuario               Consentimiento del interesado</p>
                           <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                           <ul id="l7">
                              <li data-list-text="-">
                                 <p className="s5" style={{paddingLeft: '216pt',textIndent: '-6pt',textAlign: 'left'}}>Nombre</p>
                              </li>
                              <li data-list-text="-">
                                 <p className="s5" style={{paddingLeft: '216pt',textIndent: '-6pt',textAlign: 'left'}}>Apellidos</p>
                              </li>
                              <li data-list-text="-">
                                 <p className="s5" style={{paddingLeft: '216pt',textIndent: '-6pt',textAlign: 'left'}}>Correo Electrónico</p>
                              </li>
                              <li data-list-text="-">
                                 <p className="s5" style={{paddingLeft: '213pt',textIndent: '-6pt',textAlign: 'left'}}>Móvil</p>
                              </li>
                              <li data-list-text="-">
                                 <p className="s5" style={{paddingLeft: '213pt',textIndent: '-6pt',textAlign: 'left'}}>Dirección</p>
                              </li>
                              <li data-list-text="-">
                                 <p className="s5" style={{paddingLeft: '213pt',textIndent: '-6pt',textAlign: 'left'}}>NIF</p>
                              </li>
                              <li data-list-text="-">
                                 <p className="s5" style={{paddingLeft: '213pt',textIndent: '-6pt',textAlign: 'left'}}>Contraseña</p>
                              </li>
                           </ul>
                        </li>
                     </ul>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <table style={{borderCollapse:'collapse',marginLeft:'5.35pt'}} cellspacing="0">
                        <tr style={{height:'54pt'}}>
                           <td style={{width:'151pt'}}>
                              <p className="s6" style={{paddingLeft: '2pt',textIndent: '0pt',lineHeight: '12pt',textAlign: 'left'}}>Comunicación de incidencias</p>
                           </td>
                           <td style={{width:'163pt'}}>
                              <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                           </td>
                           <td style={{width:'106pt'}}>
                              <p className="s6" style={{paddingLeft: '16pt',textIndent: '0pt',textAlign: 'left'}}>Consentimiento del interesado</p>
                           </td>
                        </tr>
                     </table>
                     <ul id="l8">
                        <li data-list-text="-">
                           <p className="s6" style={{paddingLeft: '26pt',textIndent: '-6pt',textAlign: 'left'}}>Datos del perfil</p>
                        </li>
                        <li data-list-text="-">
                           <p className="s6" style={{paddingLeft: '26pt',textIndent: '-6pt',textAlign: 'left'}}>Ubicación</p>
                        </li>
                        <li data-list-text="-">
                           <p className="s6" style={{paddingLeft: '26pt',textIndent: '-6pt',lineHeight: '12pt',textAlign: 'left'}}>Selección de Ayuntamiento</p>
                        </li>
                     </ul>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Finalidad<span className="p">: sólo utilizaremos sus datos para los fines para los que los recopilamos, a menos que consideremos razonablemente que debemos usarlo para otro motivo, notificándoselo previamente para que esté informado del motivo legal de su procesamiento y siempre y cuando el fin sea compatible con el propósito original.</span></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                  </li>
                  <li data-list-text="5.">
                     <h1 style={{paddingLeft: '22pt',textIndent: '-12pt',textAlign: 'left'}}>¿Durante cuánto tiempo conservaremos sus datos?</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '106%',textAlign: 'justify'}}>Se conservarán durante el tiempo necesario para cumplir con la finalidad para la que se recabaron y para determinar las posibles responsabilidades que se pudieran derivar de dicha finalidad y del tratamiento de los datos. Será de aplicación lo dispuesto en las diferentes normativas respecto al plazo de conservación, en lo que resulte de aplicación al presente tratamiento.</p>
                  </li>
                  <li data-list-text="6.">
                     <h1 style={{paddingTop: '3pt',paddingLeft: '22pt',textIndent: '-12pt',textAlign: 'left'}}>Menores de edad.</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '106%',textAlign: 'justify'}}>Gecor System S.L <span className="p">no autoriza a los/las menores de 14 años a facilitar sus datos personales a través de los medios habilitados en este sitio web (cumplimentación de los formularios web para la solicitud de servicios, de contacto o mediante el envío de correos electrónicos). Por tanto, las personas que faciliten datos personales utilizando dichos medios manifiestan formalmente ser mayores de 14 años quedando </span>Gecor System S.L.U <span className="p">eximido de cualquier responsabilidad por el incumplimiento de este requisito.</span></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '106%',textAlign: 'justify'}}>Si su hijo/a de edad menor al límite de edad establecido, ha proporcionado información personal a <b>Gecor System S.L.U</b>, póngase en contacto para poder solicitar el ejercicio de sus derechos aplicables.</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '111%',textAlign: 'left'}}>En aquellos casos en los que los servicios ofrecidos por <b>Gecor System S.L </b>estén destinados a menores de 14 años se habilitarán los medios para recabar la autorización de los padres o tutores legales del/de la menor</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                  </li>
                  <li data-list-text="7.">
                     <h1 style={{paddingLeft: '22pt',textIndent: '-12pt',textAlign: 'left'}}>Ejercicio de los Derechos de Protección de Datos:</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>¿Cómo ejercer dichos derechos? <span className="p">Los usuarios podrán dirigir una comunicación al domicilio social de  </span>Gecor System S.L,U  <a href="mailto:info@gecorweb.com" className="s1" target="_blank">o  dirección de correo  electrónico </a><span className="p">info@gecorweb.com, incluyendo en ambos casos fotocopia de su D.N.I u otro documento de identificación similar, para solicitar el ejercicio de los siguientes derechos:</span></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <ul id="l9">
                        <li data-list-text="-">
                           <p style={{paddingLeft: '46pt',textIndent: '-18pt',lineHeight: '113%',textAlign: 'justify'}}><u>Acceso</u> <span className="p">a sus datos personales: podrá preguntar a </span>Gecor System S.L.U <span className="p">si está usando sus datos personales.</span></p>
                        </li>
                        <li data-list-text="-">
                           <p style={{paddingLeft: '46pt',textIndent: '-18pt',lineHeight: '13pt',textAlign: 'justify'}}>A solicitar su <u><b>rectificación</b></u>, si no fuesen correctos, o a ejercer el <u><b>derecho al olvido</b></u></p>
                           <p style={{paddingTop: '2pt',paddingLeft: '46pt',textIndent: '0pt',textAlign: 'justify'}}>con respecto a los mismos.</p>
                        </li>
                        <li data-list-text="-">
                           <p style={{paddingTop: '1pt',paddingLeft: '46pt',textIndent: '-18pt',textAlign: 'justify'}}>A solicitar la <u><b>limitación del tratamiento</b></u>, en este caso, solo serán conservados por</p>
                           <p style={{paddingTop: '1pt',paddingLeft: '46pt',textIndent: '0pt',textAlign: 'justify'}}>Gecor System S.L.U <span className="p">para el ejercicio o defensa de reclamaciones</span></p>
                        </li>
                        <li data-list-text="-">
                           <p style={{paddingTop: '2pt',paddingLeft: '46pt',textIndent: '-18pt',lineHeight: '113%',textAlign: 'justify'}}>A <u><b>oponerse</b></u><b> </b>a su tratamiento: <b>Gecor System S.L </b>dejará tratar los datos en la forma que nos indiques, salvo que por motivos legítimos o para el ejercicio o defensa de posibles reclamaciones, estos deban seguir tratándose.</p>
                        </li>
                        <li data-list-text="-">
                           <p style={{paddingLeft: '46pt',textIndent: '-18pt',lineHeight: '113%',textAlign: 'justify'}}>A la <u><b>portabilidad</b></u><b> </b>de los datos: en caso de que quieras que sus datos sean tratados por otra firma, <b>Gecor System S.L.U</b>, le facilitará la portabilidad de sus datos al nuevo responsable.</p>
                        </li>
                     </ul>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}><a href="https://www.aepd.es/reglamento/derechos/index.html" className="s1" target="_blank">Podrá utilizar los modelos puestos a su disposición por la Agencia Española de Protección de Datos, para ejercer sus anteriores derechos: </a><a href="https://www.aepd.es/reglamento/derechos/index.html" className="s2" target="_blank">Aquí</a></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingTop: '4pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Reclamar ante la AEPD: si considera que hay un problema con la forma en que  <b>Gecor System S.L.U </b>está tratando sus datos, podrá dirigir sus reclamaciones a la autoridad de</p>
                     <p style={{paddingTop: '3pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}><a href="https://sedeagpd.gob.es/sede-electronica-web/vistas/formNuevaReclamacion/solicitudReclamacion.jsf%3Bjsessionid%3D5085E2038B4210818633610065963C3E" className="s1" target="_blank">control correspondiente, siendo en España, la competente para ello: </a><a href="https://sedeagpd.gob.es/sede-electronica-web/vistas/formNuevaReclamacion/solicitudReclamacion.jsf%3Bjsessionid%3D5085E2038B4210818633610065963C3E" className="s2" target="_blank">Agencia Española de</a><a href="https://sedeagpd.gob.es/sede-electronica-web/vistas/formNuevaReclamacion/solicitudReclamacion.jsf%3Bjsessionid%3D5085E2038B4210818633610065963C3E" style={{ color: '#00F', fontStyle: 'normal', fontWeight: 'normal', textDecoration: 'none', fontSize: '12pt'}} target="_blank"> </a><a href="https://sedeagpd.gob.es/sede-electronica-web/vistas/formNuevaReclamacion/solicitudReclamacion.jsf%3Bjsessionid%3D5085E2038B4210818633610065963C3E" className="s2" target="_blank">Protección de Datos.</a></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingTop: '4pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Le solicitaremos información específica para ayudarnos a confirmar su identidad y garantizar su derecho a acceder a sus datos personales (o ejercer cualquier otro de los derechos mencionados anteriormente). Esto es una medida de seguridad para garantizar que los datos personales no se revelen a ninguna persona que no tenga derecho a recibirlos.</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '106%',textAlign: 'justify'}}>Todas las solicitudes las solventaremos en el plazo legal indicado de un mes. No obstante, puede llevarnos más de un mes si su solicitud es particularmente compleja. En este caso, le notificaremos y le mantendremos actualizado.</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                  </li>
                  <li data-list-text="8.">
                     <h1 style={{paddingLeft: '22pt',textIndent: '-12pt',textAlign: 'left'}}>Comunicación de datos: prestación de servicios.</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '106%',textAlign: 'justify'}}>Es posible que, en el desempeño de nuestro trabajo, necesitemos de la ayuda de terceros, que solo trataran los datos para prestar el servicio contratado, y con los que disponemos de las correspondientes medidas para garantizar sus derechos:</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <ul id="l10">
                        <li data-list-text="-">
                           <p style={{paddingLeft: '46pt',textIndent: '-18pt',lineHeight: '113%',textAlign: 'left'}}>Proveedores de servicios que brindan servicios de administración de sistemas y tecnología de la información.</p>
                        </li>
                        <li data-list-text="-">
                           <p style={{paddingLeft: '46pt',textIndent: '-18pt',lineHeight: '113%',textAlign: 'left'}}>Asesores profesionales que incluyen abogados, auditores y aseguradores que brindan servicios de consultoría bancarios, legales, de seguros y de contabilidad.</p>
                        </li>
                        <li data-list-text="-">
                           <p className="s5" style={{paddingLeft: '46pt',textIndent: '-18pt',textAlign: 'left'}}>Al prestar el servicio de gestión de incidencias, donde actuamos como encargado de tratamiento del Ayuntamiento al que comunica la misma, siendo el mismo, el responsable del tratamiento</p>
                        </li>
                     </ul>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Todos los encargados de tratamiento a quienes transferimos sus datos respetarán la seguridad de sus datos personales y los tratarán de acuerdo al RGPD.</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}><a href="mailto:info@gecorweb.com" className="s1" target="_blank">Solo permitimos que dichos encargados traten sus datos para fines determinados y de conformidad con nuestras instrucciones. No obstante puede solicitarnos, en cumplimiento de la transparencia un listado de quienes son estas empresas que nos prestan servicios, puede hacerlo al email: info@gecorweb.com</a></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                  </li>
                  <li data-list-text="9.">
                     <h1 style={{paddingLeft: '25pt',textIndent: '-12pt',textAlign: 'left'}}>Seguridad de Datos.</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '107%',textAlign: 'justify'}}>Hemos implementado las medidas de seguridad adecuadas para evitar que sus datos personales se pierdan accidentalmente, se usen o accedan de forma no autorizada, se modifiquen o divulguen. Además, limitamos el acceso a sus datos personales a aquellos empleados, agentes contratistas y otros terceros que tengan una necesidad comercial de conocer dichos datos. Solo procesarán sus datos personales según nuestras instrucciones y estarán sujetos a un deber de confidencialidad.</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Hemos implementado procedimientos para tratar cualquier sospecha de violación de sus datos personales y se lo notificaremos a usted y a la Autoridad de Control en caso de que</p>
                     <p style={{paddingTop: '3pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>ocurriera, tal como queda regulado en el RGPD en sus artículos 33 y 34, una brecha de seguridad.</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                  </li>
                  <li data-list-text="10.">
                     <h1 style={{paddingLeft: '24pt',textIndent: '-13pt',textAlign: 'left'}}>Derechos de Protección de Datos</h1>
                  </li>
               </ol>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>INFORMACIÓN Y FORMULARIOS EN EL EJERCICIO DE LOS DERECHOS DE PROTECCIÓN DE DATOS</p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>La normativa de protección de datos recoge una serie de derechos a los que pueden acogerse todas las personas. El Gecor System S.L, para facilitar su ejercicio, le informa de los diferentes derechos, su contenido, procedimientos, plazos y le aporta los modelos que podrá utilizar en su ejercicio.</p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Es común a todos los derechos:</p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <p className="s9" style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>⦁ <span className="p">Su ejercicio es gratuito, salvo si las solicitudes son manifiestamente infundadas o excesivas</span></p>
               <p className="s9" style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>⦁ <span className="p">Las solicitudes deben responderse en el plazo de un mes, aunque, si se tiene en cuenta la complejidad y número de solicitudes, se puede prorrogar el plazo otros dos meses más</span></p>
               <p className="s9" style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>⦁ <span className="p">En todas nuestras cláusulas y Registros de Actividades recogemos el medio para ejercer los derechos</span></p>
               <p className="s9" style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>⦁ <a href="https://gecorcloud.com/pdf/Modelo-de-Representacion-Voluntaria.doc" className="s1" target="_blank">Puedes ejercer los derechos directamente o por medio de tu representante legal o voluntario . </a><a href="https://gecorcloud.com/pdf/Modelo-de-Representacion-Voluntaria.doc" className="s2" target="_blank">Haga click aquí para descargar el modelo de representación voluntaria.</a></p>
               <p className="s9" style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>⦁ <span className="p">Cabe la posibilidad de que el encargado sea quien atienda tu solicitud por cuenta del responsable, si ambos lo han establecido en el contrato o acto jurídico que les vincule</span></p>
               <p className="s9" style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>⦁ <span className="p">La edad como norma general, para el ejercicio de los derechos son los catorce años, aunque en cuestiones como la salud serán los dieciséis años.</span></p>
               <p className="s9" style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>⦁ <span className="p">Respecto a las personas fallecidas, aquéllas vinculadas al fallecido por razones familiares o de hecho, así como sus herederos podrán ejercer sus derechos de acceso, rectifica ción y supresión, salvo que el fallecido lo hubiera prohibido expresamente o una ley lo establezca.</span></p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>¿Cómo ejerzo los derechos de protección de datos?</p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <ul id="l11">
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Petición por escrito al responsable del tratamiento, Gecor System S.L (los formularios los encontrará a continuación) por cualquiera de los medios admitidos en derecho.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Identificación (fotocopia del DNI o pasaporte u otro documento válido para identificar a la persona)</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Si actúa representado, copia de la representación e identificación del representante (fotocopia del DNI o pasaporte u otro documento válido para identificar a la persona)</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '19pt',textIndent: '-9pt',lineHeight: '13pt',textAlign: 'left'}}>Derecho que ejercita, y en la medida de lo posible concreción en su petición</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '19pt',textIndent: '-9pt',lineHeight: '14pt',textAlign: 'left'}}>Dirección a efectos de notificaciones, fecha y firma</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '19pt',textIndent:'-9pt',lineHeight: '14pt',textAlign: 'left'}}>Documentos (si es necesario) que justifiquen el ejercicio de derechos</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingTop: '4pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Contenido de los Derechos:</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '14pt',textAlign: 'justify'}}>Derecho de Acceso</p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Supone que cualquier persona tenga derecho a dirigirse al Gecor System S.L, para conocer en primer lugar si estamos tratando o no sus datos personales y, de hacerlo, a obtener la siguiente información:</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '19pt',textIndent: '-9pt',lineHeight: '13pt',textAlign: 'justify'}}>Una copia de sus datos personales que estamos tratando.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '19pt',textIndent: '-9pt',lineHeight: '14pt',textAlign: 'justify'}}>Los fines para los que estamos tratando sus datos.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Los destinatarios o las categorías de destinatarios a los que se comunicaron o serán comunicados los datos personales, en particular, los destinatarios en países terceros u organizaciones internacionales.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>El plazo previsto de conservación de los datos personales o, si no es posible, los criterios utilizados para determinar ese plazo.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>La existencia del derecho a solicitar la rectificación o supresión de sus datos personales, la limitación del tratamiento de sus datos personales u oponerse a ese tratamiento.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>El derecho a presentar una reclamación ante la Agencia Española de Protección de Datos o ante el Consejo de Transparencia y Protección de Datos de Andalucía (si la actividad esta sujeta a derecho administrativo).</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '19pt',textIndent: '-9pt',lineHeight: '13pt',textAlign: 'justify'}}>Cuando no se obtuvieron directamente del interesado, a identificar el origen.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Si existen decisiones automatizadas, incluida la elaboración de perfiles, información sobre la lógica aplicada, la importancia y las consecuencias de ese tratamiento.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Cuando se transfieran datos a un tercer país o a una organización internacional, será informado de las garantías adecuadas en las que se realizan las transferencias.</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>El derecho de acceso comprende facilitar una copia de los datos personales objeto del tratamiento, no obstante, si se solicitara más de una copia se podrá requerir un canon razonable basado en los costes administrativos. Este derecho a obtener copia, no puede afectar negativamente a los derechos y libertades de otros</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}><a href="https://gecorcloud.com/pdf/Modelo-Derecho-Acceso.pdf" className="a" target="_blank">Formulario Derecho </a><a href="https://gecorcloud.com/pdf/Modelo-Derecho-Acceso.pdf" target="_blank">de Acceso</a></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <h1 style={{paddingTop: '4pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Derecho de Rectificación</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Supone que cualquier persona de la que el Gecor System S.L realiza un tratamiento de datos, pueda obtener la rectificación de sus datos. En la petición que realice deberá indicar los datos que quiere que sean rectificados, debiendo acompañarse, cuando sea necesario, la documentación que justifique la inexactitud de los mismos o el carácter incompleto. La rectificación deberá realizarse sin dilación y en el plazo máximo de un mes.</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}><a href="https://gecorcloud.com/pdf/Modelo-derecho-Rectificacion.pdf" className="a" target="_blank">Formulario Derecho </a><a href="https://gecorcloud.com/pdf/Modelo-derecho-Rectificacion.pdf" target="_blank">de Rectificación</a></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <h1 style={{paddingTop: '4pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Derecho de Supresión (derecho al olvido)</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Cualquier persona de la que el Gecor System S.L realiza un tratamiento de datos, puede ejercer este derecho siempre que se den estas circunstancias:</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Si los datos personales ya no son necesarios en relación con los fines para los que fueron recogidos o se tratan de otro modo.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '109%',textAlign: 'left'}}>Si el tratamiento de los datos personales se ha basado en el consentimiento, y se retira el mismo, no existiendo otra base jurídica que lo legitime.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '19pt',textIndent: '-9pt',lineHeight: '13pt',textAlign: 'left'}}>Si se ha ejercitado el derecho de oposición y este se basó en la negativa a tratar los datos</p>
                     <p style={{paddingTop: '1pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>para mercadotecnia directa, o se basaba en la base jurídica de interés legítimo o cumplimiento de una misión de interés público.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '19pt',textIndent: '-9pt',textAlign: 'left'}}>Si el tratamiento de datos personales fue ilícito.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Si se deben suprimir los datos personales para cumplir una obligación legal establecida en el Derecho de la Unión o de los Estados miembros.</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Si los datos se obtuvieron en relación con la oferta de servicios de la sociedad de la información, recogidos en el Reglamento General de Protección de Datos.</p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Como todos los derechos no es ilimitado, de tal forma que este derecho encuentra sus siguientes límites: con el ejercicio de la libertad de expresión e información, para el cumplimiento de una obligación legal, para el cumplimiento de una misión realizada en interés público o en el ejercicio de poderes públicos conferidos al responsable, por razones de interés público, en el ámbito de la salud pública, con fines de archivo de interés público, fines de investigación científica o histórica o fines estadísticos, o para la formulación, el ejercicio o la defensa de reclamación.</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}><a href="https://gecorcloud.com/pdf/Modeloderecho-de-supresion.pdf" className="a" target="_blank">Formulario Derecho de Supresión (derecho al </a><a href="https://gecorcloud.com/pdf/Modeloderecho-de-supresion.pdf" target="_blank">olvido)</a></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <h1 style={{paddingTop: '4pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Derecho de Oposición</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Supone que aquellas personas de las que el Gecor System S.L trata datos personales, puedan oponerse   a    dicho    tratamiento,    en    dos    supuestos: <span className="s10">– </span>Cuando sean objeto de tratamiento basado en una misión de interés público o en el interés legítimo, incluido la elaboración de perfiles. Este derecho se estimará salvo que se acrediten motivos imperiosos que prevalezcan sobre los intereses, derechos y libertades del interesado, o  para  la  formulación,  el  ejercicio  o  la  defensa  de  reclamaciones. <span className="s10">– </span>Cuando el tratamiento tenga como finalidad la mercadotecnia directa, incluida también la elaboración de perfiles. Estimado este derecho, los datos personales dejarán de ser tratados para esta finalidad.</p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}><a href="https://gecorcloud.com/pdf/Modelo-derecho-de-oposicion.pdf" className="a" target="_blank">Formulario Derecho de </a><a href="https://gecorcloud.com/pdf/Modelo-derecho-de-oposicion.pdf" target="_blank">Oposición</a></p>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <h1 style={{paddingTop: '4pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Derecho a la Limitación del Tratamiento</h1>
                     <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '109%',textAlign: 'left'}}>Pueden ejercerlo aquellas personas de las que el Gecor System S.L realiza tratamientos, con dos modalidades:</p>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '19pt',textIndent: '-9pt',lineHeight: '13pt',textAlign: 'left'}}>Solicitar la suspensión del tratamiento de sus datos:</p>
                     <ol id="l12">
                        <li data-list-text="a)">
                           <p style={{paddingLeft: '22pt',textIndent: '-11pt',textAlign: 'left'}}>Cuando se haya impugnado la exactitud de los datos, durante el plazo en que se verifican.</p>
                        </li>
                        <li data-list-text="b)">
                           <p style={{paddingTop: '1pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Cuando se haya ejercido el derecho de oposición en base a interés legítimo o misión de interés público, mientras se verifican si procede el mismo o no.</p>
                        </li>
                     </ol>
                  </li>
                  <li data-list-text="–">
                     <p style={{paddingLeft: '19pt',textIndent: '-9pt',textAlign: 'left'}}>Solicitar la conservación de sus datos:</p>
                  </li>
               </ul>
               <ol id="l13">
                  <li data-list-text="a)">
                     <p style={{paddingLeft: '10pt',textIndent: '0pt',lineHeight: '109%',textAlign: 'left'}}>Cuando el tratamiento sea ilícito y te opongas a la supresión de los datos, solicitando la limitación.</p>
                  </li>
                  <li data-list-text="b)">
                     <p style={{paddingLeft: '23pt',textIndent: '-12pt',lineHeight: '13pt',textAlign: 'left'}}>Cuando los datos ya no son necesarios para el fin del tratamiento, pero el interesado los</p>
                  </li>
               </ol>
               <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>necesita para la formulación, el ejercicio o la defensa de reclamaciones.</p>
               <p style={{paddingTop: '1pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>El hecho de que el tratamiento de los datos personales esté limitado debe constar claramente en los sistemas de información del Gecor System S.L</p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}><a href="https://gecorcloud.com/pdf/Modelo-derecho-limitacion-tratamiento.pdf" className="a" target="_blank">Formulario Derecho a la Limitación del </a><a href="https://gecorcloud.com/pdf/Modelo-derecho-limitacion-tratamiento.pdf" target="_blank">Tratamiento</a></p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <p className="s7" style={{paddingTop: '4pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Derecho a la Portabilidad</p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <p style={{paddingTop: '4pt',paddingLeft: '10pt',textIndent: '0pt',lineHeight: '--106%',textAlign: 'justify'}}>Siempre que técnicamente sea posible, cualquier persona de la que el Gecor System S.L realice un tratamiento de datos, basado en el consentimiento o en el marco de la ejecución de un contrato, podrá solicitar, siempre que el tratamiento se realice por medi os automatizados, que se le remitan los datos a él o al nuevo responsable de los mismos, en un formato estructurado, de uso común, de lectura mecánica e interoperable. Este derecho no se aplica cuando el tratamiento es necesario para el cumplimiento de una misión de interés público o en el ejercicio de poderes públicos.</p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}><a href="https://gecorcloud.com/pdf/Modelo-derecho-portabilidad.pdf" className="a" target="_blank">Formulario Derecho a la </a><a href="https://gecorcloud.com/pdf/Modelo-derecho-portabilidad.pdf" target="_blank">Portabilidad</a></p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <p className="s7" style={{paddingTop: '10pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'left'}}>Derecho a no ser objeto de decisiones individuales automatizadas</p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <p style={{paddingTop: '4pt',paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}>Toda persona de la que trata datos el Gecor System S.L, tiene derecho a no ser objeto de una decisión basada únicamente en el tratamiento automatizado, incluida la elaboración de perfiles, que produzca efectos jurídicos en él o le afecte significativamente, salvo que esté recogido en la normativa que establecerán medidas de contrapeso para esta obligación. También podrá prestar su consentimiento para ello, o que sea necesario para la elaboración de un contrato, pero en estos dos supuestos, podrá solicitar la intervención humana, podrá expresar su punto de vista y podrá impugnar la decisión.</p>
               <p style={{textIndent: '0pt',textAlign: 'left'}}><br/></p>
               <p style={{paddingLeft: '10pt',textIndent: '0pt',textAlign: 'justify'}}><a href="https://gecorcloud.com/pdf/Modelo-decisiones-individuales-automatizadas.pdf" className="a" target="_blank">Formulario Derecho a no ser objeto de decisiones individuales </a><a href="https://gecorcloud.com/pdf/Modelo-decisiones-individuales-automatizadas.pdf" target="_blank">automatizadas</a></p>

            </Container>
        );
    }

}

export { PrivacyPolicyAppPage }