import { take, call, put, fork } from 'redux-saga/effects'
import { contestApi } from './../helpers'
import { contestConstants } from './../constants'

export function* newConcursoParticipacion({ name, phone, email, body }) {
    // We send an action that tells Redux we're sending a request
    yield put({ type: contestConstants.CONTEST_SENDING_REQUEST, sending: true })

    // We then try to register or log in the user, depending on the request
    try {
        let response = yield call(contestApi.newConcursoParticipacion, email, phone, `Nombre: ${name}. ${body}`)
        return response
    } catch (error) {
        // If we get an error we send Redux the appropiate action and return
        yield put({ type: contestConstants.CONTEST_SEND_FAILURE, error: error.message })

        return false
    } finally {
        // When done, we tell Redux we're not in the middle of a request any more
        yield put({ type: contestConstants.CONTEST_SENDING_REQUEST, sending: false })
    }
}

export function* newConcursoParticipacionFlow() {
    while (true) {
        const request = yield take(contestConstants.CONTEST_SEND_REQUEST)
        const { data } = request
        const wasSuccessful = yield call(newConcursoParticipacion, data)

        if (wasSuccessful) {
            yield put({ type: contestConstants.CONTEST_SEND_SUCCESS, sended: true })
            yield put({ type: contestConstants.CONTEST_FORM_CLEAR })
        } else {
            // throw error
            yield put({ type: contestConstants.CONTEST_SEND_FAILURE, sended: false })
        }
    }
}

export default function* () {
    yield fork(newConcursoParticipacionFlow)
}
