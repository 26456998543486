import { take, call, put, fork } from 'redux-saga/effects'
import { noticesApi } from '../helpers'
import { noticesConstants } from './../constants'


/**
 * Effect to handle logging out
 */
export function* getNotices() {
    // We tell Redux we're in the middle of a request
    // yield put({ type: noticesConstants.GETALL_REQUEST, loading: true })

    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const response = yield call(noticesApi.getNotices)
        yield put({ type: noticesConstants.NOTICES_GETALL_SUCCESS, notices: response })

        return response
    } catch (error) {
        yield put({ type: noticesConstants.NOTICES_GETALL_FAILURE, error: error.message })
        return false
    } finally {
        // yield put({ type: noticesConstants.GETALL_REQUEST, loading: false })
    }
}

/**
 * Effect to handle logging out
 */
export function* getLocalNotices() {
    // We tell Redux we're in the middle of a request
    // yield put({ type: noticesConstants.GETALL_REQUEST, loading: true })

    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const response = yield call(noticesApi.loadNotices)
        yield put({ type: noticesConstants.NOTICES_GETALL_SUCCESS, notices: response })

        return response
    } catch (error) {
        yield put({ type: noticesConstants.NOTICES_GETALL_FAILURE, error: error.message })
        return false
    } finally {
        // yield put({ type: noticesConstants.GETALL_REQUEST, loading: false })
    }
}

export function* getNoticeById(payload) {
    try {
        const { id } = payload;
        const response = yield call(noticesApi.getNoticeById, id)
        yield put({ type: noticesConstants.NOTICES_GETBYID_SUCCESS, notice: response })
        return response
    } catch (error) {
        yield put({ type: noticesConstants.NOTICES_GETBYID_FAILURE, error: error.message })
        return false
    }
}

export function* getNoticeByIdFlow() {
    while (true) {
        const payload = yield take(noticesConstants.NOTICES_GETBYID_REQUEST)
        yield call(getNoticeById, payload)
    }
}

export function* getNoticesFlow() {
    while (true) {
        yield take(noticesConstants.NOTICES_GETALL_REQUEST)
        yield call(getLocalNotices)
        yield call(getNotices)
        /*
        const notices = yield call(getLocalNotices)
        if (!notices) {
            yield call(getNotices)
        }
        */
    }
}

export default function* () {
    yield fork(getNoticesFlow)
    yield fork(getNoticeByIdFlow)
}