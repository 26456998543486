import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { forwardTo } from '../../helpers'
import './header.css';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faGraduationCap, faHandHolding, faInfoCircle, faPaw, faQuestionCircle, faSeedling } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

import { useTranslation } from 'react-i18next';

export const Header_411e = props => {
  const { entity } = props;
  const { t } = useTranslation();

  return (
    <div id="header">
      <Navbar className="menu whitemenu" sticky="top" expand="lg">
        <Navbar.Brand className="iconBrand_143" target="_blank" rel="noopener noreferrer" href="http://www.puertodelrosario.org/index.php/es"><img alt="Logo Puerto del Rosario" className="img-Header_411e" src={entity.Logo} /></Navbar.Brand>
        <Navbar.Toggle className="buttomToggle" aria-controls="basic-navbar-nav" ></Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
          </Nav>

          <Nav className="menu2" >

            <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faSeedling} />{t("nav.nav9")}</>} id="basic-nav-dropdown">

              <NavDropdown.Item onClick={() => forwardTo('/local-information-page-puertorosario')}><FontAwesomeIcon icon={faInfoCircle} />{t("nav.nav9-1c")}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => forwardTo('/enviromental-information-puerto-rosario')}><FontAwesomeIcon icon={faPaw} />{t("nav.nav9-2c")}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => forwardTo('/educacion-ambiental-puerto-rosario')}><FontAwesomeIcon icon={faGraduationCap} />{t("nav.nav9-3c")}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => forwardTo('/CitizenGuidePage-pr')}><FontAwesomeIcon icon={faHandHolding} />{t("nav.nav9-4c")}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => window.open('http://www.puertodelrosario.org/index.php/es/2013-07-02-12-47-05/salud-publica', '_blank')}>{t("nav.nav9-9")}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => window.open('http://www.puertodelrosario.org/index.php/es/2013-07-02-12-47-05/playas', '_blank')}>{t("nav.nav9-10")}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => window.open('http://www.puertodelrosario.org/index.php/es/2013-07-02-12-47-05/servicios-municipales', '_blank')}>{t("nav.nav9-11")}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => forwardTo('/local-information-page-puertorosario')}><a >{t("nav.nav9-12")}</a></NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => forwardTo('/contacto-medioambiente-pr')}><FontAwesomeIcon icon={faQuestionCircle} />{t("nav.nav9-5c")}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => window.open('https://gecorcloud.com/pdf/Glosario-ambiental-GECOR.pdf')}><a >{t("nav.nav9-6")}</a></NavDropdown.Item>
              <NavDropdown.Item onClick={() => window.open('https://gecorcloud.com/pdf/enlaces.pdf')}>{t("nav.nav9-7")}</NavDropdown.Item>
              <NavDropdown.Item onClick={() => window.open('https://www.cienciasambientales.com/es/noticias-ambientales/', '_blank')}>{t("nav.nav9-8")}</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link onClick={() => forwardTo('/incidencias-puerto-rosario')}><FontAwesomeIcon icon={faExclamation} />{t("nav.nav11")}</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default Header_411e;