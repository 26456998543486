import { take, call, put, fork } from 'redux-saga/effects'
import { incidentsApi } from '../helpers'
import { incidentsConstants } from './../constants'

/**
 * Effect to handle logging out
 */
export function* getMyIncidents() {
    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const response = yield call(incidentsApi.getMyIncidents)
        yield put({ type: incidentsConstants.GET_MY_SUCCESS, myIncidents: response })

        return response
    } catch (error) {
        yield put({ type: incidentsConstants.GET_MY_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

export function* getIncidentDetail(payload) {
    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const { id } = payload;
        const response = yield call(incidentsApi.getIncidentDetail, id)
        yield put({ type: incidentsConstants.GET_INCIDENT_DETAIL_SUCCESS, incidentDetail: response })

        return response
    } catch (error) {
        yield put({ type: incidentsConstants.GET_INCIDENT_DETAIL_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

export function* addIncident(data) {
    try {
        const { photos } = data
        const { photosForService, photosForCarousel } = yield call(savePhotoBase64, photos)
        data = { ...data, photos: photosForService, photosForCarousel }
        const response = yield call(incidentsApi.addIncident, data)
        const { entityName, AvisoID: incidentId } = response;
        data = { ...data, entityName, incidentId }

        yield put({ type: incidentsConstants.NEW_INCIDENT_SUCCESS, newIncident: data })

        return response
    } catch (error) {
        yield put({ type: incidentsConstants.NEW_INCIDENT_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

export function* savePhotoBase64(data) {
    try {
        let photosForService = []
        let photosForCarousel = []

        for (let photo in data) {
            const { src } = data[photo];
            const { rutaFoto } = yield call(incidentsApi.savePhotoBase64, src)
            photosForService = [...photosForService, { rutaFoto }]
            photosForCarousel = [...photosForCarousel, rutaFoto]
        }

        // yield put({ type: incidentsConstants.NEW_INCIDENT_SUCCESS, myIncidents: response })
        return { photosForService, photosForCarousel }
    } catch (error) {
        yield put({ type: incidentsConstants.NEW_INCIDENT_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

/**
 * Effect to handle logging out
 */
export function* getNearbyIncidents(latLng) {
    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const response = yield call(incidentsApi.getNearbyIncidents, latLng)
        yield put({ type: incidentsConstants.GET_NEARBY_SUCCESS, nearbyIncidents: response })

        return response
    } catch (error) {
        yield put({ type: incidentsConstants.GET_NEARBY_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

/**
 * Effect to handle logging out
 */
export function* getFavIncidents() {
    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const response = yield call(incidentsApi.getFavoritesIncidents)
        yield put({ type: incidentsConstants.GET_FAV_SUCCESS, favIncidents: response })

        return response
    } catch (error) {
        yield put({ type: incidentsConstants.GET_FAV_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

/**
 * Effect to handle logging out
 */
export function* rateIncident(payload) {
    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const { data } = payload
        const response = yield call(incidentsApi.rateIncident, data)
        yield put({ type: incidentsConstants.RATE_SUCCESS, rated: response })

        return response
    } catch (error) {
        yield put({ type: incidentsConstants.RATE_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

export function* followUnfollowIncident(payload) {
    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const { data } = payload;
        const response = yield call(incidentsApi.followUnfollowIncident, data)
        yield put({ type: incidentsConstants.FOLLOW_SUCCESS, followed: response, data })

        return response
    } catch (error) {
        yield put({ type: incidentsConstants.FOLLOW_FAILURE, error: error.message || error })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}
export function* getPhotosDetail(payload) {
    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const { id } = payload;
         const response = yield call(incidentsApi.getPhotosIncident, id)
        yield put({ type: incidentsConstants.GET_INCIDENT_PHOTO_DETAIL_SUCCESS, incidentPhotos: response })
     
        return response
    } catch (error) {
        yield put({ type: incidentsConstants.GET_INCIDENT_PHOTO_DETAIL_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}
export function* getHistory(payload) {
    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const { id } = payload;
        const response = yield call(incidentsApi.getHistoryIncident, id)
        yield put({ type: incidentsConstants.GET_HISTORY_SUCCESS, incidentHistories: response })

        return response
    } catch (error) {
        yield put({ type: incidentsConstants.GET_HISTORY_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

export function* getNearbyStreets(payload) {

    try {
        const { latLng } = payload;
        const response = yield call(incidentsApi.getNearbyStreets, latLng)
        yield put({ type: incidentsConstants.GET_NEARBYSTREETS_SUCCESS, nearbyStreets: response })

        return response
    } catch (error) {
        yield put({ type: incidentsConstants.GET_NEARBYSTREETS_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

export function* getComments(payload) {
    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const { id } = payload;
        const response = yield call(incidentsApi.getCommentsIncident, id)

        yield put({ type: incidentsConstants.GET_COMMENTS_SUCCESS, incidentComments: response })

        return response
    } catch (error) {
        yield put({ type: incidentsConstants.GET_COMMENTS_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

export function* addComment(payload) {
    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const { data } = payload;
        const { avisoID, message } = data;
        const response = yield call(incidentsApi.newCommentIncident, avisoID, message)
        const newComment = response[0]

        yield put({ type: incidentsConstants.NEW_COMMENTS_SUCCESS, newComment })

        return newComment
    } catch (error) {
        yield put({ type: incidentsConstants.NEW_COMMENTS_FAILURE, error: error.message })
        return false
    }
    /*
    finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
    */
}

// FLOWS //
export function* getMyIncidentsFlow() {
    while (true) {
        yield take(incidentsConstants.GET_MY_REQUEST)
        yield call(getMyIncidents)
    }
}


export function* getIncidentDetailFlow() {
    while (true) {
        const payload = yield take(incidentsConstants.GET_INCIDENT_DETAIL_REQUEST)
        yield call(getIncidentDetail, payload)
    }
}

export function* addIncidentFlow() {
    while (true) {
        const { data } = yield take(incidentsConstants.NEW_INCIDENT_REQUEST)
        yield call(addIncident, data)
    }
}

export function* getNearbyIncidentsFlow() {
    while (true) {
        const latLng = yield take(incidentsConstants.GET_NEARBY_REQUEST)
        yield call(getNearbyIncidents, latLng)
    }
}

export function* getFavIncidentsFlow() {
    while (true) {
        yield take(incidentsConstants.GET_FAV_REQUEST)
        yield call(getFavIncidents)
    }
}

export function* rateIncidentFlow() {
    while (true) {
        const payload = yield take(incidentsConstants.RATE_REQUEST)
        yield call(rateIncident, payload)
    }
}

export function* followUnfollowIncidentFlow() {
    while (true) {
        const payload = yield take(incidentsConstants.FOLLOW_REQUEST)
        yield call(followUnfollowIncident, payload)
    }
}

export function* getCommentsFlow() {
    while (true) {
        const payload = yield take(incidentsConstants.GET_COMMENTS_REQUEST)
        yield call(getComments, payload)
    }
}

export function* getHistoryFlow() {
    while (true) {
        const payload = yield take(incidentsConstants.GET_HISTORY_REQUEST)
        yield call(getHistory, payload)
    }
}

export function* getNearbyStreetsFlow() {
    while (true) {
        const payload = yield take(incidentsConstants.GET_NEARBYSTREETS_REQUEST)
        yield call(getNearbyStreets, payload)
    }
}

export function* addCommentFlow() {
    while (true) {
        const payload = yield take(incidentsConstants.NEW_COMMENTS_REQUEST)
        yield call(addComment, payload)
    }
}


export function* getPhotosDetailFlow() {
    while (true) {
        const payload = yield take(incidentsConstants.GET_INCIDENT_PHOTO_DETAIL_REQUEST);        
        yield call(getPhotosDetail, payload)
    }
}

export default function* () {
    yield fork(getMyIncidentsFlow)
    yield fork(getIncidentDetailFlow)
    yield fork(addIncidentFlow)
    yield fork(getNearbyIncidentsFlow)
    yield fork(getFavIncidentsFlow)
    yield fork(followUnfollowIncidentFlow)
    yield fork(rateIncidentFlow)
    yield fork(getCommentsFlow)
    yield fork(addCommentFlow)
    yield fork(getHistoryFlow)
    yield fork(getNearbyStreetsFlow)
    yield fork(getPhotosDetailFlow)
}