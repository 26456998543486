import React from 'react';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Recaptcha from 'react-recaptcha';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './EnviromentalContactPageCh.css';
import { DynamicBackground } from './../../../../components'
import { contactActions } from './../../../../actions';
import { isWebpSupported } from 'react-image-webp/dist/utils';

class EnviromentalContactPageChForm extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  constructor(props, context) {
    super(props, context);

    this.handleClose = this.handleClose.bind(this);

    this.onCheckBoxChange = this.onCheckBoxChange.bind(this);

    this.state = {
      show: false,
    };
  }

  handleClose() {
    this.props.dispatch(contactActions.clearState())
  }

  onCheckBoxChange() {
    const { setFieldValue, values } = this.props;
    const { terms } = values;
    setFieldValue("terms", !terms);
  }

  render() {
    const {
      touched,
      errors,
      // dirty,
      setFieldValue,
      // setSubmitting,
      handleChange,
      handleSubmit,
      isValid,
      values,
      sended,
      t,
    } = this.props;

    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/backgroundContact.webp" : "https://gecorcloud.com/images/backgroundContact.jpg"}>
        <Container>
          <div className="box-contact">
            <div className="box-contentCh">
              <div className="primer-texto">
                <h2> Envíenos su consulta </h2>
                <p> Le responderemos con la mayor brevedad posible </p>
              </div>
              <Col xs={12} md={12} className="img-email2">
                <Image className="img-email" src={isWebpSupported() ? "https://gecorcloud.com/images/Email.webp" : "https://gecorcloud.com/images/Email.png"} rounded />
              </Col>
              <Form noValidate onSubmit={handleSubmit} validated={isValid}>
                <Row className="row-box" >
                  <Col md={6} xs={12}>
                    <Form.Group as={'div'} className="div-cajas" controlId="validationFormikName">
                      <Form.Control required name="name" className="div-cajas2" placeholder={t('contactg.p1')}
                        onChange={handleChange} value={values.name} isInvalid={!!errors.name} isValid={touched.name && !errors.name} />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={'div'} className="div-cajas" controlId="validationFormikEmail">
                      <Form.Control name="email" className="div-cajas2" type="email" placeholder={t('contactg.p2')}
                        required onChange={handleChange} value={values.email} isInvalid={!!errors.email} isValid={touched.email && !errors.email} />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={'div'} className="div-cajas" controlId="validationFormikPhone">
                      <Form.Control name="phone" className="div-cajas2" placeholder={t('contactg.p3')}
                        required onChange={handleChange} value={values.phone} isInvalid={!!errors.phone} isValid={touched.phone && !errors.phone} />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group as={'div'} className="box-mensaje" controlId="validationFormikBody">
                      <Form.Control name="body" className="box-mensaje2" placeholder={t('contactg.p4')} as="textarea" maxLength="376" rows="3"
                        required onChange={handleChange} value={values.body} isInvalid={!!errors.body} isValid={touched.body && !errors.body} />
                      <Form.Control.Feedback type="invalid">
                        {errors.body}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <div className="recaptcha-g">
                      <Row>
                        <Form.Group as={Col} controlId="validationFormikTerms">
                          <Form.Check name="terms" className="color-heLeido" label={t('contactg.p5')}
                            required custom type={'checkbox'} onChange={this.onCheckBoxChange} checked={values.terms} isInvalid={!!errors.terms} feedback={errors.terms} />
                          <a className="aviso-legal" target="_blank" rel="noopener noreferrer" href="./legal-advice" >{t('contactg.p6')}</a>
                        </Form.Group>
                        { /* <confirmLegal /> */}
                        <Form.Group as={Col} controlId="recaptcha">
                          <Recaptcha
                            sitekey="6LdVQ6wUAAAAAAoJOy_Yl7UPs4Vje8IWwvU7-8T7" // V2
                            // sitekey="6LfcQqwUAAAAAOepXykaiBTSvaXr2fmJhfRIjq3_" // V3
                            render="explicit"
                            // theme="dark"
                            hl={i18next.language || window.localStorage.i18nextLng}
                            verifyCallback={(response) => { setFieldValue("recaptcha", response); }}
                            onloadCallback={() => { }}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.recaptcha}
                          </Form.Control.Feedback>
                          {errors.recaptcha
                            && touched.recaptcha && (
                              <p>{errors.recaptcha}</p>
                            )}
                        </Form.Group>

                        <Col>
                          <Button className="buttom-hazTu bottonEnv" variant="primary" type="submit"> {t('contactg.p11')} </Button>

                          <div className="modal-contact">
                            <Modal show={sended} onHide={this.handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>{t('contactg.p7')}</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <p>{t('contactg.p8')}</p>
                                <p>{t('contactg.p9')}</p>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleClose}>
                                  {t('contactg.p10')}
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>

                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>

          </div>

          <div className="box-contact">
            <div className="box-contentCh">
              <div className="call-ch">Teléfono para consultas medioambientales:     <a href="tel:951-816-736">951-816-736</a>   </div>
            </div>
          </div>
        </Container>
      </DynamicBackground>
    );
  }
}

const initialValuesFormik = {
  name: '',
  email: '',
  phone: '',
  subject: 'Contacto desde la web ciudadano',
  body: '',
  terms: false,
  recaptcha: "",
}

/*  MODAL PARA CONFIRMAR AVISO LEGAL   */
/*
function confirmLegal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


 */

const tEnviromentalContactPageChForm = withTranslation()(EnviromentalContactPageChForm);
const formikEnhancer = withFormik({
  validationSchema: ({ t }) => Yup.object().shape({
    name: Yup.string().required('name is required.'),
    email: Yup.string().email('Invalid email address!').required('Email address is required!'),
    phone: Yup.number().required('Phone number is required!').integer().positive(),
    body: Yup.string().required('body is required.'),
    terms: Yup.boolean().required('terms is required.'),
    recaptcha: Yup.string().required(),
  }),
  mapPropsToValues: () => (initialValuesFormik),
  handleSubmit: (payload, { props, setSubmitting, resetForm }) => {
    const { terms } = payload
    if (terms) {
      props.dispatch(contactActions.sendMail(payload))
      setSubmitting(false)
      resetForm(initialValuesFormik)
      // handleShow();
    }

  },
  displayName: 'ContactPageForm',
})(tEnviromentalContactPageChForm);

const mapStateToProps = (state) => {
  const { contact } = state;
  const { error, sended, sending } = contact;

  return {
    error,
    sended,
    sending,
  };
}

const tFormikEnhancer = withTranslation()(formikEnhancer)
const connectedEnviromentalContactPageCh = connect(
  mapStateToProps
)(tFormikEnhancer);
export { connectedEnviromentalContactPageCh as EnviromentalContactPageCh };