import React from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Card, Form, Row, Col } from 'react-bootstrap';
import { incidentsActions } from '../../actions';
import './IncidentRate.css';
import Moment from 'react-moment';
import { withTranslation } from 'react-i18next';
import { isWebpSupported } from "react-image-webp/dist/utils";

import ReactStars from 'react-stars'
/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComments, faStar } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

class IncidentRate extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleRatedModal = this.handleRatedModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.ratingChanged = this.ratingChanged.bind(this);
        this.state = {
            show: false,
            starToRate: 0
        };
    }

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    handleChange(e) {
        const { error } = this.props;
        if (error) this.props.clearError();

        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    ratingChanged(starToRate) {
        // console.log(starToRate, this.state);
        this.setState({ starToRate })
    }

    handleRatedModal() {
        // console.log(`valorando aviso ${this.state.incidentToRate} con ${this.state.starToRate}`)      ;
        const { incident } = this.props;
        const { AvisoID } = incident;
        this.props.dispatch(incidentsActions.rateIncident({ avisoID: AvisoID, valoracion: this.state.starToRate }));
        console.log(`valorando aviso ${AvisoID} con ${this.state.starToRate}`)      ;
        this.setState({ show: false });
    }

    render() {

        const { incidentComments, t } = this.props
        const { message } = this.state;

        return (
            <>
                <div title={t('incicard.floatRate')} onClick={this.handleShow}>
                    <FontAwesomeIcon icon={faStar} />
                </div>

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered   >
                    <Modal.Body className="rate">
                        <ReactStars value={this.state.starToRate} count={5}
                            onChange={this.ratingChanged} size={54}
                            half={false} color1={'#717f8e'} color2={'#005e7d'} />
                        <p> {t("incmodal.thanksRate")}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="boton-incident-commet" waves='light'onClick={this.handleRatedModal}>{t("incmodal.rate")}</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const { incidents, users } = state;
    const { incidentComments, newComment } = incidents;
    const { user } = users;
    return {
        incidentComments,
        newComment,
        user,
    };
}
const tIncidentRate = withTranslation()(IncidentRate);
const connectedIncidentRate = connect(mapStateToProps)(tIncidentRate);
export { connectedIncidentRate as IncidentRate };
