
import React from 'react';
import { connect } from 'react-redux';
import { poisActions } from './../../actions';
import GoogleMap from "./../../components/Map/GoogleMap";
import { REACT_APP_MAP_KEY, MALAGA_CENTER } from "../../constants";
import isEmpty from "lodash.isempty";
import MarkerPoi from "./../../components/Map/MarkerPoi";
import Marker from "./../../components/Map/Marker";
import { filteredPois } from '../../reducers/pois.reducer';
import { withTranslation } from 'react-i18next';

const intialState = {
    open: false,
    poiChoosed: undefined,
    width: 0,
    height: 0,
    infoMarkerOpened: false,
    infoMarkerPoiIDOpened: 0,
}

class PoisMapPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = intialState;

        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.handleOnToggle = this.handleOnToggle.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
            currentLatLng: {
                lat: 0,
                lng: 0
            },
            isMarkerShown: false
        }
    }


    createMapOptions = maps => {
        return {
            panControl: false,
            mapTypeControl: false,
            scrollwheel: true,
            clickableIcons: false,
            gestureHandling:
                "greedy",
            styles: [
                {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    featureType: "transit.station.bus",
                    stylers: [{ "visibility": "off" }]
                }
            ]
        };
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.dispatch(poisActions.getPois());
        this.props.dispatch(poisActions.getCategories());
        this.showCurrentLocation()
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.props.dispatch(poisActions.clearSearchPoi());
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
            geocoder: new maps.Geocoder()
        });
    };

    showCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.setState(prevState => ({
                        currentLatLng: {
                            ...prevState.currentLatLng,
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        },
                        isMarkerShown: true
                    }))
                }
            )
        }
    }

    handleChangeSearch(e) {
        this.props.dispatch(poisActions.searchPoi(e.target.value));
    }

    handleOnToggle(infoMarkerPoiIDOpened) {
        this.setState({ infoMarkerOpened: (this.state.infoMarkerPoiIDOpened != infoMarkerPoiIDOpened || !this.state.infoMarkerOpened) ? true : false, infoMarkerPoiIDOpened })
    }

    render() {

        const { myPois, entity, poiHovered, categoriesPoi, t } = this.props;
        const { currentLatLng, isMarkerShown } = this.state;
        const h = (window.innerHeight - 86.77) + "px";
        
        return (

            <GoogleMap
                height={h}
                defaultZoom={15}
                defaultCenter={
                    entity
                        ? [parseFloat(entity.Latitud), parseFloat(entity.Longitud)]
                        : MALAGA_CENTER
                }
                bootstrapURLKeys={{
                    key: REACT_APP_MAP_KEY,
                    libraries: ["places", "geometry"]
                }}
                yesIWantToUseGoogleMapApiInternals
                options={this.createMapOptions}
                onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
            >
                {!isEmpty(myPois) &&
                    myPois.map(
                        poi =>
                            poi && (
                                <MarkerPoi
                                    key={poi.idItem}
                                    idItem={poi.idItem}
                                    nombre={poi.title}
                                    image={poi.backgroundImage}
                                    images={poi.Images}
                                    lat={poi.latitud}
                                    lng={poi.longitud}
                                    rutaComprarEntrada={poi.rutaComprarEntrada}
                                    currentLatLng={currentLatLng}
                                    hovered={poiHovered && poi.idItem === poiHovered.idItem}
                                    category={categoriesPoi && categoriesPoi.filter(c => c.idCategoria === poi.idCategoria)[0]}
                                    infoMarkerOpened={this.state.infoMarkerOpened}
                                    handleOnToggle={this.handleOnToggle}
                                    infoMarkerPoiIDOpened={this.state.infoMarkerPoiIDOpened}
                                />
                            )
                    )
                }
                {isMarkerShown && <Marker
                    key={0}
                    text={t('poi.p14')}
                    lat={currentLatLng.lat}
                    lng={currentLatLng.lng}
                    icon="/images/map/pin-posicion.png"
                    height="3em"
                    width="3em"
                />}
            </GoogleMap>
        )
    }
}

const mapStateToProps = (state) => {
    const { entities, pois, users } = state;
    const { poiHovered, categoriesPoi } = pois;
    const { user } = users;
    const { entity } = entities;


    return {
        myPois: filteredPois(state),
        user,
        entity,
        poiHovered,
        categoriesPoi,
    };
}

const tPoisMapPage = withTranslation()(PoisMapPage);
const connectedPoisMapPage = connect(mapStateToProps)(tPoisMapPage);
export { connectedPoisMapPage as PoisMapPage };
