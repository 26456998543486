import React from 'react';
import { connect } from 'react-redux';
import './LocalInformationPageLn.css';
import { DynamicBackground } from './../../../../components'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { TimelineLite } from "gsap/all";
import { isWebpSupported } from 'react-image-webp/dist/utils';

class LocalInformationPageLn extends React.Component {

  constructor(props) {
    super(props);
    this.logoTl = new TimelineLite({ paused: true });
    this.subhead = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logoTl
      .set(this.content, { autoAlpha: 1 })// show content div
      .from(this.head, 0.5, { left: 100, autoAlpha: 0 })
      .from(this.subhead, 0.5, { left: -100, autoAlpha: 0 }, "-=0.25") // added -0.25 seconds prior to end this.of timeline
    this.logoTl.play()
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));

  }

  render() {

    return (

      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/bgLocal.webp" : "https://gecorcloud.com/images/bgLocal.jpg"}>



        <div className="container">
          <div className="infoBoxLn " ref={div => this.subhead = div}>
            <div className="infoTit">
              <h1><b tabIndex="1">INFORMACIÓN LOCAL</b></h1>
              <hr className="line" ></hr>
            </div>
            {/*   Box 1   */}
            <Card >
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={6} >
                      <Row>
                        <Col sm={12}>
                          <div className='local-enviromental-title'>
                            <p>Trámites Medio Ambiente</p>
                            <div class="text-decor"></div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="3" className="par-hov-ln" onClick={() => window.open('https://gecorcloud.com/pdf/ORDENANZA MUNICIPAL DE PROTECCIÓN DEL MEDIO AMBIENTE CONTRA LOS RUIDOS Y VIBRACIONES.pdf')}>
                            Ordenanza Municipal de Protección del Medio Ambiente contra Ruidos y Vibraciones
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="4" className="par-hov-ln" onClick={() => window.open('https://gecorcloud.com/pdf/ORDENANZA MUNICIPAL DE INFRAESTRUCTURAS RADIOELÉCTRICAS.pdf')}>
                            Ordenanza Municipal Reguladora de la Instalación y Funcionamiento de Infraestructuras Radioeléctricas
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="5" className="par-hov-ln" onClick={() => window.open('https://gecorcloud.com/pdf/ORDENANZA MUNICIPAL DE VERTIDOS_1.pdf')}>
                            Ordenanza Municipal de Vertidos de Aguas Residuales
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="6" className="par-hov-ln" onClick={() => window.open('https://gecorcloud.com/pdf/Ordenanza Municipal de Convivencia.pdf')}>
                            Ordenanza Municipal de Normas Básicas de Convivencia de Interés Local, de Policía y su Buen Gobierno
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="7" className="par-hov-ln" onClick={() => window.open('https://gecorcloud.com/pdf/Ordenanza Municipal reguladora de la limpieza y vallado de solares y terrenos y ornato de construcciones.pdf')}>
                            Ordenanza Municipal Reguladora de la Limpieza y Vallado de Solares y Terrenos y Ornato de Construcciones
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="8" className="par-hov-ln" onClick={() => window.open('https://gecorcloud.com/pdf/lalinea Ordenanza de Playas (2).pdf')}>
                            Ordenanza Municipal Reguladora del uso y Disfrute de las Playas y Zonas Adyacentes
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 6, offset: 0 }}>
                      <img tabIndex="9" src={isWebpSupported() ? "https://gecorcloud.com/images/info1.webp" : "https://gecorcloud.com/images/info1.png"} alt="imagen-info1" ref={img => this.feature = img} />
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            {/*   Box 2   */}

            <Card ref={card => this.content = card}>
              <Card.Header tabIndex="10" className="par-ln" as="h5" ref={h5 => this.head = h5}>Información y educación ambiental</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 6, offset: 0 }}>
                    <img tabIndex="11" src={isWebpSupported() ? "https://gecorcloud.com/images/info3.webp" : "https://gecorcloud.com/images/info3.png"} alt="imagen-info3" />
                    </Col>
                    <Col md={6} >
                      <Row>
                        <Col sm={12} className="par-ln">
                          <p><b tabIndex="12">¿Qué es un gestor de residuos?</b></p>
                          <p>
                           La persona o entidad, pública o privada, registrada mediante autorización o comunicación que realice cualquiera de las operaciones que componen la gestión de los residuos, sea o no el productor de los mismos (según la Ley 22/2011, de Residuos)
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="13" className="par-hov-ln" onClick={() => window.open('https://www.boe.es/buscar/act.php?id=BOE-A-2011-13046','_blank')}>
                            <b tabIndex="14">¿Por qué debo contratar un gestor de residuos?</b>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p className="par-ln" tabIndex="15"><b>
                            ¿Cómo puedo saber si mis residuos son considerados como peligrosos?</b></p>
                          <p tabIndex="16" className="par-hov-ln" onClick={() => window.open('https://www.boe.es/diario_boe/txt.php?id=BOE-A-2002-3285','_blank')}>
                            Lista Europea de residuos
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="17" className="par-ln"><b>
                            Punto Limpio
                          </b></p>
                          <p tabIndex="18" className="par-hov-ln" onClick={() => window.open('https://gecorcloud.com/pdf/Puntos Limpios 2.018.pdf','_blank')}>
                            Residuos y cantidades admisibles
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="19" className="par-ln"><b>
                          Contenedores (Geolocalización)
                          </b></p>
                          <p tabIndex="20" className="par-hov-ln" onClick={() => window.open('https://www.google.com/maps/d/u/0/viewer?mid=1_2pFYUEl8TTg4SwvqtsYAUQZdajYM93i&ll=36.20242100449541%2C-5.340235500000072&z=13','_blank')}>
                            Mapa de Contenedores
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>


            {/*   Box 3   */}

            <Card>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={6}>
                    <Row>
                        <Col sm={12}>
                          <div className='local-enviromental-title'>
                            <p>Trámites Medio Ambiente</p>
                            <div class="text-decor"></div>
                          </div>
                    </Col>
                  </Row>
                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <p tabIndex="22" className="par-ln">
                              Servicio de recogida de RU
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="23" className="par-ln">
                          Servicio de recogida selectiva
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="24" className="par-ln">
                          Recogida de pilas
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="25" className="par-ln">
                          Servicios especiales: Servicio de retirada de muebles y enseres y  Servicio de recogida de restos de jardinería
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                            <Button tabIndex="26" className="btn-linea" onClick={() => window.open('https://gecorcloud.com/pdf/ORDENANZA MUNICIPAL DE LIMPIEZA PUBLICA.pdf')} variant="outline-dark">Ir a Residuos</Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 6, offset: 0 }}>
                    <img tabIndex="27" src={isWebpSupported() ? "https://gecorcloud.com/images/info2.webp" : "https://gecorcloud.com/images/info2.png"} alt="imagen-info2" />
                      
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>


            {/*   Box 4   */}

            <Card>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 6, offset: 0 }}>
                      <img tabIndex="29" src={isWebpSupported() ? "https://gecorcloud.com/images/info4.webp" : "https://gecorcloud.com/images/info4.png"} alt="imagen-info4" />
                    </Col>
                    <Col md={6}>
                    <Row>
                        <Col sm={12}>
                          <div className='local-enviromental-title'>
                            <p>PARQUES Y JARDINES</p>
                            <div class="text-decor"></div>
                          </div>
                    </Col>
                  </Row>
                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <p tabIndex="30" className="par-ln">Parques Públicos</p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="31" className="par-ln">
                          Pinares
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p tabIndex="32" className="par-ln">
                          Áreas de juegos
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                            <Button tabIndex="33" className="btn-linea" onClick={() => window.open('https://gecorcloud.com/pdf/ORDENANZA MUNICIPAL DE ARBOLADO, PARQUES Y JARDINES PÚBLICOS.pdf')} variant="outline-dark">Ir a Parques y Jardines</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

          </div>
        </div>

      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedLocalInformationPageLn = connect(mapStateToProps)(LocalInformationPageLn);
export { connectedLocalInformationPageLn as LocalInformationPageLn }; 