import React from 'react';
import { connect } from 'react-redux';
import './LocalInformationPageOn.css';
import { DynamicBackground } from './../../../../components'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { TimelineLite } from "gsap/all";
import { isWebpSupported } from 'react-image-webp/dist/utils';
import { faCalendar, faMapMarkerAlt, faPhone, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Carousel from 'react-bootstrap/Carousel';

class LocalInformationPageOn extends React.Component {

  constructor(props) {
    super(props);
    this.logoTl = new TimelineLite({ paused: true });
    this.subhead = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logoTl
      .set(this.content, { autoAlpha: 1 })// show content div
      .from(this.head, 0.5, { left: 100, autoAlpha: 0 })
      .from(this.subhead, 0.5, { left: -100, autoAlpha: 0 }, "-=0.25") // added -0.25 seconds prior to end this.of timeline
    this.logoTl.play()
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));

  }

  render() {

    return (

      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.5)" background={isWebpSupported() ? "https://gecorcloud.com/images/onil.webp" : "https://gecorcloud.com/images/onil.jpg"}>
        <div className="infoBoxOnil" ref={div => this.subhead = div}>
          <div className="infoTit">
            <h1><b tabIndex="1">INFORMACIÓN LOCAL</b></h1>
          </div>
          {/*   Box 1   */}
          <Card >
            <Card.Body>
              <Card.Text as="div">
                <Row>
                  <Col sm={12}>
                    <div className='local-enviromental-title'>
                      <p>Ordenanzas Medio Ambiente</p>
                      <div class="text-decor"></div>
                    </div>
                  </Col>
                  <Col md={6} >
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="3" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/Decreto_852_2020.pdf')}>
                          Ordenanza Municipal de Convivencia Ciudadana en el Espacio Público
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="4" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/65688C4C85146249A2C88E39AC787668.pdf')}>
                          Ordenanza Municipal reguladora de los Huertos Urbanos Ecológicos Municipales
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="12" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/energia solar.pdf')}>
                          Ordenanza sobre la Incorporación de Sistemas de Captación de Energía Solar Térmica en los Edificios
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="13" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/esteticacascoantiguo.pdf')}>
                          Ordenanza estética del Casco Antiguo
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="14" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/hogueras.pdf')}>
                          Ordenanza reguladora de Hogueras
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="16" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/limpieza.pdf')}>
                          Ordenanza Municipal de Limpieza Viaria y Gestión de Residuos
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="17" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/obrascementerio.pdf')}>
                          Ordenanza Reguladora de las Obras en el Cementerio
                        </p>
                      </Col>
                    </Row>                    
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="19" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/ruidos.pdf')}>
                          Ordenanza de Ruidos
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={{ span: 6, offset: 0 }}>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="5" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/OrdenazaVertidos.pdf')}>
                          Ordenanza de Vertidos
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="6" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/edificacion.pdf')}>
                          Ordenanza Reguladora de la Edificación
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="7" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/edificios protegidos.pdf')}>
                          Ordenanza de Edificios Protegidos
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="8" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/alcantarillado.pdf')}>
                          Ordenanza de Alcantarillado
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="9" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/emisionesatmosféricas.pdf')}>
                          Ordenanza de Emisiones Atmosféricas
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="15" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/obras.pdf')}>
                          Ordenanza General para la realización de Obras
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="18" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/soterramientodecontenedores.pdf')}>
                          Ordenanza sobre la Incorporación de Sistemas de Soterramiento de Contenedores de Residuos Sólidos Urbanos (RSU)
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <p tabIndex="20" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/reglamentodeaguapotable.pdf')}>
                          Reglamento regulador del Servicio de Suministro Domiciliario de Agua Potable
                        </p>
                      </Col>
                    </Row>
                  </Col>

                </Row>
              </Card.Text>
            </Card.Body>
          </Card>

          {/*   Box 2   */}

          <Card ref={card => this.content = card}>
            <Card.Body>
              <Card.Text as="div">
                <Row>
                  <Col sm={12}>
                    <div className='local-enviromental-title'>
                      <p>Gestión de residuos</p>
                      <div class="text-decor"></div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ span: 6, offset: 0 }} className='center'>
                    <img className="mt-2" src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Onil/ecoparque-movil-scaled.jpg" width="60%" alt="imagen-info3" />
                  </Col>
                  <Col sm={6} className="par">
                    <p tabIndex="22">
                      En Onil actualmente existe un sistema de recogida de residuos tradicional con la recogida de
                      contenedores en superficie y algunos soterrados con grandes camiones de recogida.  </p>
                    <p tabIndex="23">En Onil existe la recogida selectiva para las fracciones:
                      envases, papel/cartón y vidrio, todas ellas con contenedores de carga superior en la vía pública,
                      la fracción orgánica o biorresiduos implementada en 2021 con contenedores de carga trasera y
                      la recogida de la fracción resto también con contenedores de carga trasera. También se realiza
                      la recogida de otras fracciones en la vía pública como la ropa usada o textil, el aceite vegetal o
                      las pilas y baterías en edificios públicos.</p>
                    <p tabIndex="24">Para todos los demás residuos existe tanto el ecoparque fijo como el móvil:</p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <h4 tabIndex="25"> Ecoparque fijo o Punto limpio</h4>
                    <Row>
                      <Col sm={6}>
                        <Row>
                          <p tabIndex="26">Situado en el Polígono Industrial “Els Vasalos”. En este recinto se recogen todo tipo de residuos domésticos.</p>
                          <p tabIndex="27">Actualmente esta instalación es gestionada por Fobesa y los residuos son tratados por la empresa Recimed. </p>
                        </Row>
                      </Col>
                      <Col sm={6}>
                        <img src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Onil/Ecoparque-Onil.jpeg" width="100%" height="170px" alt="Ecoparque fijo" />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <iframe title="Punto Limpio Onil" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3117.1179036242343!2d-0.6894264!3d38.6231686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd63d72aad3fdaab%3A0x325218b59c2b7023!2sPunto%20Limpio%20de%20Onil!5e0!3m2!1ses!2ses!4v1708346462643!5m2!1ses!2ses" width="100%" height="170px" styles="border:0;" />
                      </Col>
                      <Col sm={6}>
                        <p tabIndex="28"><FontAwesomeIcon icon={faMapMarkerAlt} /> Av. de los Trabajadores, 8A, 03430 Onil, Alicante</p>
                        <p tabIndex="29"><FontAwesomeIcon icon={faCalendar} />El horario de apertura es de martes a domingo de 10.30h a las 14.00h. </p>
                        <p><FontAwesomeIcon icon={faPhone} /> 966952382 </p>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={6}>
                    <h4 tabIndex="30"> Ecoparque móvil</h4>
                    <Row>
                      <Col sm={6}>
                        <Row>
                          <p tabIndex="31">Está dotada con contenedores de diferentes fracciones con el objetivo de mejorar la recogida selectiva a la vez que acercar y facilitar el reciclaje a la
                            ciudadanía. Ésta es una iniciativa reciente que se ha llevado a cabo por parte de los diferentes Consorcios de la Comunitat Valenciana.
                          </p>
                        </Row>
                      </Col>
                      <Col sm={6}>
                        <img src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Onil/ecoparqueMovil.jpg" width="100%" height="170px" alt="Ecoparque fijo" />
                      </Col>
                    </Row>
                    <Row>
                      <p tabIndex="32">El Ecoparque móvil da servicio los martes de 9:00h a 11:00h en la Avda. La Paz en el cruce con la Calle Maestro Chapí.</p>
                    </Row>
                  </Col>
                </Row>
                <hr className="line" ></hr>
                <Row>
                  <Col sm={12}>
                    <p tabIndex="33" className="par-hov" onClick={() => window.open('https://gecorsystem.blob.core.windows.net/public/info/otros/Documentos/Onil/PLAN_LOCAL_RESIDUOS_DOMESTICOS_Y_ASIMILABLES.pdf', '_blank')}>
                      Plan Local de Gestión de Residuos Domésticos y Asimilables
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <p tabIndex="34" className="par-hov" onClick={() => window.open('https://www.miteco.gob.es/es/calidad-y-evaluacion-ambiental/temas/prevencion-y-gestion-residuos/flujos/domesticos/fracciones/otros/residuos-peligrosos.html', '_blank')}>
                      Ver lista de residuos peligrosos
                    </p>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>


          {/*   Box 3   */}

          <Card>
            <Card.Body>
              <Card.Text as="div">
                <Row>
                  <Col sm={12}>
                    <div className='local-enviromental-title'>
                      <p tabIndex="35">Trámites</p>
                      <div class="text-decor"></div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col sm={12}>
                        <div className="plocal">
                          <div className="textColorInfo">
                            <p tabIndex="36" className="par">
                              <b>Instrucciones para la recogida de voluminosos:</b>
                            </p>
                            <p> Los peticionarios deberán llamar al teléfono 96 695 2382, indicando su municipio, indicando su nombre, dirección y objetos a retirar. </p>
                            <img src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Onil/recogida de enseres.jpeg" width="100%" alt="Recogida de enseres" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={{ span: 6, offset: 0 }}>
                    <Row>
                      <Col sm={12}>
                        <div className="plocal">
                          <div className="textColorInfo">
                            <p tabIndex="37" className="par">
                              <b>¿Sabías que puedes crear tu propio abono?</b>
                            </p>
                            <p>Disponemos de dos islas de compostaje en Onil, donde puedes tirar los residuos orgánicos para convertirlos en compost de calidad, para nutrir la tierra y formar parte de nuevo del ciclo de vida </p>
                            <img src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Onil/compostaje.jpg" width="100%" alt="Ecoparque fijo" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>


          {/*   Box 4   */}

          <Card>
            <Card.Body>
              <Card.Text as="div">

                <Row>
                  <Col sm={12}>
                    <div className='local-enviromental-title'>
                      <p>PARQUES Y JARDINES</p>
                      <div class="text-decor"></div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Carousel>
                      <Carousel.Item key={1}>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <img
                                className="d-block w-100  mt-5"
                                src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Onil/sta_ana.jpg"
                                alt="deslizar"
                              />
                              <p className="par-hov  mt-5" onClick={() => window.open('https://www.onil.es/images/seccionturismo/casatapena.pdf', '_blank')}>
                                Descarga aquí el folleto
                              </p>
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <p tabIndex="38" className="par">
                                <b>Paraje Ermita de Santa Ana</b>
                              </p>
                              <p>En dirección este, y a algo más de 1 Km del núcleo de la población, podemos encontrar el paraje llamado área recreativa Ermita de Santa Ana, donde se encuentra la Ermita que da nombre al lugar. El acceso hasta allí resulta muy cómodo, existe una amplia zona para el ocio. Es una zona más bien fresca, pues bajo la sombra de un centenar de pinos, algunos con más de 50 años de historia otros ya centenarios, se puede descansar.
                              </p><p>El lugar está dotado de aseos, zonas para aparcar, fuentes de agua, barbacoas, así como de mesas y bancos. Además existe servicio de bar.
                              </p><p>También existe un lugar habilitado para juegos y actividades, alejado del lugar expresamente preparado para el descanso o la comida.
                              </p><p>Se pueden realizan largos paseos por sus alrededores y por el confluyen diferentes rutas senderistas. </p>
                            </Row>
                          </Col>
                        </Row>
                      </Carousel.Item>
                      <Carousel.Item key={2}>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <img
                                className="d-block w-100  mt-5"
                                src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Onil/parc_mare_deu.jpg"
                                alt="deslizar"
                              />
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <p tabIndex="39" className="par">
                                <b>Parque Mare de Déu</b>
                              </p>
                              <p>Situado en la parte suroeste de Onil, alrededor de la Ermita de la Virgen de la Salud, Patrona de Onil. Consta de una amplia zona de pinos con mesas y bancos junto con bellas zonas ajardinadas.
                              </p><p>Hay una fuente de agua fresca y cristalina. Parque y zona indicada para el disfrute de un paseo en calma y descanso por la tranquilidad del lugar. </p>
                            </Row>
                          </Col>
                        </Row>
                      </Carousel.Item>
                      <Carousel.Item key={3}>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <img
                                className="d-block w-100  mt-5"
                                src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Onil/felix.jpg"
                                alt="deslizar"
                              />
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <p tabIndex="40" className="par">
                                <b>Parque Félix Rodríguez</b>
                              </p>
                              <p>Situado entre las calles Biar, Miguel Hernández, Avda. de la Paz y Gabril Miró, muy cercano al Centro de Día de la Tercera Edad Caseta Sarrió.
                              </p><p>Este amplio parque de césped casi en su totalidad consta de bancos, fuentes y demás mobiliario urbano, posee una zona recreativa de juegos para niños, rodeada de diversidad de vegetación parte de ella autóctona de la zona.</p>
                            </Row>
                          </Col>
                        </Row>
                      </Carousel.Item>
                      <Carousel.Item key={4}>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <img
                                className="d-block w-100 mt-5"
                                src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/Web/Onil/laberint.jpg"
                                alt="deslizar"
                              />
                              <Row>
                                <p className="par-hov mt-5" onClick={() => window.open('https://www.onil.es/images/seccionturismo/casatapena.pdf', '_blank')}>
                                  Descarga aquí el folleto
                                </p>
                              </Row>
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <p tabIndex="40" className="par">
                                <b>Laberinto "Casa Tàpena"</b>
                              </p>
                              <p>Está situado a algo más de 1 Km del centro urbano de Onil, entre los parajes del Área Recreativa Ermita de Santa Ana y Casa Tàpena-Favanella.</p>
                              <p className='mt-1'><b>Horarios y días visita Laberinto:</b></p>
                              <p className='mt-1'>Invierno</p>
                              <p>De lunes a viernes de 9:00h a 14:00h</p>
                              <p>Sábados, domingos y festivos de 9:00h a 18:00h</p>
                              <p className='mt-1'>Verano</p>
                              <p>De lunes a viernes de 9:00h a 14:00h</p>
                              <p>Sábados, domingos y festivos de 9:00h a 20:00h</p>
                              <p>El laberinto cierra únicamente los días 1 de enero, 24, 25 y 31 de diciembre.</p>
                            </Row>
                          </Col>
                        </Row>
                      </Carousel.Item>
                    </Carousel>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>

        </div>
      </DynamicBackground >
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedLocalInformationPageOn = connect(mapStateToProps)(LocalInformationPageOn);
export { connectedLocalInformationPageOn as LocalInformationPageOn }; 