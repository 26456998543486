import React from 'react';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import './ResiduosRojalesPage.css';

class ResiduosRojalesPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }


  constructor(props) {
    super(props);

      this.state = { eventKey: this.props.location.state && this.props.location.state.eventKey ? this.props.location.state.eventKey : "first" };
      this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange = (e) => {
      this.setState({ eventKey: e })
    }

  render() {
    return (
      <div className='medioambiente-valverde'>
        <Container>
          <div className='valv-title'>
            <h1>INFORMACIÓN MEDIOAMBIENTAL</h1>
            <hr></hr>
          </div>
          <div className='collapse-rojales'>
            <Tab.Container defaultActiveKey={this.state.eventKey} activeKey={this.state.eventKey} id="left-tabs-example">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link onClick={() => this.handleTabChange("first")} eventKey="first">Residuos</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.handleTabChange("second")} eventKey="second">Espacios protegidos y Vías pecuarias</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.handleTabChange("third")} eventKey="third">Parque canino</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <ol> </ol>
                        <h1>RESIDUOS</h1>
                        <hr></hr>
                        <h2>¿Qué es un gestor de residuos?</h2>
                        <br></br>
                        <p>La persona o entidad, pública o privada, registrada mediante autorización o comunicación que realice cualquiera de las operaciones que componen la gestión de los residuos, sea o no el productor de los mismos (según la Ley 7/2022, de 8 de abril, de residuos y suelos contaminados para una economía circular)</p>
                        <br></br>
                        <h2>¿Por qué debo contratar un gestor de residuos?</h2>
                        <p>La Ley 7/2022, de 8 de abril, de residuos y suelos contaminados para una economía circular define que el productor u otro poseedor inicial de residuos, con objeto de asegurar el tratamiento adecuado de sus residuos, está obligado a:</p>
                        <ul>
                          <li>Realizar el tratamiento de los residuos por él mismo.</li>
                          <li>Encargar el tratamiento de sus residuos a un negociante, o a una entidad o empresa, todos ellos registrados conforme a lo establecido esta Ley.</li>
                          <li>Entregar los residuos a una entidad pública o privada de recogida de residuos, incluidas las entidades de economía social, para su tratamiento.</li>
                        </ul>
                        <p><a href="https://www.boe.es/buscar/doc.php?id=BOE-A-2022-5809" target="_blank">Ver Ley</a></p>
                        <br></br>
                        <h2>¿Cómo puedo saber si mis residuos son considerados como peligrosos?</h2>
                        <p>Se consideran residuos peligrosos aquellos que figuren en la lista europea de residuos, publicada en la Ley 7/2022, de 8 de abril, así como los recipientes y envases que los hayan contenido.</p>
                        <p><a href="https://www.boe.es/buscar/doc.php?id=BOE-A-2022-5809" target="_blank">Lista Europea de residuos</a></p>
                        <br></br>
                        <h2>Ecoparque</h2>
                        <p>Calle Norte, 9</p>
                        <p>Horario 8 a 14,30 de lunes a viernes y los sábados de 8 a 14,30 horas</p>
                        <p>Sirve de punto de recogida de podas que es necesario una autorización municipal para poder depositar dichas podas, se recoge hierro, madera, escombros ( poca cantidad ), plástico y corcho.</p>
                        <br></br>
                        {/*<h2><a href="https://gecorcloud.com/pdf/rojales/puntos-verdes-rojales.pdf" target="_blank">Contenedores Vidrio, Papel, Aceite, Plástico y Ropa</a></h2>*/}
                        <div className='img-contenedores-rojales'>
                          <img src="https://gecorcloud.com/images/rojales/residuos-contenedores.jpg" alt="contenedores rojales" className='img-contenedor-rojales'></img>
                        </div>
                        <br></br>
                        <h2>Recogida de Muebles y Enseres</h2>
                        <p>Los enseres se recogen cualquier día mediante aviso al número de teléfono 689 600 184, previo aviso llamada telefónica, whatsapp o mandando un correo electrónico a rojales.recogidaresiduos@ferrovial.com</p>
                        <br></br>
                        <h2>Ecomóbil</h2>
                        <div className='img-contenedores-rojales'>
                          <img src="https://gecorcloud.com/images/rojales/ecomobil-calendario2023.jpg" alt="ecomobil 2023" className='img-ecomobil-rojales'></img>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p> </p>
                      <h1>Espacios protegidos y Vías pecuarias</h1>
                      <hr></hr>
                      {/*<h2><a href="https://gecorcloud.com/pdf/rojales/viapecuaria.pdf" target="_blank">VER Espacios Protegidos y Vías Pecuarias</a></h2>*/}
                      <p>En el municipio existe el parque natural del recorral, parte del hoyo del serrano, parte de las salinas de la mata con acceso del camino rural de los canilleros, zona del museo de la huerta.</p>
                      <p>Zona Museo de la Huerta</p>
                      <div className='img-contenedores-rojales'>
                          <img src="https://gecorcloud.com/images/rojales/museo-de-la-huerta.jpg" alt="museo de la huerta" className='img-museo-rojales'></img>
                      </div>
                      <p>Parque Natural del Recorral</p>
                      <div className='img-contenedores-rojales'>
                          <img src="https://gecorcloud.com/images/rojales/parque-recorral.jpg" alt="parque recorral" className='img-parque-rojales'></img>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p> </p>
                      <h1>Parque canino</h1>
                      <hr></hr>
                      {/*<h2><a href="https://gecorcloud.com/pdf/rojales/parque-canino.pdf" target="_blank">VER Parque Canino</a></h2>*/}
                      <p>Avenida de Salamanca, 10</p>
                      <div className='img-contenedores-rojales'>
                          <img src="https://gecorcloud.com/images/rojales/Parque-canino.jpg" alt="parque canino" className='img-canino-rojales'></img>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedResiduosRojalesPage = connect(mapStateToProps)(ResiduosRojalesPage);
export { connectedResiduosRojalesPage as ResiduosRojalesPage };