import { infoApiUrl } from '../../constants';

export const noticesApi = {
    /**
    * Logs a user in, returning a promise with `true` when done    
    */
    getNotices() {
        const requestOptions = {
            method: 'GET',
            // headers: { 'Content-Type': 'application/json' }
        };

        return fetch(`${infoApiUrl}/getNotices`, requestOptions)
            .then(response => {
                if(response.status === 200){
                    response.json();
                }
            })
            .then(notices => {
                localStorage.setItem('notices', JSON.stringify(notices));
                return notices
            });
    },
    getNoticeById(id, user = localStorage.user) {
        /*
        const { token } = JSON.parse(user);

        const params = {
            token,
            id,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${infoApiUrl}/getNoticeById`, requestOptions)
            .then(response => response.json())
            .then(notice => {
                // localStorage.setItem('entity', JSON.stringify(entity));
                return notice
            });
            */
        const notices = JSON.parse(localStorage.getItem('notices')) || undefined;
        let notice = undefined;
        if (notices)
            notice = notices.filter(notice => notice.noticiaID == id)
        return notice;
    },
    loadNotices() {
        return JSON.parse(localStorage.getItem('notices')) || undefined;
    },
    deleteNotices() {
        localStorage.removeItem('notices');
        return Promise.resolve(true)
    }
}

export default noticesApi