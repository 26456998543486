import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import CardDeck from 'react-bootstrap/CardDeck';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { forwardToWithParams, forwardTo } from "../../../../helpers";
import './MenuLineaPage.css';
import { EntitySearch, DynamicBackground } from '../../../../components'
import { isWebpSupported } from 'react-image-webp/dist/utils';
import "react-multi-carousel/lib/styles.css";

class MenuLineaPage extends React.Component {
  //CARGAR AYUNTAMIENTO POR PARAMETRO AO
  constructor(props) {
    super(props);

    this.state = {
      aytoName: '',
      ao: { ao: 294 },
    };
    this.handleSelectedEntity = this.handleSelectedEntity.bind(this);
  }

  handleSelectedEntity(entity) {
    this.setState({ aytoName: entity.Nombre });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {
    const { ao } = this.state;
    
    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.5)" background={isWebpSupported() ? "https://gecorcloud.com/images/lalinea2_1.webp" : "https://gecorcloud.com/images/lalinea2_1.jpg"}>

      <div className='enviromental-website-home'>
        <div className='carousel-enviromental'>
        <Carousel>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src="https://images.pexels.com/photos/2757549/pexels-photo-2757549.jpeg"
                alt="First slide"
              />
              <Carousel.Caption>
                <div className='cabecera-txt-pr1'><p>Juntos</p></div>
                <div className='cabecera-txt-pr2'><p>Podemos salvar</p></div>
                <div className='cabecera-txt-pr3'><p>La Tierra</p></div>
                <div className='cabecera-btn-pr4'><Button className='btn-carousel-enviromental d-none'>Saber más</Button></div>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>

        <div className='notice-enviromental'>

        </div>

        <div className='info-enviromental'>
            <div className='info-enviromental-title'>
              <p>Menú ambiental</p>
              <div class="text-decor"></div>
            </div>

          <CardDeck>
            <Card>
              <Card.Img variant="top" src="https://www.juntadeandalucia.es/medioambiente/portal/documents/20142/40305318/Playa-de-Valdelagrana-Bah%C3%ADa-de-C%C3%A1diz.-Javier-Hernandez-Gallardo.jpg" />
              <Card.Body>
                <Card.Title>Información local ambiental</Card.Title>
                <hr></hr>
                <Card.Text>
                  Información ambiental del Ayuntamiento de La Línea a rápida disposición del ciudadano.
                </Card.Text>
                <Button variant="outline-success" onClick={() => forwardTo('/local-information-page-la-linea')}>Saber más</Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src="https://www.mamatieneunplan.com/img/actividades/3259_mamatieneunplan-donde-ver-animales-lince-cadiz.png" />
              <Card.Body>
                <Card.Title>Especies Ambientales</Card.Title>
                <hr></hr>
                <Card.Text>
                Información general sobre Especies ambientales en España y documentación relevante para la conservación de especies.
                </Card.Text>
                <Button variant="outline-success" onClick={() => forwardTo('/enviromental-information-la-linea')}>Saber más</Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src="https://www.tododisca.com/wp-content/uploads/2022/08/playas-turismo-cadiz-1140x703.jpg" />
              <Card.Body>
                <Card.Title>Educación Ambiental</Card.Title>
                <hr></hr>
                <Card.Text>
                Documentación medioambiental de importancia para el amprendizaje para un futuro sostenible.
                </Card.Text>
                <Button variant="outline-success" onClick={() => forwardTo('/educacion-ambiental-la-linea')}>Saber más</Button>
              </Card.Body>
            </Card>
          </CardDeck>


          <CardDeck>
            <Card>
              <Card.Img variant="top" src="https://a.cdn-hotels.com/gdcs/production194/d762/23601246-1af9-45a8-b6d0-fd6039b2a57c.jpg" />
              <Card.Body>
                <Card.Title>Glosario Ambiental</Card.Title>
                <hr></hr>
                <Card.Text>
                Glosario que contiene palabras, definiciones e imagenes descriptivas de palabras relacionadas con medio ambiente.
                </Card.Text>
                <Button variant="outline-success" onClick={() => window.open('https://gecorcloud.com/pdf/Glosario-ambiental-GECOR.pdf')}>Saber más</Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src="https://www.dechiclana.com/wp-content/uploads/cache/images/2023/05/pinar-claveran-chiclana-de-la-frontera/pinar-claveran-chiclana-de-la-frontera-2111529516.jpg" />
              <Card.Body>
                <Card.Title>Prácticas Sobre Medio Ambiente</Card.Title>
                <hr></hr>
                <Card.Text>
                Prácticas del día a día para mejorar nuestro mundo y sostenibilidad medioambiental.
                </Card.Text>
                <Button variant="outline-success" onClick={() => forwardTo('/CitizenGuidePage-la-linea')}>Saber más</Button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Img variant="top" src="https://a.cdn-hotels.com/gdcs/production90/d436/9c2ee77f-2347-43f6-b44e-27abf8dcacfc.jpg" />
              <Card.Body>
                <Card.Title>Enlaces de Interés</Card.Title>
                <hr></hr>
                <Card.Text>
                Directorio de enlaces de interés en relación con el Medio Ambiente.
                </Card.Text>
                <Button variant="outline-success" onClick={() => window.open('https://gecorcloud.com/pdf/enlaces.pdf', '_blank')}>Saber más</Button>
              </Card.Body>
            </Card>
          </CardDeck>
        </div>

        <div className='download-enviromental'>

            <div className='download-enviromental-title'>
              <p>CONSULTAS MEDIOAMBIENTALES</p>
            </div>
            <CardDeck>
              <Card>
              <Card.Body>
                <div className='message-ico-enviromental'>
                  <img className='message-ico-png' src="https://cdn-icons-png.flaticon.com/512/520/520648.png"></img>
                  <div class="text-decor"></div>
                </div>
                <Card.Text>
                Aquí puedes realizar cualquier tipo de consulta medioambiental. Responderemos lo más pronto posible.
                </Card.Text>
                <Button variant="outline-success" onClick={() => forwardTo('/contacto-medioambiente-la-linea')}>Realizar consulta</Button>
              </Card.Body>
            </Card>
            </CardDeck>
        </div>

        <div className='incident-enviromental'>
            <Container className='container-incident-enviromanetal'>
              <Row>
                <Col sm={8}>
                  <div className='info-enviromental-title'>
                    <p>Menú ambiental</p>
                  </div>
                  <div className='incident-txt'>
                    <p>Para realizar cualquier tipo de incidencia en la vía urbana en el Ayuntamiento de la Línea de la Concepción, basta con hacerla a través de la App de GECOR.</p>
                    <p>Descarga la App, selecciona tu ayuntamiento, y comienza a hacer incidencias. Es muy sencillo, sólo necesitas seleccionar el tipo de incidencia, aportar una imagen y la ubicación.</p>
                    <p>También tenemos disponible un gestor de incidencias web, para los que no puedan descargar la APP:</p>
                    <Button variant="outline-success" onClick={() => window.open('https://lalineaentumano.es/login?ao=294')}>Gestor de incidencias web</Button>
                  </div>

                  </Col>
                <Col sm={4}>
                <div className='download-enviromental-stores'>
                <div className='info-enviromental-title'>
                    <p>Menú ambiental</p>
                    <div class="text-decor"></div>
                  </div>
                </div>
                <Row className="row-incident">
                          <Col sm={6}>
                            <img tabIndex="12" src="https://gecorcloud.com/images/QRApple.jpg" className="qr-app-store" alt="qr-app-store" />
                            <br></br>
                            <a tabIndex="13" target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/es/app/gecor/id1127259219?mt=8">
                              <img src="https://gecorcloud.com/images/app-store-ico.png" alt="app-store" className="download-store-btn-ln" />
                            </a>
                          </Col>
                          <Col sm={6}>
                            <img tabIndex="14" src="https://gecorcloud.com/images/QRAndroid.jpg" className="qr-google-play" alt="qr-google-play" />
                            <br></br>
                            <a tabIndex="15" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.gecorsystem.gecorn&hl=es">
                              <img src="https://gecorcloud.com/images/google-play-ico.png" className="download-store-btn-ln" alt="google-play" />
                            </a>
                          </Col>
                        </Row>

                </Col>
              </Row>
            </Container>
        </div>
      </div>
      <EntitySearch className="ayto-borde" redirect={false} onSelected={this.handleSelectedEntity} ao={ao} hide={true} />


    </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedMenuLineaPage = connect(mapStateToProps)(MenuLineaPage);
export { connectedMenuLineaPage as MenuLineaPage };