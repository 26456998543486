import { noticesConstants } from '../constants';

const initialState = {
    notices: [],
    notice: undefined
}

export function news(state = initialState, action) {
    switch (action.type) {
        case noticesConstants.NOTICES_GETALL_SUCCESS:
            return {
                ...state,
                notices: action.notices
            }
        case noticesConstants.NOTICES_GETBYID_SUCCESS:
            const notice = action.notice ? action.notice[0] : undefined;
            return {
                ...state,
                notice: notice
            }
        case noticesConstants.NOTICES_CLEAR:
            return {
                ...state,
                notices: initialState.notices
            }
        case noticesConstants.NOTICES_GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            }
        case noticesConstants.NOTICES_GETBYID_FAILURE:
            return {
                ...state,
                error: action.error
            }
        default:
            return state
    }
}