import { contactConstants } from '../constants';

// The initial application state
let initialState = {
    formState: {
        name: '',
        email: '',
        phone: '',
        subject: '',
        body: '',
    },
    error: '',
    sended: false,
    sending: false,
    legalTerms: false,
}

// Takes care of changing the application state
export function contact(state = initialState, action) {
    switch (action.type) {
        case contactConstants.CONTACT_FORM_CLEAR:
            return { ...state, formState: initialState.formState }
        case contactConstants.CONTACT_SEND_SUCCESS:
            return { ...state, sended: action.sended }
        case contactConstants.CONTACT_SENDING_REQUEST:
            return { ...state, currentlySending: action.sending }
        case contactConstants.CONTACT_SEND_FAILURE:
            return { ...state, error: action.error, sended: action.sended }
        case contactConstants.CONTACT_CLEAR_ERROR:
            return { ...state, error: '' }
        case contactConstants.CONTACT_CLEAR_STATE:
            state = initialState
            return state
        default:
            return state
    }
}

export default contact