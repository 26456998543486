import React from 'react';
import './BannerCertificate.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { isWebpSupported } from 'react-image-webp/dist/utils';

export const BannerCertificate = props => {
    const { t, page } = props;
    return (
        <div id={page == 'global'? "banner-certificate-global" :"banner-certificate"} >
            <Container>             
                <Row className="BannerCertificateRow">
                    <Col sm={3}>
                    <a href="https://gecorcloud.com/pdf/Certificado-ISO-9001.pdf" target="_blank" rel="noopener noreferrer" ><img alt={t("banner.bcer-ISO-9001")} src={isWebpSupported() ? "https://gecorcloud.com/images/9001.webp" : "https://gecorcloud.com/images/9001.png"}></img></a>
                    <a href="https://gecorcloud.com/pdf/Certificado-ISO-14001.pdf" target="_blank" rel="noopener noreferrer" ><img alt={t("banner.bcer-ISO-14001")} src={isWebpSupported() ? "https://gecorcloud.com/images/14001.webp" : "https://gecorcloud.com/images/14001.png"}></img></a>
                    </Col>
                    <Col sm={3}>
                        <a href="https://marketplace.fiware.org/pages/solutions/341e349f40a7e26169755d31" target="_blank" rel="noopener noreferrer" ><img alt="Fiware logo" className="fiw" src={isWebpSupported() ? "https://gecorcloud.com/images/fiware-logo.webp" : "https://gecorcloud.com/images/fiware-logo.png"} ></img></a>
                    </Col>
                    <Col sm={3}>
                    <a href="https://gecorcloud.com/pdf/dti.pdf" target="_blank" rel="noopener noreferrer" ><img alt="Red DTI" className="Certificado red dti" src={isWebpSupported() ? "https://gecorcloud.com/images/dti.webp" : "https://gecorcloud.com/images/dti.jpg"}></img></a>
                    </Col>
                    <Col sm={3}>
                    <a href="https://gecorcloud.com/pdf/ens.pdf" target="_blank" rel="noopener noreferrer" ><img alt={t("banner.bcer-ENS")} className="ens" src={isWebpSupported() ? "https://gecorcloud.com/images/ens.webp" : "https://gecorcloud.com/images/ens.png"}></img></a>
                    </Col>
                </Row>
            </Container>
          <p className="copy-ban" >{t("footer.copyright", { year: 2024 })}</p>
        </div>
    );
}

export default BannerCertificate;