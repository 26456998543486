import { take, call, put, fork, race } from 'redux-saga/effects'
import { entityApi, forwardTo } from '../helpers'
import { entityConstants, incidentsConstants } from './../constants'


/**
 * Effect to handle logging out
 */
export function* getEntities() {
    // We tell Redux we're in the middle of a request
    yield put({ type: entityConstants.GETALL_REQUEST, loading: true })

    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const response = yield call(entityApi.getAll)
        yield put({ type: entityConstants.GETALL_SUCCESS, entities: response })

        return response
    } catch (error) {
        yield put({ type: entityConstants.GETALL_FAILURE, error: error.message })
        return false
    } finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
}

/**
 * Effect to handle logging out
 */
export function* getLocalEntities() {
    // We tell Redux we're in the middle of a request
    yield put({ type: entityConstants.GETALL_REQUEST, loading: true })

    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const response = yield call(entityApi.loadEntities)
        yield put({ type: entityConstants.GETALL_SUCCESS, entities: response })

        return response
    } catch (error) {
        yield put({ type: entityConstants.GETALL_FAILURE, error: error.message })
        return false
    } finally {
        yield put({ type: entityConstants.GETALL_REQUEST, loading: false })
    }
}

export function* getEntiyById(payload) {
    // We tell Redux we're in the middle of a request
    yield put({ type: entityConstants.ENTITY_REQUEST, loading: true })

    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {
        const { id } = payload;
        const response = yield call(entityApi.getEntityById, id)
        yield put({ type: entityConstants.ENTITY_SUCCESS, entity: response })

        yield call(getEntityInfo, payload)

        // CONFIG
        const entityConfig = yield call(entityApi.getMoreData(id))
        if (entityConfig)
            yield put({ type: entityConstants.ENTITY_CONFIG, entityConfig })
        else
            yield put({ type: entityConstants.ENTITY_CONFIG, entityConfig: undefined })

        return response
    } catch (error) {
        yield put({ type: entityConstants.ENTITY_FAILURE, error: error.message })
        return false
    } finally {
        yield put({ type: entityConstants.ENTITY_REQUEST, loading: false })
    }
}

export function* getTipologyById({ token }) {
    // yield put({ type: entityConstants.ENTITY_REQUEST, loading: true })

    try {

        // TIPOLOGY
        const idioma = window.localStorage.i18nextLng;
        const tipologyResp = yield call(entityApi.getTipologiaPorAyuntamiento, token, idioma)
        if (tipologyResp && tipologyResp.Elementos && tipologyResp.Incidencias)
            yield put({ type: incidentsConstants.INCIDENTS_TIPOGRAFY, families: tipologyResp.Familias, elements: tipologyResp.Elementos, incidentsTypes: tipologyResp.Incidencias })


        // STREETS
        const streets = yield call(entityApi.getCallesPorAyuntamiento, token)
        if (streets)
            yield put({ type: entityConstants.ENTITY_STREETS, streets })
        else
            yield put({ type: entityConstants.ENTITY_STREETS, streets: undefined })

        // BUILDINGS
        const buildings = yield call(entityApi.getEdificiosPorAyuntamiento, token)
        if (buildings)
            yield put({ type: entityConstants.ENTITY_BUILDINGS, buildings })
        else
            yield put({ type: entityConstants.ENTITY_BUILDINGS, buildings: undefined })

        // UNITS
        const units = yield call(entityApi.getUnidadesPorAyuntamiento, token)
        if (units)
            yield put({ type: incidentsConstants.INCIDENTS_UNITS, unitsTypes: units })
        else
            yield put({ type: incidentsConstants.INCIDENTS_UNITS, unitsTypes: undefined })
        
        // PROMOTIONS
        const promotions = yield call(entityApi.getPromocionesPorAyuntamiento, token)
        if (promotions)
            yield put({ type: incidentsConstants.INCIDENTS_PROMOTIONS, promotionsTypes: promotions })
        else
            yield put({ type: incidentsConstants.INCIDENTS_PROMOTIONS, promotionsTypes: undefined })


    } catch (error) {
        yield put({ type: entityConstants.ENTITY_TIPOLOGY_FAILURE, error: error.message })
        return false
    } finally {
        // yield put({ type: entityConstants.ENTITY_REQUEST, loading: false })
    }
}

export function* getEntityInfo(payload) {
    // We tell Redux we're in the middle of a request
    yield put({ type: entityConstants.ENTITY_REQUEST, loading: true })

    // Similar to above, we try to log out by calling the `logout` function in the
    // `auth` module. If we get an error, we send an appropiate action. If we don't,
    // we return the response.
    try {

        yield call(entityApi.getNotices)

        const { id } = payload;
        const response = yield call(entityApi.getEntityInfo, id)
        yield put({ type: entityConstants.ENTITY_INFO_SUCCESS, entityInfo: response })

        return response
    } catch (error) {
        yield put({ type: entityConstants.ENTITY_INFO_FAILURE, error: error.message })
        return false
    } finally {
        yield put({ type: entityConstants.ENTITY_REQUEST, loading: false })
    }
}

export function* changeEntity() {
    try {
        // entity
        const response = yield call(entityApi.changeEntity);
        yield put({ type: entityConstants.ENTITY_CHANGED, entity: '' })
        // tipology
        yield put({ type: incidentsConstants.INCIDENTS_TIPOGRAFY, families: [], elements: [], incidentsTypes: [], unitsTypes: [], promotionsTypes: []  })
        // config
        yield put({ type: entityConstants.ENTITY_CONFIG, entityConfig: undefined })
        return response
    } catch (error) {
        return false
    }
}

export function* getLocalEntity() {
    try {
        const response = yield call(entityApi.loadEntity);
        yield put({ type: entityConstants.ENTITY_SUCCESS, entity: response })

        return response
    } catch (error) {
        return false
    }
}

export function* getExternalStreets(payload) {
    try {
        const response = yield call(entityApi.getMoreStreetsFromExternalUrl, payload)

        const { features } = response;
        let externalStreets = [];

        if (features) {
            features.forEach(element => {
                let street = {};
                if (element.geometry) {
                    const { coordinates } = element.geometry;
                    street.lng = coordinates[0];
                    street.lat = coordinates[1];
                }
                if (element.properties) {
                    const { properties } = element;
                    street.id = properties.id_numero;
                    street.CalleID = properties.codvial;
                    street.Numero = properties.numero;
                    street.Bis = properties.bis;
                    street.TipoVia = properties.abrtipvial;
                    street.Nombre = properties.nomvial;
                }
                externalStreets = [...externalStreets, street];
            });
        }

        yield put({ type: entityConstants.STREET_SEARCH_SUCCESS, externalStreets })

        return response
    } catch (error) {
        yield put({ type: entityConstants.STREET_SEARCH_FAILURE, error: error.message })
        return false
    } finally {
        // yield put({ type: entityConstants.STREET_SEARCH_REQUEST, loading: false })
    }
}

export function* getEntitiesFlow() {
    while (true) {
        yield take(entityConstants.GETALL_REQUEST)
        const entities = yield call(getLocalEntities)
        if (!entities) {
            yield call(getEntities)
        }
    }
}

export function* getEntiyFlow() {
    while (true) {

        const { data } = yield take(entityConstants.ENTITY_REQUEST)
        if (data) { //si hay parametros...
            const { redirect } = data;
            const winner = yield race({
                getEntityById: call(getEntiyById, data),
                getLocalEntity: take(entityConstants.ENTITY_LOAD),
                changeEntity: take(entityConstants.ENTITY_CHANGE)
            })

            if (winner.getEntityById) {
                yield put({ type: entityConstants.ENTITY_SEARCH_CLEAR })
                if (redirect) forwardTo('/login')
            }
        }
    }
}

export function* getExternalStreetsFlow() {
    while (true) {

        const { streetQuery } = yield take(entityConstants.STREET_SEARCH_REQUEST)

        if (streetQuery) {
            const wasSuccessful = yield call(getExternalStreets, streetQuery)

            // If we could register a user, we send the appropiate actions
            if (wasSuccessful) {
                // yield put({ type: userConstants.SET_AUTH, newAuthState: true }) // User is logged in (authorized) after being registered
                // yield put({ type: userConstants.CHANGE_FORM, newFormState: { username: '', password: '' } }) // Clear form
                // forwardTo('/') // Go to dashboard page
            } else {
                // throw error
            }

        }

        /*
        if (data) { //si hay parametros...
            const { redirect } = data;
            const winner = yield race({
                getEntityById: call(getEntiyById, data),
                getLocalEntity: take(entityConstants.ENTITY_LOAD),
                changeEntity: take(entityConstants.ENTITY_CHANGE)
            })

            if (winner.getEntityById) {
                yield put({ type: entityConstants.ENTITY_SEARCH_CLEAR })
                if (redirect) forwardTo('/login')
            }
        }
        */
    }
}

export function* getTipologyFlow() {
    while (true) {
        // We always listen to `UPDATE_REQUEST` actions
        const request = yield take(entityConstants.ENTITY_TIPOLOGY_REQUEST)
        const { data } = request

        // We call the `authorize` task with the data, telling it that we are registering a user
        // This returns `true` if the registering was successful, `false` if not
        const wasSuccessful = yield call(getTipologyById, data)

        // If we could register a user, we send the appropiate actions
        if (wasSuccessful) {
            // yield put({ type: userConstants.SET_AUTH, newAuthState: true }) // User is logged in (authorized) after being registered
            // yield put({ type: userConstants.CHANGE_FORM, newFormState: { username: '', password: '' } }) // Clear form
            // forwardTo('/') // Go to dashboard page
        } else {
            // throw error
        }
    }
}

export function* changeEntityFlow() {
    while (true) {
        yield take(entityConstants.ENTITY_CHANGE)

        yield call(changeEntity)
        // forwardTo('/entities')
    }
}

export function* localEntityFlow() {
    while (true) {
        yield take(entityConstants.ENTITY_LOAD)
        const localEntity = yield call(getLocalEntity)

        if (!localEntity)
            forwardTo('/login')
        /*
        else
            forwardTo('/login')
        */
    }
}

export default function* () {
    yield fork(getEntitiesFlow)
    yield fork(getEntiyFlow)
    yield fork(getTipologyFlow)
    yield fork(changeEntityFlow)
    yield fork(localEntityFlow)
    yield fork(getExternalStreetsFlow)
}