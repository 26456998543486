import React from 'react';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import './NatureValverdePage.css';

class NatureValverdePage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }


  constructor(props) {
    super(props);

      this.state = { eventKey: this.props.location.state && this.props.location.state.eventKey ? this.props.location.state.eventKey : "first" };
      this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange = (e) => {
      this.setState({ eventKey: e })
    }

  render() {
    return (
      <div className='medioambiente-valverde'>
        <Container>
          <div className='valv-title'>
            <h1>INFORMACIÓN MEDIOAMBIENTAL</h1>
            <hr></hr>
          </div>
          <div className='collapse-valverde'>
            <Tab.Container defaultActiveKey={this.state.eventKey} activeKey={this.state.eventKey} id="left-tabs-example">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link onClick={() => this.handleTabChange("first")} eventKey="first">Rutas y caminos</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.handleTabChange("second")} eventKey="second">Fauna</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.handleTabChange("third")} eventKey="third">Flora</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.handleTabChange("four")} eventKey="four">Hidrologia</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.handleTabChange("five")} eventKey="five">Micología</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.handleTabChange("six")} eventKey="six">Patrimonio</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.handleTabChange("seven")} eventKey="seven">Unidad didáctica</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link onClick={() => this.handleTabChange("eigth")} eventKey="eigth">Punto Limpio</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <ol> </ol>
                        <p>1. Rutas de senderismo por el término municipal de Valverde del Camino:</p>
                        <hr></hr>
                        <ol> </ol> 
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta01g.jpg" target="_blank" rel="noopener noreferrer">R1 Lomero Llano y Arroyo de la Lapa.</a></li>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta01p.jpg" target="_blank" rel="noopener noreferrer">R1 Mapa.</a></li>
                        </ul>
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta02g.jpg" target="_blank" rel="noopener noreferrer">R2 Las tres riveras y antigua mina de la Ratera.</a></li>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta02p.jpg" target="_blank" rel="noopener noreferrer">R2 Mapa.</a></li>
                        </ul>
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta03g.jpg" target="_blank" rel="noopener noreferrer">R3 La dehesa y dólmenes de los Gabrieles.</a></li>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta03p.jpg" target="_blank" rel="noopener noreferrer">R3 Mapa.</a></li>
                        </ul>
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta04g.jpg" target="_blank" rel="noopener noreferrer">R4 Rivera de Valverde en su curso medio.</a></li>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta04p.jpg" target="_blank" rel="noopener noreferrer">R4 Mapa.</a></li>
                        </ul>
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta05g.jpg" target="_blank" rel="noopener noreferrer">R5 Dehesa de los Machos y camino romano.</a></li>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta05p.jpg" target="_blank" rel="noopener noreferrer">R5 Mapa.</a></li>
                        </ul>
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta06g.jpg" target="_blank" rel="noopener noreferrer">R6 Sierra Rite y Castaño.</a></li>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta06p.jpg" target="_blank" rel="noopener noreferrer">R6 Mapa. </a></li>
                        </ul>
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta07g.jpg" target="_blank" rel="noopener noreferrer">R7 Minas de Campanario.</a></li>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta07p.jpg" target="_blank" rel="noopener noreferrer">R7 Mapa.</a></li>
                        </ul>
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta08g.jpg" target="_blank" rel="noopener noreferrer">R8 Molinos del Odiel.</a></li>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta08p.jpg" target="_blank" rel="noopener noreferrer">R8 Mapa.</a></li>
                        </ul>
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta09g.jpg" target="_blank" rel="noopener noreferrer">R9 Paraje de la Parrillera y Vía Verde.</a></li>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta09p.jpg" target="_blank" rel="noopener noreferrer">R9 Mapa.</a></li>
                        </ul>
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta10g.jpg" target="_blank" rel="noopener noreferrer">R10 Pico del águila, Carabales y Fresnajoso.</a></li>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta10p.jpg" target="_blank" rel="noopener noreferrer">R10 Mapa.</a></li>
                        </ul>
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta11g.jpg" target="_blank" rel="noopener noreferrer">R11 Dehesa de las Capellanías y Lomero Llano.</a></li>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta11p.jpg" target="_blank" rel="noopener noreferrer">R11 Mapa.</a></li>
                        </ul>
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta12g.jpg" target="_blank" rel="noopener noreferrer">R12 Parque periurbano el Saltillo y Lomero Llano.</a></li>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/rutas/ruta12p.jpg" target="_blank" rel="noopener noreferrer">R12 Mapa.</a></li>
                        </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p> </p>
                      <p>2. Guía de fauna de Valverde del Camino</p>
                      <hr></hr>
                      <ul>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/fauna/indicecomun.pdf" target="_blank" rel="noopener noreferrer">Indice común.</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/fauna/indicecientifico.pdf" target="_blank" rel="noopener noreferrer">Indice científico.</a></li>
                      </ul>
                      <ul>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/fauna/introduccion.pdf" target="_blank" rel="noopener noreferrer">Introducción.</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/fauna/anfibios.pdf" target="_blank" rel="noopener noreferrer">Anfibios</a>.</li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/fauna/aves.pdf" target="_blank" rel="noopener noreferrer">Aves.</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/fauna/mamiferos.pdf" target="_blank" rel="noopener noreferrer">Mamíferos.</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/fauna/peces.pdf" target="_blank" rel="noopener noreferrer">Peces</a>.</li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/fauna/reptiles.pdf" target="_blank" rel="noopener noreferrer">Reptiles</a></li>
                      </ul>
                      <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/fauna/bibliografia.pdf" target="_blank" rel="noopener noreferrer">Bibliografía</a>.</li>
                        </ul>
                        <ul>
                        <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/fauna/glosario.pdf" target="_blank" rel="noopener noreferrer">Glosario</a>.</li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p> </p>
                      <p>3. Flora</p>
                      <hr></hr>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/00Introduccion.pdf" target="_blank" rel="noopener noreferrer">Introducción.</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/01Equisetaceae.pdf" target="_blank" rel="noopener noreferrer">01 Equisetaceae.pdf</a></li>
                      </ul>
                      <ul>
                      </ul>
                      <ul>
                      </ul>
                      <ul>
                      </ul>
                      <ul>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/02Polypodiaceae.pdf" target="_blank" rel="noopener noreferrer">02 Polypodiaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/03Sinopteridaceae.pdf" target="_blank" rel="noopener noreferrer">03 Sinopteridaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/04Adiantaceae.pdf" target="_blank" rel="noopener noreferrer">04 Adiantaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/05Hypolepidaceae.pdf" target="_blank" rel="noopener noreferrer">05 Hypolepidaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/06Aspleniaceae.pdf" target="_blank" rel="noopener noreferrer">06 Aspleniaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/07Pinaceae.pdf" target="_blank" rel="noopener noreferrer">07 Pinaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/08Lauraceae.pdf" target="_blank" rel="noopener noreferrer">08 Lauraceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/09Aristolochiaceae.pdf" target="_blank" rel="noopener noreferrer">09 Aristolochiaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/10Ranunculaceae.pdf" target="_blank" rel="noopener noreferrer">10 Ranunculaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/11Papaveraceae.pdf" target="_blank" rel="noopener noreferrer">11 Papaveraceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/12Fumariaceae.pdf" target="_blank" rel="noopener noreferrer">12 Fumariaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/13Ulmaceae.pdf" target="_blank" rel="noopener noreferrer">13 Ulmaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/14Moraceae.pdf" target="_blank" rel="noopener noreferrer">14 Moraceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/15Urticaceae.pdf" target="_blank" rel="noopener noreferrer">15 Urticaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/16Fagaceae.pdf" target="_blank" rel="noopener noreferrer">16 Fagaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/17Cactaceae.pdf" target="_blank" rel="noopener noreferrer">17 Cactaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/18Chenopodiaceae.pdf" target="_blank" rel="noopener noreferrer">18 Chenopodiaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/19Portulacaceae.pdf" target="_blank" rel="noopener noreferrer">19 Portulacaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/20Caryophyllaceae.pdf" target="_blank" rel="noopener noreferrer">20 Caryophyllaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/21Polygonaceae.pdf" target="_blank" rel="noopener noreferrer">21 Polygonaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/22Plumbaginaceae.pdf" target="_blank" rel="noopener noreferrer">22 Plumbaginaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/23Clusiaceae.pdf" target="_blank" rel="noopener noreferrer">23 Clusiaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/24Malvaceae.pdf" target="_blank" rel="noopener noreferrer">24 Malvaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/25Cistaceae.pdf" target="_blank" rel="noopener noreferrer">25 Cistaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/26Tamaricaceae.pdf" target="_blank" rel="noopener noreferrer">26 Tamaricaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/27Cucurbitaceae.pdf" target="_blank" rel="noopener noreferrer">27 Cucurbitaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/28Salicaceae.pdf" target="_blank" rel="noopener noreferrer">28 Salicaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/29Capparaceae.pdf" target="_blank" rel="noopener noreferrer">29 Capparaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/30Brassicaceae.pdf" target="_blank" rel="noopener noreferrer">30 Brassicaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/31Resedaceae.pdf" target="_blank" rel="noopener noreferrer">31 Resedaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/32Ericaceae.pdf" target="_blank" rel="noopener noreferrer">32 Ericaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/33Primulaceae.pdf" target="_blank" rel="noopener noreferrer">33 Primulaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/34Crassulaceae.pdf" target="_blank" rel="noopener noreferrer">34 Crassulaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/35Saxifragaceae.pdf" target="_blank" rel="noopener noreferrer">35 Saxifragaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/36Rosaceae.pdf" target="_blank" rel="noopener noreferrer">36 Rosaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/37Caesalpiniaceae.pdf" target="_blank" rel="noopener noreferrer">37 Caesalpiniaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/38Papilionaceae.pdf" target="_blank" rel="noopener noreferrer">38 Papilionaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/39Lythraceae.pdf" target="_blank" rel="noopener noreferrer">39 Lythraceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/40Thymeleaceae.pdf" target="_blank" rel="noopener noreferrer">40 Thymeleaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/41Myrtaceae.pdf" target="_blank" rel="noopener noreferrer">41 Myrtaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/42Santalaceae.pdf" target="_blank" rel="noopener noreferrer">42 Santalaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/43Raflessiaceae.pdf" target="_blank" rel="noopener noreferrer">43 Raflessiaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/44Euphorbiaceae.pdf" target="_blank" rel="noopener noreferrer">44 Euphorbiaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/45Rhamnaceae.pdf" target="_blank" rel="noopener noreferrer">45 Rhamnaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/46Vitaceae.pdf" target="_blank" rel="noopener noreferrer">46 Vitaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/47Linaceae.pdf" target="_blank" rel="noopener noreferrer">47 Linaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/48Anacardiaceae.pdf" target="_blank" rel="noopener noreferrer">48 Anacardiaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/49Rutaceae.pdf" target="_blank" rel="noopener noreferrer">49 Rutaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/50Oxalidaceae.pdf" target="_blank" rel="noopener noreferrer">50 Oxalidaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/51Geraniaceae.pdf" target="_blank" rel="noopener noreferrer">51 Geraniaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/52Araliaceae.pdf" target="_blank" rel="noopener noreferrer">52 Araliaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/53Apiaceae.pdf" target="_blank" rel="noopener noreferrer">53 Apiaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/54Gentianaceae.pdf" target="_blank" rel="noopener noreferrer">54 Gentianaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/55Apocynaceae.pdf" target="_blank" rel="noopener noreferrer">55 Apocynaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/56Solanaceae.pdf" target="_blank" rel="noopener noreferrer">56 Solanaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/57Convolvulaceae.pdf" target="_blank" rel="noopener noreferrer">57 Convolvulaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/58Boraginaceae.pdf" target="_blank" rel="noopener noreferrer">58 Boraginaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/59Verbenaceae.pdf" target="_blank" rel="noopener noreferrer">59 Verbenaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/60Lamiaceae.pdf" target="_blank" rel="noopener noreferrer">60 Lamiaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/61Plantaginaceae.pdf" target="_blank" rel="noopener noreferrer">61 Plantaginaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/62Oleaceae.pdf" target="_blank" rel="noopener noreferrer">62 Oleaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/63Scrophulariaceae.pdf" target="_blank" rel="noopener noreferrer">63 Scrophulariaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/64Orobanchaceae.pdf" target="_blank" rel="noopener noreferrer">64 Orobanchaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/65Campanulaceae.pdf" target="_blank" rel="noopener noreferrer">65 Campanulaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/66Rubiaceae.pdf" target="_blank" rel="noopener noreferrer">66 Rubiaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/67Caprifoliaceae.pdf" target="_blank" rel="noopener noreferrer">67 Caprifoliaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/68Valerianaceae.pdf" target="_blank" rel="noopener noreferrer">68 Valerianaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/69Dipsacaceae.pdf" target="_blank" rel="noopener noreferrer">69 Dipsacaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/70Asteraceae.pdf" target="_blank" rel="noopener noreferrer">70 Asteraceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/71Alismataceae.pdf" target="_blank" rel="noopener noreferrer">71 Alismataceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/72Arecaceae.pdf" target="_blank" rel="noopener noreferrer">72 Arecaceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/73Araceae.pdf" target="_blank" rel="noopener noreferrer">73 Araceae.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/flora/74Juncaceae.pdf" target="_blank" rel="noopener noreferrer">74 Juncaceae.pdf</a></li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="four">
                      <p>4. Hidrologia</p>
                      <hr></hr>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/hidrologia/fuentes.pdf" target="_blank" rel="noopener noreferrer">Fuentes.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/hidrologia/publicacion_hidrologia.pdf" target="_blank" rel="noopener noreferrer">Publicación hidrología.pdf</a></li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="five">
                      <p>5.  Micología.</p>
                      <hr></hr>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/micologia/micologia.pdf" target="_blank" rel="noopener noreferrer">Micologia.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/micologia/poster_micologia.jpg" target="_blank" rel="noopener noreferrer">Poster micologia.jpg</a></li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="six">
                      <p>6. Patrimonio</p>
                      <hr></hr>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/patrimonio/publicacion_patrimonio.pdf" target="_blank" rel="noopener noreferrer">Publicacion patrimonio.pdf</a></li>
                      </ul>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/patrimonio/panel_cronologico.jpg" target="_blank" rel="noopener noreferrer">Panel cronologico.jpg</a></li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="seven">
                      <p>7. Unidad didáctica</p>
                      <hr></hr>
                      <ul>
                      <li><a href="https://www.valverdedelcamino.es/repositorio/valverdeverde/unidades/unidades_didacticas.pdf" target="_blank" rel="noopener noreferrer">Unidades_didacticas.pdf</a></li>
                      </ul>
                    </Tab.Pane>
                    <Tab.Pane eventKey="eigth">
                      <p>8. Punto limpio</p>
                      <hr></hr>
                      <p>El Punto Limpio de Valverde es una instalación municipal para la recepción y almacenamiento temporal de ciertos residuos generados en el ámbito doméstico. Los ciudadanos que voluntaria y gratuitamente depositen sus residuos en el Punto Limpio estarán colaborando de forma significativa en la mejora del Medio Ambiente.</p>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedNatureValverdePage = connect(mapStateToProps)(NatureValverdePage);
export { connectedNatureValverdePage as NatureValverdePage };