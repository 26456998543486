import React from 'react';
import './Cookie.css';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Cookies from "js-cookie";

class CookiePage extends React.Component {

    rechazarCookiesGA() {
        // const cargaGA = false
        const obj = Cookies.get()
        /*
        Object.keys(obj).forEach(key => {
            if (key != 'CookieConsent' && cargaGA)
                Cookies.remove(key, 'pathne');
        });
        */
        //Cookies.set("cookie-rejected", true);
    }

    aceptarCookiesGa() {
        /*
        Cookies.set("cookie-rejected", false);
        */
    }

    render() {
        return (
            <Container className="cookies text-justify float-center">
                <div className="WordSection1">
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#333333"
        }}
      >
        1.
      </span>
    </b>
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#008EAA"
        }}
      >
        &nbsp;¿QUÉ SON LAS COOKIES?
      </span>
    </b>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span className="SpellE">
      <b>
        <span
          style={{
            fontFamily: '"Garamond",serif',
            msoBidiFontFamily: '"Times New Roman"'
          }}
        >
          Gecor
        </span>
      </b>
    </span>
    <b>
      <span
        style={{
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"'
        }}
      >
        {" "}
        <span className="SpellE">System</span> S.L.U
      </span>
    </b>
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      {" "}
      es responsable de la página web https://gecorweb.com, y utiliza cookies
      y/u otros archivos de funcionalidad similar (en adelante "Cookies").
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "18.0pt" }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      Una cookie es un archivo de texto que un sitio web, aplicación u otra
      plataforma guarda en tu dispositivo, como tu computadora, tableta o
      teléfono inteligente. Contiene información sobre tu navegación o uso, como
      una etiqueta que identifica tu dispositivo. Las cookies son necesarias
      para facilitar la navegación y comprender cómo interactúan los usuarios
      con las webs, lo que permite realizar mejoras. También son útiles para
      personalizar la publicidad según tus preferencias y para otras finalidades
      detalladas más adelante. Las cookies no dañan tu dispositivo.
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "18.0pt" }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      <p>&nbsp;</p>
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#333333"
        }}
      >
        2.
      </span>
    </b>
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#008EAA"
        }}
      >
        &nbsp;TIPOLOGÍA DE COOKIES{" "}
      </span>
    </b>
  </p>
  <p className="TextBody">
    <span
      style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Garamond",serif'
      }}
    >
      Según el propietario de las cookies, se pueden clasificar en:
      <p />
    </span>
  </p>
  <p
    className="TextBody"
    style={{
      marginLeft: "36.0pt",
      textIndent: "-18.0pt",
      msoList: "l1 level1 lfo1",
      tabStops: "list 36.0pt"
    }}
  >
    {/*[if !supportLists]*/}
    <span style={{ msoBidiFontFamily: "Calibri" }}>
      <span style={{ msoList: "Ignore" }}>
        a)
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
    </span>
    {/*[endif]*/}
    <span
      style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"',
        color: "#008EAA"
      }}
    >
      Cookies propias
    </span>
    <span
      style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Garamond",serif'
      }}
    >
      : Estas cookies se envían al ordenador o dispositivo del usuario desde el
      equipo o dominio gestionado por el propio editor, que proporciona la web o
      servicio solicitado por el usuario.
    </span>
  </p>
  <p
    className="TextBody"
    style={{
      marginLeft: "36.0pt",
      textIndent: "-18.0pt",
      msoList: "l1 level1 lfo1",
      tabStops: "list 36.0pt"
    }}
  >
    {/*[if !supportLists]*/}
    <span style={{ msoBidiFontFamily: "Calibri" }}>
      <span style={{ msoList: "Ignore" }}>
        b)
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </span>
    </span>
    {/*[endif]*/}
    <span
      style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"',
        color: "#008EAA"
      }}
    >
      Cookies de terceros
    </span>
    <span
      style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Garamond",serif'
      }}
    >
      : Estas cookies se envían al ordenador o dispositivo del usuario desde un
      equipo o dominio que no es gestionado por el editor, sino por otra entidad
      que procesa los datos obtenidos a través de las cookies.
    </span>
    <span
      style={{
        fontSize: "12.0pt",
        lineHeight: "115%",
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      {" "}
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "3.75pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      En función de la finalidad de la Cookie, pueden clasificarse en:
      <p />
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "3.75pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      <p>&nbsp;</p>
    </span>
  </p>
  <ul style={{ marginTop: "0cm" }} type="disc">
    <li
      className="MsoNormal"
      style={{
        marginBottom: "3.75pt",
        textAlign: "justify",
        lineHeight: "18.0pt",
        msoList: "l0 level1 lfo2",
        msoHyphenate: "none",
        tabStops: "list 36.0pt"
      }}
    >
      <span
        style={{
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"'
        }}
      >
        Las
        <b>
          <u>cookies estrictamente necesarias (técnicas)</u>
        </b>{" "}
        son aquellas que permiten al usuario navegar por una página web,
        plataforma o aplicación y utilizar las diferentes opciones o servicios
        que se ofrecen en ellas. Estas cookies desempeñan funciones esenciales,
        como controlar el tráfico, identificar datos o sesiones, acceder a
        secciones restringidas, recordar elementos de un pedido, gestionar
        pagos, garantizar la seguridad del servicio, permitir la participación
        en eventos, almacenar contenidos multimedia y compartir contenido en
        redes sociales. Estas cookies técnicas se descargan automáticamente
        cuando se muestra la web o se brinda el servicio solicitado por el
        usuario.
      </span>
    </li>
  </ul>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "3.75pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      <p>&nbsp;</p>
    </span>
  </p>
  <ul style={{ marginTop: "0cm" }} type="disc">
    <li
      className="MsoNormal"
      style={{
        marginBottom: "3.75pt",
        textAlign: "justify",
        lineHeight: "18.0pt",
        msoList: "l0 level1 lfo2",
        msoHyphenate: "none",
        tabStops: "list 36.0pt"
      }}
    >
      <span
        style={{
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"'
        }}
      >
        Las
        <b>
          <u>cookies de funcionalidad o personalización</u>
        </b>{" "}
        son aquellas que permiten recordar información para proporcionar al
        usuario un servicio o plataforma con características específicas que
        diferencian su experiencia de la de otros usuarios. Estas
        características pueden incluir el idioma, el número de resultados
        mostrados en una búsqueda, el aspecto o contenido del servicio en
        función del navegador utilizado o la región desde la que se accede al
        servicio, entre otros. No aceptar estas cookies puede resultar en un
        rendimiento lento de la página web o recomendaciones inadecuadas.
      </span>
    </li>
  </ul>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "3.75pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      <p>&nbsp;</p>
    </span>
  </p>
  <ul style={{ marginTop: "0cm" }} type="disc">
    <li
      className="MsoNormal"
      style={{
        marginBottom: "3.75pt",
        textAlign: "justify",
        lineHeight: "18.0pt",
        msoList: "l0 level1 lfo2",
        msoHyphenate: "none",
        tabStops: "list 36.0pt"
      }}
    >
      <span
        style={{
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"'
        }}
      >
        Las
        <b>
          <u>cookies de análisis</u>
        </b>{" "}
        se emplean para recopilar información sobre el número de usuarios, las
        secciones visitadas en la web y su interacción con la misma. Estos datos
        se utilizan para realizar un análisis estadístico de la utilización que
        hacen los usuarios del servicio, con el objetivo de identificar áreas de
        mejora basadas en los patrones de uso.
      </span>
    </li>
  </ul>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "3.75pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      <p>&nbsp;</p>
    </span>
  </p>
  <ul style={{ marginTop: "0cm" }} type="disc">
    <li
      className="MsoNormal"
      style={{
        marginBottom: "14.0pt",
        textAlign: "justify",
        lineHeight: "19.2pt",
        msoList: "l0 level1 lfo2",
        msoHyphenate: "none",
        tabStops: "list 36.0pt"
      }}
    >
      <span
        style={{
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"'
        }}
      >
        Las
        <b>
          <u>cookies de publicidad comportamental</u>
        </b>{" "}
        registran y almacenan información sobre los hábitos de navegación de los
        usuarios, lo que facilita la creación de perfiles específicos para
        mostrar anuncios personalizados. Estas cookies permiten una gestión
        eficiente de los espacios publicitarios que el editor ha incluido, ya
        sea de forma directa o en colaboración con terceros.
        <b>
          <p />
        </b>
      </span>
    </li>
  </ul>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#333333"
        }}
      >
        3.
      </span>
    </b>
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#008EAA"
        }}
      >
        &nbsp;FINALIDAD DE LAS COOKIES EN NUESTRA WEB
      </span>
    </b>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      Las cookies desempeñan un papel fundamental en el funcionamiento de
      nuestra web. Su objetivo principal es permitir una navegación más cómoda y
      eficiente. Por ejemplo, se utilizan para recordar tus preferencias durante
      la navegación y en futuras visitas.{" "}
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      También nos ayudan a personalizar la publicidad en función de tus hábitos
      de navegación. La información recopilada a través de las cookies nos
      permite realizar estimaciones estadísticas y análisis de uso, como el
      número de visitas y las secciones más consultadas. Esto nos permite
      mejorar nuestros servicios y adaptar la plataforma a tus intereses
      individuales. <p />
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      En algunas ocasiones, utilizamos cookies para recopilar información que
      nos permite mostrar publicidad basada en tus hábitos de navegación en
      nuestra plataforma y otras plataformas de terceros.
      <p />
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      A continuación, se detallan tanto los terceros que pueden configurar y
      acceder a Cookies de su equipo, como las finalidades con las que se
      utiliza la información que almacenan:
      <p />
    </span>
  </p>
  <p className="TextBody">
    <p>&nbsp;</p>
  </p>
  <span
    style={{
      fontSize: "12.0pt",
      fontFamily: '"Aptos",sans-serif',
      msoAsciiThemeFont: "minor-latin",
      msoFareastFontFamily: "Aptos",
      msoFareastThemeFont: "minor-latin",
      msoHansiThemeFont: "minor-latin",
      msoBidiFontFamily: '"Times New Roman"',
      msoBidiThemeFont: "minor-bidi",
      msoAnsiLanguage: "ES",
      msoFareastLanguage: "EN-US",
      msoBidiLanguage: "AR-SA"
    }}
  >
    <br
      clear="all"
      style={{ msoSpecialCharacter: "line-break", pageBreakBefore: "always" }}
    />
  </span>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "3.75pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      <p>&nbsp;</p>
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#333333"
        }}
      >
        4.
      </span>
    </b>
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#008EAA"
        }}
      >
        &nbsp;GESTIÓN COOKIES EN NUESTRA WEB
      </span>
    </b>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      En el panel de configuración de cookies, al que puedes acceder en
      cualquier momento en nuestra plataforma, encontrarás información detallada
      sobre las cookies que utilizamos. En él, se especifica su propósito,
      duración y quién las gestiona (nosotros o terceros). Este panel te permite
      gestionar la activación y desactivación de aquellas cookies que no son
      estrictamente necesarias para el funcionamiento de la plataforma, además
      de cambiar tu consentimiento en cualquier momento.{" "}
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      Puedes acceder a la configuración pulsando{" "}
      <b>
        <u>
          <a href="">
            aquí
          </a>
        </u>
      </b>
      <span>,</span>{" "}
      para cambiar tus preferencias o retirar su consentimiento{" "}
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      Puedes ejercer esta opción de evitar el uso de Cookies en cualquier
      momento. Ten en cuenta que la gestión del panel de configuración de
      Cookies y la opción de rechazarlas son específicas para cada navegador que
      utilices. <p />
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      Por lo tanto, si configuras las Cookies de una determinada manera en un
      dispositivo y deseas que esa opción se aplique de manera similar en otro
      dispositivo, deberás activar la misma opción en ese otro dispositivo.
      <p />
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "18.0pt" }}
  >
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#333333"
        }}
      >
        5.
      </span>
    </b>
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#008EAA"
        }}
      >
        &nbsp;USO DE LA INFORMACIÓN ALMACENADA EN LAS COOKIES
      </span>
    </b>
  </p>
  <p
    className="MsoNormal"
    style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "18.0pt" }}
  >
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#008EAA"
        }}
      >
        <p>&nbsp;</p>
      </span>
    </b>
  </p>
  <p
    className="MsoNormal"
    style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "18.0pt" }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      La información almacenada en las Cookies de nuestra web es utilizada
      únicamente por nosotros. Sin embargo, algunas Cookies de terceros,
      gestionadas por entidades externas, pueden ser utilizadas para brindarnos
      servicios adicionales. Para más detalles, consulta nuestro panel de
      configuración de Cookies, accesible en todo momento en nuestra web.
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "18.0pt" }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      <p>&nbsp;</p>
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "18.0pt" }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      Te sugerimos que revises nuestra Política de Privacidad en nuestra web
      para obtener información detallada sobre cómo se tratan tus datos
      personales en nuestra colaboración con terceros, así como en las
      transferencias internacionales de datos. Además, te recomendamos que
      consultes las políticas de privacidad y las configuraciones de privacidad
      de estos colaboradores externos a través de sus propias plataformas.
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "18.0pt" }}
  >
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#333333"
        }}
      >
        <p>&nbsp;</p>
      </span>
    </b>
  </p>
  <p
    className="MsoNormal"
    style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "18.0pt" }}
  >
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#333333"
        }}
      >
        6.
      </span>
    </b>
    <b>
      <span
        style={{
          fontSize: "14.0pt",
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"',
          color: "#008EAA"
        }}
      >
        &nbsp;ACTUALIZACIONES Y CAMBIOS EN LA POLÍTICA DE COOKIES
      </span>
    </b>
  </p>
  <p
    className="MsoNormal"
    style={{
      marginBottom: "18.0pt",
      textAlign: "justify",
      lineHeight: "18.0pt"
    }}
  >
    <span className="SpellE">
      <b>
        <span
          style={{
            fontFamily: '"Garamond",serif',
            msoBidiFontFamily: '"Times New Roman"'
          }}
        >
          Gecor
        </span>
      </b>
    </span>
    <b>
      <span
        style={{
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"'
        }}
      >
        {" "}
        <span className="SpellE">System</span> S.L.U
      </span>
    </b>
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      {" "}
      puede modificar esta Política de Cookies en función de nuevas exigencias
      legislativas, reglamentarias, o con la finalidad de adaptar dicha política
      a las instrucciones dictadas por la Agencia Española de Protección de
      Datos.
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "18.0pt" }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      Si desea más información sobre qué uso hacemos de las Cookies, puede
      enviarnos un e-mail a:{" "}
    </span>
    <a href="mailtinfo@gecorweb.com">
      <span
        style={{
          fontFamily: '"Garamond",serif',
          msoBidiFontFamily: '"Times New Roman"'
        }}
      >
        info@gecorweb.com
      </span>
    </a>
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      <p />
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "18.0pt" }}
  >
    <span
      style={{
        fontFamily: '"Garamond",serif',
        msoBidiFontFamily: '"Times New Roman"'
      }}
    >
      <p>&nbsp;</p>
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{ marginBottom: "0cm", textAlign: "justify", lineHeight: "18.0pt" }}
  >
    <p>&nbsp;</p>
  </p>
  <p className="MsoNormal">
    <p>&nbsp;</p>
  </p>
</div>
<button type="button" data-cc="show-preferencesModal">Manage cookie preferences</button>
            </Container>
        );
    }
}

export { CookiePage };