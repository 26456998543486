export const config = {
  apiUrl: "https://gecorapiwe.azurewebsites.net/api/",
  apiUrlCemi: "https://gestmovil.malaga.eu/GecorApi/api",
  apiUrlCemiPruebas: "http://cgestmovil.aytomalaga.intranet/GecorApi/api",
  apiUrlStaging: "https://gecorapiwe-gecorapiwe-s1.azurewebsites.net/api/",
  apiUrlDaniPc: "http://192.168.1.76/gecorapi/api",
  apiUrlJuniorPc: "http://192.168.1.25/gecorapi/api",
  apiUrlJosePc: "http://192.168.1.147/gecorapi/api",
  apiUrlLocalhost: "http://localhost/gecorapi/api",
  infoApiUrl: "https://infogecorwe.azurewebsites.net/api",
  infoApiUrlStaging: "http://infogecorwe-infogecorwe-s1.azurewebsites.net/api",
  infoApiUrlCemi: "https://gestmovil.malaga.eu/infogecor/api",
  infoApiUrlCemiPruebas: "http://cgestmovil.aytomalaga.intranet/infogecor/api",
  infoApiUrlJosePC: "http://192.168.1.147/infogecor/api",
  infoApiUrlJuniorPC: "http://192.168.1.25/infogecor/api",
  infoApiUrlDaniPC: "http://192.168.1.76/infogecor/api",
  infoApiUrlLocalhost: "http://localhost/InfoGecor/api",
  blobGecorPath: "https://gecorsystem.blob.core.windows.net/public/",
  apiUrlFuengirola: "https://fuengirola.gecorcloud.com/gecorapi/api",
  apiUrlVera:"https://gecor.vera.es/gecorapi/api"
};

export const apiUrl = process.env.REACT_APP_API_URL || config.apiUrl;
export const apiUrlVera = config.apiUrlVera;
export const apiUrlFuengirola = config.apiUrlFuengirola;
export const infoApiUrl = process.env.REACT_APP_INFO_API_URL || config.infoApiUrl;
export const defaultEmail = process.env.REACT_APP_DEFAULT_EMAIL || "info@gecorweb.com"; // 'info@gecorweb.com';
export const defaultAyuntamientoID = process.env.REACT_APP_AYTO_ID || 19; // para malaga hay que ponerlo a 1!!!!
export const blobGecorPath = process.env.REACT_APP_BLOB_URL || config.blobGecorPath;
export const REACT_APP_MAP_KEY = process.env.REACT_APP_MAP_KEY || "AIzaSyA-89Oq-WqMG2mrwAO76RegV5iUTcDE0gc";
export const MALAGA_CENTER = process.env.REACT_APP_MAP_CENTER || [36.7182857, -4.4842863];

export const googleClientId = "926032824743-4anr5pt7fj7vip0suimc0pid6528bg81.apps.googleusercontent.com";
export const facebookAppId = "966036306826184";