import { infoApiUrl } from '../../constants';

export const surveysApi = {
 
    getSurveys() {
        // const { AyuntamientoID } = JSON.parse(entity);
        const entity = JSON.parse(localStorage.getItem('entity'));
        const user = JSON.parse(localStorage.getItem('user'));
        const { token, CiudadanoID } = user;
        const { AyuntamientoID } = entity;

        const params = {
            token,
            ayuntamientoID: AyuntamientoID,
            ciudadanoID: CiudadanoID,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };
        return fetch(`${infoApiUrl}/getEncuestasUsuario`, requestOptions)
            .then(response => response.json())
            .then(surveys => {
                localStorage.setItem('mySurveys', JSON.stringify(surveys));               
                return surveys
            });
    },

    getSurveyDetail(EncuestaID) {
        const _surveys = surveysApi.loadSurveys()
        const pending = _surveys.Pendientes.filter(survey => survey.EncuestaID === 0 || survey.EncuestaID === parseInt(EncuestaID)) 
        const conducted = _surveys.Realizadas.filter(survey => survey.EncuestaID === 0 || survey.EncuestaID === parseInt(EncuestaID))
        return pending[0] || conducted[0]
    },
    
    sendSurvey(data, user = localStorage.user, entity = localStorage.entity) {

        const { token} = JSON.parse(user);
        const { TokenAyuntamiento} = JSON.parse(entity);
        const { encuestaID, preguntas} = data;
        
        const params = {
            token,
            tokenAyto: TokenAyuntamiento,
            encuestaID: encuestaID,
            preguntas: preguntas
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        return fetch(`${infoApiUrl}/respuestas/setRespuestasEncuestas`, requestOptions)
            .then(response => response.json())
            .then(RowsAffected => {
                // localStorage.setItem('myIncidents', JSON.stringify(incidents));         
                return { ...RowsAffected }
            });
    },



    loadSurveys() {
        return JSON.parse(localStorage.getItem('mySurveys')) || undefined;
    },  
    onChange() { }
}

export default surveysApi
