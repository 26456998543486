import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import './ContactAytoPageCh.css';
import { DynamicBackground } from '../../../../components'
import { isWebpSupported } from 'react-image-webp/dist/utils';

class ContactAytoPageCh extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {

    const { entity } = this.props;


    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/michelangelo-71282_960_720.webp" : "https://gecorcloud.com/images/michelangelo-71282_960_720.jpg"}>
        <div className="container">

          <div className="infoBox">
            <div className="tituloContact">
              <h1>CONTACTO</h1>
              <hr className="line3" ></hr>
            </div>
            <div className="boxContact">

              <Card>
                <Card.Header as="h5">CONTACTO - AYTO. DE {entity && entity.Nombre}</Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    <div className="map-ayto">
                      <p>
                        <b>DIRECCIÓN: </b> Plaza Blas Infante, 1.
                      </p>
                      <p>
                        <b>TELÉFONO: </b> 952 379 400.
                      </p>
                      <p>
                        <b>HORARIO DE ATENCIÓN AL PÚBLICO: </b> De Lunes a Viernes, de 9:00 a 13:30 h.
                      </p>
                    </div>
                    <iframe title="video" className="iframe-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3202.2151387361087!2d-4.507736775084429!3d36.62120776160326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd72fb8e25e6d8db%3A0x85aeeded5d94e3a1!2sAyuntamiento+de+Torremolinos!5e0!3m2!1ses!2ses!4v1557229043505!5m2!1ses!2ses" allowFullScreen></iframe>
                  </Card.Text>
                </Card.Body>
              </Card>

            </div>
          </div>
        </div>
      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users, entities } = state;
  const { user } = users;
  const { entity } = entities;

  return {
    user,
    entity
  };
}

const connectedContactAytoPageCh = connect(mapStateToProps)(ContactAytoPageCh);
export { connectedContactAytoPageCh as ContactAytoPageCh };