import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import { forwardTo } from '../../helpers'
import './header.css';

/* ICONS */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faPlusCircle, faSignOutAlt, faMapMarked, faList, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
/* ICONS */

import { useTranslation } from 'react-i18next';
import { incidentsActions } from './../../actions/incidents.actions';

export const Header_180 = props => {
    const { users, dispatch, entity } = props;
    const { t } = useTranslation();

    const logOut = () => {
        dispatch(incidentsActions.clearIncidents());
        forwardTo('/login')
    }

    return (
        <div id="header" className="header_180">
            <Navbar className="menu " sticky="top" expand="lg">
                <Col sm={8}>
                    <Navbar.Brand className="iconBrand_143" target="_blank" rel="noopener noreferrer" href="https://www.lastorresdecotillas.es">
                        <img alt="Logo Las Torres de Cotillas" className="img-Header_180" src={entity.LogoWebCiudadanos} />
                    </Navbar.Brand>
                </Col>
                <Navbar.Toggle className="buttomToggle" aria-controls="basic-navbar-nav" ></Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                    </Nav>
                    <Nav className="menu2" >
                    {users.loggedIn &&
              <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faExclamationCircle} />{t("nav.navInc")}</>} id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => forwardTo('/new-incident')}><FontAwesomeIcon icon={faPlusCircle} />{t("nav.nav6-1")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => forwardTo('/incidents')}><FontAwesomeIcon icon={faList} />{t("nav.nav6-2")}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => forwardTo('/nearby')}><FontAwesomeIcon icon={faMapMarked} />{t("nav.nav6-3")}</NavDropdown.Item>
              </NavDropdown>
            }
                        {!users.loggedIn ?
                            <Nav.Link onClick={() => forwardTo('/login')}><FontAwesomeIcon icon={faUser} />{t("nav.nav5")}</Nav.Link>
                            :
                            <NavDropdown className="expadir-menu" title={<><FontAwesomeIcon icon={faUser} />{t("nav.nav6")}</>} id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => forwardTo('/settings')}>{t("nav.nav6-4")}</NavDropdown.Item>
                                <NavDropdown.Item onClick={logOut}><FontAwesomeIcon icon={faSignOutAlt} />{t("nav.nav6-5")}</NavDropdown.Item>
                            </NavDropdown>
                        }
                        <Nav.Link className="nuevo-aviso" onClick={() => forwardTo('/new-incident')}><FontAwesomeIcon icon={faPlusCircle} />Nueva Queja o Sugerencia</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
}
export default Header_180;