import React from 'react';
import './HomePage.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

export const SectionVideo = props => {
    const { t } = props;

    return (
        <div id="section-video">
            <div className="section-video-gecor">
                <Container>
                    <h3 tabIndex="13" className="card-titulo">{t('home.box1-title')}</h3>
                    <Row>
                        <Col md={6}>
                            <iframe tabIndex="14" title="video" className="iframe-img" width="100%" height="100%"
                                src={t('home.citizen-video')} frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen=""></iframe>
                        </Col>
                        <Col md={6}>
                            <div className="video-text">
                                <p tabIndex="15" dangerouslySetInnerHTML={{ __html: t('home.box1-p1') }}></p>
                                <p tabIndex="16" dangerouslySetInnerHTML={{ __html: t('home.box1-p2') }}></p>
                                <p tabIndex="17" dangerouslySetInnerHTML={{ __html: t('home.box1-p3') }}></p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default SectionVideo;