import React from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import './RulesPage.css';
import { DynamicBackground } from './../../components'
import { isWebpSupported } from 'react-image-webp/dist/utils';

class RulesPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {

    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/bg-rules.webp" : "https://gecorcloud.com/images/bg-rules.jpg"}>
        <div className="container">
             <div className="rules-card">
              <Card>
                <Card.Header as="h4" className="title-rules">CONCURSO DE IDEAS GECOR</Card.Header>
                <Card.Body className="body-rules">
                  <Card.Text>

                    <div className="rule-txt">
                      <br></br>
                        <div className="title-rule">
                            <h1><b>¡Con Gecor, tus ideas tienen premio!</b></h1>
                        </div>
                        <br></br>
                        <div className="txt-body-rules">
                            <p>
                              Queremos conocer tu opinión y que nos ayudes a construir Gecor. Cuéntanos tu idea para mejorar Gecor y hacerlo más útil para los ciudadanos y ciudadanas. Si aún no nos conoces, descárgate nuestra App y empieza a colaborar mejorando la calidad de vida en tu ciudad. 
                            </p>
                        </div>
                        <div className="download-rules">
                          <a href="https://apps.apple.com/es/app/gecor/id1127259219" alt="download-appstore" target="_blank" rel="noopener noreferrer">
                            <img src={isWebpSupported() ? "https://gecorcloud.com/images/app-store-ico.webp" : "https://gecorcloud.com/images/app-store-ico.png"} alt="download-appstore"/>
                          </a>
                          <a href="https://play.google.com/store/apps/details?id=com.gecorsystem.gecorn&hl=es" alt="download-googleplay" target="_blank" rel="noopener noreferrer">
                            <img src={isWebpSupported() ? "https://gecorcloud.com/images/google-play-ico.webp" : "https://gecorcloud.com/images/google-play-ico.png"} alt="download-googleplay"/>
                          </a>
                        </div>
                        <div className="title-rule2">
                            <h1><b>Premios:</b></h1>
                        </div>
                        <br></br>
                        <div className="awards-rules">
                            <Row>
                                <Col sm={12} className="award1">
                                    <img src="https://gecorcloud.com/images/ipad2.png" alt="netflix-premio"></img>
                                </Col>
                            </Row>
                        </div>
                        <br></br>
                        <div className="prem-txt">
                          <p>
                              Primer Premio: <b>IPad</b>
                          </p>
                          <p>
                              Segundo Premio: <b>Tarjeta Regalo Netflix 25€ </b>
                          </p>
                          <p>
                              Tercer Premio: <b>Tarjeta Regalo Netflix 15€ </b>
                          </p>
                        </div>
                        <br></br>
                        <div className="txt-tit4">
                          <p>
                              Para participar, visítanos en nuestras redes sociales disponibles y sigue los pasos:
                          </p>
                        </div>
                        <div className="rule-comp-ico">
                            <a href="https://play.google.com/store/apps/details?id=com.gecorsystem.gecorn&hl=es/gecor/id1127259219" target="_blank" rel="noopener noreferrer"><img src="https://gecorcloud.com/images/rule-icon1.png" alt="icon-facebook"/></a>
                            <a href="https://apps.apple.com/es/app/gecor/id1127259219" target="_blank" rel="noopener noreferrer"><img src="https://gecorcloud.com/images/rule-icon2.png" alt="icon-twitter"/></a>
                        </div>

                        <br></br>
                        <div className="txt-tit4">
                          <p>
                              Las 5 mejores ideas, seleccionadas por Gecor, se someterán a votación pública para decidir la ganadora. 
                          </p>
                        </div>
                        <div className="lucky">
                          <p>
                              <h1><b>¡Suerte!</b></h1>
                          </p>
                        </div>
                    </div>

                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
        </div>
      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { entities } = state;
  const { entity } = entities;
  return {
    entity
  };
}

const connectedRulesPage = connect(mapStateToProps)(RulesPage);
export { connectedRulesPage as RulesPage };