import React from 'react';
import './Footer.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

export const FooterComponent_294e = props => {
    const { t } = useTranslation();

    return (
        <div id="footer">
            <footer >
                <div className="enviromental-footer">
                    <Container>
                        <Row>
                            <Col sm={4}>
                                <div className="footer-about">
                                    <Row>
                                        <h3 className="footer-informacion-Ln">La Línea</h3>
                                    </Row>
                                    <p>{t('footer.lalinea-2')}</p>
                                    <p>Powered by GECOR.</p>

                                </div>
                            </Col>
                            <Col sm={8}>
                                <div className="badge-ln">
                                    <p>{t("footer.feder-1")}</p>
                                    <p>{t("footer.feder-2")}</p>
                                    <br></br>
                                    <p><h2>"{t("footer.feder-3")}"</h2></p>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </footer>
        </div>
    );
}

export default FooterComponent_294e;