import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import CardGroup from 'react-bootstrap/CardGroup';
import { forwardToWithParams, forwardTo } from "../../../../helpers";
import './MenuRojalesPage.css';
import { EntitySearch, DynamicBackground } from '../../../../components'
import { isWebpSupported } from 'react-image-webp/dist/utils';
import "react-multi-carousel/lib/styles.css";
import { NoticesCarousel } from "./../../../../components/NoticesCarousel/NoticesCarousel"

class MenuRojalesPage extends React.Component {
  //CARGAR AYUNTAMIENTO POR PARAMETRO AO
  constructor(props) {
    super(props);

    this.state = {
      aytoName: '',
      ao: { ao: 10423 },
    };
    this.handleSelectedEntity = this.handleSelectedEntity.bind(this);
  }

  handleSelectedEntity(entity) {
    this.setState({ aytoName: entity.Nombre });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {
    const backgroundImage = {
      backgroundImage: 'url(\'./images/rojales/bg-rojales.jpg\')',
    };
    const { ao } = this.state;
    
    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0)" background={isWebpSupported() ? "" : ""}>
        <div className="envi-menu2" id="home-alcala">
          <EntitySearch className="ayto-borde" redirect={false} onSelected={this.handleSelectedEntity} ao={ao} hide={true} />
          <div className="container-main-img" style={backgroundImage}>
            <Image src="./images/rojales/logo-rojales.png" className="escudo"></Image>
            <div className="container-main-img-r">
              <h3>AYUNTAMIENTO DE ROJALES</h3>
              <hr />
              <h5>PORTAL</h5>
              <h5>MEDIOAMBIENTAL</h5>
            </div>
          </div>
          <div id="homeAlc">
            {/*INICIO NOTICIAS*/}
            <Container id="noticias">
              <Row className="cards-alc">
                <Col md={12}>
                  <NoticesCarousel AytoID={this.state.ao.ao} />
                </Col>
              </Row>
            </Container>
            {/*FIN NOTICIAS*/}
            {/*INICIO INFORMACION AMBIENTAL*/}
            <Row className="section-info-ambiental-rojales" id="info-ambiental">
              <Col sm={12}>
                  <div className="title-valverde-2">
                    <h1>Información local</h1>
                    <hr></hr>
                  </div>
              </Col>
            </Row>
            <Row className="section-info-ambiental-rojales" id="info-ambiental">
              <Col sm={12}>
                <Container>
                  <CardGroup>
                    <Card className="card-valverde">
                      <Card.Img variant="top" src="https://gecorcloud.com/images/valverde/hidrologia.jpg" />
                      <Card.Body>
                        <Card.Title>Consultas</Card.Title>
                        <Card.Text>
                        Realice su consulta ambiental, le atenderemos en la mayor brevedad posible.
                        </Card.Text>
                        <Button variant="primary" onClick={() => forwardTo('/contact')}>Contacto</Button>
                      </Card.Body>
                    </Card>
                    <Card className="card-valverde">
                      <Card.Img variant="top" src="https://gecorcloud.com/images/rojales/residuos-rojales.jpg" />
                      <Card.Body>
                        <Card.Title>Residuos</Card.Title>
                        <Card.Text>
                          Acceda a toda la información sobre el tratamiento de residuos.
                        </Card.Text>
                        <Button variant="primary" onClick={() => forwardToWithParams('/informacion-rojales', {eventKey: 'first'})}>Leer mas</Button>
                      </Card.Body>
                    </Card>
                    <Card className="card-valverde">
                      <Card.Img variant="top" src="https://gecorcloud.com/images/valverde/flora.jpg" />
                      <Card.Body>
                        <Card.Title>Espacios protegidos y Vías pecuarias</Card.Title>
                        <Card.Text>
                          Información sobre los espacios protegidos y vías de Rojales.
                        </Card.Text>
                        <Button variant="primary" onClick={() => forwardToWithParams('/informacion-rojales', {eventKey: 'second'})}>Leer mas</Button>
                      </Card.Body>
                    </Card>
                    <Card className="card-valverde">
                      <Card.Img variant="top" src="https://gecorcloud.com/images/valverde/rutas.jpg" />
                      <Card.Body>
                        <Card.Title>Parque canino</Card.Title>
                        <Card.Text>
                          Información sobre el parque canino disponible en Rojales.
                        </Card.Text>
                        <Button variant="primary" onClick={() => forwardToWithParams('/informacion-rojales', {eventKey: 'third'})}>Leer mas</Button>
                      </Card.Body>
                    </Card>
                  </CardGroup>
                </Container>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className='ordenanzas-rojales'>
                  <div className='tit-ordenanzas-rojales'>
                    ORDENANZAS
                  </div>
                  <Row>
                    <Col sm={4}>
                      <div className='img-ordenanzas-rojales'>
                        <img className='src-ordenanzas-rojales' src="https://gecorcloud.com/images/rojales/lista-de-verificacion.png" alt="ordenanzas-rojales"></img>
                      </div>
                    </Col>
                    <Col sm={8}>
                      <div className='txt-ordenanzas-rojales'>
                      <ListGroup>
                        <ListGroup.Item onClick={() => window.open('https://gecorcloud.com/pdf/Rojales/Ordenanza-reguladora-de-la-tenencia-de-animales-de-compania-y-potencialmente-peligrosos.pdf')}>Ordenanza Reguladora de la Tenencia de Animales de Compañía y Potencialmente Peligrosos.</ListGroup.Item>
                        <ListGroup.Item onClick={() => window.open('https://gecorcloud.com/pdf/Rojales/ORDENANZA-REGULADORA-DE-LA-DENOMINACIÓN-Y-ROTULACIÓN-DE-VÍAS-PÚBLICAS-Y-NUMERACIÓN-DE-EDIFICIOS-EN-EL-MUNICIPIO-DE-ROJALES.pdf')}>Ordenanza Reguladora de la Denominación y Rotulación de Vías Públicas y Numeración de Edificios</ListGroup.Item>
                        <ListGroup.Item onClick={() => window.open('https://gecorcloud.com/pdf/Rojales/Reglamento-regulador-del-servicio-de-abastecimiento-de-agua-potable.pdf')}>Reglamento Regulador del Servicio de Abastecimiento de Agua Potable.</ListGroup.Item>
                        <ListGroup.Item onClick={() => window.open('https://gecorcloud.com/pdf/Rojales/Reglamento-regulador-del-servicio-de-alcantarillado.pdf')}>Reglamento de Prestación del Servicio de Alcantarillado.</ListGroup.Item>
                        <ListGroup.Item onClick={() => window.open('https://gecorcloud.com/pdf/Rojales/REGLAMENTO-REGULADOR-HUERTOS-URBANOS.pdf')}>Reglamento Regulador del Procedimiento de Otorgamiento de Autorizaciones de Dominio Público y del Régimen Jurídico de los Huertos Urbanos.</ListGroup.Item>
                      </ListGroup>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="section-info-ambiental-rojales" id="info-ambiental">
              <Col sm={12}>
                <div className="title-valverde-2">
                    <h1>Enlaces de interés</h1>
                </div>
              </Col>
            </Row>
            {/*INICIO ENLACES INTERÉS*/}
            <Row className="section-enlaces-interes" id="enlacesInteres" >
              <Col md={12}>
                <br></br>
                <div className="container-enlaces-interes">
                <div className="item-enlace-interes">
                    <Image src="https://gecorcloud.com/images/logo_gecor.png" alt="Imagen gecor" />
                    <div className="item-enlace-interes-des" onClick={() => forwardTo('/login', '?ao=10423')}>
                      <h4>GECOR</h4>
                      <h5>Participa en la gestión de incidencias en la vía pública con la app GECOR.</h5>
                    </div>
                  </div>
                  <div className="item-enlace-interes">
                    <Image src="https://gecorsystem.blob.core.windows.net/public/info/otros/Fotos/2021/01/banner protocolo NOX_mod.jpg" alt="imagen protocolo NOx" />
                    <div className="item-enlace-interes-des" onClick={() => window.open('http://www.rojales.es/', '_blank')}>
                      <h4>Web del Ayuntamiento</h4>
                      <h5>Enlace a la web del ayuntamiento y a todos sus servicios</h5>
                    </div>
                  </div>
                  <div className="item-enlace-interes">
                    <Image src="https://gecorcloud.com/images/pl-alc-med.png" alt="Imagen punto limpio móvil" />
                    <div className="item-enlace-interes-des" onClick={() => window.open('https://rojales.sedelectronica.es/info.0', '_blank')}>
                      <h4>Sede Electrónica</h4>
                      <h5>Acceda a la sede electrónica del Ayuntamiento de Rojales</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/*FIN ENLACES INTERÉS*/}
          </div>
        </div>
      </DynamicBackground >
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedMenuRojalesPage = connect(mapStateToProps)(MenuRojalesPage);
export { connectedMenuRojalesPage as MenuRojalesPage };