import { socialConstants } from '../constants';

const initialState = {
  user: null
}

export function social(state = initialState, action) {
  switch (action.type) {
    case socialConstants.SOCIAL_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user
      }
    case socialConstants.SOCIAL_LOGOUT:
      return {
        ...state,
        user: initialState.user
      }
    case socialConstants.SOCIAL_LOGIN_FAILURE:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}