import React from 'react';
import { connect } from 'react-redux';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import './EnviromentalInformationPagePr.css';
import { DynamicBackground } from '../../../../components'
import { TimelineLite } from "gsap/all";
import { isWebpSupported } from 'react-image-webp/dist/utils';

class EnviromentalInformationPagePr extends React.Component {

  constructor(props) {
    super(props);
    this.logoTx = new TimelineLite({ paused: true });

    this.content = null;
    this.head = null;
    this.subhead = null;
    this.feature = null;
    // this.description = null;
    this.icons = [];
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logoTx
      // .set(this.content, { autoAlpha: 1 })// show content div
      //	.from(this.head, 0.5, { left: 100, autoAlpha: 0 })
      .from(this.subhead, 0.5, { left: -100, autoAlpha: 0 }, "-=0.25") // added -0.25 seconds prior to end this.of timeline
      // .from(this.feature, 0.5, { scale: .5, autoAlpha: 0 }, "feature") // added 0.5 seconds after end of timeline
      //.from(this.description, 0.5, { left: 100, autoAlpha: 0 }, "feature+=0.25")
      .staggerFrom(this.icons, 0.2, { scale: 0, autoAlpha: 0 }, 0.1); //animate all icons with 0.1 second stagger

    this.logoTx.play()
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {

    return (
      <DynamicBackground customOverlay="rgba(255, 255, 255, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/bgAmbi7.webp" : "https://gecorcloud.com/images/bgAmbi7.jpg"}>
        <div className="container margin-bottom">

          <div className="environ">

            <div className="enviBoxPr" ref={div => this.subhead = div}>

              <div className="infoTit">
                <h1><b>ESPECIES AMBIENTALES</b></h1>
                <hr className="line" ></hr>
              </div>

              {/*   Box 1   

              <Card>
                <Card.Header as="h5">Salud Pública</Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    <Row>
                      <Col md={{ span: 4, offset: 0 }}>
                        <img src={isWebpSupported() ? "https://gecorcloud.com/images/health-report.webp" : "https://gecorcloud.com/images/health-report.png"} alt="avispas" />
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col sm={12}>
                            <p className="text" >Controles de calidad de las aguas de abasto, aguas de baño en  playas , aguas regeneradas, controles sanitarias de alimentos ,en colaboración con el área de salud de Fuerteventura, control de animales de compañía, campañas de vacunación antirrábica e identificación, expedición de licencias para la tenencia de perros potencialmente peligroso ,etc.
                            </p>
                            <hr className="line" ></hr>
                            <a href="http://www.puertodelrosario.org/index.php/es/2013-07-02-12-47-05/salud-publica" target="_blank" rel="noopener noreferrer" className="enlace" >Ir a Salúd Pública</a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
*/}

              {/*   Box 1   */}

              <Card>
                <Card.Header as="h5">¡En defensa de las Abejas!</Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    <Row>
                      <Col md={{ span: 4, offset: 0 }}>
                        <img src={isWebpSupported() ? "https://gecorcloud.com/images/envir1.webp" : "https://gecorcloud.com/images/envir1.png"} alt="avispas" />
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col sm={12}>
                            <p className="text" >Éste artículo va especialmente dirigido a aquellas personas que odian y temen a las abejas, y haré mi mejor esfuerzo por mejorar su terrible
                              imagen de “insectos malvados”. Si usted las detesta, no le culpo ...
                            </p>
                            <hr className="line" ></hr>
                            <a href="https://gecorcloud.com/pdf/avispas.pdf" target="_blank" rel="noopener noreferrer" className="enlace" >"¡En defensa de las ABEJAS!"</a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>


              {/*   Box 2   */}

              <Card>
                <Card.Header as="h5">Animales en peligro de extinción</Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    <Row>
                      <Col md={{ span: 4, offset: 0 }}>
                        <img src={isWebpSupported() ? "https://gecorcloud.com/images/envir2.webp" : "https://gecorcloud.com/images/envir2.png"} alt="extincion" />
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col sm={12}>
                            <p className="text" >Aunque la vida silvestre en España es bastante rica y
                              abarca gran parte del país existen entre sus ejemplares animales que se encuentran en peligro de extinción bien ...
                            </p>
                            <hr className="line" ></hr>
                            <a href="https://gecorcloud.com/pdf/5especies.pdf" target="_blank" rel="noopener noreferrer" className="enlace" >"Animales en peligro de extinción en España"</a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>

              {/*   Box 3   */}

              <Card>
                <Card.Header as="h5">Cuatro acciones que ayudarán a “resucitar” el planeta</Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    <Row>
                      <Col md={{ span: 4, offset: 0 }}>
                        <img src={isWebpSupported() ? "https://gecorcloud.com/images/envir3.webp" : "https://gecorcloud.com/images/envir3.png"} alt="planeta" />
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col sm={12}>
                            <p className="text" >
                              Una motivación similar debería llevar a los ciudadanos a generar un cambio para revivir un planeta ...
                            </p>
                            <hr className="line" ></hr>
                            <a href="https://gecorcloud.com/pdf/Cuatro acciones que ayudaran.pdf" target="_blank" rel="noopener noreferrer" className="enlace" >"Cuatro acciones que ayudarán a “resucitar” el planeta"</a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>

              {/*   Box 4   */}

              <Card>
                <Card.Header as="h5">El arte de no ser detectado</Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    <Row>
                      <Col md={{ span: 4, offset: 0 }}>
                        <img src={isWebpSupported() ? "https://gecorcloud.com/images/envir9.webp" : "https://gecorcloud.com/images/envir9.png"} alt="camuflaje" />
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col sm={12}>
                            <p className="text"> El mimetismo y el camuflaje han existido desde que los animales evolucionaron,
                            y han formado parte de la naturaleza por siempre como una herramienta de supervivencia...
                            </p>
                            <hr className="line" ></hr>
                            <a href="https://gecorcloud.com/pdf/ArteNoSerDetectado.pdf" target="_blank" rel="noopener noreferrer" className="enlace" >"El arte de no ser detectado"</a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>


              {/*   Box 5   */}

              <Card>
                <Card.Header as="h5">Insectos que adoran los libros</Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    <Row>
                      <Col md={{ span: 4, offset: 0 }}>
                        <img src={isWebpSupported() ? "https://gecorcloud.com/images/envir5.webp" : "https://gecorcloud.com/images/envir5.png"} alt="libros" />
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col sm={12}>
                            <p className="text" >Los pececillos de plata son insectos que pueden literalmente devorar libros y revistas,
                           aunque también gustan del papel tapiz y de algunas de nuestras más preciadas y antiguas fotografías que cuelgan en...
                            </p>
                            <hr className="line" ></hr>
                            <a href="https://www.20minutos.es/noticia/1484056/0/insectos-casa/como-acabar-con/pececillos-plata/" target="_blank" rel="noopener noreferrer" className="enlace" >"Insectos que adoran los libros"</a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>

              {/*   Box 6   */}

              <Card>
                <Card.Header as="h5">Conciencia ambiental</Card.Header>
                <Card.Body>
                  <Card.Text as="div">
                    <Row>
                      <Col md={{ span: 4, offset: 0 }}>
                        <img src={isWebpSupported() ? "https://gecorcloud.com/images/envir4.webp" : "https://gecorcloud.com/images/envir4.png"} alt="ambiente" />
                      </Col>
                      <Col md={8}>
                        <Row>
                          <Col sm={12}>
                            <p className="text" >El desarrollo sostenible es la capacidad de una sociedad para cubrir las necesidades
                         básicas de las personas sin perjudicar el ecosistema ni ocasionar daños en el medio ambiente. De este modo...
            </p>
                            <hr className="line" ></hr>
                            <a href="https://gecorcloud.com/pdf/ConcienciaAmbiental.pdf" target="_blank" rel="noopener noreferrer" className="enlace" >"Conciencia ambiental"</a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedEnviromentalInformationPagePr = connect(mapStateToProps)(EnviromentalInformationPagePr);
export { connectedEnviromentalInformationPagePr as EnviromentalInformationPagePr };