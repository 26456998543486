import { apiUrl, defaultEmail, defaultAyuntamientoID } from '../../constants';

export const contactApi = {

    /**
    * Registers a user and then logs them in
    * @param  {string} id The ayuntamientoID
    */
    sendMail(subject, body, too = defaultEmail, ayuntamientoID = defaultAyuntamientoID) {

        if (localStorage.entity) {
            const { AyuntamientoID } = JSON.parse(localStorage.entity)
            ayuntamientoID = AyuntamientoID
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ subject, body, ayuntamientoID, too })
        };

        return fetch(`${apiUrl}/Utils/sendEmail`, requestOptions)
            //.then(response => response.json())
            .then(resp => {
                return resp
            });
    }
}

export default contactApi