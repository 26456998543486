import React from 'react';

export const DynamicBackground = props => {
    const { background, customOverlay } = props
    const overlay = customOverlay ? `linear-gradient(${customOverlay}, ${customOverlay}),` : ""
   
    return (
        <div style={{ 
            background: `${overlay} url("${background}")  no-repeat center center fixed `, 
            'WebkitBackgroundSize': 'cover',
            'MozBackgroundSize': 'cover',
            'OBackgroundSize': 'cover',
            'backgroundSize': 'cover',
            'height': '100%',     
        }}>
            {props.children}
        </div>
    );
}

export default DynamicBackground;