import { take, call, put, fork } from 'redux-saga/effects'
import { surveysApi } from '../helpers'
import { surveysConstants } from './../constants'

/**
 * Effect to handle logging out
 */
export function* getSurveys() {

    try {
        const response = yield call(surveysApi.getSurveys)
        yield put({ type: surveysConstants.GET_MY_SUCCESS, mySurveys: response })       
        return response
    } catch (error) {
        yield put({ type: surveysConstants.GET_MY_FAILURE, error: error.message })
        return false
    }
}

export function* getSurveyDetail(payload) {

    try {
        const { EncuestaID } = payload;
        const response = yield call(surveysApi.getSurveyDetail,EncuestaID)
        yield put({ type: surveysConstants.GET_SURVEY_DETAIL_SUCCESS, surveyDetail: response })
        return response
    } catch (error) {
        yield put({ type: surveysConstants.GET_SURVEY_DETAIL_FAILURE, error: error.message })
        return false
    }
}

export function* sendSurvey(data) {
    try {
        const response = yield call(surveysApi.sendSurvey, data)
        yield put({ type: surveysConstants.SEND_SURVEY_SUCCESS, sendSurvey: response })
        return response
    } catch (error) {
        yield put({ type: surveysConstants.SEND_SURVEY_FAILURE, error: error.message })
        return false
    }
}

// FLOWS //
export function* getSurveysFlow() {
    while (true) {                
        yield take(surveysConstants.GET_MY_REQUEST)
        yield call(getSurveys)
    }
}


export function* getSurveyDetailFlow() {
    while (true) {
        const payload = yield take(surveysConstants.GET_SURVEY_DETAIL_REQUEST)
        yield call(getSurveyDetail, payload)
    }
}

export function* sendSurveyFlow() {
    while (true) {
        const { data } = yield take(surveysConstants.SEND_SURVEY_REQUEST)
        yield call(sendSurvey, data)
    }
}

export default function* () {
    yield fork(getSurveysFlow)    
    yield fork(getSurveyDetailFlow)     
    yield fork(sendSurveyFlow)    
}
