import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import './ForgotPassPage.css';
import { DynamicBackground } from './../../components'
import { withTranslation } from 'react-i18next';
import { isWebpSupported } from 'react-image-webp/dist/utils';

class ForgotPassPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));
  }

  render() {

    const { email, t } = this.props;

    return (
      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/michelangelo-71282_960_720.webp" : "https://gecorcloud.com/images/71282_960_720.jpg"}>
        <div className="container">

          <div className="enviBox">
            <div className="tituloContact">
              <h1>GECOR</h1>
              <hr className="line3" ></hr>
            </div>
            <div className="boxContact">

              <Card>
                <Card.Header as="h5">{t('forgotpass.t')}</Card.Header>
                <Card.Body>
                  <Card.Text>
                    {email && email.resetPassword}
                  </Card.Text>
                </Card.Body>
              </Card>

            </div>
          </div>
        </div>
      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { entities } = state;
  const { entity } = entities;
  return {
    entity
  };
}
const tForgotPassPage = withTranslation()(ForgotPassPage);
const connectedForgotPassPage = connect(mapStateToProps)(tForgotPassPage);
export { connectedForgotPassPage as ForgotPassPage };