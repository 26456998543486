import React from 'react';
import { connect } from 'react-redux';
import './LocalInformationPage.css';
import { DynamicBackground } from './../../../../components'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import { TimelineLite } from "gsap/all";
import { isWebpSupported } from 'react-image-webp/dist/utils';

class LocalInformationPage extends React.Component {

  constructor(props) {
    super(props);
    this.logoTl = new TimelineLite({ paused: true });
    this.subhead = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.logoTl
      .set(this.content, { autoAlpha: 1 })// show content div
      .from(this.head, 0.5, { left: 100, autoAlpha: 0 })
      .from(this.subhead, 0.5, { left: -100, autoAlpha: 0 }, "-=0.25") // added -0.25 seconds prior to end this.of timeline
    this.logoTl.play()
  }

  handleDeleteUser(id) {
    //return (e) => this.props.dispatch(userActions.delete(id));

  }

  render() {

    return (

      <DynamicBackground customOverlay="rgba(0, 0, 0, 0.6)" background={isWebpSupported() ? "https://gecorcloud.com/images/bgLocal.webp" : "https://gecorcloud.com/images/bgLocal.jpg"}>

        <div className="container">
          <div className="infoBox " ref={div => this.subhead = div}>
            <div className="infoTit">
              <h1><b>INFORMACIÓN LOCAL</b></h1>
              <hr className="line" ></hr>
            </div>
            {/*   Box 1   */}
            <Card >
              <Card.Header as="h5" >ORDENANZAS</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={6} >
                      <Row>
                        <Col sm={12} >
                          <p> {/* ref={ p => this.description = p } */}
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/1.1.pdf">
                              Ordenanza Municipal obre Conservación, Rehabilitación y Estado Ruinoso de las Edificaciones.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/1.2.pdf">
                              Ordenanza de Protección y Defensa de la Convivencia Ciudadana y de los Bienes de Domino Público Municipal.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/1.3.pdf">
                              Ordenanza de Protección y Tenencia de Animales.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/1.4.pdf">
                              Ordenanza Reguladora del uso y Protección de Zonas Verdes y de Masa Arbórea.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/1.5.pdf">
                              Ordenanza Reguladora de los Horarios de Establecimientos y Espectáculos Públicos y Actividades Recreativas.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/1.6.pdf">
                              Ordenanza Sobre Condiciones de Protección Contra Incendios
                            </a>
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 6, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/info1.webp" : "https://gecorcloud.com/images/info1.png"} alt="info1" ref={img => this.feature = img} />
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            {/*   Box 2   */}

            <Card ref={card => this.content = card}>
              <Card.Header as="h5" ref={h5 => this.head = h5}>RESIDUOS</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 6, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/info2.webp" : "https://gecorcloud.com/images/info2.png"} alt="info2" />
                    </Col>
                    <Col md={6} >
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/2.1.pdf">
                              Ordenanza de Protección y Defensa de la Convivencia Ciudadana y de los Bienes de Domino Público Municipal.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://www.boe.es/diario_boe/txt.php?id=BOE-A-2002-3285">
                              Lista Europea de residuos
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/2.3.pdf">
                              Generalidades Residuos.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/2.4.pdf">
                              Residuos de Jardinería y Muebles.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/2.5.pdf">
                              Residuos Sólidos Urbanos.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/2.6.pdf">
                              Residuos Reciclaje.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <h5>¿Qué es un gestor de residuos?</h5>
                              <p>
                                <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="http://www.juntadeandalucia.es/medioambiente/site/portalweb/menuitem.7e1cf46ddf59bb227a9ebe205510e1ca/?vgnextoid=efd43470956ad310VgnVCM2000000624e50aRCRD&vgnextchannel=3a3edab304ae1410VgnVCM2000000624e50aRCRD">
                                  Información general.
                                </a>
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>


            {/*   Box 3   */}

            <Card>
              <Card.Header as="h5">INFORMACIÓN AMBIENTAL</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <p>
                                Es la información del ambiente, los recursos naturales o culturales y el desarrollo sustentable.
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/3.1.pdf">
                              Folleto Senderos Torremolinos.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/3.2.pdf">
                              Control de Plagas.
                             </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/3.3.pdf">
                              Medio Físico, Flora y Fauna.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/3.5.pdf">
                              Educación Ambiental Para Colegios.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/3.6.ProgramaConservacionyCriaAutilloFinal.pdf">
                              Programa de Conservación y Cría en Cautividad del Autillo Europeo (Otus Scops).
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <h5>Medio ambiente</h5>
                              <hr className="line" ></hr>
                              <p>
                                <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/3.7.Tramites.pdf">
                                  Medio ambiente.
                                </a>
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 6, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/info3.webp" : "https://gecorcloud.com/images/info3.png"} alt="info3" />
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>


            {/*   Box 4   */}

            <Card>
              <Card.Header as="h5">PARQUES Y JARDINES</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 6, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/info4.webp" : "https://gecorcloud.com/images/info4.png"} alt="info4" />
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <h4>Zonas Verdes:</h4>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/4.1.Parques y Jardines.pdf">
                              Parques y Jardines.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/4.2.Jardin Botanico Molino de Inca.pdf">
                              Jardín Botánico "Molino del Inca".
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <h5>Información</h5>
                              <hr className="line" ></hr>
                              <p>
                                El Servicio de Parques y Jardines del Ayuntamiento de Torremolinos es el encargado de la conservación y mantenimiento de las zonas verdes, ya sean zonas ajardinadas o parques urbanos de la ciudad.
                              </p>
                            </div>
                          </div>
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            {/*   Box 5   */}

            <Card>
              <Card.Header as="h5">AGUAS Y SANEAMIENTOS</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/5.1Aguas y Saneamiento.pdf">
                              Aguas y Saneamiento.
                            </a>
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <h5>Información</h5>
                              <hr className="line" ></hr>
                              <p>
                                En relación al tratamiento de las aguas residuales urbanas, se atiene a lo establecido en el Real Decreto 1290/2012, de 7 de septiembre, por el que se modifica el Reglamento del Dominio Público Hidráulico, aprobado por el Real Decreto 849/1986, de 11 de abril y el Real Decreto 509/1996, de 15 de marzo, de desarrollo del Real Decreto-ley 11/1995, de 28 de diciembre, por el que se establecen las normas aplicables al tratamiento de las aguas residuales urbanas.
                              </p>
                            </div>
                          </div>
                        </Col>

                      </Row>
                    </Col>
                    <Col md={{ span: 6, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/info5.webp" : "https://gecorcloud.com/images/info6.png"} alt="info5" />
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>

            {/*   Box 6   */}

            <Card>
              <Card.Header as="h5">SALUD PÚBLICA</Card.Header>
              <Card.Body>
                <Card.Text as="div">
                  <Row>
                    <Col md={{ span: 6, offset: 0 }}>
                      <img src={isWebpSupported() ? "https://gecorcloud.com/images/info6.webp" : "https://gecorcloud.com/images/info6.png"} alt="info6" />
                    </Col>
                    <Col md={6}>
                      <Row>
                        <Col sm={12}>
                          <p>
                            <a className="linkslocal" target="_blank" rel="noopener noreferrer" href="https://gecorcloud.com/pdf/6.1.Salud Publica.pdf">
                              Salud Pública.
                            </a>
                          </p>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12}>
                          <div className="plocal">
                            <div className="textColorInfo">
                              <h5>Información</h5>
                              <hr className="line" ></hr>
                              <p>
                                La definición de la Organización Mundial de la Salud (OMS) de salud como: “Un estado de completo bienestar físico, mental y social, y no solamente la ausencia de enfermedad o dolencia”, a pesar de contener un enfoque social y abarcar más allá del ámbito sanitario, no deja de ser utópica e inalcanzable. La salud no puede estar asociada a un completo y permanente bienestar, ya que, entendiendo así el concepto de salud, se debería admitir que nadie tiene la posibilidad de estar sano.
                              </p>
                            </div>
                          </div>
                        </Col>

                      </Row>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>


          </div>
        </div>

      </DynamicBackground>
    );
  }
}

const mapStateToProps = (state) => {
  const { users } = state;
  const { user } = users;
  return {
    user
  };
}

const connectedLocalInformationPage = connect(mapStateToProps)(LocalInformationPage);
export { connectedLocalInformationPage as LocalInformationPage }; 